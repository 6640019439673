import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
 const Editorformats = [
    // 'header', 'font', 'size',
    'bold', 'italic','color', 'underline', 'blockquote',
    'list', 'bullet',
    'link', 'image', 'video'
  ]
   const Editormodules = {
    toolbar: [
    //   [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    //   [{size: []}],
      ['bold', 'italic',{color:[]}, 'underline', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, ],
      ['link', 'image', 'video'],
    //   ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
export default function TextEditor({
    
}
   
) {
      return (
        <ReactQuill 
        theme="snow"
        // onChange={this.handleChange}
        // value={this.state.editorHtml}
        modules={Editormodules}
        formats={Editorformats}
         placeholder="Heading"
       />
      )
  }
