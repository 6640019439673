import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../../../../common/dropdown";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FirstButton from "../../../../common/button";

const TechNeedSubmitResponse = () => {
  const navigate = useNavigate();
  return (
    <div className="srp_main">
      <h1 className="mpto_h1">
        Responding for Seeking in-field tools to accurately measure citrus raw
        juice quality
      </h1>
      <p className="sr_f1">
        Find out how we can help you and your business. Get in touch using the
        form below and our experts will get in contact soon! . Your first post
        will be on free trial basis.
        <Link className="srp_fw" style={{ color: "#19976A" }}>
          Learn more{" "}
        </Link>
        <br />
        Smaarket will handle your personal information in accordance with the IT
        Act, 2000 and our
        <Link className="srp_fw" style={{ color: "#19976A" }}>
          {" "}
          Privacy Policy.{" "}
        </Link>
      </p>
      <p className=" sr_f1 sr_f2">
        Remember: Don’t share anything that could be considered confidential at
        this point.
      </p>

      <div className="sr_cont">
        <div className="sr_mr">
          <p className="sr_f4">Engagement Opportunity</p>
          <Dropdown placeholder="Select" />
        </div>
        <div className="sr_mr">
          <p className="sr_f4">Description of your proposal </p>
          <p className="tnsr_f1">
            Describe in your proposal what your product, technology, process,
            procedure, research, service, or project is about without disclosing
            any confidential information. Include the stage of development,
            commercial details, and IP status.
          </p>

          <textarea
            className="precampfaq_textarea sr_textarea"
            placeholder="Type Here"
          ></textarea>
          <div>
            <p className="sr_f4 sr_mr1">Attachments</p>
            <p className="tnsr_f1">
              Add pictures and other visuals to increase the description of your
              proposal.
            </p>
            <TextField
              id="outlined-basic"
              type="file"
              inputProps={{
                multiple: true,
              }}
              style={{
                marginTop: "1rem",
                background: "#F5F5F5",
                border: "none !imporatant",
                outline: "none !important",
                width: "97.5%",
              }}
            />
          </div>
        </div>

        <div>
          <FormControlLabel
            style={{ alignItems: "start" }}
            control={
              <Checkbox
                sx={{
                  color: "#19976A",
                  "&.Mui-checked": { color: "#19976A" },
                  "& .MuiSvgIcon-root": { fontSize: 24 },
                }}
                style={{ padding: "0px 9px 0px 9px" }}
              />
            }
            labelPlacement="end"
            label={
              <span style={{ fontSize: "1.6rem", padding: "2rem 0" }}>
                I agree toSmaarket’s terms of use and read and understand
                privacy policy.
              </span>
            }
          />
        </div>
        <div className="srp_btncont">
          <FirstButton
            buttonText="Save as draft"
            className="borderbtn"
            onClick={() => navigate("/")}
          />
          <FirstButton
            buttonText="Submit Response"
            className="btn"
            onClick={() => navigate("/marketplace/techneedsuccess")}
          />
        </div>
      </div>
    </div>
  );
};

export default TechNeedSubmitResponse;
