import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import FirstButton from '../../../common/button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import Modal from '@mui/material/Modal';

const AddwatchlistModal = (props) => {

  return (
    <div>
        <Modal
        open={props.show}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='mdpd_modal'>
            <div className="mdpd_modalbtn"> <button className="mdpd_close" onClick={props.close}><CloseIcon/></button>
            </div>
            <div className="addwatchlist_modalmain">
                <h1 className="addwatchlist_h1">Be the first to know when the eyeuity campaign goes live!</h1>
                <div className="mdpd_cont2">
                    <p className='mcdt_p2'>How much would you consider investing ?</p>
                    <input type="text" className='login_input addwatchlist_select' />
                    <p className="addwatchmodal_cont">
                    <FormControlLabel control={<Checkbox sx={{color: "green",'&.Mui-checked': { color: "green",},'& .MuiSvgIcon-root': { fontSize: 24 } }} />}  labelPlacement="end"
   label={<span style={{ fontSize: '1.6rem',marginLeft:"1rem" }}>I agree  to receive emails from Smaarket and eyeuity campaign.</span>} />

                    </p>
                </div>

                <div className='addwatchlist_btncont'>
                    <FirstButton buttonText="Add to my watchlist" onClick={props.close} className="btn"/>
                </div>
            </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddwatchlistModal