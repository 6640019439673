import React from "react";
import Dropdown from "../../../../../../common/dropdown";
import Select from "react-select";
import FirstButton from "../../../../../../common/button";
import pin from "../../../../../../assets/images/icons/pin.svg";
import send from "../../../../../../assets/images/icons/send.svg";
const Discussion = () => {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
  ];

  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      background: "#f5f5f5",
      fontSize: "1.3rem",
      fontWeight: "500",
      padding: ".6rem 0",
    }),

    menuList: (styles) => ({
      ...styles,
      background: "#fff",
      borderRadius: "5px",
      boxShadow: "0 1rem 2rem rgba(0,0,0,.1)",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      fontSize: "1.3rem",
      background: isFocused ? "#19976A" : isSelected ? "#fff" : undefined,
      zIndex: 1,
      color: isFocused ? "#fff" : isSelected ? "#000" : undefined,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <>
      <div className="discussion">
        <div className="discussion_container">
          <h1 className="dis_primary">Questions from community</h1>
          <p className="dis_secondary" style={{ margin: "1rem 0" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim praesent elementum facilisis
            leo, vel fringilla est ullamcorper eget nulla facilisi etiam
            dignissim diam quis enim lobortis scelerisque fermentum dui faucibus
            in ornare quam viverra
          </p>
          {/* <div style={{ margin: "2rem 0" }}>
            <p style={{ color: "#B2B2B2" }} className="mcdt_p2">
              What is Your Question?
            </p>
            <input
              id="search"
              placeholder="Type Here"
              type="text"
              className="login_input"
            />
          </div> */}
          <div className="dflex">
            <div className="w90"></div>
            <div className="w10">
              <Select
                name="filter"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={colourStyles}
                placeholder="sort by"
              />
            </div>
          </div>
          <div className="discussion_qcont">
            <div className="discussion_content--heading">
              <p className="dis_name">Derek Joseph</p>
              <p className="dis_date">23 June 2022</p>

              <img
                style={{ margin: "0 0 0 2rem", height: "1.5rem" }}
                src={pin}
              />

              <i className="fa-solid fa-flag"></i>
            </div>
            <p className="dis_comment">
              Good Concept, My wife and I are interested in investing in this
              company. Have concerns about your revenue projections also will
              like to have more information on your past year financials,
              interested in investing with significant amount. Thank you in
              advance for this information.
            </p>
            <div className="discussion_send">
              <input className="login_input" />
              <img src={send} />
            </div>
          </div>
          <div className="discussion_qcont">
            <div className="discussion_content--heading">
              <p className="dis_name">Shyam Kumar</p>
              <p className="dis_date">23 June 2022</p>

              <img
                style={{ margin: "0 0 0 2rem", height: "1.5rem" }}
                src={pin}
              />

              <i className="fa-solid fa-flag"></i>
            </div>
            <p className="dis_comment">
              Hi there, Is this the same company as RAD intelligence? Would you
              mind commenting on your previous raises?
            </p>
            <div className="discussion_send">
              <input className="login_input" />
              <img src={send} />
            </div>
          </div>
          <div className="discussion_content">
            <div className="discussion_content--heading">
              <p className="dis_name">Rama Rao</p>
              <p className="dis_date">23 June 2022</p>
              <i className="fa-solid fa-flag"></i>
            </div>
            <p className="dis_comment">
              Noting the close relationship with Wavemaker and Miso, will your
              product(s) be made available and become a platform across
              industries for other customers?
            </p>
            <div className="dis_reply">
              <div className="discussion_content--heading">
                <p className="dis_name">Ankit Malholtra</p>
                <div style={{ margin: "0 1rem" }} className="tag">
                  <p className="tag_label">CEO</p>
                </div>
                <p className="dis_date">23 June 2022</p>
                <i className="fa-solid fa-flag"></i>
              </div>
              <p className="dis_comment">
                Hi Rama! If you want to become a customer or know someone who
                does, please send a note to info@allyrobotics.com.
              </p>
            </div>
          </div>

          <div className="discussion_content">
            <div className="discussion_content--heading">
              <p className="dis_name">Pichachi Bundar</p>
              <div style={{ margin: "0 1rem" }} className="tag">
                <p className="tag_label">INVESTOR</p>
              </div>
              <p className="dis_date">15 February 2022</p>
              <i className="fa-solid fa-flag"></i>
            </div>
            <p className="dis_comment">
              Cool project! Regarding competition, what do you think about
              https://www.abcdef.com ?
            </p>
            <div className="dis_reply">
              <div className="discussion_content--heading">
                <p className="dis_name">Ankit Malholtra</p>
                <div style={{ margin: "0 1rem" }} className="tag">
                  <p className="tag_label">CEO</p>
                </div>
                <p className="dis_date">17 February 2022</p>
                <i className="fa-solid fa-flag"></i>
              </div>
              <p className="dis_comment">
                Thank you. We're not going to comment on other products and
                companies. Our customers are excited about what we're developing
                and from industry leaders we've repeatably been told variations
                of, "there's nothing like what you're doing on the market".
              </p>
            </div>
          </div>

          <div className="discussion_content">
            <div className="discussion_content--heading">
              <p className="dis_name">Dasan kartha</p>
              <div style={{ margin: "0 1rem" }} className="tag">
                <p className="tag_label">INVESTOR</p>
              </div>
              <p className="dis_date">23 June 2022</p>
              <i className="fa-solid fa-flag"></i>
            </div>
            <p className="dis_comment">
              Hi. When is the last day to invest in ?
            </p>
            <div className="dis_reply">
              <div className="discussion_content--heading">
                <p className="dis_name">Ankit Malholtra</p>
                <div style={{ margin: "0 1rem" }} className="tag">
                  <p className="tag_label">CEO</p>
                </div>
                <p className="dis_date">23 June 2022</p>
                <i className="fa-solid fa-flag"></i>
              </div>
              <p className="dis_comment">
                How long the campaign remains open will depend on investor
                demand, though we certainly suggest completing your investment
                as early as you are able.
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstButton
              marginTop={"2rem"}
              buttonText="Load more"
              className="greenBorder"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Discussion;
