import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Scrolltotop from "../../../../assets/images/oicons/svg/scrollup.svg";

const MainDonationPitch = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/maincampdonations/aboutus");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />

        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Pitch and Details</h4>
          <div className="cfp_wd1">
            <div>
              <p className="mcdt_p2">
                Make a positive first impression by outlining the goals of your
                campaign and inspiring interest in learning more..
              </p>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Business Highlights</p>
              <p className="mcdt_p2">
                Hint :Tractions,Accomplisments,Profits,Clients ,Partners.
              </p>
              <input
                type="text"
                className="login_input mcp_input"
                placeholder="HighLight 1"
              />
              <input
                type="text"
                className="login_input mcp_input"
                placeholder="HighLight 2"
              />
              <input
                type="text"
                className="login_input mcp_input"
                placeholder="HighLight 3"
              />
              <input
                type="text"
                className="login_input mcp_input"
                placeholder="HighLight 4"
              />
              <input
                type="text"
                className="login_input mcp_input"
                placeholder="HighLight 5"
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Pitch Deck </p>
              <p className="mcdt_p2">
                Upload campaign deck in PDF format.Maximum 15 slides If you've
                already created a slide deck to fundraise, we'll use it to
                create your profile faster. or Visit Partners page .
              </p>
              <TextField
                variant="standard"
                type="file"
                InputProps={{
                  multiple: true,
                  disableUnderline: true,
                }}
                style={{
                  background: "#F5F5F5",
                  border: "none !imporatant",
                  outline: "none !important",
                  width: "97.5%",
                  padding: "1.2rem 0.6rem",
                }}
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">FAQs</p>
              <p className="mcdt_p2">About your business and campaign.</p>
              <div className="mcp_cont">
                <div className="precampfaq_cont ">
                  <p>FAQ 1</p>
                  <DeleteIcon
                    style={{ color: "#19976A", fontSize: "1.6rem" }}
                  />
                </div>
                <div className="">
                  <p className="precampfaq_cont1">
                    Is this technology protected and can anyone else make it?
                  </p>
                  <p className="precampfaq_cont1">
                    We have global coverage of our intellectual property
                    including patents, designs and trademarks.We are the sole
                    manufacturer of all our products.
                  </p>
                </div>
              </div>
              <div>
                <div className="mcp_cont">
                  <div className="precampfaq_cont ">
                    <p>FAQ 2</p>
                    <DeleteIcon
                      style={{ color: "#19976A", fontSize: "1.6rem" }}
                    />
                  </div>
                  <div className="">
                    <p
                      style={{ color: "#b2b2b2" }}
                      className="precampfaq_cont1"
                    >
                      Question
                    </p>
                    <p
                      style={{ color: "#b2b2b2" }}
                      className="precampfaq_cont1"
                    >
                      Answer 
                    </p>
                  </div>
                </div>
                <div>
                  <FirstButton
                    className="borderbtn"
                    buttonText="Add +"
                    width="100%"
                    borderRadius="5rem"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/maincampdonations/baiscdetails")}
        />
        <FirstButton
          buttonText="Save and next"
          onClick={handleSubmit}
          className="btn"
        />
      </div>
    </div>
  );
};

export default MainDonationPitch;
