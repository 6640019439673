import { textAlign } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import icon1 from "../../../assets/images/icons/money.png";
import FirstButton from "../../../common/button";
import { role } from "../../utils";
const data = [
  {
    title: "Mergers and acquisition",
    desc: "Access curated and highly-vetted opportunities across every industry.",
  },
  {
    title: "Technology transfer",
    desc: "Thinking Your Innovation is the next Game-Changer?",
  },
  {
    title: "Partnership",
    desc: "Leverage the joint strengths of the institutions",
  },
  {
    title: "Technology Assessment ",
    desc: "Evaluation of University and research labs Intellectual capital,research and innovation",
  },
  {
    title: "Licensing ",
    desc: "Rather of inventing from scratch, licensing technology may be an effective alternative for your organization",
  },
  {
    title: "Research Collaboration",
    desc: "Collaboration at different levels and show that inter-institutional and international collaboration",
  },
  {
    title: "Investment",
    desc: "Our programs and funding streams are meant to reduce common barriers to innovation and advancement, whether you are a start-up, SME, or researcher.",
  },
  {
    title: "Commercialization",
    desc: "Take research from the lab to the market, creating real-world economic, environmental and society-wide value",
  },
];

const Offers = () => {
  const navigate = useNavigate();
  const userrole = localStorage.getItem("role");
  return (
    <>
      <div className="showCards">
        <div id="landing_primary" className="main_text">
          <h1 className="primary_title">What do we offer</h1>
        </div>
        <div className="showCards_container">
          <div className="osc_card">
            {data.map((item, index) => (
              <div className="osc">
                <p className="osc_f1">{item.title}</p>
                <p className="osc_f2">{item.desc}</p>
              </div>
            ))}
            <div className="osx_con15">
              <p className="osc_f2">Explore all opportunities !!</p>
              {userrole === role[2].role ? (
                <FirstButton
                  className="btn"
                  buttonText="Go to marketplace"
                  onClick={() => navigate("/marketplace/techofferlisting")}
                />
              ) : (
                <FirstButton
                  className="btn"
                  buttonText="Signup for marketplace"
                  onClick={() => navigate("/signup")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offers;
