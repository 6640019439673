import React from "react";
import Img from "../../../assets/images/img/congrats1.png";
import Img1 from "../../../assets/images/img/z5.png";
import Img2 from "../../../assets/images/img/z6.png";
import Img3 from "../../../assets/images/img/z7.png";
import Img4 from "../../../assets/images/icons/discord.svg";
import Img5 from "../../../assets/images/icons/facebook.svg";
import Img6 from "../../../assets/images/icons/reddit.svg";
import Img7 from "../../../assets/images/icons/linkedin.svg";
import Img8 from "../../../assets/images/icons/twitter.svg";
import FirstButton from "../../../common/button";
import { useNavigate } from "react-router-dom";
const InvestCampEnd = () => {
  const navigate = useNavigate();
  return (
    <div className="ice_main">
      <div>
        <img src={Img} alt="" />
        <h1 className="ice_h1">
          Congratulations..!! You’ve just invested in Samai
        </h1>
        <p className="ice_p1">Let the founders know why you have invested !</p>
      </div>
      <div className="ice_cont2">
        <div className="ice_cont">
          <div>
            <img src={Img1} alt="" />
            <p className="ice_p1">Pooja Bandi</p>
          </div>
          <div>
            <img src={Img2} alt="" />
            <p className="ice_p1">Shilpa zinta</p>
          </div>
          <div>
            <img src={Img3} alt="" />
            <p className="ice_p1">Rajesh Kumar</p>
          </div>
        </div>
        <div className="ice_cont1">
          <p className="ice_p1 ice_fw">
            What made you decide to invest in our business?
          </p>
          <div>
            <input type="text" className="login_input" />
          </div>
        </div>
        <div className="ice_mr1">
          <h2 className="ice_p2">Help Samai Raise more money</h2>
          <p className="ice_p1 ice_fw">
            Help Samai raise more money, the faster they raise sooner they can
            focus on what matters, the business..!!
          </p>
        </div>
        <div className="ice_cont">
          <FirstButton
            buttonText={[
              <img src={Img8} className="ice_btnimg" />,
              "Share on Twitter",
            ]}
            className="btnblack"
          />
          <FirstButton
            buttonText={[
              <img src={Img5} className="ice_btnimg" />,
              "Share on Facebook",
            ]}
            className="btnblack"
          />
          <FirstButton
            buttonText={[
              <img src={Img7} className="ice_btnimg" />,
              "Share on Linkedin",
            ]}
            className="btnblack"
          />
          <FirstButton
            buttonText={[
              <img src={Img4} className="ice_btnimg" />,
              "Share on Discord",
            ]}
            className="btnblack"
          />
          <FirstButton
            buttonText={[
              <img src={Img6} className="ice_btnimg" />,
              ,
              "Share on Reddit",
            ]}
            className="btnblack"
          />
        </div>
      </div>
      <FirstButton
        buttonText="Skip and Go To Dashboard"
        className="borderbtn"
        marginTop="2rem"
        onClick={() => navigate("/investment/portfolio")}
      />
    </div>
  );
};

export default InvestCampEnd;
