import React from "react";
import { Button } from "@mui/material";
import storyImage from "../../assets/images/img/home18.PNG";
import FirstButton from "../../common/button";
const Story = () => {
  return (
    <>
      <div className="story m-0">
        <div className="story_container">
          <div className="story_content story_p">
            <h1 className="story_heading">Our story</h1>
            <p className="story_text">
              We were inspired to create Smaarket based on our own experiences
              trying to bring ideas to life.{" "}
            </p>
            <p className="story_text">
              We founded Smaarket in 2022 because we believed in and wanted to
              encourage our friends' startups—to help make their ideas come to
              life, dream bigger, and achieve their goals.
            </p>
            {/* <p className="story_text">
              So much natural potential is being squandered across India and
              abroad.{" "}
            </p> */}
            <p className="story_text">
              The goal of Smaarket is to change that and assist tens of
              thousands more founders and stakeholders in "taking their shot."
              It’s time to start discovering the next big ideas.
            </p>
            {/* <Button id='mui_greenBtn' color="primary" size='medium' variant='contained'>Learn More</Button> */}
            <FirstButton
              margin={"0 0 2rem 0"}
              className="whitebtn"
              buttonText="Learn More"
            />
          </div>
          {/* <div className='story_img'>
          <img className='storyImage' alt='' src={storyImage} />
        </div>
       */}
        </div>
      </div>
    </>
  );
};

export default Story;
