import React from "react";
import IMG01 from "../../../../assets/images/img/balley.png";
import ProgressBar from "../../../../common/progressBar/Progress";
const data = {
  amount: "₹ 55,00,000 Raised Successfully",
  percentage: 110,
  status: "First goal Hit..!! (You can still invest)",
  duration: "20 Days Left",
  color: "#105039",
  bgcolor: "#19976A",
  value: "20",
};
const SearchDetailsMain = () => {
  return (
    <>
      <div className="searchMain p-13">
        <div className="searchMain_container">
          <div className="searchMain_container--1">
            <img style={{ width: "100%" }} alt="" src={IMG01} />
          </div>
          <div className="searchMain_container--2">
            <h1 className="comp_heading">Bally Robotics</h1>
            <div className="comp_details">
              <p className="comp_link">webxsite.com</p>
              <div className="comp_social">
                <i className="fa-brands fa-twitter comp_social_icon"></i>
                <i className="fa-brands fa-linkedin-in comp_social_icon"></i>
                <i className="fa-brands fa-google comp_social_icon"></i>
                <i className="fa-brands fa-facebook-f comp_social_icon"></i>
              </div>
            </div>
            <div className="tags">
              <div style={{ backgroundColor: "#fcfcfc" }} className="tag">
                <p className="tag_label">SECTOR</p>
              </div>
              <div style={{ backgroundColor: "#fcfcfc" }} className="tag">
                <p className="tag_label">CSOP</p>
              </div>
              <div style={{ backgroundColor: "#fcfcfc" }} className="tag">
                <p className="tag_label">CSOP</p>
              </div>
            </div>
            <p style={{ color: "#070908" }} className="secondaryText">
              We help robotic companies bridge the gap between humans and
              robots. You focus on the technology, we make it easier for your
              users to set-up, use, service, and maintenance the device or
              interface.
            </p>
            <div style={{ margin: "2rem 0" }}>
              <ProgressBar data={data} type="samai" />
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#070908" }} className="counts">
        <div className="counts_container">
          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              CSOP
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Type of Instrument
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              20%
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Discount
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              30th Feb 2026
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              End Date
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              ₹ 10,000
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Min Investment
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              ₹ 50,00,000
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Target
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchDetailsMain;
