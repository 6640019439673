import React from 'react';

import Dropdown from "../../../../common/dropdown"
import SearchIcon from '@mui/icons-material/Search';

 
const selected = [
  { value: 1, label: "AI & Machine Learning" },
  { value: 2, label: "₹ 1 CR+ Revenue" },
]
const SearchFilter = ()=>{

  return (
    <> 
    <div className='filter'>
       <div className='searchInput'>
       <input className='login_input' placeholder='Search'/>
       </div>

       <div className='searchFilter'>
       <Dropdown placeholder="Sector"/>
       </div>

       <div className='searchFilter'>
       <Dropdown placeholder="More"/>
       </div>

       <div className='searchFilter'>
       <Dropdown placeholder="Sort by"/>
       </div>
        
    </div>    
     <div className='selected_options'>
      <div className="options">
      {
          selected.map((item,index)=>(
            <div className='selected' key={index}>
              <p className='selected_label'>{item.label}</p>
              <i className='fa fa-close'></i>
            </div>
            
          ))
         }
      </div>
       <div  className='clearAll'>
         <p className='clearAll_label'>Clear All</p>
       </div>
     </div> 
    </>

  )
}
 
export default SearchFilter