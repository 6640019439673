import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SideBar = (props) => {
  const navigate = useNavigate();
  const [openNav, setOpenNav] = useState(false);
  const email = localStorage.getItem("email");
  const role = localStorage.getItem("role");
  useEffect(() => {
    setOpenNav(props.classValue);
  }, [props]);

  const handleRouting = (value) => {
    props.getValue(false);
    navigate(value);
  };
  const handleClose = (value) => {
    props.getValue(value);
  };

  const handlelogout = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    window.location.reload();
    navigate("/");
  };
  return (
    <div className={openNav ? "overlay" : ""}>
      <div className={`sidebar ${openNav ? "openSidebar" : ""}`}>
        <div className="sidebar_container">
          <div
            onClick={(val) => handleClose((val = false))}
            className="sidebar_close"
          >
            <i className="fa fa-circle-xmark"></i>
          </div>
          <div className="sidebar_content">
            <ul className="sidebar_ul">
              <li
                onClick={(route) => handleRouting((route = "/"))}
                className="sidebar_link"
              >
                Home
              </li>

              <li
                onClick={(route) => handleRouting((route = "explore-startups"))}
                className="sidebar_link"
              >
                Explore Startups
              </li>

              <li
                onClick={(route) => handleRouting((route = "market-place"))}
                className="sidebar_link"
              >
                Market Place
              </li>

              <li
                onClick={(route) => handleRouting((route = "raise-capital"))}
                className="sidebar_link"
              >
                Raise Capital
              </li>
              {role !== "" && role !== undefined && role !== null ? (
                <li onClick={handlelogout} className="sidebar_link">
                  Logout
                </li>
              ) : (
                <></>
              )}

              {email !== "" && email !== undefined && email !== null ? (
                <></>
              ) : (
                <>
                  <li
                    onClick={(route) => handleRouting((route = "/login"))}
                    className="sidebar_link"
                  >
                    Login
                  </li>
                  <li
                    onClick={(route) => handleRouting((route = "/signup"))}
                    className="sidebar_link"
                  >
                    Signup
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SideBar;
