import React from "react";
import CardImg from "../../../../../assets/images/logo/Reaserchcardimg.png";
import FirstButton from "../../../../../common/button";
const list = ["Transportation", "AI", "Automobiles", "B2C", "Motor", "Engine"];
const ResearchOffer = () => {
  return (
    <div>
      <h4 className="reoff_h4">Research offers</h4>
      {/* <div className='reffcard_cont'>
        <div>
        <div className="reoffcard_main">
<div>
    <img src={CardImg} alt="" width="100%"/>
</div>

<p className='reoffcard_p1'>Title of th tech Offer in two Lines</p>
<p className="reoffcard_p2">Description in 4 lines of content. Lorem ipsum dolor sit amet, conser adipiscing elit dsgrg. dolor sit amet, conser adipiscing.</p>
<div className='reoffcard_cont2'>

{list.map((item,index)=>(
    <div className='reoffcard_cont6 reoffcard_p2'>
        {item}
    </div>
))}

</div>  

<div className='reoffcard_cont3'>
    <p className='reoffcard_p2'>Engagements opportunity</p>
    <p className='reoffcard_p2' style={{fontWeight:700,marginTop:"0.5rem"}}>Licensing, Partnership, Research </p>




</div>
        </div>
        <div className='reoff_cont8'>
    Number of interests :04
</div>
        </div>
        <div>
        <div className="reoffcard_main">
<div>
    <img src={CardImg} alt="" width="100%"/>
</div>

<p className='reoffcard_p1'>Title of th tech Offer in two Lines</p>
<p className="reoffcard_p2">Description in 4 lines of content. Lorem ipsum dolor sit amet, conser adipiscing elit dsgrg. dolor sit amet, conser adipiscing.</p>
<div className='reoffcard_cont2'>

{list.map((item,index)=>(
    <div className='reoffcard_cont6 reoffcard_p2'>
        {item}
    </div>
))}

</div>

<div className='reoffcard_cont3'>
    <p className='reoffcard_p2'>Engagements opportunity</p>
    <p className='reoffcard_p2' style={{fontWeight:700,marginTop:"0.5rem"}}>Licensing, Partnership, Research </p>




</div>


        </div>
        <div className='reoff_cont8'>
    Number of interests :04
</div>
        </div>
        
        </div>
        <div className='reoff_cont7'>
            <FirstButton buttonText="View more" className='btn'/>
        </div>

        <div className='reoff_cont7'>
            <p className='reoffcard_p2' style={{margin:"2rem 0 0.5rem 0"}}>Keep Exploring !</p>
            <FirstButton buttonText="Explore MarketPlace" className="btn"/>
        </div> */}
    </div>
  );
};

export default ResearchOffer;
