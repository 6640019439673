import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import { useNavigate } from "react-router-dom";
const Contact = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />

        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Primary Contact Information</h4>
          <div className="cfp_wd">
            <div>
              <p className="reoffcard_p1 prequal_mr ">
                Legal Name of Representative
              </p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1 prequal_mr">Designation </p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1 prequal_mr">Official email ID </p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1 prequal_mr">Contact number </p>
              <p className="signupready_p5">Primary Contact number </p>
              <input type="text" className="login_input" placeholder="+91" />
              <p className="cfp_p">Alternative Contact number </p>
              <input type="text" className="login_input" placeholder="+91" />
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/prequalify/basicdetails")}
        />
        <FirstButton
          buttonText="Save and next"
          className="btn"
          onClick={() => navigate("/prequalify/questions")}
        />
      </div>
    </div>
  );
};

export default Contact;
