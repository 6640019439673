import React from "react";
import FundRaiseMain from "../components/LandingPages/fundRaiser/FundRaiseMain";
import Counts from "../components/Home/Counts";
import ShowCards from "../components/LandingPages/fundRaiser/ShowCards";
import ThreeGrid from "../components/LandingPages/fundRaiser/ThreeGrid";
import FundRaiseStory from "../components/LandingPages/fundRaiser/FundRaiseStory";
import SuccessStory from "../components/LandingPages/fundRaiser/SuccessStory";
import FundRaiseReview from "../components/LandingPages/fundRaiser/FundRaiseReview";
import FundRaiseFAQ from "../components/LandingPages/fundRaiser/FundRaiseFAQ";
const FundRaisers = ()=>{
  return (
    <>

    <FundRaiseMain/>
    <Counts/>
    <ThreeGrid/>
    <ShowCards/>
    <FundRaiseStory/>
    <SuccessStory/>
    <FundRaiseFAQ/>
    {/* <FundRaiseReview/> */}
    
    </>
  )
}

export default FundRaisers