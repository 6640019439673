import React from "react";
import SearchDetailsMain from "../components/InvestmentDonations/SeachPage/SearchDetailsMain";
import UpdateAndNews from "../components/InvestmentDonations/SeachPage/UpdateAndNews";
import DetailTabs from "../components/InvestmentDonations/SeachPage/tabsView/DetailTabs";
const SearchDetails = ()=>{
  return(
   <>
    <SearchDetailsMain/>
    <UpdateAndNews/>
    <DetailTabs/>
   </>
  )
}

export default SearchDetails