import React from "react";
import { useNavigate } from "react-router-dom";
import FirstButton from "../../../common/button";

const ExploreFAQ = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{ marginBottom: "0", backgroundColor: "#fff" }}
        className="faq"
      >
        <div className="faq_container">
          <h1 className="faq_primary faq_color">Investor FAQ</h1>
          <div className="questions">
            <div className="question_container--1">
              <div className="question1">
                <h1 className="question faq_color ">What is Smaarket?</h1>
                <p className="answer faq_color ">
                  There is no better location to begin the search for
                  fascinating and creative businesses and inventions that
                  surprise and amaze with live crowdfunding campaigns and a
                  marketplace.
                </p>
              </div>
              <div className="question2 faq_color ">
                <h1 className="question faq_color ">Who may invest?</h1>
                <p className="answer faq_color ">
                  Smaarket allows any Indian national over the age of 18 to make
                  investments. <br></br> We have a diversified investor base
                  with regard to age, gender, income, background, and point of
                  view.
                </p>
              </div>
            </div>

            <div className="question_container--2">
              <div className="question1 faq_color ">
                <h1 className="question faq_color ">
                  How much money can I expect to make?
                </h1>
                <p className="answer faq_color ">
                  First things first: there are no guarantees for returns in
                  crowdfunding. That's why seasoned investors diversify their
                  startup portfolios. While most early-stage businesses will
                  probably fail, some may flourish, and a select few may evolve
                  into "unicorns," generating enormous returns.
                </p>
              </div>
              <div className="question2">
                <h1 className="question faq_color ">
                  How much money can I invest?
                </h1>
                <p className="answer faq_color ">
                  As part of our commitment to broadening investing access,
                 Smaarket allows investments starting at ₹ 50,000 —the lowest in
                  the industry.
                  <br />
                  Ultimately, every startup fundraising on Smaarket sets its own
                  minimum investment.
                </p>
              </div>
            </div>
          </div>
          <FirstButton
            className="borderbtn"
            buttonText="Read More"
            marginTop="5rem"
            onClick={() => navigate("/help/faq")}
          />
        </div>
      </div>
    </>
  );
};

export default ExploreFAQ;
