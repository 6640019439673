import React from "react";
import SearchDetailsMain from "./SearchDetailsMain";
import UpdateAndNews from "./UpdateAndNews";
import DetailTabs from "./tabsView/DetailTabs";
const DashCampPage = () => {
  return (
    <>
      <SearchDetailsMain />
      <UpdateAndNews />
      <DetailTabs />
    </>
  );
};

export default DashCampPage;
