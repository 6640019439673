import React, { useState } from "react";
import FirstButton from "../../../common/button";
import Sidebar from "./Sidebar";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from "chart.js";
import { Doughnut, Bar, Line } from "react-chartjs-2";

import { Tabs, Tab } from "@mui/material";
import Investment from "./PortfolioTabs/Investment";
import Donation from "./PortfolioTabs/Donation";
import { useNavigate } from "react-router-dom";
const options = { maintainAspectRatio: false };
const Portfolio = () => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (e, value) => {
    setTabIndex(value);
  };
  return (
    <div className="cfp_main">
      <Sidebar />

      <div className="cfp_cont1 wd68">
        <h4 className="market_h4">Portfolio</h4>
        <div className="w98">
          <div className="iada_cont">
            <div className="w50">
              {/* <h2 className="iada_f2">Local Sector Breakup</h2> */}
              <div className="iada_cont1">
                <Doughnut data={dataLocal} options={options} />
              </div>
            </div>
            <div className="w50">
              {/* <h2 className="iada_f2">Global Sector Breakup</h2> */}
              <div className="iada_cont1">
                <Doughnut data={dataGlobal} options={options} />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h5 className="portfolio_f1 port_mr1">Your Activity </h5>

          <div className="portfolio_cont">
            {list.map((item, index) => (
              <div key={index} className="w30" >
                <p className="portfolio_f2">{item.detail}</p>
                <p className="portfolio_f3">{item.title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="port_mr">
          <Tabs
            variant="fullWidth"
            onChange={handleChange}
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#19976A",
              },
            }}
          >
            {tablist.map((item, index) => (
              <Tab
                style={{
                  fontSize: "1.6rem",
                  color: `${tabIndex === index ? "#070908" : "#070908"}`,
                  fontWeight: `${tabIndex === index ? "bold" : 400}`,
                  textTransform: "none",
                }}
                value={index}
                label={item}
                key={index}
              />
            ))}
          </Tabs>
          {tabIndex === 0 && <Investment />}
          {tabIndex === 1 && <Donation />}
        </div>
        <div className="invest_watchlistbtncont">
          <p className="invest_watchlistp1">Keep Exploring !</p>
          <FirstButton
            buttonText="Explore More Deals"
            marginTop="1rem"
            className="btn"
            onClick={() => navigate("/investment/activedeals")}
          />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;

const list = [
  { title: "Number of Investments", detail: "3" },
  { title: "Total Investments", detail: "₹ 55,000" },
  { title: "Number of Donations", detail: "4" },
  { title: "Total Donations", detail: "₹ 35,000" },
];

const tablist = ["Investments", "Donations"];
const dataLocal = {
  labels: ["Jet Aero", "Green Power", "Tooth Pik"],
  datasets: [
    {
      label: "My First Dataset",
      data: [40, 30, 30],
      backgroundColor: ["#26ABA4", "#9D53F2", "#3290ED"],
      borderWidth: 2,
      responsive: true,
      // cutout: 130,
      maintainAspectRatio: true,
      animation: { duration: 3000 },

      // radius: 100,
    },
  ],
};

const dataGlobal = {
  labels: ["Nir Bio", "Prana Labs", "Farm Minds"],
  datasets: [
    {
      label: "My First Dataset",
      data: [50, 100, 300],
      backgroundColor: ["#26ABA4", "#9D53F2", "#3290ED"],
      borderWidth: 2,
      responsive: true,
      // cutout: 130,
      maintainAspectRatio: true,
      animation: { duration: 3000 },

      // radius: 100,
    },
  ],
};
