import React from 'react'
import Avatar from "../../../assets/images/icons/avatar2.png"
import FirstButton from '../../../common/button'
const DashPreCampCompSubmission = () => {
  return (
    <div className='dashcompsub_contmain'>
        <div className="dashcompsubmain">
            <div className='dashcompsub1'>
                <div className='dashcompsub_cont'>
                    <div> <img src={Avatar} alt="" /> </div>
                    <div> 
                        <h2 className='dashcompsub_h2'>Bally Robotics</h2>
                        <p className='dashcompsub_p'>Application ID : SM02103A</p>
                    </div>
                </div>
                <div>
                <FirstButton buttonText="Contact Campaign Manager" width="100%" marginTop="2rem " className="borderbtn"/>
                <FirstButton buttonText="View Campaign History" width="100%" marginTop="1.5rem " className="borderbtn" />
                </div>
            </div>
            <div className='dashcompsub1'>
                <p className="brand_name dashcompsub_p1">Pre Campaign draft Saved  </p>
<p className='brand_name dashcompsub_p1'>28 Days to go for Pre Campaign launch </p>
<p className='brand_name'>Pre campaign application Submission pending. Submit your application  in  7 days .</p>
    
                <FirstButton buttonText="Complete Your Submission" width="100%" marginTop="3.5rem" className="btn"/>
            </div>
        </div>
    </div>
  )
}

export default DashPreCampCompSubmission