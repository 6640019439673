import React, { useEffect, useState } from "react";
import FirstButton from "../../common/button";
import { Link, useNavigate } from "react-router-dom";
import { Divider, TextField } from "@mui/material";
import { GoogleLogin } from "react-google-login";
import { roles, role } from "../utils";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userrole, setUserRole] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    // roles(username)
    if (username === role[0].email) {
      localStorage.setItem("email", username);
      localStorage.setItem("role", role[0].role);
      localStorage.setItem("name", role[0].name);
      setUserRole(role[0].role);
      navigate("/raise-capital");
    } else if (username === role[1].email) {
      localStorage.setItem("email", username);
      localStorage.setItem("role", role[1].role);
      localStorage.setItem("name", role[1].name);
      setUserRole(role[1].role);
      navigate("/explore-startups");
    } else if (username === role[2].email) {
      localStorage.setItem("email", username);
      localStorage.setItem("role", role[2].role);
      localStorage.setItem("name", role[2].name);
      setUserRole(role[2].role);
      navigate("/market-place");
    } else if (username === role[3].email) {
      localStorage.setItem("email", username);
      localStorage.setItem("role", role[3].role);
      localStorage.setItem("name", role[3].name);
      navigate("/admin");
    }
    window.location.reload();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  return (
    <div className="login_main">
      <div className="login_heading">HI! Welcome back!</div>
      <div className="login_body">
        <GoogleLogin
          buttonText="Login in with Google "
          className="login_button"
        />
        <Divider className="login_divider">or Login with</Divider>
        <div>
          <p className="login_para">
            <label> Email ID</label>
          </p>
          <input
            className="login_input"
            value={username}
            name="username"
            onChange={handleChange}
            placeholder="Enter Your Email Id"
            id="outlined-basic"
            label="Email ID"
            variant="outlined"
          />
        </div>
        <div>
          <p className="login_para">
            <label htmlFor=""> Password</label>
          </p>
          <input
            className="login_input"
            type="password"
            name="password"
            onChange={handleChange}
            placeholder="Enter Your Password"
            value={password}
            id="outlined-basic"
            label="Password"
            variant="outlined"
          />
         
        </div>
        <p className="login_para">
          <Link className=" login_link">Forget password?</Link>
        </p>
        <div>
          <FirstButton
            className="btn"
            buttonText="Login"
            marginTop="3rem"
            width="100%"
            onClick={handleSubmit}
          />
          <p className="login_last_para">
            Don't have an Account?
            <span>
              <Link to="/signup" className="login_link">
                {" "}
                Signup
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
