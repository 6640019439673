import React from "react";
import Img1 from "../../../../../assets/images/img/cardImg1.png";
import Img2 from "../../../../../assets/images/img/cardImg2.png";
import Img3 from "../../../../../assets/images/img/cardImg3.png";
import Img4 from "../../../../../assets/images/img/cardImg4.png";
import FirstButton from "../../../../../common/button";
import Card from "../../../../../common/cards/Card";
import Scrolltotop from "../../../../../assets/images/oicons/svg/scrollup.svg";
import { useNavigate } from "react-router-dom";
const list = ["Transportation", "AI", "Automobiles", "B2C", "Motor", "Engine"];

const TechOffer = () => {
  const navigate=useNavigate()
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <h4 className="reoff_h4">Tech offers</h4>
      <div>
        <div className="reffcard_cont">
          {cardData.map((item, index) => (
            <div key={index} className="w30">
              <Card type="upcoming" data={item} />
            </div>
          ))}
        </div>
      </div>
      <div className="reoff_cont7">
        <FirstButton buttonText="View more" className="btn" />
      </div>

      <div className="reoff_cont7">
        <p className="reoffcard_p2" style={{ margin: "2rem 0 0.5rem 0" }}>
          Keep Exploring !
        </p>
        <FirstButton buttonText="Explore MarketPlace" className="btn" onClick={()=>navigate("/marketplace/techofferlisting")}/>
      </div>
      <div className="stti">
        <img src={Scrolltotop} onClick={handleScroll} />
      </div>
    </div>
  );
};

export default TechOffer;

const cardData = [
  {
    image: Img1,
    description:
      "A simple, visual sensor and digital platform that improves water and nutrient use by farmers.",
    title: "The Al Irrigation  -Chameleon Card",
    tags: ["Agriculture", "Food", "Digital"],
    engagements: "Licensing, Partnership, Investment  ",
    patientorigin: "India",
    patientownership: ["India", "Latam"],
    trllevel: "Four",
    daysleft: "40",
    numberofinterests: "14",
  },
  {
    image: Img2,
    description:
      "Actively configurable wheel with incorporated suspension.This extends the ability of small vehicles to travel on rough terrain by providing an alternative suspension system in a small package.",
    title: "Adjustable wheel",
    tags: ["Space", "Defense", "Manufacturing"],
    engagements: "Licensing, Partnership, Investment  ",
    patientorigin: "India",
    patientownership: ["India", "U.S.A"],
    trllevel: "Nine",
    daysleft: "40",
    numberofinterests: "60",
  },

  {
    image: Img3,
    description:
      "henoMobile Lite technology is a mobile ground-based platform for the phenotyping of crops.",
    title: "Non destructive in-field crop phenotyping tech",
    tags: ["Agriculture", "Food", "Automobiles"],
    engagements: "Licensing, Jointventure, Investment ",
    patientorigin: "Africa",
    patientownership: ["India", "Africa"],
    trllevel: "Seven",
    daysleft: "40",
    numberofinterests: "04",
  },
  {
    image: Img4,
    description:
      "Winegrape yield forecasting tool based on interpretation of video data using machine learning methods.",
    title: "Non-destructive grapevine yield estimation",
    tags: ["Agriculture", "Food", "IT"],
    engagements: "Licensing, Partnership, Field trail ",
    patientorigin: "India",
    patientownership: ["India"],
    trllevel: "Four",
    daysleft: "50 ",
    numberofinterests: "14",
  },
];
