import React from "react";
import FirstButton from "../../../../../common/button";
import DeleteIcon from "@mui/icons-material/Delete";
import doc from "../../../../../assets/images/oicons/svg/document.svg";
import policy from "../../../../../assets/images/oicons/svg/policy.svg";

const CompFinancial = () => {
  const data = [
    {
      number: 1,
      text: "It is difficult to compete directly with existing single-use cups at this stage. Because of massive volume production, most single-use cups are very inexpensive to produce and sell.  Paper, plastic and styrofoam also have To-Go solutions and multiple sizes to fit every need. We have plans to develop lids and many more sizes, but our ability to compete on these merits alone will be limited in the beginning.  Our pricing, however, competes head to head in the low volume, personalized label space.   ",
    },
    {
      number: 2,
      text: "The Company may never receive a future equity financing or elect to convert the Securities upon such future financing. In addition, the Company may never undergo a liquidity event such as a sale of the Company or an IPO. If neither the conversion of the Securities nor a liquidity event occurs, the Purchasers could be left holding the Securities in perpetuity. The Securities have numerous transfer restrictions and will likely be highly illiquid, with no secondary market on which to sell them. The Securities are not equity interests, have no ownership rights, have no rights to the Company’s assets or profits and have no voting rights or ability to direct the Company or its actions.",
    },
    {
      number: 3,
      text: "Our future success depends on the efforts of a small management team. The loss of services of the members of the management team may have an adverse effect on the company. There can be no assurance that we will be successful in attracting and retaining other personnel we require to successfully grow our business. ",
    },
  ];

  const tableData1 = [
    {
      date: "20-08-2020",
      type: "Equity",
      investor: "Prasanth Koman",
      amount: "₹ 1,00,000,000",
    },
    {
      date: "20-04-2021",
      type: "Equity",
      investor: "Dushanth",
      amount: "₹ 10,00,000",
    },
    {
      date: "20-04- 2021",
      type: "Equity",
      investor: "vuble angles",
      amount: "₹ 10,00,000",
    },
    {
      date: "20-04-2021",
      type: "Equity",
      investor: "GuruX",
      amount: "₹ 10,00,000",
    },
    {
      date: "20-04-2022",
      type: "Equity",
      investor: "Time venture",
      amount: "₹ 10,00,000",
    },
  ];
  const tableData2 = [
    {
      date: "21-08-2020",
      type: "Loan",
      investor: "Amar ",
      amount: "₹ 10,00,000",
    },
    {
      date: "20-04-2021",
      type: "Equity",
      investor: "Kuber ",
      amount: "₹ 10,00,000",
    },
    {
      date: "20-04-2021",
      type: "Equity",
      investor: "Laxmi ",
      amount: "₹ 10,00,000",
    },
    {
      date: "20-04-2021",
      type: "Equity",
      investor: "Venkat",
      amount: "₹ 10,00,000",
    },
    {
      date: "20-04-2022",
      type: "Equity",
      investor: "Balaji",
      amount: "₹ 10,00,000",
    },
  ];
  return (
    <>
      <div className="comp_financials">
        <div className="comp_financials_container">
          <h1 className="brands_primary">At a Glance</h1>
          <div className="comp_info_container">
            <div className="comp_info">
              <div>
                <p className="comp_info--data center">₹ 438500</p>
                <p className="comp_info--title center">Revenue</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--data center">-340%</p>
                <p className="comp_info--title center">Net Margin </p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--data center">₹ -$14,915</p>
                <p className="comp_info--title center">Net Loss</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--data center">-55%</p>
                <p className="comp_info--title center">Gross Margin</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--data center">₹ 100000000</p>
                <p className="comp_info--title center">Previous valuation</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--data center">-41%</p>
                <p className="comp_info--title center">Return on Assets</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--data center">₹ 150000 </p>
                <p className="comp_info--title center">Cash in Hand</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--data center"> ₹ 36542.66 </p>
                <p className="comp_info--title center">Revenue per Employee </p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--data center">₹ -0.02 </p>
                <p className="comp_info--title center">Earnings per Share</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--data center">70%</p>
                <p className="comp_info--title center">Cash to Assets</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--data center">N/A</p>
                <p className="comp_info--title center">
                  Revenue to Receivables
                </p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--data center">205% </p>
                <p className="comp_info--title center">Debt Ratio </p>
              </div>
            </div>
          </div>

          <h1
            style={{ marginTop: "10rem", marginBottom: "4rem" }}
            className="brands_primary"
          >
            Documents
          </h1>
          <div className="documents">
            <div className="document--1">
              <div
                className="document_detail"
                style={{ justifyContent: "space-between" }}
              >
                <h1 className="document_name">Due Diligence documents </h1>
                <img src={doc} width="20%" alt="document" />
              </div>
              <a href="/Ag/Agreement.pdf" download="smaarketAgreement">
                <FirstButton
                  marginTop={"2rem"}
                  buttonText="Download"
                  className="greenBorder"
                />
              </a>
            </div>
            <div className="document--2">
              <div
                className="document_detail"
                style={{ justifyContent: "space-between" }}
              >
                <h1 className="document_name">Sample agreement </h1>
                <img src={policy} width="20%" alt="document" />
              </div>
              <a href="/Ag/Agreement.pdf" download="smaarketAgreement">
                <FirstButton
                  marginTop={"2rem"}
                  buttonText="Download"
                  className="greenBorder"
                />
              </a>
            </div>
          </div>

          <h1
            style={{ marginTop: "10rem", marginBottom: "4rem" }}
            className="brands_primary"
          >
            Use of Funds
          </h1>
          <div style={{ overflowX: "auto" }}>
            <div className="useOfFunds">
              <div className="fund_amount">₹ 25,00,000</div>
              <div className="fund_use">
                50% Production Development (finish build of our R&D / manual
                production facility), 23% payment towards related party bridge
                loan, 16.5% Operations, 7.5% We Smaarket fees, 3% Sales &
                Marketing.
              </div>
            </div>

            <div className="useOfFunds">
              <div className="fund_amount">₹ 40,00,000</div>
              <div className="fund_use">
                35% Production Development (commission the building of 2 high
                volume automated production lines), 22% Operations, 20.5% Sales
                & Marketing, 10% Reserves, 7.5% We Smaarket fees 5% payment
                towards related party bridge loan.
              </div>
            </div>

            <div style={{ borderBottom: "none" }} className="useOfFunds">
              <div className="fund_amount">₹ 60,00,000</div>
              <div className="fund_use">
                50% Production Development (finish build of our R&D / manual
                production facility), 23% payment towards related party bridge
                loan, 16.5% Operations, 7.5% We Smaarket fees, 3% Sales &
                Marketing.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstButton
              marginTop={"2rem"}
              buttonText="View more"
              className="greenBorder"
            />
          </div>

          <h1
            style={{ marginTop: "10rem", marginBottom: "4rem" }}
            className="brands_primary"
          >
            Past fund raises
          </h1>
          <div style={{ backgroundColor: "#fff" }} className="mdsp_tablecont">
            <table style={{ backgroundColor: "#fff" }} className="mdsp_table">
              <thead className="mdsp_tablehead">
                <tr className="mdsp_tableheadtr">
                  <th>Date</th>
                  <th>Type</th>
                  <th>Lead investor or Funders</th>
                  <th>amount</th>
                </tr>
              </thead>
              <tbody className="mdsp_body">
                {tableData1.map((item, key) => {
                  return (
                    <tr key={key} className="mdsp_tableheadtr">
                      <td>{item.date}</td>
                      <td>{item.type}</td>
                      <td>{item.investor}</td>
                      <td>{item.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstButton
              marginTop={"2rem"}
              buttonText="View more"
              className="greenBorder"
            />
          </div>

          <h1
            style={{ marginTop: "10rem", marginBottom: "4rem" }}
            className="brands_primary"
          >
            Financial Liabilities
          </h1>
          <div style={{ backgroundColor: "#fff" }} className="mdsp_tablecont">
            <table style={{ backgroundColor: "#fff" }} className="mdsp_table">
              <thead className="mdsp_tablehead">
                <tr className="mdsp_tableheadtr">
                  <th>Date</th>
                  <th>Type</th>
                  <th>Lead investor or Funder</th>
                  <th>amount</th>
                </tr>
              </thead>
              <tbody className="mdsp_body">
                {tableData2.map((item, key) => {
                  return (
                    <tr key={key} className="mdsp_tableheadtr">
                      <td>{item.date}</td>
                      <td>{item.type}</td>
                      <td>{item.investor}</td>
                      <td>{item.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstButton
              marginTop={"2rem"}
              buttonText="View more"
              className="greenBorder"
            />
          </div>

          <h1
            style={{ marginTop: "10rem", marginBottom: "4rem" }}
            className="brands_primary"
          >
            Risks Involved
          </h1>
          {data.map((item, index) => (
            <div
              key={index}
              style={{
                color: "#070908",
                display: "flex",
                alignItems: "center",
              }}
              className="tile_secondary heighlights_text"
            >
              <span className="heighlight_point">{item.number}</span>
              <p>{item.text}</p>
            </div>
          ))}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstButton
              marginTop={"2rem"}
              buttonText="View More"
              className="greenBorder"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompFinancial;
