import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import FirstButton from "../../../../common/button";

const PremiumPackages = () => {
  return (
    <div className="premium_main">
      <div className="premium_padding">
        <h1 className="faq_primary ">Pick a Package</h1>
      </div>
      <div className="premium_card_contmain">
        <div className="premium_card">
          <div className="premium_card_cont1">
            <h2 className="premium_card_f1">Basic</h2>
            <p className="premium_card_f2">Free</p>
            <p className="premium_card_f3">Lifetime free</p>
          </div>
          <div style={{ width: "90%", margin: "auto" }}>
            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>
              <p className="premium_card_f4">Anonymous Technology Needs X 1</p>
            </div>

            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>{" "}
              <p className="premium_card_f4">Anonymous Technology Offer X 1</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CancelIcon sx={{ color: "#C65353" }} />
              </p>{" "}
              <p className="premium_card_f4">Verified and filtered leads</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CancelIcon sx={{ color: "#C65353" }} />
              </p>{" "}
              <p className="premium_card_f4"> Dedicated relationship manager</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CancelIcon sx={{ color: "#C65353" }} />
              </p>{" "}
              <p className="premium_card_f4">
                Get special tags to attract more inquiries
              </p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CancelIcon sx={{ color: "#C65353" }} />
              </p>{" "}
              <p className="premium_card_f4"> Email promotion</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CancelIcon sx={{ color: "#C65353" }} />
              </p>{" "}
              <p className="premium_card_f4">Social Media Promotion</p>
            </div>
            <div style={{ margin: "1rem 0" }}>
              <FirstButton
                buttonText="Select Plan"
                className="btn"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div className="premium_card">
          <div className="premium_card_cont1">
            <h2 className="premium_card_f1">Premium</h2>
            <p className="premium_card_f2">₹ 700/month</p>
            <p className="premium_card_f3">Per month, billed annually</p>
          </div>
          <div style={{ width: "90%", margin: "auto" }}>
            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>
              <p className="premium_card_f4">Anonymous Technology Needs X 5</p>
            </div>

            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>{" "}
              <p className="premium_card_f4">Anonymous Technology Offer X 5</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>{" "}
              <p className="premium_card_f4">Verified and filtered leads</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CancelIcon sx={{ color: "#C65353" }} />
              </p>{" "}
              <p className="premium_card_f4"> Dedicated relationship manager</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CancelIcon sx={{ color: "#C65353" }} />
              </p>{" "}
              <p className="premium_card_f4">
                Get special tags to attract more inquiries
              </p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CancelIcon sx={{ color: "#C65353" }} />
              </p>{" "}
              <p className="premium_card_f4"> Email promotion</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CancelIcon sx={{ color: "#C65353" }} />
              </p>{" "}
              <p className="premium_card_f4">Social Media Promotion</p>
            </div>
            <div style={{ margin: "1rem 0" }}>
              <FirstButton
                buttonText="Select Plan"
                className="btn"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div className="premium_card">
          <div className="premium_card_cont1">
            <h2 className="premium_card_f1">Premium Plus</h2>
            <p className="premium_card_f2">₹ 1800/month</p>
            <p className="premium_card_f3">Per month, billed annually</p>
          </div>
          <div style={{ width: "90%", margin: "auto" }}>
            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>
              <p className="premium_card_f4">Anonymous Technology Needs X 15</p>
            </div>

            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>{" "}
              <p className="premium_card_f4">Anonymous Technology Offer X 15</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>{" "}
              <p className="premium_card_f4">Verified and filtered leads</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>{" "}
              <p className="premium_card_f4"> Dedicated relationship manager</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>{" "}
              <p className="premium_card_f4">
                Get special tags to attract more inquiries
              </p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>{" "}
              <p className="premium_card_f4"> Email promotion</p>
            </div>
            <div className="premium_card_cnt2">
              <p>
                <CheckCircleIcon sx={{ color: "#19976A" }} />
              </p>{" "}
              <p className="premium_card_f4">Social Media Promotion</p>
            </div>
            <div style={{ margin: "1rem 0" }}>
              <FirstButton
                buttonText="Select plan"
                className="btn"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumPackages;
