import React from "react";
import reviews from "../../assets/images/review.png";
import user1 from "../../assets/images/user01.png";
import user2 from "../../assets/images/user02.png";
import img1 from "../../assets/images/icons/x1.png";
import img2 from "../../assets/images/icons/x2.png";
import img3 from "../../assets/images/icons/x3.png";
import img4 from "../../assets/images/icons/x4.png";
import img5 from "../../assets/images/icons/x5.png";
import img6 from "../../assets/images/icons/x6.png";
import img7 from "../../assets/images/icons/newx1.png";
import img8 from "../../assets/images/icons/newx2.png";
import img9 from "../../assets/images/icons/x9.png";
import img11 from "../../assets/images/icons/x11.png";
import img12 from "../../assets/images/icons/x12.png";
import img13 from "../../assets/images/main/rv13.png";
import img14 from "../../assets/images/icons/x14.png";
import img15 from "../../assets/images/main/rv14.png";
import img16 from "../../assets/images/icons/newx3.png";
import img17 from "../../assets/images/icons/newx4.png";
import img18 from "../../assets/images/icons/newx5.png";
import img19 from "../../assets/images/main/rv4.png";
import img21 from "../../assets/images/main/rv6.png";
import img22 from "../../assets/images/main/rv7.png";
import img23 from "../../assets/images/main/rv8.png";
import img24 from "../../assets/images/main/rv9.png";
import img25 from "../../assets/images/main/rv10.png";
import img26 from "../../assets/images/main/rv11.png";
import img27 from "../../assets/images/main/rv12.png";
import img28 from "../../assets/images/icons/newx6.png";
import img29 from "../../assets/images/icons/newx7.png";

const Review = () => {
  return (
    <>
      <div className="review">
        <div className="review_container">
          <h1 className="review_primary reviewmr">
            Here's what our members had to say
          </h1>
          <div className="ic">
            <div className="ic_cont1">
              {list1.map((item, index) => (
                <div key={index} className="ic_cont2">
                  <img src={item.img} className="ic_image " alt="" />
                  <div>
                    <p className="ic_p">{item.comment}</p>
                    <p className=" ic_p ic_fw">{item.name}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="ic_cont1">
              {list2.map((item, index) => (
                <div key={index} className="ic_cont2">
                  <img src={item.img} alt="" className="ic_image " />
                  <div>
                    <p className="ic_p">{item.comment}</p>
                    <p className=" ic_p ic_fw">{item.name}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="ic_cont1">
              {list3.map((item, index) => (
                <div key={index} className="ic_cont2">
                  <img src={item.img} className="ic_image " alt="" />
                  <div>
                    <p className="ic_p">{item.comment}</p>
                    <p className=" ic_p ic_fw">{item.name}</p>
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="ic_cont1">
              {list4.map((item, index) => (
                <div key={index} className="ic_cont2">
                  <img src={item.img} className="ic_image " alt="" />
                  <div>
                    <p className="ic_p">{item.comment}</p>
                    <p className=" ic_p ic_fw">{item.name}</p>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>

      <div className="comment">
        <div className="comment_container">
          <h1 style={{ color: "#000" }} className="review_primary">
            Inspiring stories
          </h1>
          <div className="is_main">
            <div className="comments-cont">
              <div className="comment_user">
                <img className="profile_img" src={user2} alt="" />
              </div>
              <div className="comment_text">
                <p className="comment_primary">
                  We've used the Smaarket Market Place to post technical
                  challenges as well as respond to technology calls for
                  potential IP out-licensing in non-competition industries.
                </p>
                <p className="comment_secondary"> Austin zolda, R&D Director</p>
              </div>
            </div>
            <div className="comments-cont">
              <div className="comment_user">
                <img className="profile_img" src={user1} alt="" />
              </div>
              <div className="comment_text">
                <p className="comment_primary">
                  Smaarket market place is giving us the opportunity to share
                  our university portfolio of research findings and capabilities
                  to the global institutions.
                </p>
                <p className="comment_secondary">
                  Bidia anuiera,Technology transfer office{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
const list1 = [
  {
    img: img1,
    comment:
      "Excited to invest in this project. looking forward to getting involved in many more future project",
    name: " Krishna Murthy invested in exas Co",
  },
  {
    img: img2,
    comment:
      "I've had T1D for 14+ years now, and after participating in a BP insulin-only trial.",
    name: " Tenali Shankar invested in Neurocarrsu",
  },
  {
    img: img3,
    comment:
      "My wife and I visited your main location last weekend and loved it!",
    name: "Tanya Singh invested in artingale",
  },
  {
    img: img4,
    comment:
      " My wife suffers from chronic untreated pain from the ehlers-danlos syndrome.",
    name: "Sowmya Rao invested in eta Bionics",
  },
  {
    img: img5,
    comment:
      "This company is a game changer for all startups and growth companies! ",
    name: " Tarun Shekar invested in Liquid Bio",
  },
  {
    img: img6,
    comment:
      "Great to see your continued progress; enthused about increasing my investment.",
    name: "Laila invested in Inventer",
  },
  {
    img: img7,
    comment:
      "As a woman and a business owner myself uego Fino's goals and passion spoke to me .",
    name: " Chetan Prasad invested in Power",
  },
  {
    img: img8,
    comment:
      "Filled with gratitude that I have partnered with such an outstanding global movement with powers!",
    name: "Rachana invested in uego Fino",
  },
  {
    img: img9,
    comment:
      "This site was recommended to me by my pal. I have been an avid listener since and really believe .",
    name: "Nirmal Sharma invested in etit Vour",
  },
];

const list2 = [
  {
    img: img11,
    comment:
      "A user of the platform; happy to invest and wishing you and the team all the success it deserves!",
    name: "Anjam Akbar invested in eleum",
  },
  {
    img: img12,
    comment:
      "I'm excited to be a small part of this very worthy venture. I've lost two dogs to cancer",
    name: "Jenny Joseph invested in EAH Labs",
  },
  {
    img: img13,
    comment:
      "Great to see your continued progress; enthused about increasing my investment",
    name: "Tony Singh invested in Piston",
  },
  {
    img: img14,
    comment:
      "I love etit Vour, and I’m so excited to get the opportunity to invest. Can’t wait to see what’s in store",
    name: "Katrina invested in Digitally Aero ",
  },
  {
    img: img15,
    comment:
      "Rocky and Jenny are the most savvy and hard-working entrepreneurs I've met. This is an easy choice",
    name: "Dr Ravigundumani invested in Ramsonn",
  },
  {
    img: img16,
    comment:
      "So excited to see this pursuit of local business, product excellence, and sustainability!",
    name: "Elizabeth invested in JJ fister",
  },
  {
    img: img17,
    comment:
      "I invested because I have been involved with farming and ranching most of my life.",
    name: "Kiren Pinvested in GrowGurru",
  },
  {
    img: img18,
    comment:
      "Love the idea of bazz, I think we all need to save the environment and use plant-based materials/oils to clean",
    name: "Vasuki  invested in State Laboratories",
  },
  {
    img: img19,
    comment: "I invested because Aradiya is a rockstar!",
    name: "Joshi mutt invested in the needle",
  },
];
const list3 = [
  {
    img: img21,
    comment:
      "I have a love of nature and believe in protecting the environment",
    name: " Gunashekar invested in YALBI",
  },
  {
    img: img22,
    comment:
      "As I am a consumer of your product - I know the tremendous value it",
    name: "Nikhil Adiga invested in Bevo",
  },
  {
    img: img23,
    comment:
      "I believe we should do our best to make this world a better place. You’re doing that. ",
    name: "Shantala  invested in Universal Tree",
  },
  {
    img: img24,
    comment: "I have a strong belief that indy will continue to grow",
    name: "Janardhan invested in Indy",
  },
  {
    img: img25,
    comment:
      "It is a promising and innovative technology that has tremendous potential to make it big",
    name: "Nirmala raman invested in ponderr",
  },
  {
    img: img26,
    comment:
      "Sourabh is an amazing person. Mindpower is a company that the world",
    name: "Jerry invested in Mindpower",
  },
  {
    img: img27,
    comment:
      "Great idea! I look forward to your Product becoming mainstream and consolidating all these financial applications",
    name: "Davis invested in Avifoxx",
  },
  {
    img: img28,
    comment:
      "Great idea! I look forward to your Product becoming mainstream and consolidating all these financial applications",
    name: "Davis invested in Avifoxx",
  },
  {
    img: img29,
    comment:
      "Great idea! I look forward to your Product becoming mainstream and consolidating all these financial applications",
    name: "Davis invested in Avifoxx",
  },
];

