import React from "react";
import Card from "../../../../../common/cards/Card";
import Img1 from "../../../../../assets/images/img/techneed1.png";
import Img2 from "../../../../../assets/images/img/techneed2.png";
import Img3 from "../../../../../assets/images/img/techneed3.png";
import Img4 from "../../../../../assets/images/img/techneed4.png";
import Img5 from "../../../../../assets/images/img/techneed5.png";
import Img6 from "../../../../../assets/images/img/techneed6.png";
import FirstButton from "../../../../../common/button";
import DropDown from "../../../../../common/dropdown";
import { useNavigate } from "react-router-dom";
const list = ["AI & Machine Learning", "₹ 1 CR+ Revenue", "Clear all"];
const TechonologyNeed = () => {
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    if (id === 1) {
      navigate("/marketplace/techneedpage");
    }
  };
  return (
    <div>
      <div className="mtpo_cont1">
        <div style={{ width: "100%" }}>
          <input className="login_input" placeholder=" Start typing for Search" />
        </div>
        <div style={{ width: "100%" }}>
          <DropDown placeholder="Sector" />
        </div>
        <div style={{ width: "100%" }}>
          <DropDown placeholder="Sort by" />
        </div>
      </div>
      <div className="mtpo_cont2">
        {list.map((item, index) => (
          <div className="mtpo_p" key={index}>
            <p>{item} </p>
            <p>
              {" "}
              <i className="fa fa-close"></i>
            </p>
          </div>
        ))}
      </div>
      <div className="mtpo_cardmain">
        {cardData.map((item, index) => (
          <div
            key={index}
            className="mtpo_mr wd30"
            onClick={() => handleNavigate(item.id)}
          >
            <Card data={item} type="need" />
          </div>
        ))}
      </div>

      <div className="reoff_cont7">
        <FirstButton buttonText="View More" className="borderbtn" />
      </div>
    </div>
  );
};

export default TechonologyNeed;

const cardData = [
  {
    id: 1,
    image: Img1,
    description:
      "Our goal is to identify or develop tools which can accurately measure TA and brix in the field expediently without requiring expert training.",
    title:
      "Seeking In-Field Tools To Accurately Measure Citrus Raw Juice Quality",
    tags: ["Food ", "Agriculture", "IT"],
    engagements: "Licensing, Partnership, Research  ",
    size: "$50,000",
    daysleft: "40",
    numberofinterests: "07",
    patientorigin:"India"
  },
  {
    image: Img2,
    description:
      "Seeker is evaluating different natural color ingredients and technologies to replace Yellow #5, Yellow #6, Red #40, and Blue #1 for multiple food and beverage brands in order to address consumer demand. ",
    title: "Seeking Natural Colors For Foods And Beverages",
    tags: ["Food ", "Beverage", "Technology"],
    engagements: "Licensing, Technology Transfer   ",
    size: "$2,50,000",
    daysleft: "40",
    numberofinterests: "03",
      patientorigin:"USA"
  },

  {
    image: Img3,
    description:
      "60% of the total carbon footprint of the laundry product comes from what’s called “use phase”, meaning when we use the products to clean our clothes at home. ",
    title: "Seeking Fabric Care Sustainable Chemistry Solutions",
    tags: ["Environment ", "Consumer Products", "Technology"],
    engagements: "Partnership, Research  ",
    size: "$ 100,000",
    daysleft: "40",
    numberofinterests: "04",
      patientorigin:"Netherlands"
  },
  {
    image: Img4,
    description:
      "Re-closable fiber-based closure for a paper beverage bottle that can be recycled in the paper stream. ",
    title: "Seeking Recyclable Closure For Paper Beverage Bottles",
    tags: ["Recycling ", "Food", "Waste Management"],
    engagements: "Licensing, Partnership, Research ",
    size: "$ 80,000 ",
    daysleft: "50",
    numberofinterests: "05",
    patientorigin:"France"
  },
  {
    image: Img5,
    description:
      "Develop a super absorbent polymer - SAP - material from sustainable sources with good fluid handling properties, able to replace the currently used raw material.",
    title: "Seeking Sustainable Superabsorbent Polymer",
    tags: ["Environment", "Chemical", "Sustainability"],
    engagements: "Licensing, Partnership, Technology Transfer ",
    size: "$75,000",
    daysleft: "50",
    numberofinterests: "04",
      patientorigin:"India"
    
  },
  {
    image: Img6,
    description:
      "Invention relates to a method and a device useful for directly and rapidly quantifying the presence in milk of Aflatoxin M1",
    title: "Rapid Detection Of Aflatoxin M1 In Milk",
    tags: ["Food", "Beverage", "Microbiology"],
    engagements: "Licensing ",
    patientorigin: "India",
    patientownership: ["India", "U.S.A"],
    trllevel: "Nine",
    daysleft: "50 ",
    numberofinterests: "04",
     size: "NA",
  },
];
