import React from "react";
import t1 from "../../../../../assets/images/team1.png";
import t2 from "../../../../../assets/images/team2.png";
import t3 from "../../../../../assets/images/team3.png";
import t4 from "../../../../../assets/images/team4.png";
import t5 from "../../../../../assets/images/team5.png";
import { Button } from "@material-ui/core";

const About = () => {
  return (
    <>
      <div className="about">
        <div className="about_container">
          <h1 className="brands_primary">About Panwar Jet</h1>
          <div className="comp_info_container">
            <div className="comp_info">
              <div>
                <p className="comp_info--title">Legal Name </p>
                <p className="comp_info--data">Panwar Jet </p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--title">No of employees </p>
                <p className="comp_info--data">26</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--title">Incorporation number</p>
                <p className="comp_info--data">U749999KR20190PC009348</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--title">DIPP number</p>
                <p className="comp_info--data">41102</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--title">Head Quartered  Location</p>
                <p className="comp_info--data">Bangalore,Karnataka</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--title">Incorporation date</p>
                <p className="comp_info--data">30th November 2019</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--title">Organization type</p>
                <p className="comp_info--data">Private </p>
              </div>

              {/* <div style={{marginTop:"4rem"}}>
              <p className="comp_info--title">No of employees </p>
              <p className="comp_info--data">26</p>
            </div> */}
            </div>
          </div>

          <div className="teams">
            <h1 className="teams_primary">Our Team</h1>
            <div className="teams_container">
              <div className="teamsRow--1">
                <img alt="" src={t4} />
                <div className="team_info">
                  <p className="comp_info--data">
                    Nick Joe{" "}
                    <span style={{ color: "#B2B2B2" }}>
                      Chief Engineer & C0-Founder
                    </span>{" "}
                    <i className="fa-brands fa-linkedin-in comp_social_icon"></i>{" "}
                  </p>
                  <p style={{ margin: "2rem 0" }} className="comp_info--data">
                    DIN Number
                    <span style={{ color: "#B2B2B2" }}>xxxxxxxxxx</span>
                  </p>
                  <p className="reoffcard_p2">
                    Nick Panhwar has 20 years of experience as a dynamic leader
                    with a marine & aerospace engineering background
                  </p>
                </div>
              </div>
              <div className="teamsRow--2">
                <img alt="" src={t3} />
                <div className="team_info">
                  <p className="comp_info--data">
                    Manish Chaudary{" "}
                    <span style={{ color: "#B2B2B2" }}>CTO and Co-Founder</span>{" "}
                    <i className="fa-brands fa-linkedin-in comp_social_icon"></i>{" "}
                  </p>
                  <p style={{ margin: "2rem 0" }} className="comp_info--data">
                    DIN Number
                    <span style={{ color: "#B2B2B2" }}>xxxxxxxxxx</span>
                  </p>
                  <p className="reoffcard_p2">
                    He also worked with leading aerospace companies on different
                    technology solutions
                  </p>
                </div>
              </div>
            </div>

            <div className="teams_container">
              <div className="teamsRow--1">
                <img alt="" src={t1} />
                <div className="team_info">
                  <p className="comp_info--data">
                    Yisha Panwar{" "}
                    <span style={{ color: "#B2B2B2" }}>CEO & Founder</span>{" "}
                    <i className="fa-brands fa-linkedin-in comp_social_icon"></i>{" "}
                  </p>
                  <p style={{ margin: "2rem 0" }} className="comp_info--data">
                    DIN Number
                    <span style={{ color: "#B2B2B2" }}>xxxxxxxxxx</span>
                  </p>
                  <p className="reoffcard_p2">
                    Ayisha Panhwar is Innovation champion with a 17 years of a
                    AI system engineering background.
                  </p>
                </div>
              </div>
              <div className="teamsRow--2">
                <img alt="" src={t2} />
                <div className="team_info">
                  <p className="comp_info--data">
                    Syeda Zainab{" "}
                    <span style={{ color: "#B2B2B2" }}>COO & Co-Founder</span>{" "}
                    <i className="fa-brands fa-linkedin-in comp_social_icon"></i>{" "}
                  </p>
                  <p style={{ margin: "2rem 0" }} className="comp_info--data">
                    DIN Number
                    <span style={{ color: "#B2B2B2" }}>xxxxxxxxxx</span>
                  </p>
                  <p className="reoffcard_p2">
                    Zainab understands the complexities of business, politics,
                    and culture. She has been assisting with research work, look
                    after the simulation & testing.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mentors">
            <h1 className="teams_primary">Investor and Mentor </h1>
            <div className="mentors_list">
              <div className="mentor">
                <img src={t5} alt="" />
                <p className="comp_info--title">Shika Joe</p>
                <p className="comp_info--data">Mentor</p>
              </div>

              <div className="mentor">
                <img src={t2} alt="" />
                <p className="comp_info--title">Manish Chaudary</p>
                <p className="comp_info--data">Angel investor</p>
              </div>

              <div className="mentor">
                <img src={t3} alt="" />
                <p className="comp_info--title">Prashanth Koman</p>
                <p className="comp_info--data">Investor</p>
              </div>

              <div className="mentor">
                <img src={t4} alt="" />
                <p className="comp_info--title">Dushyanth</p>
                <p className="comp_info--data">Angel investor</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flowchart">
        <div className="flowchart_container">
          <h1 style={{ color: "#fff" }} className="primary_title">
            Journey so far
          </h1>
          <div className="box_container">
            <div className="boxItem">
              <p style={{ color: "#b2b2b2", visibility: "hidden" }}>
                jbfdjsjfhsbdjs
              </p>
              <div className="box">
                <span className="box_text">May 2022</span>
              </div>
              <p className="box_subtext">Finalized Our Technology</p>
            </div>

            <div className="boxItem">
              <p className="box_subtext">Finished aircraft designing</p>
              <div className="box">
                <span className="box_text">June 2022</span>
              </div>
              <p style={{ color: "#b2b2b2", visibility: "hidden" }}>
                jbfdjsjfhsbdjs
              </p>
            </div>

            <div className="boxItem">
              <p style={{ color: "#b2b2b2", visibility: "hidden" }}>
                jbfdjsjfhsbdjs
              </p>
              <div className="box">
                <span className="box_text">July 2022</span>
              </div>
              <p className="box_subtext">Production of our first aircraft </p>
            </div>

            <div className="boxItem">
              <p className="box_subtext">Testing our first aircraft</p>
              <div className="box">
                <span className="box_text">August 2022</span>
              </div>
              <p style={{ color: "#b2b2b2", visibility: "hidden" }}>
                jbfdjsjfhsbdjs
              </p>
            </div>

            <div className="boxItem">
              <p style={{ color: "#b2b2b2", visibility: "hidden" }}>
                jbfdjsjfhsbdjs
              </p>
              <div className="box">
                <span className="box_text">September 2022</span>
              </div>
              <p className="box_subtext">File for FAA certification</p>
            </div>
          </div>

          {/* ahead */}
          <h1
            style={{ color: "#fff", marginTop: "15rem" }}
            className="primary_title"
          >
            Journey Ahead
          </h1>
          <div className="box_container">
            <div className="boxItem">
              <p style={{ color: "#b2b2b2", visibility: "hidden" }}>
                jbfdjsjfhsbdjs
              </p>
              <div className="box">
                <span className="box_text">October 2023</span>
              </div>
              <p className="box_subtext">
                Complete production of our first prototype
              </p>
            </div>

            <div className="boxItem">
              <p className="box_subtext">
                Testing in the controlled  environment
              </p>
              <div className="box">
                <span className="box_text">December 2023</span>
              </div>
              <p style={{ color: "#b2b2b2", visibility: "hidden" }}>
                jbfdjsjfhsbdjs
              </p>
            </div>

            <div className="boxItem">
              <p style={{ color: "#b2b2b2", visibility: "hidden" }}>
                jbfdjsjfhsbdjs
              </p>
              <div className="box">
                <span className="box_text">April 2024</span>
              </div>
              <p className="box_subtext">Take our first flight</p>
            </div>

            <div className="boxItem">
              <p className="box_subtext">Complete FAA certification</p>
              <div className="box">
                <span className="box_text">August 2025</span>
              </div>
              <p style={{ color: "#b2b2b2", visibility: "hidden" }}>
                jbfdjsjfhsbdjs
              </p>
            </div>

            <div className="boxItem">
              <p style={{ color: "#b2b2b2", visibility: "hidden" }}>
                jbfdjsjfhsbdjs
              </p>
              <div className="box">
                <span className="box_text">December 2025</span>
              </div>
              <p className="box_subtext">Start delivering aircrafts</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom_status">
        <div></div>
        <Button id="closed" className="btn" variant="contained">
          Investment Closed
        </Button>
      </div>
    </>
  );
};

export default About;
