import React from "react";
import { useNavigate } from "react-router-dom";
import img from "../../../assets/images/icons/market1.svg";
import FirstButton from "../../../common/button";
import { role } from "../../utils";

const MarketPlaceMain = () => {
  const userrole = localStorage.getItem("role");
  const navigate = useNavigate();
  const handelNavigate = () => {
    if (userrole === "marketplace") {
      navigate("/marketplace/techofferlisting");
      window.location.reload();
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <div className="main">
        <div className="main_text">
          <h1 className="primaryText">
            Meet the best-fit technologies of today.
          </h1>
        </div>
        <div className="main_container w90 main_container_mr">
          <div className="main_tiles mr8">
            <p className="secondaryText">
              We scout and validate technologies for you, ensuring that
              innovation teams have access to the technology they require to
              solve business challenges, identify opportunities, and create new
              products.
            </p>
            {userrole === role[2].role ? (
              <FirstButton
                className="btn"
                float="left"
                marginTop="5rem"
                buttonText="Explore Marketplace"
                onClick={handelNavigate}
              />
            ) : (
              <FirstButton
                className="btn"
                float="left"
                marginTop="5rem"
                buttonText="Signup for Marketplace"
                onClick={() => navigate("/signup")}
              />
            )}
          </div>

          <div className="main_content main_imgcont2">
            <img src={img} className="main_img1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketPlaceMain;
