import React from "react";
import thumb from "../../assets/images/card.png";
import company from "../../assets/images/cardIcon.png";
import ProgressBar from "../progressBar/Progress";

import { useNavigate } from "react-router-dom";
const data = {
  amount: "",
  percentage: 140,
  status: 45,
  duration: "",
};

const Card = (props) => {
  const data = props.data;
  const navigate = useNavigate();
  const type = props.type;
  return (
    <div>
      <div className="reoffcard_main w100">
        <div className="reoffcard_main_container">
          <div>
            <img src={data?.image} alt="" width="100%" />
          </div>

          <p className="reoffcard_p1 reoffcard_ex">{data?.title}</p>
          <p className="reoffcard_p2 reheight">{data?.description}.</p>
          <div style={{ height: "8rem" }}>
            <div className="reoffcard_cont2">
              {data &&
                data.tags.map((item, index) => (
                  <div
                    className="reoffcard_cont6 reoffcard_p2"
                    key={index}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item}
                  </div>
                ))}
            </div>
          </div>
          <div className="reoffcard_cont3">
            <p className="reoffcard_p2">Engagements opportunity</p>
            <p
              className="reoffcard_p2"
              style={{ fontWeight: 700, marginTop: "0.5rem", height: "3rem" }}
            >
              {data?.engagements}
            </p>
            <div className="card_cont2">
              <div className="card_cont1">
                {props.type === "need" ? (
                  <div>
                    <p className="card_font1">Project Size</p>
                    <p style={{ fontWeight: "bold" }} className="card_font1">
                      {data?.size}
                    </p>
                  </div>
                ) : (
                  <div style={{ height: "4rem" }}>
                    <p className="card_font1">Patent Origin</p>
                    <p style={{ fontWeight: "bold" }} className="card_font1">
                      {data?.patientorigin}
                    </p>
                  </div>
                )}
                {props.type === "need" ? (
                  <div>
                    <p className="card_font1">Requirement Origin</p>
                    <p style={{ fontWeight: "bold" }} className="card_font1">
                      {data?.patientorigin}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="card_font1">TRL Level</p>
                    <p style={{ fontWeight: "bold" }} className="card_font1">
                      {data?.trllevel}
                    </p>
                  </div>
                )}
              </div>
              <div className="card_cont1">
                {props.type === "need" ? (
                  <div>
                    <p className="card_font1">Days left</p>
                    <p style={{ fontWeight: "bold" }} className="card_font1">
                      {data?.daysleft}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="card_font1">Patent ownership</p>
                    <div style={{ height: "2rem" }}>
                      {data &&
                        data.patientownership.map((item, index) => (
                          <p
                            style={{ fontWeight: "bold" }}
                            className="card_font1"
                          >
                            {item}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
                {props.type === "need" ? (
                  <></>
                ) : (
                  <div>
                    <p className="card_font1">Days left</p>
                    <p style={{ fontWeight: "bold" }} className="card_font1">
                      {data?.daysleft}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="reoff_cont8">
          Number of interests : {data?.numberofinterests}
        </div>
      </div>
    </div>
  );
};

export default Card;
