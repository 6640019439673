import React from "react";
import Card from "../../../common/cards/Card";
import Img2 from "../../../assets/images/img/mtpoimg2.png";
import Img3 from "../../../assets/images/img/techneed2.png";
import Img5 from "../../../assets/images/img/techneed5.png";
import FirstButton from "../../../common/button";
import { useNavigate } from "react-router-dom";
import { role } from "../../utils";
const DonationCards = () => {
  const userrole = localStorage.getItem("role");
  const navigate = useNavigate();
  return (
    <>
      <div className="donation_cards">
        <div className="donation_cards_container">
          <h1 className="story_heading " style={{ marginBottom: "2rem" }}>
            Top 3 Deals now
          </h1>
          <div className="search_results">
            {cardData.map((item, index) => (
              <div key={index} className="mtpo_mr">
                <Card data={item} type="need" />
              </div>
            ))}
          </div>
          <div style={{ textAlign: "center", margin: "2rem 0" }}>
            {userrole === role[2].role ? (
              <FirstButton
                buttonText="Explore All Deals"
                className="btn"
                onClick={() => navigate("/marketplace/techofferlisting")}
              />
            ) : (
              <FirstButton
                buttonText="Signup for Marketplace"
                className="btn"
                onClick={() => navigate("/signup")}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DonationCards;

const cardData = [
  {
    image: Img2,
    description:
      "A superior agitator for mixing tanks in mineral processing plants.",
    title: "Wirl flow - superior agitator for mixing tanks",
    tags: ["Mining", "Minerals", "Environment"],
    engagements: "Licensing, Partnership, Joint Venture  ",
    patientorigin: "Europe",
    patientownership: ["India", "Europe"],
    trllevel: " Six",
    daysleft: "32 ",
    numberofinterests: "07",
    size: "$75,000",
  },
  {
    image: Img3,
    description:
      "Seeker is evaluating different natural color ingredients and technologies to replace Yellow #5, Yellow #6, Red #40, and Blue #1 for multiple food and beverage brands in order to address consumer demand. ",
    title: "Seeking Natural Colors For Foods And Beverages",
    tags: ["Food ", "Beverage", "Technology"],
    engagements: "Licensing, Technology Transfer   ",
    size: "$2,50,000",
    daysleft: "40",
    numberofinterests: "03",
    patientorigin: "USA",
  },
  {
    image: Img5,
    description:
      "Develop a super absorbent polymer - SAP - material from sustainable sources with good fluid handling properties, able to replace the currently used raw material.",
    title: "Seeking Sustainable Superabsorbent Polymer",
    tags: ["Environment", "Chemical", "Eco"],
    engagements: "Licensing, Partnership, Technology Transfer ",
    size: "$75,000",
    daysleft: "50",
    numberofinterests: "04",
    patientorigin: "India",
  },
];
