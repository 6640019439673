import React from "react";
import { Line, Circle } from "rc-progress";

const ProgressBar = (props) => {
  const type = props.type
  return (
    <div className="progressBar_container">
      <div className="progressData" style={{ marginBottom: "0.5rem" }}>
        <p>
          {" "}
          <span style={{ fontWeight: "700", marginLeft: "0.8rem" }}>{`${props && props.data && props.data.percentage
            }%`}</span>{" "}
          Raised{" "}
        </p>
        <p>
          {" "}
          {type === "samai" ? <span style={{ fontWeight: "400", marginRight: "0.8rem" }}>
            {props && props.data && props.data.status}
          </span> : <span style={{ fontWeight: "700" }}>
            {`${props && props.data && props.data.status}`} days left
          </span>}

        </p>
      </div>
      {/* <progress
      
        className="progressBar"
        id="progressid"
        value={props && props.data && props.data.value}
        max="100"
        style={{
          color: `${props.color}!important`,
          backgroundColor: `${props.bgcolor}!important`,
        }}
      ></progress> */}
      <Line
        // percent={props && props.data && props.data.value}
        percent={props && props.data && props.data.value}
        strokeColor={`${props?.data.color}`}
        trailColor={props?.data.bgcolor}
        style={{
          height: "1.5rem",
          width: "100%",
          boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.25)",
          borderRadius: "25rem",
        }}
      />
      <div className="progressData">
        <p>
          {" "}
          <span style={{ fontWeight: "700", marginLeft: "0.8rem" }}>
            {props && props.data && props.data.amount}
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400" }}>
            {props && props.data && props.data.duration}
          </span>
        </p>
      </div>
    </div>
  );
};
export default ProgressBar;
