import React from "react";
import Sidebar from "../Sidebar";
import Img from "../../../../assets/images/oicons/svg/congrats.svg";
import FirstButton from "../../../../common/button";
import { useNavigate } from "react-router-dom";

const MarketPlaceMain = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/marketplace/marketplaceactivity");
  };
  return (
    <div className="cfp_main">
      <Sidebar />

      <div className="cfp_cont1">
        <div style={{ textAlign: "center", paddingTop: "8rem" }}>
          <img src={Img} alt="" width="164rem" height="164rem" />
          <h4 className="cfp_mr2">Bring your project to life.</h4>
          <FirstButton
            buttonText="Discover Marketplace"
            className="btn"
            marginTop="2rem"
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default MarketPlaceMain;
