import React from "react";
import Img1 from "../../../../assets/images/img/completecard1.png";
import Img2 from "../../../../assets/images/img/completecard2.png";
import Img3 from "../../../../assets/images/img/completecard3.png";
import Img4 from "../../../../assets/images/img/completecard4.png";
import Img5 from "../../../../assets/images/img/completecard5.png";
import Img6 from "../../../../assets/images/img/completecard6.png";
import Img7 from "../../../../assets/images/img/completecard7.png";
import Img8 from "../../../../assets/images/img/completecard8.png";
import Img9 from "../../../../assets/images/img/completecard9.png";
import Img10 from "../../../../assets/images/img/investcard1.png";
import Img11 from "../../../../assets/images/img/investcard17.png";
import Img12 from "../../../../assets/images/img/investcard3.png";
import Imgsmall1 from "../../../../assets/images/img/completesmall1.png";
import Imgsmall2 from "../../../../assets/images/img/completesmall2.png";
import Imgsmall3 from "../../../../assets/images/img/completesmall3.png";
import Imgsmall4 from "../../../../assets/images/img/completesmall4.png";
import Imgsmall5 from "../../../../assets/images/img/completesmall5.png";
import Imgsmall6 from "../../../../assets/images/img/completesmall6.png";
import Imgsmall7 from "../../../../assets/images/img/completesmall7.png";
import Imgsmall8 from "../../../../assets/images/img/completesmall8.png";
import Imgsmall9 from "../../../../assets/images/img/completesmall9.png";
import Imgsmall10 from "../../../../assets/images/img/investsmall1.png";
import Imgsmall11 from "../../../../assets/images/img/investsmall17.png";
import Imgsmall12 from "../../../../assets/images/img/investsmall3.png";
import InvestmentCard from "../../../../common/cards/InvestmentCard";
import FirstButton from "../../../../common/button";
import SearchFilter from "../SearchFilter";
import { useNavigate } from "react-router-dom";
const All = () => {
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    if (id === 3) {
      navigate("/search/detail");
    }
  };
  return (
    <div>
      <div className="tabContent">
        <h1 className="tab_heading">Completed campaigns</h1>
        <p className="tab_subHeading">
          90% of Smaarket campaigns have been successfully funded
        </p>
      </div>
      <div className="search-filter">
        <SearchFilter />
      </div>
      <div className="adaall_main">
        {cardData.map((item, index) => (
          <InvestmentCard
            data={item}
            type="complete"
            key={index}
            onClick={() => handleNavigate(item.id)}
          />
        ))}
      </div>
      <div className="mdp_cont adall_btnmr">
        <FirstButton buttonText="View More" marginTop="2rem" className="btn" />
      </div>
      <div>
        <div className="tabContent">
          <h1 className="tab_heading">Active Deals</h1>
        </div>
        <div className="search-filter">{/* <SearchFilter/> */}</div>
        <div className="adaall_main">
          {cardDataupcoming.map((item, index) => (
            <InvestmentCard data={item} type="donations" key={index} />
          ))}
        </div>
        <div className="mdp_cont adall_btnmr">
          <FirstButton buttonText="See all" marginTop="2rem" className="btn" />
        </div>
      </div>
    </div>
  );
};

export default All;
const cardData = [
  {
    img: Img1,
    img2: Imgsmall1,
    title: "Blue Thread",
    description:
      "Custom-fit clothing made to your measurements in one week, from just 2 photos",
    tags: ["Fashion", "Women Founder", "CCD", "B2C", "Venture Funded"],
    progressdata: {
      amount: "",
      percentage: 126.2,
      status: 90,
      duration: "",
      value: "20",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "1,40,75,000",
    target: "2,25,00,000",
    investors: "178",
  },
  {
    img: Img2,
    img2: Imgsmall2,
    title: "Dezolenator",
    description:
      "Solar Thermal Desalination Solution | Off-grid, affordable, and scalable",
    tags: ["Power", "Sustainability", "CCD", "Technology", "B2B"],
    progressdata: {
      amount: "",
      percentage: 251.4,
      status: 90,
      duration: "",
      value: "50",
      color: "#105039",
      bgcolor: "#19976A",
    },
    minamount: "1,65,54,000",
    target: "1,00,00,000",
    investors: "156",
  },
  {
    id: 3,
    img: Img3,
    img2: Imgsmall3,
    title: "Panwar Aero",
    description:
      "Luxury All-Electric Long Range Jet disrupting Aviation Industry",
    tags: ["Mobilty", "Transportation", "CCD", "Sustainability", "Technology"],
    progressdata: {
      amount: "",
      percentage: 156.2,
      status: 60,
      duration: "",
      value: "60",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "76,00,000",
    target: "50,50,000",
    investors: "79",
  },
  {
    img: Img4,
    img2: Imgsmall4,
    title: "Redd Aerospace",
    description:
      "World's 1st carbon-neutral biofuel-powered space launch service.",
    tags: ["Aerospace", "Sustainability", "CCD", "Space", "B2B"],
    progressdata: {
      amount: "",
      percentage: 106.5,
      status: 45,
      duration: "",
      value: "10",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "2,20,50,000",
    target: "1,25,50,000",
    investors: "234",
  },
  {
    img: Img5,
    img2: Imgsmall5,
    title: "Swiz Rail",
    description: "5x faster and 40x cheaper than traditional transit.",
    tags: ["Transportation", "Mobility", "CCD", "B2C", "Women founder"],
    progressdata: {
      amount: "",
      percentage: 142.45,
      status: 45,
      duration: "",
      value: "40",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "1,40,56,000",
    target: "50,00,000",
    investors: "45",
  },
  {
    img: Img6,
    img2: Imgsmall6,
    title: "Yess Tech",
    description: "Safe, Advanced, Affordable, Personal Mobility.",
    tags: ["Health Care", "B2C", "CCD", "Health and wellness"],
    progressdata: {
      amount: "",
      percentage: 111.15,
      status: 45,
      duration: "",
      value: "10",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "70,20,000",
    target: "35,000",
    investors: "136",
  },
  {
    img: Img7,
    img2: Imgsmall7,
    title: "Zeco Tech",
    description:
      "Advanced automation & road materials for more sustainable infrastructure.",
    tags: ["Mobility", "Transportation", "CCD", "B2C"],
    progressdata: {
      amount: "",
      percentage: 100.25,
      status: 45,
      duration: "",
      value: "1",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "89,65,000",
    target: "50,00,000",
    investors: "212",
  },
  {
    img: Img8,
    img2: Imgsmall8,
    title: "Bio Workz",
    description: "Extracting lithium using engineered microbes. ",
    tags: ["Bio-Technology", "generator", "CCD", "B2B", "Venture Funded"],
    progressdata: {
      amount: "",
      percentage: 376.5,
      status: 45,
      duration: "",
      value: "80",
      color: "#091712",
      bgcolor: "#105039",
    },
    minamount: "98,54,000",
    target: "45,70,000",
    investors: "189",
  },
  {
    img: Img9,
    img2: Imgsmall9,
    title: "ModVans",
    description: "Pioneering healthy, fresh food & Desi styled coffee.",
    tags: [
      "Artificial intelligence",
      "B2B",
      "CSOP",
      "Mobility",
      "Sustainability",
    ],
    progressdata: {
      amount: "",
      percentage: 230.35,
      status: 45,
      duration: "",
      value: "30",
      color: "#105039",
      bgcolor: "#19976A",
    },
    minamount: "76,67,000",
    target: "2,25,00,000",
    investors: "142",
  },
];

const cardDataupcoming = [
  {
    img: Img10,
    img2: Imgsmall10,
    title: "SAMAI",
    description: "Patented, single-use alternative: edible, compostable cups",
    tags: ["Sustainable","Women founder", "CCD", "Bio-Technology", "Food"],
    progressdata: {
      amount: "",
      percentage: 44.56,
      status: 40,
      duration: "",
      value: "40",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "20,000",
    target: "1,25,50,000",
    investors: "23",
  },
  {
    img: Img11,
    img2: Imgsmall11,
    title: "ARACE FEMCARE",
    description:
      "First farm-to-body tampon with hemp fiber - leading innovation for period care",
    tags: ["Sustainable", "Women Founder", "CCD", "Health and wellness", "B2B"],
    progressdata: {
      amount: "",
      percentage: 70.55,
      status: 34,
      duration: "",
      value: "70.55",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "40,000",
    target: "90,00,000",
    investors: "68",
  },
  {
    img: Img12,
    img2: Imgsmall12,
    title: "ZYSTEM 3E",
    description:
      "Building has never been easier. The only dry-stack wall construction system in the world.",
    tags: ["Construction", "Technology", "CCD", "Venture Funded", "B2B"],
    progressdata: {
      amount: "",
      percentage: 100.92,
      status: 12,
      duration: "",
      value: "2",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "35,000",
    target: "75,50,000",
    investors: "100",
  },
];
