import React from "react";
import { useNavigate } from "react-router-dom";
import FirstButton from "../../../../common/button";
import Sidebar from "../Sidebar/Sidebar";

const MainDonationFinancials = () => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/maincampdonations/analytics");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Financial</h4>
          <div className="cfp_wd1">
            <div>
              <p className="tab_subHeading">
                Demonstrate to the donors that you will be able to handle the
                money responsibly and successfully.
              </p>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">
                Utilization of crowd funding raised money{" "}
              </p>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Amount</p>
                  <input
                    placeholder="Enter"
                    type="text"
                    className="login_input"
                  />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Intended purpose</p>
                  <input
                    placeholder="Type here"
                    type="text"
                    className="login_input"
                  />
                </div>
              </div>
              <div className="mdf_btncont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add +"
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Risks and Challenges</p>
              <input
                placeholder="Type Here"
                type="text"
                className="login_input"
              />
              <div className="mdf_btncont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add +"
                  width="99%"
                  borderRadius="5rem"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/maincampdonations/perks")}
        />
        <FirstButton
          buttonText="Save and next"
          onClick={handleSubmit}
          className="btn"
        />
      </div>
    </div>
  );
};

export default MainDonationFinancials;
