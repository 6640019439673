import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import scrollto from "../../../assets/images/oicons/svg/scrollup.svg";
const FoundersFaq = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {question.map((item, index) => (
        <Accordion
          key={index}
          style={{ boxShadow: "none", border: "none", padding: "0" }}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#19976A", fontSize: "1.6rem" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ padding: "0 !important", border: "0 !important" }}
          >
            <p className="faq_f3 faq_f600">{item.ques}</p>
          </AccordionSummary>

          <AccordionDetails>
            <div
              className="faq_f3"
              dangerouslySetInnerHTML={{ __html: item.ans }}
            />
          </AccordionDetails>
        </Accordion>
      ))}

      <div className="dflex alcenter faq_mr3">
        <p className="faq_f3">
          If you cant find what youre looking for write to us.{" "}
          <span>
            {" "}
            <Link className="faq_f600">Email us </Link>{" "}
          </span>
        </p>
        <img src={scrollto} onClick={scrolltotop} />
      </div>
    </div>
  );
};

export default FoundersFaq;

const question = [
  {
    ques: "What is Smaarket?",
    ans: "There is no better location to begin the search for fascinating and creative businesses and inventions that surprise and amaze with live crowdfunding campaigns and a marketplace.",
  },
  {
    ques: "What are the product stages in donation?",
    ans: "There are four product stages: <br/> Concept Stage: Campaigns in the concept stage have an idea for a physical perk that the campaigner intends to create but have yet to create a physical prototype. They may have mockups, drawings, or 3D renderings demonstrating the perk’s appearance or functionality once it is produced. Very basic prototypes (such as those composed of clay, wood, or paper) can also demonstrate that your campaign is in the concept stage. All Tech & Innovation campaigns that opt-in to product stages will default to concept stages.<br/> Prototype: Campaigns in the prototype stage have a working version of their physical perk. The campaign’s video successfully demonstrates the performance of core functions and working features as described in the campaign story. To be approved for the prototype stage, campaigners must embed a high-quality video showing a working prototype on their campaign page. The video provided on the campaign page must demonstrate the key features and functionality of the physical prototype. <br/> Production: Campaigns in the production stage have begun the process of turning their prototype into a mass-produced perk. In order to be approved for production, campaigners need to provide clear evidence that their perk is being manufactured at scale. <br/> Shipping: Campaigns in the shipping stage currently have the inventory to ship perks to their backers and have begun actively shipping contributions to their backer community. In order to be approved for the shipping stage, proof of visual preparation for shipping, such as completed components or packaging, is required. If a campaigner is using Post, once 80% of their contributions’ tracking numbers have been uploaded, their campaign will be approved for the shipping stage. Keep in mind that all projects must meet Smaarket Terms of Use.",
  },
  {
    ques: "Where can I learn more about a campaign's progress?",
    ans: "We suggest that backers read through the campaign page; videos, photos, and updates are good resources to learn about a campaign’s production progress and development.",
  },
  {
    ques: "Why don't all campaigns have product stages?",
    ans: "Product stages are currently only available for campaigns that fall under 'Technology & Innovation' with physical perks. We hope to expand this program to other categories in the future.",
  },
  {
    ques: "What campaigns fall under the 'technology and innovation' category?",
    ans: "Energy and Green Technology, Food & Beverages, Health & Fitness, Home Productivity, Transportation.",
  },
  {
    ques: "How to Evaluate a Campaign?",
    ans: "Who Are The People Behind The Campaign? <br/>The campaign owner and any team members will be listed in the 'Campaigner Profile Cards,' located below the Campaign Owner's Name. Click 'About' to see more information about the campaign owner and the team behind the campaign. <br/>Who are they? Why are they raising money? What is their background and experience? Why are they passionate? Are photos of the team members included? Does the campaigner appear in the campaign’s video? Are external links included (websites, news/media coverage, etc.)? If their background and motivation for the project are not clear in the pitch, you can always do a web search to learn more about their professional experience and how it may relate to the project. What Is the Campaign Raising Money For? The campaign’s pitch should be clear, and transparent, and reflect the team’s passion and hard work. Is the pitch transparent and personal? How about the perk info? Any images of the perks? Descriptions? Does the pitch text provide any perk fulfillment information? When would you get your perk? Has the campaign already raised funds? Successful projects usually secure a strong funding base from close family and friends who know the campaign owners personally.",
  },
  {
    ques: "Does Smaarket Guarantee Perks?",
    ans: "Smaarket is unable to guarantee that projects will succeed or that perks will be delivered or deemed satisfactory.",
  },
  {
    ques: "What does Smaarket do to protect us?",
    ans: "If something looks wrong to you, let us know! Use the 'feedback' link, located at the bottom of every campaign, to report the campaign to our Trust & Safety Team. The Trust & Safety team monitors feedback in order to determine the correct course of action to protect our community.",
  },
  {
    ques: "What do you charge?",
    ans: "Smaarket charges 0.5% as a convenience fee of the total donated + Tax (if applicable)+ Payment gateway fee (if applicable) on the total donation, but only if successful. These fees are not refundable or adjustable.",
  },
];
