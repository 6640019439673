import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Img from "../../../assets/images/logo/userProfilemyprofiler.png";

const Sidebar = () => {
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const name = localStorage.getItem("name");
  return (
    <div className="sidebar_main" style={{textAlign:"start",alignItems:"start"}}>
      <div className="sidebar_details sideBarNav_profilepd">
        <img src={Img} alt="" />
        <p>{name}</p>
        <p>AW0145</p>
      </div>

      <div></div>

      <div className="sidebar_cont1">
        <nav className="nav-wd">
          <div className="nav_cont">
            <div
              className={
                splitLocation[2] === "profile" ? "sideBarNav_itemprofileactive" : "sideBarNav_itemprofile"
              }
            >
              <Link to="/investment/profile">My Profile</Link>
            </div>
            <div
              className={
                splitLocation[2] === "portfolio" ? "sideBarNav_itemprofileactive" : "sideBarNav_itemprofile"
              }
            >
              <Link to="/investment/portfolio">Portfolio</Link>
            </div>

            <div
              className={
                splitLocation[2] === "watchlist" ? "sideBarNav_itemprofileactive" : "sideBarNav_itemprofile"
              }
            >
              <Link to="/investment/watchlist">Watchlist</Link>
            </div>

            <div
              className={
                splitLocation[2] === "referandearn"
                  ? "sideBarNav_itemprofileactive"
                  : "sideBarNav_itemprofile"
              }
            >
              <Link to="/investment/referandearn">Refer and Earn</Link>
            </div>

            <div
              className={
                splitLocation[2] === "settings" ? "sideBarNav_itemprofileactive" : "sideBarNav_itemprofile"
              }
            >
              <Link to="/investment/settings">Settings</Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
