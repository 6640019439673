import React, { useState } from "react";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import CompletedImg from "../../assets/images/oicons/svg/tick.svg";

import FirstButton from "../../common/button";
const steps = ["Select campaign settings", "Create an ad group"];
const Verification = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isBankVerified, setIsBankVerified] = useState(false);
  const { role } = props;
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    isBankVerified(false);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setIsVisible(true);
    setActiveStep(null);
    setTimeout(() => {
      setIsVisible(false);
      setIsBankVerified(true);
      handleNext();
      if (completed[1] === true) {
        props.change(2, props.role);
      }
    }, 3000);
  };

  return (
    <div>
      <Stepper activeStep={activeStep} className="stepper">
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton className="stepButton" onClick={handleStep(index)}>
              {/* {label} */}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div></div>
      {activeStep === 0 && (
        <div className="step0_main">
          <p className="step0_p1">Know Your Customer</p>
          <p className="step0_p2">
            The law requires us to gather some data in order to raise money.
            <br />
            Your data are encrypted and secured.
          </p>

          {role === 1 ? (
            <div className="step0_cont1">
              <h4 className="step0_h4">Legal Business Name</h4>
              <p className="step0_p4 step_font">
                Enter your business name as registered.
              </p>
              <input
                className="login_input"
                type="text"
                placeholder="Type Here"
              />

              <div className="step0_cont1" style={{ maxWidth: "40rem" }}>
                <h4
                  className="step0_h4"
                  style={{ padding: "2rem 0 2rem 0", width: "50rem" }}
                >
                  Business Permanent Account Number (PAN)
                </h4>
                <input
                  className="login_input"
                  type="text"
                  placeholder="Type Here"
                />
              </div>

              <div className="step0_cont3">
                <FirstButton
                  buttonText="Verify"
                  width="100%"
                  margin=""
                  className="btn"
                  onClick={handleComplete}
                />
              </div>
            </div>
          ) : role == 2 ? (
            <div className="step0_cont1">
              <h4 className="step0_h4">Full Legal Name</h4>
              <p className="step0_p4">
                Enter your business name as registered.
              </p>
              <input
                className="login_input"
                type="text"
                placeholder="Type Here"
              />

              <div className="step0_cont1" style={{ maxWidth: "40rem" }}>
                <h4
                  className="step0_h4"
                  style={{ padding: "2rem 0 2rem 0", width: "50rem" }}
                >
                  Your Permanent Account Number
                </h4>
                <input
                  className="login_input"
                  type="text"
                  placeholder="Type Here"
                />
              </div>

              <div className="step0_cont3">
                <FirstButton
                  buttonText="Verify"
                  width="100%"
                  margin=""
                  className="btn"
                  onClick={handleComplete}
                />
              </div>
            </div>
          ) : role == 3 ? (
            <div className="step0_cont1">
              <h4 className="step0_h4">Full Legal Name</h4>
              <p className="step0_p4">
                Enter your business name as registered.
              </p>
              <input
                className="login_input"
                type="text"
                placeholder="Type Here"
              />

              <div className="step0_cont1" style={{ maxWidth: "40rem" }}>
                <h4
                  className="step0_h4"
                  style={{ padding: "2rem 0 2rem 0", width: "50rem" }}
                >
                  Your Permanent Account Number
                </h4>
                <input
                  className="login_input"
                  type="text"
                  placeholder="Type Here"
                />
              </div>

              <div className="step0_cont3">
                <FirstButton
                  buttonText="Verify"
                  width="100%"
                  margin=""
                  className="btn"
                  onClick={handleComplete}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {isVisible === true &&
        completed[0] === true &&
        isBankVerified === false && (
          <div className="setp_completed">
            <img src={CompletedImg} alt="" />
            <p className="">Permanent Account Number verified</p>
          </div>
        )}

      {activeStep === 1 && (
        <div className="step0_main">
          <p className="step0_p1">Bank Account Verification</p>
          <p className="step0_p2">
            The law requires us to gather some data in order to raise money.
            <br />
            Your data are encrypted and secured.
          </p>
          <div className="step0_cont1" style={{ maxWidth: "40rem" }}>
            {role === 2 ? (
              <h4 className="step0_h4" style={{ padding: "0rem 0 1rem 0" }}>
                Bank Account Number
              </h4>
            ) : (
              <h4 className="step0_h4" style={{ padding: "0rem 0 1rem 0" }}>
                Current Account Number
              </h4>
            )}

            <input
              className="login_input"
              type="text"
              placeholder="Type Here"
            />

            <div className="step0_cont1" style={{ maxWidth: "40rem" }}>
              <h4 className="step0_h4" style={{ padding: "2rem 0 1rem 0" }}>
                IFSC Code
              </h4>
              <input
                className="login_input"
                type="text"
                placeholder="Type Here"
              />
            </div>

            <div className="step0_cont3">
              <p className="step0_p5">
               Smaarket does not process or store your bank account information.
              </p>
              <FirstButton
                buttonText="Verify"
                width="100%"
                margin="3rem 0 2rem 0"
                className="btn"
                onClick={handleComplete}
              />
            </div>
          </div>
        </div>
      )}
      {completed[1] === true && isBankVerified === true && (
        <div className="setp_completed">
          <img src={CompletedImg} alt="" />
          <p className="">Bank Details verified</p>
        </div>
      )}
    </div>
  );
};

export default Verification;
