import React from "react";
import Dropdown from "../../../common/dropdown";
import scroll from "../../../assets/images/oicons/svg/scrollup.svg";
import FirstButton from "../../../common/button";
import { Navigate, useNavigate } from "react-router-dom";

const list = [
  { value: "1", label: "University" },
  { value: "2", label: "Academic institutions" },
  { value: "3", label: "Research labs" },
];

const TechnologyAssessment = () => {
  const navigate = useNavigate();
  const handlescroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="ta_main">
        <div className="ta_maincont">
          <h1 className="ta_h1">Technology Assessment</h1>
          <div className="ta_mr">
            <h2 className="ta_f1">Category</h2>
            <p className="ta_f2">Please choose organisation type </p>
            <Dropdown MenuList={list} />
          </div>
          <div className="ta_mr">
            <h2 className="ta_f1">Title</h2>
            <p className="ta_f2">
              Please enter full title  of research paper / thesis submitted{" "}
            </p>
            <input className="login_input" placeholder="Type Here" />
          </div>
          <div className="ta_mr">
            <h2 className="ta_f1">Sector</h2>
            <p className="ta_f2">
              Please choose the industry your solution is most  relevant for
            </p>
            <Dropdown />
          </div>
          <div className="ta_mr">
            <h2 className="ta_f1">Abstract</h2>
            <p className="ta_f2">
              Please enter abstract of your research paper /Thesis (maximum 1000
              words)
            </p>
            <input className="login_input" placeholder="Type Here" />
          </div>
          <div className="ta_mr">
            <h2 className="ta_f1">Contact details </h2>
            <p className="ta_f2">Author name</p>
            <input className="login_input" placeholder="Type Here" />
            <p className="ta_f2">Mobile</p>
            <input className="login_input" placeholder="+91" />
            <p className="ta_f2">Email</p>
            <input className="login_input" placeholder="Type Here" />
          </div>
          <div className="ta_mr">
            <h2 className="ta_f1">Nationality</h2>
            <Dropdown />
          </div>
          <div className="ta_mr">
            <h2 className="ta_f1">Published Date</h2>
            <input type="date" className="date" />
          </div>
        </div>
        <div className="ta_scrollcont" style={{ textAlign: "end" }}>
          <img src={scroll} alt="" onClick={handlescroll} />
        </div>
      </div>
      <div className="ta_bottombtn">
        <FirstButton
          className="btn"
          buttonText="Submit"
          onClick={() => navigate("/marketplace/techofferlisting")}
        />
      </div>
    </>
  );
};

export default TechnologyAssessment;
