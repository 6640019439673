import React from "react";
import img01 from "../../../../assets/images/img/s2.png";
import img02 from "../../../../assets/images/img/s3.png";
import img03 from "../../../../assets/images/img/s4.png";
const data = [
  {
    tilte: "110% funded",
    date: "April 20 2022",
    desc: "THANK YOU, once again, for supporting our mission and being one of our early investors..",
    img: img01,
  },
  {
    tilte: "We're excited to move into the next phase of our campaign",
    date: "April 20 2022",
    desc: "We're super happy to share this great news to kick off the weekend! You should be receiving an email from Wefunder asking you to confirm your investment.",
    img: img02,
  },
  {
    tilte: "Our campaign is off to a great start!",
    date: "April 20 2022",
    desc: "After a impressive responses from wellwishers and a successful precampaign  were live..!! After a ",
    img: img01,
  },
];
const UpdateAndNews = () => {
  return (
    <>
      <div className="updateAndNews">
        <div className="updateAndNews_container">
          <h1 className="brands_primary">Updates & News</h1>
          <div className="updates_container">
            {data &&
              data.map((item, index) => (
                <div className="updates">
                  <div className="update_thumb">
                    <img className="update_img" alt="" src={item.img} />
                  </div>
                  <div className="update_content">
                    <h1 style={{ color: "#070908" }} className="tile_primary">
                      {item.tilte}
                    </h1>
                    <p style={{ color: "#070908" }} className="unic">
                      {item.date}
                    </p>
                    <p style={{ color: "#070908" }} className="tile_secondary">
                      {item.desc}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateAndNews;
