import React from "react";
import InvestmentCard from "../../../common/cards/InvestmentCard";
import Img1 from "../../../assets/images/img/investcard17.png";
import Img from "../../../assets/images/img/investcard1.png";
import Img2 from "../../../assets/images/img/investcard3.png";
import Imgsmall1 from "../../../assets/images/img/investsmall1.png";
import Imgsmall2 from "../../../assets/images/img/investsmall17.png";
import Imgsmall3 from "../../../assets/images/img/investsmall3.png";
import Img9 from "../../../assets/images/img/investcard10.png";
import Img10 from "../../../assets/images/img/investcard11.png";
import Img11 from "../../../assets/images/img/investcard12.png";
import Imgsmall10 from "../../../assets/images/img/investsmall10.png";
import Imgsmall11 from "../../../assets/images/img/investsmall11.png";
import Imgsmall12 from "../../../assets/images/img/investsmall12.png";
import Img20 from "../../../assets/images/img/completecard1.png";
import Img21 from "../../../assets/images/img/completecard2.png";
import Img22 from "../../../assets/images/img/completecard3.png";
import Imgsmall20 from "../../../assets/images/img/completesmall1.png";
import Imgsmall21 from "../../../assets/images/img/completesmall2.png";
import Imgsmall22 from "../../../assets/images/img/completesmall3.png";
import FirstButton from "../../../common/button";
import { useNavigate } from "react-router-dom";
import { role } from "../../utils";
const DonationCards = () => {
  const navigate = useNavigate();
  const userrole = localStorage.getItem("role");
  const handleSubmit = (id) => {
    if (id === cardData[0]?.id){ navigate("/investment/investdetails")}

  };
  const handleCompleteSubmit=(id)=>{
    if(id===cardDatacomplete[2]?.id){
      navigate("/search/detail")}

  }
  return (
    <>
      <div className="donation_cards">
        <div className="donation_cards_container">
          <h1 className="story_heading">Top 3 Deals now</h1>
          <div className="search_results">
            {cardData.map((item, index) => (
              <InvestmentCard
                data={item}
                type="donations"
                key={index}
                onClick={() => handleSubmit(item.id)}
              />
            ))}
          </div>
          <h1 style={{ marginTop: "8rem" }} className="story_heading">
            Upcoming Deals
          </h1>
          <div className="search_results">
            {cardDataupcoming.map((item, index) => (
              <InvestmentCard data={item} type="upcoming" key={index} />
            ))}
          </div>
          {userrole === role[1].role ? (
            <div style={{ textAlign: "center", margin: "2rem 0" }}>
              <FirstButton
                buttonText="View Pre Campaigns"
                className="btn"
                onClick={() => navigate("/investment/upcomingdeals")}
              />
            </div>
          ) : (
            <></>
          )}
          {userrole === role[1].role ? (
            <>
              <h1 style={{ marginTop: "8rem" }} className="story_heading">
                Completed campaigns
              </h1>
              <div className="search_results">
                {cardDatacomplete.map((item, index) => (
                  <InvestmentCard data={item} type="complete" key={index} onClick={() => handleCompleteSubmit(item.id)} />
                ))}
              </div>
              <div style={{ textAlign: "center", margin: "2rem 0" }}>
                <FirstButton
                  buttonText="View Completed campaigns"
                  className="btn"
                  onClick={() => navigate("/search")}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
export default DonationCards;

const cardData = [
  {
    id: 1,
    img: Img,
    img2: Imgsmall1,
    title: "SAMAI",
    description: "Patented, single-use alternative: edible, compostable cups",
    tags: ["Sustainable", "Women founder", "CCD","Bio-Technology", "Food"],
    progressdata: {
      amount: "",
      percentage: 44.56,
      status: 40,
      duration: "",
      value: "40",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "20,000",
    target: "1,25,50,000",
    investors: "23",
  },
  {
    img: Img1,
    img2: Imgsmall2,
    title: "ARACE FEMCARE",
    description:
      "First farm-to-body tampon with hemp fiber - leading innovation for period care",
    tags: ["Sustainable", "Women Founder", "CCD", "Health and wellness", "B2B"],
    progressdata: {
      amount: "",
      percentage: 70.55,
      status: 34,
      duration: "",
      value: "70.55",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "40,000",
    target: "90,00,000",
    investors: "68",
  },
  {
    img: Img2,
    img2: Imgsmall3,
    title: "ZYSTEM 3E",
    description:
      "Building has never been easier. The only dry-stack wall construction system in the world.",
    tags: ["Construction", "Technology", "CCD", "Venture Funded", "B2B"],
    progressdata: {
      amount: "",
      percentage: 100.92,
      status: 12,
      duration: "",
      value: "2",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "35,000",
    target: "35,000",
    investors: "70",
  },
];
const cardDataupcoming = [
  {
    img: Img9,
    img2: Imgsmall10,
    title: "Reyght charger",
    description:
      "Rightcharge helps electric car drivers choose the right charger, connect with the right installer and switch to the right energy tariff",
    tags: ["Energy", "Electric vehicle", "CCD", "Sustainability", "Women founder"],
  },
  {
    img: Img10,
    img2: Imgsmall11,
    title: "Salvatech",
    description:
      "Salvtech’s award-winning eco-friendly water technology enables farmers to grow more, save water and improve soil health.",
    tags: ["Technology", "Water", "CSOP", "Venture Funded", "Sustainability"],
  },
  {
    img: Img11,
    img2: Imgsmall12,
    title: "Hydro one",
    description:
      "Game changing innovations in offshore wind power, energy storage and water desalination",
    tags: ["Energy", "Climate Tech", "CSOP", "B2B", "Venture Funded"],
  },
];
const cardDatacomplete = [
  {
     id:1,
    img: Img20,
    img2: Imgsmall20,
    title: "Blue Thread",
    description:
      "Custom-fit clothing made to your measurements in one week, from just 2 photos",
    tags: ["Fashion", "Women Founder", "CCD", "B2C", "Venture Funded"],
    progressdata: {
      amount: "",
      percentage: 126.2,
      status: 90,
      duration: "",
      value: "20",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "1,40,75,000",
    target: "2,25,00,000",
    investors: "178",
  },
  {
     id:2,
    img: Img21,
    img2: Imgsmall21,
    title: "Dezolenator",
    description:
      "Solar Thermal Desalination Solution | Off-grid, affordable, and scalable",
    tags: ["Power", "Sustainability", "CCD", "Technology", "B2B"],
    progressdata: {
      amount: "",
      percentage: 251.4,
      status: 90,
      duration: "",
      value: "50",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "1,65,54,000",
    target: "1,00,00,000",
    investors: "156",
  },
  {
    id:3,
    img: Img22,
    img2: Imgsmall22,
    title: "Panwar Aero",
    description:
      "Luxury All-Electric Long Range Jet disrupting Aviation Industry",
    tags: ["Mobilty", "Transportation", "CCD", "Sustainability", "Technology"],
    progressdata: {
      amount: "",
      percentage: 156.2,
      status: 60,
      duration: "",
      value: "60",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "76,00,000",
    target: "50,50,000",
    investors: "79",
  },
];
