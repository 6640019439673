import React from "react";
import Card from "../../../../common/cards/Card";
import FirstButton from "../../../../common/button";
import Img1 from "../../../../assets/images/img/techneed4.png";
import Img2 from "../../../../assets/images/img/techneed5.png";
import Img3 from "../../../../assets/images/img/techneed6.png";
import Img4 from "../../../../assets/images/img/oranges.png";
import share from "../../../../assets/images/icons/share.png";
import doc from "../../../../assets/images/oicons/svg/document.svg";
import { useNavigate } from "react-router-dom";

const tags = ["Food", "AI", "Agriculture "];
const TechNeedPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="toc_main">
        <div className="toc_cont">
          <div className="w50">
            <img src={Img4} width="100%" alt="oranges" />
          </div>
          <div className="w50">
            <h1 className="mpto_h1">
              Seeking In-Field Tools To Accurately Measure Citrus Raw Juice
              Quality
            </h1>
            <div className="toc_cont1">
              <p className="toc_f1">Request Number : A0083Z</p>
              <div className="toc_cont2">
                <button className="share_btn">
                  <img src={share} alt="share" />
                </button>
                <FirstButton
                  buttonText="Add to watchlist"
                  className="borderbtn"
                />
              </div>
            </div>
            <div className="toc_cont3">
              {tags.map((item, index) => (
                <p className="scp_p2" key={index}>
                  {item}
                </p>
              ))}
            </div>
            <p className="toc_f2">
              Citrus fruit must be harvested when they are mature (ripe) and
              quickly processed so as not to compromise quality or incur yield
              losses due to fruit drop, pests, and diseases.
            </p>

            <div className="toc_cont2">
              <p className="toc_f2">Engagement Opportunity</p>
              <p className="toc_f2 toc_f3">
                Licensing, Partnership, Investment{" "}
              </p>
            </div>
            <div className="toc_btn">
              <FirstButton
                buttonText="Show Interest"
                className="btn "
                onClick={() => navigate("/marketplace/techneedsubmitresponse")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="toc_cont4">
        <div className="counts_container">
          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              20-04-2023
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Deadline
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              India
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Country of origin
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              Content
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Item
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              Content
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Item
            </p>
          </div>
        </div>
      </div>
      <div className="toc_cont5">
        <div>
          <h2 className="cfp_mr2">About the Need</h2>
          <div className="tnp_mr">
            <p className="tnp_f1 toc_mr ">Desired Outcome</p>
            <p className="cfp_p">
              Citrus fruit must be harvested when they are mature (ripe) and
              quickly processed so as not to compromise quality or incur yield
              losses due to fruit drop, pests, and diseases. Citrus fruit is
              non-climacteric and cannot be matured after it has been detached
              from the tree. Maturity is rated directly by measuring accumulated
              brix and titratable acidity both of which are important juice
              quality characteristics. The State of Florida defines maturity for
              oranges at a minimum brix of 9%. There are further definitions of
              processed orange juice by the FDA which define grades and products
              specifications. The brix and titratable acidity (TA) measurements
              in the field must be accurate to inform timely harvesting
              decisions. While there are available tools in the market to
              measure TA , brix or both parameters, they provide approximate
              values that are difficult to ascertain or may require complex
              machines, reagents and skilled end-users. Our goal is to identify
              or develop tools which can accurately measure TA and brix in the
              field expediently without requiring expert training.
            </p>
          </div>
          <div>
            <p className="tnp_f1 toc_mr ">Past work and out Of scope List</p>
            <p className="cfp_p">
              We have previously explored color-indicator acid-base tools to
              measure titratable acidity (TA). Our experience from this is the
              tools do not meet our needs because they do not have the requisite
              TA ranges, ph does not correlate well to TA, or require complex
              machines and reagents that cannot be easily used in the field.
              Moreover, we would like the flexibility to travel to regions of
              interest, and tools relaying on liquid reagents could be present
              problems.
            </p>
          </div>
        </div>
        <div>
          <h2 className="cfp_mr2">What do we expect.</h2>
          <div className="tnp_mr">
            <p className="tnp_f1 tnp_mr1 ">Must-Haves</p>
            <ol className="cfp_p toc_ul">
              <li>
                Accurate measurement in-field of Brix and Titratable acidity (2
                decimal place accuracy)
              </li>
              <li>Ease of use in field and training of personnel</li>
              <li>
                Ease of transportation to use in the US and in other proxy
                environments (i.e. Does not require reagents that are difficult
                to transport or acquire)
              </li>
            </ol>
          </div>
          <div className="tnp_mr">
            <p className="tnp_f1 tnp_mr1 ">Nice-to-Haves</p>
            <ol className="cfp_p toc_ul">
              <li>
                Accurate measurement in-field of Brix and Titratable acidity (2
                decimal place accuracy)
              </li>
              <li>Ease of use in field and training of personnel</li>
              <li>
                Ease of transportation to use in the US and in other proxy
                environments (i.e. Does not require reagents that are difficult
                to transport or acquire)
              </li>
            </ol>
          </div>
        </div>
        <div className="toc_mr1">
          <h2 className="cfp_mr2">Documents</h2>
          <div>
            <div className="prequaapp_card">
              <div className="prequaapp_cont2">
                <p className="signupready_p3">
                  Response <br />
                  format
                </p>
                <img src={doc} alt="" width="80rem" height="80rem" />
              </div>
              <p className="prequaapp_p1">
                To submit your response, use this response format.
              </p>
              <div>
                <FirstButton
                  buttonText="Download Format"
                  className="borderbtn"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 className="cfp_mr2">Similar Technology needs</h2>
          <div className="toc_cont6">
            {cardData.map((item, index) => (
              <div key={index} className="w30">
                <Card data={item} type="need" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="scpdetail_cont3 toc_mr3">
        <p className="brand_name">
          Could this technology provide you with a competitive edge?
        </p>
        <FirstButton buttonText="Respond Now " onClick={()=>navigate("/marketplace/techneedsubmitresponse")} className="btn" />
      </div>
    </div>
  );
};

export default TechNeedPage;

const cardData = [
  {
    image: Img1,
    description:
      "Re-closable fiber-based closure for a paper beverage bottle that can be recycled in the paper stream. ",
    title: "Seeking Recyclable Closure For Paper Beverage Bottles",
    tags: ["Recycling ", "Food", "Waste Management"],
    engagements: "Licensing, Partnership, Research ",
    size: "$ 80,000 ",
    daysleft: "50",
    numberofinterests: "05",
    patientorigin: "India",
  },
  {
    image: Img2,
    description:
      "Develop a super absorbent polymer - SAP - material from sustainable sources with good fluid handling properties, able to replace the currently used raw material.",
    title: "Seeking Sustainable Superabsorbent Polymer",
    tags: ["Environment", "Chemical", "Sustainability"],
    engagements: "Licensing, Partnership, Technology Transfer ",
    size: "$75,000",
    daysleft: "50",
    numberofinterests: "04",
    patientorigin: "India",
  },
  {
    image: Img3,
    description:
      "Invention relates to a method and a device useful for directly and rapidly quantifying the presence in milk of Aflatoxin M1",
    title: "Rapid Detection Of Aflatoxin M1 In Milk",
    tags: ["Food", "Beverage", "Microbiology"],
    engagements: "Licensing ",
    patientorigin: "India",
    patientownership: ["India", "U.S.A"],
    trllevel: "Nine",
    size: "-",
    daysleft: "50 ",
    numberofinterests: "04",
  },
];
