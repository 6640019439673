import React from "react";
import img1 from "../../../../assets/images/img/prot1.png";
import img2 from "../../../../assets/images/img/port2.png";
import img3 from "../../../../assets/images/img/prot3.png";
import { Link } from "react-router-dom";
import FirstButton from "../../../../common/button";

const Investment = () => {
  const data = [
    {
      img: img1,
      organisation: "Jet aero ",
      date: "10-04- 2022",
      amount: "₹ 1,000,00",
      security: "Jet aero - CCD",
    },
    {
      img: img2,
      organisation: "Green Power",
      amount: "₹ 2,000,00",
      date: "29-07-2022",
      security: "Green Power - CSOP",
    },
    {
      img: img3,
      organisation: "Thooth Pik",
      date: "29-07-2022",
      amount: "₹ 2,000,00",
      security: "Thooth Pik - CCD",
    },
  ];
  return (
    <div style={{overflowX:"scroll"}}>
      <table className="mdsp_table tablebgcolor">
        <thead className="mdsp_tablehead">
          <tr className="mdsp_tableheadtr">
            <th>Organisation</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Security</th>
            <th>Agreement </th>
          </tr>
        </thead>
        <tbody className="mdsp_body">
          {data.map((item, key) => {
            return (
              <tr key={key} className="mdsp_tableheadtr">
                <td
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <img src={item.img} alt="" />
                  {item.organisation}
                </td>

                <td>{item.date}</td>
                <td>{item.amount}</td>
                <td>
                  <Link
                    style={{
                      fontWeight: "600",
                    }}
                    className="pricolor"
                  >
                    {item.security}
                  </Link>
                </td>
                <td>
                  <Link
                    style={{
                      fontWeight: "600",
                    }}
                    className="pricolor"
                  >
                    Download
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ textAlign: "center", margin: "2rem 0 2rem 0" }}>
        <FirstButton buttonText="View More" className="borderbtn" />
      </div>
    </div>
  );
};

export default Investment;
