import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import FirstButton from "../../../../../../common/button";
import Scrolltotop from "../../../../../../assets/images/oicons/svg/scrollup.svg";
import PostImg from "../../../../../../assets/images/logo/posttable.png";
import Img1 from "../../../../../../assets/images/img/marketimg7.png";
import Img2 from "../../../../../../assets/images/img/marketimg8.png";
import Img3 from "../../../../../../assets/images/img/marketimg9.png";
import Img4 from "../../../../../../assets/images/img/marketimg10.png";
import Img5 from "../../../../../../assets/images/img/marketimg11.png";
import Img6 from "../../../../../../assets/images/img/marketimg12.png";
const yourReplies = [
  {
    title: {
      image: Img1,
      title: "SARS-CoV-2 RNA-dependent RNA Polymerase Targeted Library",
    },
    type: "Research Need",
    date: "21-11-2022",
    validtill: "20-11-2022",
    status: "Active",
  },
  {
    title: {
      image: Img2,
      title:
        "Oxidized Cellulose Used to Prevent Allergic Reaction in Asthma, Seasonal Allergies, and Atopic Dermatitis",
    },
    type: "Tech Need",
    date: "30-11-2022",
    validtill: "21-05-2023",
    status: "Active",
  },
  {
    title: {
      image: Img3,
      title:
        "Isolation of the unsaponifiable fraction from oils and minor compounds",
    },
    type: "Research Offer",
    date: "01-01-2022",
    validtill: "31-01-2022",
    status: "Expired",
  },
];

const draftpost = [
  {
    title: { image: Img4, title: "Viral RNA-dependent RNA Polymerases" },
    type: "Tech Need",
    date: "01-01-2022",
  },
  {
    title: {
      image: Img5,
      title:
        "Procedure to repair clustered roads in failure and ARP-Path/All-Path network bridge",
    },
    type: "Research Need",
    date: "28-04-2022",
  },
  {
    title: {
      image: Img6,
      title: "New method for manufacturing powder metallurgy magnets",
    },
    type: "Tech Need",
    date: "14-11-2022",
  },
];

const Replies = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div>
        <h4 className="market_p">Your Replies</h4>

        <div>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="markettable_head" width="30%">
                    Title
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Type
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Reply Date
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Valid till
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Status
                  </TableCell>
                  <TableCell
                    align="left"
                    className="markettable_head"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {yourReplies.map((row, index) => (
                  <TableRow
                    key={index}
                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <div className="posttablecell1">
                        <img
                          src={row.title && row.title.image}
                          alt=""
                          width="44rem"
                          height="44rem"
                        />
                        <p className="markettable_font">
                          {row.title && row.title.title}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.type}
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.date}
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.validtill}
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.status}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="markettable_font"
                      style={{ fontWeight: "600" }}
                    >
                      {" "}
                      <a className="pricolor" href="">
                        Retract
                      </a>{" "}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="postbtncont" style={{ textAlign: "center" }}>
          <FirstButton className="borderbtn" buttonText="View more" />
        </div>
      </div>
      <div>
        <h4 className="market_p">Draft Replies</h4>

        <div>
          <TableContainer className="markettable_font">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="markettable_head" width="30%">
                    Title
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Last Updated
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Type
                  </TableCell>
                  <TableCell
                    align="left"
                    className="markettable_head"
                  ></TableCell>
                  <TableCell
                    align="left"
                    className="markettable_head"
                  ></TableCell>
                  <TableCell
                    align="left"
                    className="markettable_head"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {draftpost.map((row, index) => (
                  <TableRow
                    key={index}
                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <div className="posttablecell1">
                        <img
                          src={row.title && row.title.image}
                          alt=""
                          width="44rem"
                          height="44rem"
                        />
                        <p className="markettable_font">
                          {row.title && row.title.title}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.date}
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.type}
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.status}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="markettable_font"
                      style={{ fontWeight: "600" }}
                    >
                      {" "}
                      <a className="pricolor" href="">
                        Edit
                      </a>{" "}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="markettable_font"
                      style={{ fontWeight: "600" }}
                    >
                      {" "}
                      <a className="pricolor" href="">
                        Delete
                      </a>{" "}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="postbtncont" style={{ textAlign: "center" }}>
          <FirstButton className="borderbtn" buttonText="View more" />
        </div>
      </div>
    </>
  );
};

export default Replies;
