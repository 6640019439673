import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import scrollto from "../../../assets/images/oicons/svg/scrollup.svg";
const MarketplaceFaq = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      {question.map((item, index) => (
        <Accordion
          key={index}
          style={{ boxShadow: "none", border: "none", padding: "0" }}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#19976A", fontSize: "1.6rem" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ padding: "0 !important", border: "0 !important" }}
          >
            <p className="faq_f3 faq_f600">{item.ques}</p>
          </AccordionSummary>

          <AccordionDetails>
            <div
              className="faq_f3"
              dangerouslySetInnerHTML={{ __html: item.ans }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="dflex alcenter faq_mr3">
        <p className="faq_f3">
          If you cant find what youre looking for write to us.{" "}
          <span>
            {" "}
            <Link className="faq_f600">Email us </Link>{" "}
          </span>
        </p>
        <img src={scrollto} onClick={scrolltotop} />
      </div>
    </div>
  );
};

export default MarketplaceFaq;

const question = [
  {
    ques: "What is Smaarket?",
    ans: "There is no better location to begin the search for fascinating and creative businesses and inventions that surprise and amaze with live crowdfunding campaigns and a marketplace.",
  },
  {
    ques: "What happens to my data?",
    ans: "Your data is yours and yours alone. We do not reveal your identity or grant third-party applications or companies access to the platform. We do not sell or rent data.",
  },
  {
    ques: "Why should I subscribe to Premium or Premium plus?",
    ans: "We do not advertise, sell, or rent data, nor do we make data available to third-party applications. As such, we charge a small subscription fee to cover the cost of providing this service.",
  },
  {
    ques: "How much is the success fee?",
    ans: "Based on the positive outcome above the subscription fee, we charge between 3% and 12%.",
  },
];
