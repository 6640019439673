import React from "react";
import FirstButton from "../../../common/button";
import Img from "../../../assets/images/img/congrats1.png";
import { useNavigate } from "react-router-dom";

const TechNeedEnd = () => {
  const navigate = useNavigate();
  return (
    <div className="mtoc_main">
      <img src={Img} alt="" className="tne_mr" />
      <h1 className="tne_f1">Congratulations. Your need has been submitted.</h1>
      <FirstButton
        buttonText="Go to Your Control center"
        className="borderbtn"
        onClick={() => navigate("/marketplace/marketplaceactivity")}
      />
    </div>
  );
};

export default TechNeedEnd;
