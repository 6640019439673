import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import Goals from "../components/SignUpofFounders/Goals";
import Verification from "../components/SignUpofFounders/Verification";
import Interests from "../components/SignUpofFounders/Interests";
import Risks from "../components/SignUpofFounders/Risks";
const tablist = ["Goals", "Verification", "Interests", "Risks"];
const SignupofFounders = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [role, setRole] = useState(0);

  const handleChange = (e, value) => {
    setTabIndex(value);
  };
  const handleSubmit = (value, id) => {
    setTabIndex(value);
    setRole(id);
  };
  return (
    <div className="signupoffounders_main">
      <div className="borderTab">
        <Tabs
          variant="fullWidth"
          onChange={handleChange}
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#19976A",
              height: 3,
            },
          }}
        >
          {tablist.map((item, index) => (
            <Tab
              style={{
                fontSize: "1.6rem",
                color: `${tabIndex === index ? "black" : "black"}`,
                fontWeight: `${tabIndex === index ? "bold" : 400}`,
                textTransform: "none",
                fontFamily: "Montserrat",
              }}
              value={index}
              label={item}
              key={index}
            />
          ))}
        </Tabs>
      </div>
      <div>
        {tabIndex === 0 && (
          <div>
            <Goals change={handleSubmit} />
          </div>
        )}
        {tabIndex === 1 && (
          <div>
            <Verification change={handleSubmit} role={role} />
          </div>
        )}
        {tabIndex === 2 && (
          <div>
            <Interests change={handleSubmit} role={role} />
          </div>
        )}
        {tabIndex === 3 && (
          <div>
            <Risks change={handleSubmit} role={role} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SignupofFounders;
