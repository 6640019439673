import React from "react";
import {
  Link,
  Navigate,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";

const Sidebar = () => {
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const navigate = useNavigate();
  return (
    <div className="sidebar_main admin_sidebar">
      <h1
        style={{ color: "white", cursor: "pointer" }}
        onClick={() => navigate("/admin")}
      >
        Dashboard
      </h1>
      <div className="sidebar_cont1">
        <nav className="nav-wd">
          <div className="nav_cont">
            <div
              className={
                splitLocation[2] === "precampains"
                  ? "active"
                  : "sidebarNav_item"
              }
            >
              <Link to="/admin/precampains" className="color_pri">
                PreCampains
              </Link>
            </div>
            <div
              className={
                splitLocation[2] === "fundraisers"
                  ? "active"
                  : "sidebarNav_item"
              }
            >
              <Link to="/admin/fundraisers" className="color_pri">
                Fundraisers
              </Link>
            </div>

            <div
              className={
                splitLocation[2] === "marketplace"
                  ? "active"
                  : "sidebarNav_item"
              }
            >
              <Link to="/admin/marketplace" className="color_pri">
                Marketplace
              </Link>
            </div>

            <div
              className={
                splitLocation[2] === "startups" ? "active" : "sidebarNav_item"
              }
            >
              <Link to="/admin/startups" className="color_pri">
                Startups
              </Link>
            </div>

            {/* <div
              className={
                splitLocation[2] === "investors" ? "active" : "sidebarNav_item"
              }
            >
              <Link to="/admin/investors" className="color_pri">
                Investors
              </Link>
            </div>

            <div
              className={
                splitLocation[2] === "founders" ? "active" : "sidebarNav_item"
              }
            >
              <Link to="/admin/founders" className="color_pri">
                Founders
              </Link>
            </div> */}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
