import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import Upload from "../../../../assets/images/logo/upload.svg";
import Upload2 from "../../../../assets/images/logo/upload2.svg";
import Upload3 from "../../../../assets/images/logo/upload3.svg";
import { useNavigate } from "react-router-dom";
import Scrolltotop from "../../../../assets/images/oicons/svg/scrollup.svg";

const MainBasic = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/maincampcreation/pitch");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Basic Details</h4>
          <div className="cfp_wd1">
            <div>
              <p className="counts_title">
                A Hint to Start :<br />
                The nature of the campaign can vary, but for the majority of
                successful outcomes that we've seen, the campaign requires a
                solid strategy,mission and a story to tell.
                <br />
                Present a clear opportunity and value for stakeholders.
              </p>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Campaign title</p>
              <p className="mcdt_p2">Short description of Campaign</p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Business tagline</p>
              <p className="mcdt_p2">
                Explain the value of the product, service, or brand
              </p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Description of campaign </p>
              <p className="mcdt_p2">
                Customer seek solutions to problems in their lives.
                <br />
                Start your story explicitly stating the problem you are
                addressing.
              </p>
              <textarea
                className="precampfaq_textarea"
                placeholder="Type Here"
              ></textarea>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Business Logo </p>
              <p className="mcdt_p2">
                {" "}
                This logo will represent your business in Campaign main image .
                <br />
                100 x 100 pixels recommended resolution, maximum size 200kb.
              </p>
              <button className="UploadBtn">
                <img src={Upload} alt="" className="w100" />
              </button>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Campaign Tile </p>
              <p className="mcdt_p2">
                {" "}
                This image will represent as Campaign main tile image <br />
                302 x 200 pixels recommended resolution, maximum size 600kb.
              </p>
              <button className="UploadBtn">
                <img src={Upload3} alt="" className="w100" />
              </button>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Campaign banner </p>
              <p className="mcdt_p2">
                {" "}
                This image will represent as header in the main campaign page{" "}
                <br />
                648 x 488 pixels recommended resolution, maximum size 900kb.
              </p>
              <button className="UploadBtn">
                <img src={Upload2} alt="" width="100%" height="50%" />
              </button>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Sector tags </p>
              <p className="mcdt_p2">
                {" "}
                Personalize your campaign by adding tags
              </p>
              <input type="text" className="login_input" />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Social media </p>
              <p className="mcdt_p2">
                {" "}
                Smaarket is based on community . The more the merrier!
              </p>
              <p className="mcdt_p2"> Twitter</p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
              <div>
                <p className="mcdt_p2"> Facebook</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2"> Linkedin</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2"> Discord</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Business website</p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Your Mobile application</p>
              <div>
                <p className="mcdt_p2">Google playstore</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2">Apple store</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
            </div>
            <div></div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton buttonText="Previous" className="borderbtn" />
        <FirstButton
          buttonText="Save and next"
          onClick={handleSubmit}
          className="btn"
        />
      </div>
    </div>
  );
};

export default MainBasic;
