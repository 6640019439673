import React from "react";
import img1 from "../../../assets/images/icons/invest13.svg";
import img2 from "../../../assets/images/icons/invest14.svg";
import img3 from "../../../assets/images/icons/invest15.svg";
const ExploreGrid = () => {
  return (
    <>
      <div style={{ backgroundColor: "#fff" }} className="threeGrid">
        <div className="threeGrid_container">
          <h1
            style={{ color: "#070908", marginBottom: "2rem" }}
            className="story_heading"
          >
            A smarter strategy
          </h1>
          <div className="threeGrid_row">
            {/* <div className="threeGrid_col">
            <h1 style={{color:"#070908"}} className="title">Top 3 feature</h1>
            <p style={{color:"#070908"}} className="sub_text">Only accredited investors, who make up a tiny portion of India's population, have always been permitted to fund private enterprises. We have been working to make laws more egalitarian and to include venture in every investor's portfolio for the past ten years. mainly because . make laws more egalitarian and to include venture in every investor's portfolio for the past ten years. mainly because </p>
          </div> */}
            {cardlist.map((item, index) => (
              <div key={index} className="threeGrid_width ">
                <div>
                  <img src={item.img} className="w100" alt="" />
                </div>
                <div>
                  <p className="eg_f1">{item.title}</p>
                  <p className="eg_f2">{item.desc}</p>
                </div>
              </div>
            ))}
          </div>

          <p className="eg_f2">
            We offer lower minimums than traditional angel investments so you
            can back more companies. When you diversify, you increase your odds
            of positive returns.
          </p>
        </div>
      </div>
    </>
  );
};

export default ExploreGrid;

const cardlist = [
  {
    img: img1,
    title: "Invest  or Donate in vetted Startups",
    desc: "Only a small selection of  startups make it through our due diligence process. ",
  },
  {
    img: img2,
    title: "Invest in top-quality deals",
    desc: "All deals are sourced by respected early-stage investors with successful track records.",
  },
  {
    img: img3,
    title: "Diversify your portfolio",
    desc: "Access varied transaction flow from funds that span multiple sectors, locations, and stages.",
  },
];
