import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import TextField from "@mui/material/TextField";
import DeleteIcon from "../../../../assets/images/oicons/svg/deleteIcon.svg";
import { useNavigate } from "react-router-dom";
import Scrolltotop from "../../../../assets/images/oicons/svg/scrollup.svg";

const MainPitch = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/maincampcreation/aboutus");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />

        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Pitch and Details</h4>
          <div className="cfp_wd">
            <div>
              <p className="mcdt_p2">
                Make a positive first impression by outlining the goals of your
                campaign and inspiring interest in learning more..
              </p>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Business Highlights</p>
              <p className="mcdt_p2">
                Present the company at a glance and highlight key company facts
                such as traction, accomplishments, profits, clients, partners,
                products, and sales.
              </p>
              <input
                type="text"
                className="login_input mcp_input"
                placeholder="HighLight 1"
              />
              <input
                type="text"
                className="login_input mcp_input"
                placeholder="HighLight 2"
              />
              <input
                type="text"
                className="login_input mcp_input"
                placeholder="HighLight 3"
              />
              <input
                type="text"
                className="login_input mcp_input"
                placeholder="HighLight 4"
              />
              <input
                type="text"
                className="login_input mcp_input"
                placeholder="HighLight 5"
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Pitch Deck </p>
              <p className="mcdt_p2">
                Upload campaign deck in PDF format.Maximum 15 slides If you've
                already created a slide deck to fundraise, we'll use it to
                create your profile faster. or Visit Partners page .
              </p>
              <TextField
                id="outlined-basic"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                style={{
                  marginTop: "1rem",
                  background: "#F5F5F5",
                  border: "none !imporatant",
                  outline: "none !important",
                  width: "100%",
                }}
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">FAQs</p>
              <p className="mcdt_p2">About your business and campaign.</p>
              <div className="mcp_cont" style={{ margin: "0 0 2rem 0" }}>
                <div className="precampfaq_cont " style={{ padding: "0rem " }}>
                  <p>FAQ1</p>
                  <img src={DeleteIcon} alt="" />
                </div>
                <div className="">
                  <p className="precampfaq_cont1">
                    Do you offer any guarantees or returns ?
                  </p>
                  <p className="precampfaq_cont1">
                    No, we do not guarantee any returns.
                  </p>
                </div>
              </div>
              <div>
                <div className="mcp_cont">
                  <div className="precampfaq_cont ">
                    <p>FAQ2</p>
                    <img src={DeleteIcon} alt="" />
                  </div>
                  <div className="">
                    <p className="precampfaq_cont1">
                      Does your business intend to stay private, plan to IPO, or
                      hope to be acquired?  
                    </p>
                    <p className="precampfaq_cont1">
                      We are open to all scenarios for an exit and hope to
                      maximize the opportunity for our investor when the
                      situation arises.  
                    </p>
                  </div>
                </div>
                <div>
                  <FirstButton
                    className="borderbtn"
                    buttonText="Add +"
                    width="100%"
                    borderRadius="5rem"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/maincampcreation/baiscdetails")}
        />
        <FirstButton
          buttonText="Save and next"
          onClick={handleSubmit}
          className="btn"
        />
      </div>
    </div>
  );
};

export default MainPitch;
