import React, { useState } from "react";
import Sidebar from "./Sidebar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import FirstButton from "../../../common/button";
import Scrolltotop from "../../../assets/images/oicons/svg/scrollup.svg";
import { Link } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import linkedin from "../../../assets/images/oicons/svg/linkedin.svg";
import gmail from "../../../assets/images/oicons/svg/gmail.svg";
import facebook from "../../../assets/images/oicons/svg/facebook.svg";
import twitter from "../../../assets/images/oicons/svg/twitter.svg";
import  link from "../../../assets/images/oicons/svg/link.svg";
const refer = [
  { value: "02", indicator: "New Donations" },
  { value: "05", indicator: "New Investments" },
  { value: "01", indicator: "Startups Applied" },
  { value: "03", indicator: "Marketplace Deals" },
];

const refertable = [
  {
    title: "SAMAI",
    type: "Investments",
    date: "20.04.2020",
    status: "Rewarded",
  },
  {
    title: "Sky labs ",
    type: "Donations",
    date: "20.04.2020",
    status: "Rewarded",
  },
  {
    title: "cotton aero",
    type: "Marketplace",
    date: "20.04.2020",
    status: "Yet to subscribe",
  },
];
const MarketReferandEarn = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="cfp_main">
      <Sidebar />
      <div className="cfp_cont1">
        <h4 className="market_h4">Refer and earn</h4>
        <div>
          <p className="market_p">
            Your contribution to the Smaarket community:
          </p>

          <div className="market_cont1">
            {refer.map((item, index) => (
              <div key={index}>
                <p className="market_p1">{item.value}</p>
                <p className="market_p2">{item.indicator}</p>
              </div>
            ))}
          </div>

          <div className="refer_cont">
            <div className="refer_cont8">
              <div className="refer_cont3">
                <p className="refer_p1">
                  Play a crucial role in introducing new business
                </p>
                <p className="markettable_font">
                  Empowers startups to fundraise and market their business at
                  the same time. Help founders you know grow their business and
                  get rewarded for it by inviting them to Smaarket with the
                  Referal Code
                </p>
                <Link className="markettable_font linkcolor">Learn more</Link>
              </div>
              <div className="refer_cont6">
                <p className="refer_p1">
                  Refer a startup. <p>Earn upto ₹ 15,000.</p>
                </p>
                <div className="refer_cont5">
                  <p className="refer_number_icon">1</p>
                  <p className="markettable_font">
                    Share your unique link with companies you love.
                  </p>
                </div>
                <div className="refer_cont5">
                  <p className="refer_number_icon">2</p>
                  <p className="markettable_font">
                    Founders save upto ₹ 15,000, thanks to you.
                  </p>
                </div>
                <div className="refer_cont5">
                  <p className="refer_number_icon">3</p>
                  <p className="markettable_font">
                    If you are the first person to introduce a (deal that goes
                    live we will give you upto INR 15,000 after you complete the
                    necessary paperwork.
                  </p>
                </div>
              </div>
            </div>

            <div className="refer_cont8">
              <div className="refer_cont4">
                <p className="refer_p1">Your Referal Code</p>
                <div className="refer_input">
                  <input type="text" className="login_input" placeholder="SMGSDKIGN0063" />
                  <ContentCopyIcon
                    sx={{ color: "#19976A", fontSize: "2.4rem" }}
                  />
                </div>
                <div className="refer_btn-grp">
                  <button className="refer_btn">
                   <img src={linkedin}/>
                  </button>
                  <button className="refer_btn">
                   <img src={gmail}/>
                  </button>
                  <button className="refer_btn">
                  <img src={facebook}/>
                  </button>
                  <button className="refer_btn">
                  <img src={twitter}/>
                  </button>
                  <button className="refer_btn">
                  <img src={link}/>
                  </button>
                
                </div>
              </div>
              <div className="refer_cont2">
                <div className="refer_cont6">
                  <p className="refer_p1">
                    Refer a Marketplace. <p>Earn upto ₹ 5,000.</p>
                  </p>
                  <div className="refer_cont5">
                    <p className="refer_number_icon">1</p>
                    <p className="markettable_font">
                      Share your unique link with companies you love.
                    </p>
                  </div>
                  <div className="refer_cont5">
                    <p className="refer_number_icon">2</p>
                    <p className="markettable_font">
                      Founders save upto ₹ 15,000, thanks to you.
                    </p>
                  </div>
                  <div className="refer_cont5">
                    <p className="refer_number_icon">3</p>
                    <p className="markettable_font">
                      If you are the first person to introduce a (deal that goes
                      live we will give you upto INR 15,000 after you complete
                      the necessary paperwork.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="market_p">Referal Activities</p>
          {/* table */}
          <div className="">
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="markettable_head">Company</TableCell>
                    <TableCell align="left" className="markettable_head">
                      Type
                    </TableCell>
                    <TableCell align="left" className="markettable_head">
                      Date
                    </TableCell>
                    <TableCell align="left" className="markettable_head">
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {refertable.map((row, index) => (
                    <TableRow
                      key={index}
                      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        <div className="posttablecell1">
                          <p className="markettable_font">{row.title}</p>
                        </div>
                      </TableCell>
                      <TableCell align="left" className="markettable_font">
                        {row.type}
                      </TableCell>
                      <TableCell align="left" className="markettable_font">
                        {row.date}
                      </TableCell>
                      <TableCell align="left" className="markettable_font">
                        {row.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div style={{ textAlign: "center", margin: "2rem 0 2rem 0" }}>
              <FirstButton buttonText="View More" className="borderbtn" />
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketReferandEarn;
