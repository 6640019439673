import React from "react";
import z1 from "../../../../../assets/images/img/z1.png";
import z2 from "../../../../../assets/images/img/z2.png";
import z3 from "../../../../../assets/images/img/z3.png";
import z4 from "../../../../../assets/images/img/z4.png";
import z5 from "../../../../../assets/images/img/z5.png";
import z6 from "../../../../../assets/images/img/z6.png";
import z7 from "../../../../../assets/images/img/z7.png";
import FirstButton from "../../../../../common/button";

import { Button } from "@material-ui/core";

const About = () => {
  const role = localStorage.getItem("role");
  return (
    <>
      <div className="about">
        <div className="about_container">
          <h1 className="brands_primary">About Samai</h1>
          <div className="comp_info_container">
            <div className="comp_info">
              <div>
                <p className="comp_info--title">Legal Name </p>
                <p className="comp_info--data">Samai </p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--title">No of employees </p>
                <p className="comp_info--data">12</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--title">Incorporation number</p>
                <p className="comp_info--data">U 23568 AP 2021 KLT 678594</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--title">DIPP number</p>
                <p className="comp_info--data">KP 616 786954</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--title">Head Quartered  Location</p>
                <p className="comp_info--data">Ananthapur, AP</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--title">Incorporation date</p>
                <p className="comp_info--data">21 Novemeber 2021</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--title">Organization type</p>
                <p className="comp_info--data">Private Limited </p>
              </div>

              {/* <div style={{marginTop:"4rem"}}>
              <p className="comp_info--title">No of employees </p>
              <p className="comp_info--data">26</p>
            </div> */}
            </div>
          </div>

          <div className="teams">
            <h1 className="teams_primary">Our Team</h1>
            <div className="teams_container" style={{ flexWrap: "nowrap" }}>
              <div className="teamsRow--1">
                <div>
                  <img alt="" src={z5} />
                </div>

                <div className="team_info">
                  <p className="comp_info--data">
                    Pooja Bandi{" "}
                    <span style={{ color: "#B2B2B2" }}>Founder and CEO</span>{" "}
                    <i className="fa-brands fa-linkedin-in comp_social_icon"></i>{" "}
                  </p>
                  <p style={{ margin: "0.5rem 0" }} className="comp_info--data">
                    DIN Number
                    <span style={{ color: "#B2B2B2" }}>xxxxxxxxxx</span>
                  </p>
                  <p className="reoffcard_p2">
                    Former GM & VP of Operations at Panasonic Hollywood Labs,
                    Jeannine has over 30 years experience in product development
                    through high-volume manufacturing.
                  </p>
                </div>
              </div>
              <div className="teamsRow--2">
                <div>
                  <img alt="" src={z6} />
                </div>
                <div className="team_info">
                  <p className="comp_info--data">
                    Shilpa zinta{" "}
                    <span style={{ color: "#B2B2B2" }}>
                      Liaison & Fundraising
                    </span>{" "}
                    <i className="fa-brands fa-linkedin-in comp_social_icon"></i>{" "}
                  </p>
                  <p style={{ margin: "0.5rem 0" }} className="comp_info--data">
                    DIN Number
                    <span style={{ color: "#B2B2B2" }}>xxxxxxxxxx</span>
                  </p>
                  <p className="reoffcard_p2">
                    Our first angel! Joan is a results-driven fundraiser for
                    both non-profit and for-profit sectors. Former account
                    manager at Sysco Foods and EcoLab. Experienced trade show
                    and events project manager.
                  </p>
                </div>
              </div>
            </div>

            <div className="teams_container">
              <div className="teamsRow--1">
                <div>
                  <img alt="" src={z7} />
                </div>
                <div className="team_info">
                  <p className="comp_info--data">
                    Rajesh Kumar{" "}
                    <span style={{ color: "#B2B2B2" }}>
                      Supply & Distribution
                    </span>{" "}
                    <i className="fa-brands fa-linkedin-in comp_social_icon"></i>{" "}
                  </p>
                  <p style={{ margin: "0.5rem 0" }} className="comp_info--data">
                    DIN Number
                    <span style={{ color: "#B2B2B2" }}>xxxxxxxxxx</span>
                  </p>
                  <p className="reoffcard_p2">
                    Over 20 years leading global supply chains as VP for
                    Activision's Guitar Hero, Nike, Meta's Oculus/Portal, and
                    COO for Farmgirl Flowers, Laura is an expert in program
                    management with ten-year focus on sustainability.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mentors">
            <h1 className="teams_primary">Mentors and Advisors </h1>
            <div className="mentors_list">
              {Mentor.map((item, index) => (
                <div className="mentor w18">
                  <img src={item.img} alt="" />
                  <p className="comp_info--data">
                    {item.name}{" "}
                    <i className="fa-brands fa-linkedin-in comp_social_icon"></i>{" "}
                  </p>
                  <p className="comp_info--title">{item.role}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="flowchart">
        <div className="flow_container">
          <h1 className="flow_h1">Journey so far</h1>
          <div className="flow_main">
            <div className="flow_cont">
              <div className="flow_cont1"></div>
              <div className="flow">May 2022</div>
              <div className="flow_cont3">
                Finalized on materials and Technology
              </div>
            </div>

            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1">
                {" "}
                <span className="bottom">Finalized on design</span>
              </div>
              <div className="flow">June 2022</div>
              <div className="flow_cont3"></div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1"> </div>
              <div className="flow">June 2022</div>
              <div className="flow_cont3">
                Production and Testing First Prototype
              </div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1">
                <span className="bottom">
                  Testing our first prototype Production and Testing First
                  Prototype
                </span>
              </div>
              <div className="flow">August 2022</div>
              <div className="flow_cont3"></div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1"></div>
              <div className="flow">September 2022</div>
              <div className="flow_cont3">
                First sale of our product in Amazon.
              </div>
            </div>
          </div>
        </div>
        <div className="flow_container">
          <h1 className="flow_h1">Journey ahead</h1>
          <div className="flow_main">
            <div className="flow_cont">
              <div className="flow_cont1"></div>
              <div className="flow">October 2022</div>
              <div className="flow_cont3">
                Design and Testing Multi product{" "}
              </div>
            </div>

            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1">
                {" "}
                <span className="bottom">
                  Production and distribution of New products{" "}
                </span>
              </div>
              <div className="flow">January 2023</div>
              <div className="flow_cont3"></div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1"> </div>
              <div className="flow">September 2023</div>
              <div className="flow_cont3">
                Presence in 28 states servicing a variety of applications{" "}
              </div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1">
                <span className="bottom">
                  Profitable by 2024 with $4.5M EBIT{" "}
                </span>
              </div>
              <div className="flow">April 2024 </div>
              <div className="flow_cont3"></div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1"></div>
              <div className="flow">April 2026</div>
              <div className="flow_cont3">
                Achieve 1.25% market share by 2026{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

const Mentor = [
  { name: "Shikara  ", role: "Mentor", img: z1 },
  { name: "Prasanth proaksh", role: "Angel investor", img: z2 },
  { name: "yamini sharma", role: "Investor", img: z3 },
  { name: "Ravan", role: "Angel investor", img: z4 },
];
