import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img01 from "../../../../../assets/images/img/s1.png";
import img02 from "../../../../../assets/images/slider/sami1.PNG";
import img03 from "../../../../../assets/images/slider/sami2.PNG";
import img04 from "../../../../../assets/images/slider/sami3.PNG";
import img05 from "../../../../../assets/images/slider/sami4.PNG";
import img06 from "../../../../../assets/images/slider/sami5.PNG";
import img07 from "../../../../../assets/images/slider/sami6.PNG";
import img08 from "../../../../../assets/images/slider/sami7.PNG";
import img09 from "../../../../../assets/images/slider/sami8.PNG";
import img10 from "../../../../../assets/images/slider/sami9.PNG";
import img11 from "../../../../../assets/images/slider/sami10.PNG";
import FirstButton from "../../../../../common/button";
const Pitch = () => {
  const data = [
    {
      number: 1,
      text: "India. single-use cup market is $4.2B. Samai's alternative is well-timed to compete in this space.",
    },
    {
      number: 2,
      text: "Theme park, travel, service partnerships in place to grow to est. $44.7M by 2027, 5 year CAGR 79.8%",
    },
    {
      number: 3,
      text: "Presence in 14 states servicing a variety of applications. 2022-2023 capacity already SOLD OUT!",
    },
    {
      number: 4,
      text: "Early-to-market upcycled, made in the  India  edible cup. Profitable by 2024 with $4.5M EBIT (est.).",
    },
    {
      number: 5,
      text: "Advisory board rounds out our team by bringing agricultural research and food production expertise",
    },
    {
      number: 6,
      text: "We aim to keep 285M single-use cups out of landfill and prevent >4,800 tons of food waste by 2027.",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
  };
  return (
    <>
      <div className="pitch">
        <div className="pitch-container">
          <div className="heighlights">
            <h1 className="brands_primary">Highlights</h1>

            {data.map((item, index) => (
              <div
                key={index}
                style={{
                  color: "#070908",
                  display: "flex",
                  alignItems: "center",
                }}
                className="tile_secondary heighlights_text"
              >
                <span className="heighlight_point">{item.number}</span>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="slider">
        <Slider {...settings}>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img02}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img03}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img04}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img05}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img06}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img07}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img08}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img09}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img10}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img11}></img>
          </div>
        </Slider>
      </div>

      <div
        style={{ backgroundColor: "#fff", marginBottom: "0" }}
        className="faq"
      >
        <div className="faq_container">
          <h1 className="faq_primary li_dark">Company FAQ</h1>
          <div className="questions">
            <div className="question_container--1">
              <div className="question1">
                <h1 className="question light-dark">
                  What major food allergies does the cup contain?
                </h1>
                <p className="answer light-dark">
                  At this time the recipe does contain gluten. However, they are
                  soy, nut, dairy and egg free. We will reformulate a gluten
                  free version at a later stage, but probably not for a year or
                  so after production starts{" "}
                </p>
              </div>
              <div className="question2">
                <h1 className="question light-dark">
                  What is their price point compared to a cone or other
                  recyclable cups?{" "}
                </h1>
                <p className="answer light-dark">
                  When compared with recyclable cups for lower volume catering
                  applications, for example, we're priced on par - especially
                  with our customized label option.{" "}
                </p>
              </div>
            </div>

            <div className="question_container--2">
              <div className="question1 ">
                <h1 className="question light-dark">Will they hold liquid?</h1>
                <p className="answer light-dark">
                  Yes indeed, they hold liquid. You can put your coffee in the
                  cups, or steep tea at 212 degrees Fahrenheit. They stay
                  crunchy up to 45 minutes with liquid in them.
                </p>
              </div>
              <div className="question2">
                <h1 className="question light-dark">
                  How will you do that in an eco-friendly way?
                </h1>
                <p className="answer light-dark">
                  We'd only begin exporting after careful consideration about
                  how to do it responsibly-{" "}
                </p>
              </div>
            </div>
          </div>
          {/* <FirstButton className="btn" buttonText="View More" marginTop="5rem"/> */}
        </div>
      </div>
    </>
  );
};

export default Pitch;
