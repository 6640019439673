import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import DeleteIcon from "@mui/icons-material/Delete";
import Dropdown from "../../../../common/dropdown";
import Money from "../../../../assets/images/logo/icon.svg";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Scrolltotop from "../../../../assets/images/oicons/svg/scrollup.svg";

const list = ["menu", "low"];
const MainDonationAboutus = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/maincampdonations/perks");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">About "Company Name"</h4>
          <div className="cfp_wd1">
            <div className="mcdt_cont">
              <p className="mcdt_p1">Organisation Details</p>
              <div>
                <p className="mcdt_p2">Legal Business name</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2">Business head quarters</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2">Organization type</p>
                <Dropdown placeholder={"Select..."} MenuList={list} />
              </div>
              <div>
                <p className="mcdt_p2">Corporate identification number</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2">DPIIT/DIPP Number</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2">Business Incorporation date</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2">Number of employees</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Primary contact</p>
              <div>
                <p className="mcdt_p2">Name of Representative</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2">Designation</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2">Official email ID</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2">Mobile number</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
              <div>
                <p className="mcdt_p2">Business Linkedin Profile</p>
                <input
                  placeholder="Type here"
                  type="text"
                  className="login_input"
                />
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Your Core Team</p>
              <div>
                <div className="mcb_cont">
                  <p>Person 1</p>
                  <DeleteIcon sx={{ color: "#B2B2B2" }} />
                </div>
                <div className="mcb_cont4">
                <div style={{width:"10rem"}}>
                  <img src={Money} alt="" width="100%" height="auto" style={{marginTop:"3rem"}} />
                  </div>
                  <div style={{width:"90%"}}>
                    <TextField
                      id="standard-basic"
                      label="Name"
                      fullWidth
                      variant="standard"
                      className="mcb_inp"
                      InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Title"
                      fullWidth
                      variant="standard"
                      className="mcb_inp"
                      InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Bio"
                      fullWidth
                      variant="standard"
                      className="mcb_inp"
                      InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Din Number"
                      fullWidth
                      variant="standard"
                      className="mcb_inp"
                      InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Linked profile"
                      fullWidth
                      variant="standard"
                      className="mcb_inp"
                      InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                  </div>
                </div>
                <div className="mcb_btn_cont">
                  <FirstButton
                    className="borderbtn"
                    buttonText="Add +"
                    width="100%"
                    borderRadius="5rem"
                  />
                </div>
                <div>
                  <img src="" alt="" />
                  <div></div>
                </div>
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Investors and Mentors</p>
              <div>
                <div className="mcb_cont">
                  <p>Person 1</p>
                  <DeleteIcon sx={{ color: "#B2B2B2" }} />
                </div>
                <div className="mcb_cont4">
                <div style={{width:"10rem"}}>
                  <img src={Money} alt="" width="100%" height="auto" style={{marginTop:"3rem"}} />
                  </div>
                  <div style={{width:"90%"}}>
                    <TextField
                      id="standard-basic"
                      label="Name"
                      fullWidth
                      variant="standard"
                      className="mcb_inp"
                      inputProps={{ style: { fontSize: 16 } }}
                      InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Title"
                      fullWidth
                      variant="standard"
                      className="mcb_inp"
                      inputProps={{ style: { fontSize: 16 } }}
                      InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                    <TextField
                      id="standard-basic"
                      label="Linked profile"
                      fullWidth
                      variant="standard"
                      className="mcb_inp"
                      inputProps={{ style: { fontSize: 16 } }}
                      InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                  </div>
                </div>
                <div className="mcb_btn_cont">
                  <FirstButton
                    className="borderbtn"
                    buttonText="Add +"
                    width="100%"
                    borderRadius="5rem"
                  />
                </div>
                <div>
                  <img src="" alt="" />
                  <div></div>
                </div>
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Journey so far</p>
              <p className="mcdt_p2">
                Share your journey to date with your investor.
              </p>
              <div className="mcb_cont">
                <div className="mcb_cont1">
                  <label className="mcdt_p2">Year</label>
                  <input
                    placeholder="Enter"
                    type="text"
                    className="login_input"
                  />
                </div>
                <div className="mcb_cont2">
                  <label className="mcdt_p2">Month</label>
                  <Dropdown placeholder={"Month"} MenuList={[]} />
                </div>
                <div className="mcb_cont3">
                  <label className="mcdt_p2">Milestones</label>
                  <input
                    placeholder="Type here"
                    type="text"
                    className="login_input"
                  />
                </div>
              </div>
              <div className="mcb_btn_cont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add +"
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
            <div clasName="mcdt_cont">
              <p className="mcdt_p1">Journey ahead</p>
              <p className="mcdt_p2">
                What plans do you have for your business's future?
              </p>
              <div className="mcb_cont">
                <div className="mcb_cont1">
                  <label className="mcdt_p2">Year</label>
                  <input
                    placeholder="Enter"
                    type="text"
                    className="login_input"
                  />
                </div>
                <div className="mcb_cont2">
                  <label className="mcdt_p2">Month</label>
                  <Dropdown placeholder={"Month"} MenuList={[]} />
                </div>
                <div className="mcb_cont3">
                  <label className="mcdt_p2">Milestones</label>
                  <input
                    placeholder="Type here"
                    type="text"
                    className="login_input"
                  />
                </div>
              </div>
              <div className="mcb_btn_cont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add +"
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/maincampdonations/pitch")}
        />
        <FirstButton
          buttonText="Save and next"
          onClick={handleSubmit}
          className="btn"
        />
      </div>
    </div>
  );
};

export default MainDonationAboutus;
