import React from "react";
import img1 from "../../../assets/images/icons/explore2.svg";
import img2 from "../../../assets/images/icons/explore3.svg";
import img3 from "../../../assets/images/icons/explore4.svg";
const ThreeGrid = () => {
  return (
    <>
      <div className="threeGrid">
        <div className="threeGrid_container">
          <h1 className="title">Why smaarket?</h1>
          <div className="threeGrid_row">
            <div className="threeGrid_col">
              <img className="w80" src={img1} alt="" />
              <p className="title_h1">Quick and efficient</p>
              <p className="threeGrid_p">
                Begin in a matter of minutes. Launch in a few days. Smaarket's
                platform boasts a high success record for companies that have
                raised funds.
              </p>
            </div>
            <div className="threeGrid_col">
              <img className="w80" src={img2} alt="" />
              <p className="title_h1">More than just money</p>
              <p className="threeGrid_p">
                With the support of  Smaarket campaign, companies have tripled
                user bases, sold crores of products, gained press coverage,
                connected with VCs, and funded follow-on rounds on favorable
                terms.
              </p>
            </div>
            <div className="threeGrid_col">
              <img className="w80" src={img3} alt="" />
              <p className="title_h1">Venture Capital friendly</p>
              <p className="threeGrid_p">
                Our Crowd instruments have received VC approval. Smaarket allows
                you to raise financing before, during, or after your venture
                round. A crowd round is a valuable supplement to traditional
                venture capital, not its substitute.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThreeGrid;
