import React from "react";
import Sidebar from "./Sidebar";
import GroupsIcon from "@mui/icons-material/Groups";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { Table } from "./Table/Table";
import CampaignIcon from "@mui/icons-material/Campaign";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const Admin = () => {
  return (
    <div className="admin_main">
      <div className="admin_w20">
        <Sidebar />
      </div>
      <div className="admin_w80">
        <div className="admin_cont">
          {list.map((item, index) => (
            <div className="admin_cont1" key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {item?.id === "1" ? (
                  <CampaignIcon sx={{ color: "#26ABA4", fontSize: "6rem" }} />
                ) : item?.id == "2" ? (
                  <CurrencyRupeeIcon
                    sx={{ color: "#26ABA4", fontSize: "5rem" }}
                  />
                ) : item?.id === "3" ? (
                  <LightbulbOutlinedIcon
                    sx={{ color: "#26ABA4", fontSize: "5rem" }}
                  />
                ) : item?.id === "4" ? (
                  <GroupsIcon sx={{ color: "#26ABA4", fontSize: "6rem" }} />
                ) : (
                  <></>
                )}
                <p className="scp_h1">{item.details}</p>
              </div>

              <p className="admin_f1">{item.title}</p>
            </div>
          ))}
        </div>
        <div className="admin_cont7">
          <div className="admin_cont4  ">
            <Line data={data} />
            <div
              className="admin_cont2 "
              style={{ textAlign: "start", margin: "2rem 0 0 0" }}
            >
              <p style={{ fontWeight: 800, fontSize: "2rem" }}>
                Total Fund Raised
              </p>
              <p className="admin_f1">₹ 3434</p>
            </div>
          </div>
          <div className="admin_cont4  ">
            <Line data={data2} />
            <div
              className="admin_cont2 "
              style={{ textAlign: "start", margin: "2rem 0 0 0" }}
            >
              <p style={{ fontWeight: 800, fontSize: "2rem" }}>
                Total Fund Asked
              </p>
              <p className="admin_f1">₹ 3434</p>
            </div>
          </div>
        </div>

        <div>
          <h4 className="scp_h1" style={{ margin: "2rem 0" }}>
            Recent Activities
          </h4>
          <div>
            <Table />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;

const list = [
  { id: "1", details: "33", title: "Pre-Campaigns" },
  { id: "2", details: "22", title: "Fundraisers" },
  { id: "3", details: "11", title: "Startups" },
  { id: "4", details: "44", title: "investors" },
];

const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Total Fund Raised",
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: "#26ABA4",
      borderColor: "#26ABA4",
      tension: 0.6,
    },
  ],
};
const data2 = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Total Fund Asked",
      data: [20, 100, 200, 600, 500, 200, 100, 55, 200, 45, 300, 500],
      fill: true,
      backgroundColor: "#F2536D",
      borderColor: "#F2536D",
      tension: 0.6,
    },
  ],
};
