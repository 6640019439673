import React, { useEffect, useState } from "react";
import img from "../../assets/images/icons/home1.svg";
import img1 from "../../assets/images/icons/home2.svg";
import img2 from "../../assets/images/icons/home3.svg";
import img3 from "../../assets/images/img/home5.png";
import img4 from "../../assets/images/img/home6.png";
import img5 from "../../assets/images/img/home7.png";
import { Link, useNavigate } from "react-router-dom";
import FirstButton from "../../common/button";

const Main = () => {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      index === 2 ? setIndex(0) : setIndex(index + 1);
    }, 3000);

    return () => {
      if (interval) clearInterval(interval);
    };
  });

  return (
    <div className="main">
      <div className="main_container">
        <div className="home_main">
          <div  className={index === 0 ? "home_tileactive" : "home_tile"}></div>
          <div  className={index === 1 ? "home_tileactive" : "home_tile"}></div>
          <div  className={index === 2 ? "home_tileactive" : "home_tile"}></div>
       
        </div>
        <div className="main_tiles">
          <div
            className={index === 0 ? "sideactive" : "side_tile"}
            onClick={() => setIndex(0)}
          >
            <div className="tile_image">
              <img className="tile_img" src={img} alt="" />
            </div>
            <div className="tile_text">
              <h1 className="tile_primary">Explore startups</h1>
              <p className="tile_secondary">
                Access curated and highly-vetted opportunities across sectors
              </p>
            </div>
          </div>

          <div
            className={index === 1 ? "sideactive" : "side_tile"}
            onClick={() => setIndex(1)}
          >
            <div className="tile_image">
              <img className="tile_img" src={img1} alt="" />
            </div>
            <div className="tile_text">
              <h1 className="tile_primary">Raise Capital</h1>
              <p className="tile_secondary">
                Online fundraising with no friction for SAFE and contribution
                rounds
              </p>
            </div>
          </div>

          <div
            className={index === 2 ? "sideactive" : "side_tile"}
            onClick={() => setIndex(2)}
          >
            <div className="tile_image">
              <img className="tile_img" src={img2} alt="" />
            </div>
            <div className="tile_text">
              <h1 className="tile_primary">Marketplace</h1>
              <p className="tile_secondary">
                Solve business problems, identify opportunities, and build new
                products worldwide
              </p>
            </div>
          </div>
        </div>

        {index === 0 && (
          <div className="main_content">
            <h1 className="main_h1">Invest in most exciting businesses</h1>
            <div className="main_textcenter">
              <img src={img3} alt="" className="mainImg" />
            </div>
            <div className="main_btn">
              <FirstButton
                className="btn"
                buttonText="Get Started "
                onClick={() => navigate("/signup")}
              />
            </div>
          </div>
        )}

        {index === 1 && (
          <div className="main_content">
            <h1 className="main_h1">
              Raising fund made <br></br> simple &nbsp; &nbsp; &nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </h1>
            <div className="main_textcenter">
              <img src={img4} alt="" className="mainImg" />
            </div>
            <div className="main_btn">
              <FirstButton
                className="btn"
                buttonText=" &nbsp; Raise now &nbsp;"
                onClick={() => navigate("/signup")}
              />
            </div>
          </div>
        )}
        {index === 2 && (
          <div className="main_content">
            <h1 className="main_h1">A tailored funnel to reach your target.</h1>
            <div className="main_textcenter">
              <img src={img5} alt="" className="mainImg" />
            </div>
            <div className="main_btn">
              <FirstButton
                className="btn"
                buttonText=" &nbsp; Start now &nbsp;"
                onClick={() => navigate("/signup")}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Main;
