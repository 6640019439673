import React, { useState } from "react";
import FirstButton from "../../../common/button";
import Sidebar from "./Sidebar";
import upload from "../../../assets/images/logo/upload.svg";
import Dropdown from "../../../common/dropdown";
import Scrolltotop from "../../../assets/images/oicons/svg/scrollup.svg";

const interests = [
  { id: 1, name: "Aerospace" },
  { id: 2, name: "Agriculture" },
  { id: 3, name: "Artificial Intelligence" },
  { id: 4, name: "Assistive Devices" },
  { id: 5, name: "B2B" },
  { id: 6, name: "B2C" },
  { id: 7, name: "Bio-Technology" },
  { id: 8, name: "Digital Health" },
  { id: 9, name: "Entertainment" },
  { id: 10, name: "Farming" },
  { id: 11, name: "Food" },
  { id: 12, name: "Generator" },
  { id: 13, name: "Health and Wellness" },
  { id: 14, name: "Mobility" },
  { id: 15, name: "Space" },
  { id: 16, name: "Sustainability" },
  { id: 17, name: "Technology" },
  { id: 18, name: "Venture Funded" },
  { id: 19, name: "Women Founder" },
];
const droplist = [
  { value: "1", label: "asdmasd" },
  { value: "3", label: "asdmasd" },
  { value: "2", label: "asdmasd" },
];
const OrganisationList = [
  { value: "1", label: "University" },
  { value: "2", label: "Research lab" },
  { value: "3", label: "Startup" },
  { value: "4", label: "MSME" },
  { value: "5", label: "Agent/Broker" },
  { value: "6", label: "Consultant" },
];

const Interest2 = [
  "Intrets",
  "INT",
  "Interests",
  "Fintech",
  "Unselected tag",
  "Sector",
  "Itc",
  "Interests2",
  "Unselected Tag",
];
const MarketProfile = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const [active, setActive] = useState([]);

  const handleClick = (val) => {
    setActive((oldArray) => [...oldArray, val]);
  };

  return (
    <div className="cfp_main">
      <Sidebar />

      <div className="cfp_cont1">
        <h4 className="market_h4">Company Profile</h4>
        <div className="cfp_cont">
          <h4 className="cfp_h4 cfp_mr"> Organisation Type</h4>
          <Dropdown MenuList={OrganisationList} placeholder="select" />
        </div>
        <div className="cfp_cont">
          <h4 className="cfp_h4 cfp_mr">Legal Business Name</h4>
          <input type="text" className="login_input" placeholder="Type Here" />
          <div className="cfp_btn_cont">
            {/* <FirstButton buttonText="Cancel" className="borderbtn"/> */}
            <FirstButton
              buttonText="Request"
              className="btn"
              margin="0 0rem 0 2rem"
            />
          </div>
        </div>
        <div className="cfp_cont">
          <h4 className="cfp_h4">Primary Representative</h4>
          {/* <p className="cfp_p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> */}
          <p className="cfp_p">Legal name of representative</p>
          <input type="text" className="login_input" placeholder="Type Here" />
          <p className="cfp_p">Mobile Number</p>
          <input type="text" className="login_input" placeholder="Type Here" />
          <p className="cfp_p">Official Email ID</p>
          <input type="text" className="login_input" placeholder="Type Here" />
          <div className="cfp_btn_cont">
            <FirstButton buttonText="Cancel" className="borderbtn" />
            <FirstButton
              buttonText="Verify"
              className="btn"
              margin="0 0rem 0 2rem"
            />
          </div>
          <div className="cfp_cont">
            <p className="cfp_p">Linkedin Profile</p>
            <input
              type="text"
              className="login_input"
              placeholder="Type Here"
            />
          </div>
        </div>
        <div className="cfp_cont">
          <h4 className="cfp_h4">Profile Image</h4>
          <p className="cfp_p">
            Upload a square image that represents your campaign.
            <br /> 100 x 100 pixels recommended resolution, maximum size 300kb.
          </p>
          <div>
            <button className="UploadBtn">
              <img src={upload} alt="" className="w100" />
            </button>
          </div>
        </div>

        <div className="cfp_cont">
          <h4 className="cfp_h4 cfp_mr"> Business Headquarters </h4>
          <Dropdown MenuList={droplist} placeholder="select" />
        </div>
        <div className="cfp_cont">
          <h4 className="cfp_h4 cfp_mr">Corporate Identification Number</h4>
          <input type="text" className="login_input" placeholder="Type Here" />
        </div>
        <div className="cfp_cont">
          <h4 className="cfp_h4 cfp_mr">DPIIT/DIPP Number</h4>
          {/* <p className="cfp_p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> */}
          <input type="text" className="login_input" placeholder="Type Here" />
        </div>

        <div className="cfp_cont">
          <h4 className="cfp_h4">Your interests</h4>

          <div>
            <p className="cfp_p">
              This helps us recommend Opportunities for you
            </p>
            <div className="invest_profilecont">
              {interests.map((int, index) => (
                <p
                  key={index}
                  className={
                    active.includes(int.id)
                      ? "invest_profiletilesactive"
                      : "invest_profiletiles"
                  }
                  onClick={() => handleClick(int.id)}
                >
                  {int.name}
                </p>
              ))}
            </div>
          </div>
          <div className="cfp_btn_cont">
            <FirstButton buttonText="Discard" className="borderbtn" />
            <FirstButton
              buttonText="Save"
              className="btn"
              margin="0 0rem 0 2rem"
            />
          </div>
          <div></div>
        </div>
        <div className="stti">
          <img src={Scrolltotop} onClick={handleScroll} />
        </div>
      </div>
    </div>
  );
};

export default MarketProfile;
