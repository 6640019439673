import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import SwitchGreen from "../../../../common/Switch";
import { FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";

const List = [
  "I Understand Smaarket does not provide legal, financial or tax advice of any kind you will consult a professional adviser.",
  "I have the sophistication to evaluate investment Campaign on Smaarket on my own.",
  "I agree to the Terms, and Privacy Policy.",
];
const MainFinish = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleClick = () => {
    navigate("/maincampcreation");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />

        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Finishing up</h4>
          <div className="cfp_wd1">
            <div>
              <p className="reoffcard_p1">Terms and conditions </p>
              {List.map((item, index) => (
                <FormControlLabel
                style={{alignItems:"start"}}
                  label={<p className="risk_label">{item}</p>}
                  control={<SwitchGreen sx={{ m: 1 }} />}
                  key={index}
                  onChange={handleChange}
                  sx={{ m: 1, width: "85%" }}
                />
              ))}
            </div>
            <div>
              <p className="reoffcard_p1">
                Allow for the collection of donations for the Yenilik
                Foundation. 
              </p>
              <p className="signupready_p5">
                Help us to make a more significant impact on our Enterprenership
                and Innovation ecosystem.
                <br /> Thank you for your support and impact!
              </p>
              <FormControlLabel
              style={{alignItems:"start"}}
                label={
                  <p className="risk_label">
                    I wish to support the initiative and the full amount of
                    donations collected goes to the Yenilik Foundation.
                  </p>
                }
                control={<SwitchGreen sx={{ m: 1 }} />}
                onChange={handleChange}
                sx={{ m: 1, width: "85%" }}
              />
            </div>
            <div>
              <p className="reoffcard_p1">Launch date</p>
              <p className="signupready_p5">
                {" "}
                We recommend at least 30 days of pre-campaign before the main
                campaign launches.
                <br />
                Select below the main campaign launch date.
              </p>
              <input type="date" className="date" />
              <p
                className="font_p2"
                style={{ textAlign: "center", color: "#198097" }}
              >
                28 days to go for launch
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/maincampcreation/analytics")}
        />
        <FirstButton
          buttonText="Preview and Submit"
          onClick={handleClick}
          className="btn"
        />
      </div>
    </div>
  );
};

export default MainFinish;
