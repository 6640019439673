import React, { useState } from "react";
import Dropdown from "../../common/dropdown";
import Sidebar from "./Sidebar";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FirstButton from "../../common/button";
import scroll from "../../assets/images/oicons/svg/scrollup.svg";

const Feedback = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="dflex ">
      <Sidebar />

      <div className="w70 p132">
        <h1 className="faq_f1 faq_mr"> Feedback </h1>
        <p className="feedback_f1">
          Did we miss a feature? How can we make smaarket your utopian
          destination ? <br />
          We are listening! We believe feedback from our users are our most
          valuable source of inspiration. All comments are accepted with
          gratitude!
        </p>
        <div className="faq_mr">
          <h2 className="faq_f2 faq_mr1">What would you like to share ?</h2>
          <Dropdown MenuList={list} />
        </div>
        <div className="faq_mr">
          <h2 className="faq_f2 faq_mr1">
            Please share your thoughts or feedback
          </h2>
          <textarea
            className="precampfaq_textarea"
            placeholder="Type Here"
            er="Type Here"
          ></textarea>
        </div>
        <div className="faq_mr">
          <h2 className="faq_f3 faq_mr1">
            Upload a screen shot or document to help us better understand what
            you're referring to:
          </h2>
          <TextField
            id="outlined-basic"
            type="file"
            inputProps={{
              multiple: true,
            }}
            style={{
              marginTop: "1rem",
              background: "#F5F5F5",
              border: "none !imporatant",
              outline: "none !important",
              width: "100%",
            }}
          />
        </div>
        <div className="faq_mr">
          <h2 className="faq_f3">
            How likely is it that you would recommend Smaarket to a friend or a
            colleague?
          </h2>
          <RadioGroup
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="yes"
              control={
                <Radio
                  sx={{
                    color: "#19976A",
                    "&.Mui-checked": { color: "#19976A" },
                    "& .MuiSvgIcon-root": { fontSize: 24 },
                  }}
                />
              }
              label={<span style={{ fontSize: "1.6rem" }}>Very likely</span>}
            />
            <FormControlLabel
              sx={{ fontSize: 16 }}
              value="no"
              control={
                <Radio
                  sx={{
                    color: "#19976A",
                    "&.Mui-checked": { color: "#19976A" },
                    "& .MuiSvgIcon-root": { fontSize: 24 },
                  }}
                />
              }
              label={<span style={{ fontSize: "1.6rem" }}>Maybe</span>}
            />
            <FormControlLabel
              sx={{ fontSize: 16 }}
              value="no"
              control={
                <Radio
                  sx={{
                    color: "#19976A",
                    "&.Mui-checked": { color: "#19976A" },
                    "& .MuiSvgIcon-root": { fontSize: 24 },
                  }}
                />
              }
              label={<span style={{ fontSize: "1.6rem" }}>Less likely</span>}
            />
          </RadioGroup>
        </div>
        <div>
          <div style={{ textAlign: "center" }}>
            <FirstButton buttonText="Send Feedback" className="btn" />
          </div>
        </div>
        <div className="textend">
          <img src={scroll} onClick={handleScroll} />
        </div>
      </div>
    </div>
  );
};

export default Feedback;

const list = [
  { value: "1", label: "Personal feedback " },
  { value: "2", label: "Issue" },
  { value: "3", label: "other error" },
];
