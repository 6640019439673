import React from 'react'
import Img from "../../../../assets/images/img/congrats1.png"
import Img1 from "../../../../assets/images/logo/marketcardLogo4.png"
import Img2 from "../../../../assets/images/logo/star.png"
import Arrow from "../../../../assets/images/logo/forwardarrow.png"
import { useNavigate } from 'react-router-dom'

const TechNeedSuccess = () => {
  const navigate=useNavigate()
  const handleNavigate=(id)=>{
    if(id===1){
      navigate("/marketplace/techofferbasicdetails")
    }else{
      navigate("/marketplace/premium")
    }

  }
  return (
    <div className='mtoc_main'>
        <img src={Img} alt="" srcset="" />
        <p className='mtoc_p'>Congratulations..!! You've expressed an interest.</p>
        <div className='spar_cont' >
        {cardlist.map((item,index)=>(
        <div className='mpto_cont1  spar_mr' key={index} onClick={()=>handleNavigate(item.id)} >
        <img src={item.img} alt="" />
        <div className='mpto_cont2'>
        <p className='mpto_cardtitle'>{item.title} </p>
        <p><img src={Arrow}/></p> 
        </div>
        <p className='mpto_p1'>{item.description}</p>
        </div>))}
        </div>

    </div>
  )
}

export default TechNeedSuccess


const cardlist=[{id:1,img:Img1,title:"Post your offer",description:"Seeking out for ready-to-market technological, scientific products  or partnerships."},
{id:2,img:Img2,title:"Marketplace Premium",description:"Simply subscribe to one of our packages and close your engagement really fast."},
]