import React from 'react'
import Sidebar from '../SidebarforPrecamp/SidebarPreCamp'
import FirstButton from '../../../../common/button'
import { useNavigate } from 'react-router'

const ExternalLinks = () => {
  const navigate=useNavigate()
  const handleClick=()=>{
    navigate("/precampaigncreation/faq")
  }
  return (
    <div>
    <div className='cfp_main'>
        <Sidebar/>
        <div className='cfp_cont1'>
        <div className='precampbtncont1'>
          <FirstButton buttonText="Save and Exit" className="borderbtn"/>
        </div>
      <h4 className='market_h4'>External links</h4>
      <div className="cfp_cont">
<h4 className='cfp_h4'>Social media </h4>
<p className="cfp_p">Smaarket is based on community . The more the merrier!</p>
<p className="cfp_p">Twitter</p>

<input type="text" placeholder='Type here' className="login_input" />
<p className="cfp_p">Faceboook</p>

<input type="text" placeholder='Type here' className="login_input" />
<p className="cfp_p">Linkedin</p>

<input type="text" placeholder='Type here' className="login_input" />
<p className="cfp_p">Discord</p>

<input type="text" placeholder='Type here' className="login_input" />

      </div>

      <div className="cfp_cont">
<h4 className='cfp_h4'>Business Website </h4>

<input type="text" placeholder='Type here' className="login_input" />

      </div>
      <div className="cfp_cont">
<h4 className='cfp_h4'>Your Mobile application  </h4>
<p className="cfp_p">Google playstore</p>
<input type="text" placeholder='Type here' className="login_input" />
<p className="cfp_p">Apple store</p>
<input type="text" placeholder='Type here' className="login_input" />


      </div>
        </div>
    </div>
    <div className="precampbtncont">
        <FirstButton buttonText="Previous" className="borderbtn" onClick={()=>navigate("/precampaigncreation/projectdetails")}/>
        <FirstButton buttonText="Save and next" onClick={handleClick} className="btn"/>
      </div>
    </div>
  )
}

export default ExternalLinks