import React from "react";
import img01 from "../../../../assets/images/img/balley1.png";
import img02 from "../../../../assets/images/img/balley2.png";
import TextField from "@mui/material/TextField";
const data = [
  {
    tilte: "Top 20 finalist in the 2022 TechCrunch Disrupt conference",
    date: "May 01 2022",
    desc: "Ally Robotics has been selected as a top 20 finalist in the 2022 TechCrunch Disrupt conference in San Francisco!",
    img: img01,
  },
  {
    tilte: "100% funded",
    date: "Feb 30 2022",
    desc: "After a impressive responses from wellwishers and a successful precampaign  were live..!! After a impressive responses from wellwishers and a successful precampaign  were live..!! After a impressive responses from wellwishers and a successful precampaign  were live..!! ",
    img: img02,
  },
  {
    tilte: "50% funded",
    date: "Jan 19 2022",
    desc: "After a impressive responses from wellwishers and a successful precampaign  were live..!! After a ",
    img: img01,
  },
];
const UpdateAndNews = () => {
  return (
    <>
      <div className="updateAndNews">
        <div className="updateAndNews_container">
          <h1 className="brands_primary">Updates & News</h1>
          <div className="updates_container">
            {data &&
              data.map((item, index) => (
                <div className="updates">
                  <div className="update_thumb">
                    <img className="update_img" alt="" src={item.img} />
                  </div>
                  <div className="update_content">
                    <h1 style={{ color: "#070908" }} className="tile_primary">
                      {item.tilte}
                    </h1>
                    <p style={{ color: "#070908" }} className="unic">
                      {item.date}
                    </p>
                    <p style={{ color: "#070908" }} className="tile_secondary">
                      {item.desc}
                    </p>
                  </div>
                </div>
              ))}
          </div>

          <div>
            <h2 style={{ color: "#070908" }} className="tile_primary">
              Add a News/Update
            </h2>
            <p style={{ color: "#070908" }} className="tile_secondary">
              Keep your followers informed about the latest developments
            </p>
            <div style={{ display: "flex", gap: "2rem" }}>
              <input className="login_input" />
              <TextField
                variant="standard"
                type="file"
                InputProps={{
                  multiple: true,
                  disableUnderline: true,
                }}
                style={{
                  background: "#F5F5F5",
                  border: "none !imporatant",
                  outline: "none !important",
                  width: "97.5%",
                  padding: "1.2rem  1rem ",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateAndNews;
