import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import Img from "../../../assets/images/img/marketplaceprofile.png"

const Sidebar = () => {
	const {pathname}=useLocation()
	const splitLocation = pathname.split("/");
  return (
    <div className='sidebar_main'style={{textAlign:"start",alignItems:"start"}}>
        <div className="sidebar_details sideBarNav_profilepd">
            <img src={Img} alt="" />
            <p>Callum biotech</p>
            <p>SM-JP-0145</p>
        </div>

		<div className='sidebar_cont1'>
	<nav className='nav-wd' >
					
						<div className='nav_cont'>
						<div className={splitLocation[2] === "companyprofile" ? "sideBarNav_itemprofileactive" : "sideBarNav_itemprofile"}> 
						<Link to="/marketplace/companyprofile">Company Profile</Link>
						</div>

							<div className={splitLocation[2] === "marketplace" ? "sideBarNav_itemprofileactive" : "sideBarNav_itemprofile"}> 
							<Link to="/marketplace/marketplace">Marketplace Activity</Link>
							</div>

							<div className={splitLocation[2] === "watchlist" ? "sideBarNav_itemprofileactive" : "sideBarNav_itemprofile"}> 
							<Link to="/marketplace/watchlist">Watchlist</Link>
							</div>

			<div className={splitLocation[2] === "referandearn" ? "sideBarNav_itemprofileactive" : "sideBarNav_itemprofile"}> 
			<Link to="/marketplace/referandearn">Refer and Earn</Link>
							</div>

			<div className={splitLocation[2] === "settings" ? "sideBarNav_itemprofileactive" : "sideBarNav_itemprofile"}> 
			<Link to="/marketplace/settings">Settings</Link>
							</div>

		
					</div>
</nav>
        </div>
    </div>
  )
}

export default Sidebar