import React from "react";
import FirstButton from "../../../../common/button";
import PreQualiy1 from "../../../../assets/images/img/prequaliy1.png";
import PreQualiy2 from "../../../../assets/images/img/prequaliy2.png";
import key from "../../../../assets/images/oicons/svg/key.svg";
import { useNavigate } from "react-router-dom";
const PreQualifyMain = () => {
  const navigate = useNavigate();
  return (
    <div className="prequaapp_main">
      <div>
        <h4 className="review_primary" style={{ color: "black" }}>
          See if you qualify to raise on Smaarket
        </h4>
        <p className="signupready_p2">
          Every application is highly vetted, and historically only 1% of
          applications have been selected to raise on Smaarket Here are a few
          things we look for:
        </p>
        <div className="prequaapp_cont1">
          <div style={{ width: "100%" }}>
            <p
              className="reoffcard_p2"
              style={{ fontWeight: "700", padding: "1rem 0" }}
            >
              Investment Campaigns
            </p>
            <ul className="reoffcard_p2 prequaapp_list prequal_pd">
              <li>
                Product differentiation and scalability to venture-sized returns
              </li>
              <li>
                Experienced team - successful exits and early-stage startup
                experience is preferred
              </li>
              <li>Campaign can't involve prohibited items.</li>
            </ul>
          </div>

          <div style={{ width: "100%" }}>
            <p
              className="reoffcard_p2"
              style={{ fontWeight: "700", padding: "1rem 0" }}
            >
              Donation Campaign
            </p>
            <ul className="reoffcard_p2 prequaapp_list prequal_pd">
              <li>
                Experienced team - Early-stage startup experience is preferred
              </li>
              <li>
                Commitments or Support from Reputed University,Research
                labs,Institution,Incubation centre or accelerator to build POC{" "}
              </li>
              <li>Campaign can't involve prohibited items.</li>
            </ul>
          </div>
          <div style={{ width: "100%" }}>
            <p
              className="reoffcard_p2"
              style={{ fontWeight: "700", padding: "1rem 0" }}
            >
              Private Deal room
            </p>
            <ul className="reoffcard_p2 prequaapp_list prequal_pd">
              <li>
                Connect directly with smaarket network of High Net Worth and
                Sophisticated investors who wish to lead funding rounds and
                ultimately invest offline.
              </li>
              <li>Fund as Service : Launch your own Micro fund/Large Fund</li>
            </ul>
          </div>
        </div>

        <div>
          <p className="market_h4">Pick a method to raise fund.</p>
        </div>
        <div className="prequaapp_cont1">
          <div className="prequaapp_card">
            <div className="prequaapp_cont2">
              <p className="signupready_p3">
                Investment <br />
                campaign
              </p>
              <img src={PreQualiy1} alt="" width="20%" />
            </div>
            <p className="prequaapp_p1" style={{ height: "5rem" }}>
              Looking to Raise INR 15 Lakhs to 1 Crore ?
            </p>
            <div>
              <FirstButton
                buttonText="Seek investment"
                className="btn"
                onClick={() => navigate("/prequalify/basicdetails")}
              />
            </div>
          </div>

          <div className="prequaapp_card">
            <div className="prequaapp_cont2">
              <p className="signupready_p3">
                Donation <br /> Campaign
              </p>
              <img src={PreQualiy2} alt="" width="20%" />
            </div>
            <p className="prequaapp_p1" style={{ height: "5rem" }}>
              Looking to Raise INR 5 Lakhs to 15 Lakhs ?
            </p>
            <div>
              <FirstButton
                buttonText="Seek Donation"
                className="btn"
                onClick={() => navigate("/prequalify/basicdetails")}
              />
            </div>
          </div>
          <div className="prequaapp_card">
            <div className="prequaapp_cont2">
              <p className="signupready_p3">
                Private Deal <br /> room
              </p>
              <img src={key} alt="" width="17%" />
            </div>
            <p className="prequaapp_p1" style={{ height: "5rem" }}>
              Exclusive opportunities for High Net Worth and Sophisticated
              investors.
            </p>
            <div>
              <FirstButton buttonText="Request Invitation" className="btn" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreQualifyMain;
