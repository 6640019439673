import React, { useState } from "react";
import Sidebar from "./Sidebar";
import FirstButton from "../../../common/button";
import { FormControlLabel } from "@mui/material";
import SwitchGreen from "../../../common/Switch";
import { useNavigate } from "react-router-dom";

const TechNeedFinish = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleSubmit = () => {
    navigate("/marketplace/techneedend");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton
              buttonText="Save and Exit"
              className="borderbtn"
              onClick={() => navigate("/marketplace/marketplaceactivity")}
            />
          </div>
          <div className="tnf_main">
            <h1 className="tnbd_h1">Finishing up Submission agreement </h1>
            <p className="tnbd_f3">
              Users who want to submit a proposal for technology need will
              carefully read the terms of the agreement and will send the form
              only if they agree with and accept the conditions included in the
              submission agreement.
            </p>
          </div>
          <div className="tnbd_cont">
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Submission agreement </h4>
              {risksList.map((item, index) => (
                <FormControlLabel
                  label={<p className="risk_label">{item.label}</p>}
                  control={<SwitchGreen sx={{ m: 1 }} />}
                  key={index}
                  onChange={handleChange}
                  sx={{ m: 1 }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton buttonText="Previous" className="borderbtn" />
        <FirstButton
          buttonText="Preview And Submit"
          className="btn"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default TechNeedFinish;

const risksList = [
  {
    label:
      "I agree and warrant  - I  have the right to disclose the  information to Smaarket",
  },
  {
    label:
      "I agree and warrant  - information is non-confidential and publishing this information does not interfere with the intellectual property rights of any party other than myself or organisation.",
  },
  {
    label:
      "I  acknowledge and accept Smaarket to represent and respond  to submissions",
  },
];
