import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import Scrolltotop from "../../../../assets/images/oicons/svg/scrollup.svg";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../../common/dropdown";

const MainDealTerm = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/maincampcreation/financials");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />

        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Deal terms</h4>
          <div className="cfp_wd1">
            <div className="mcdt_cont">
              <p className="mcdt_p1">Deal Type </p>
              <p className="mcdt_p2">Structure your raise .</p>
              <Dropdown placeholder={"select..."} MenuList={[]} />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Raise Amount </p>
              <p className="mcdt_p2">
                We encourage you to seta specific target though your potential
                raise will be subject to regulations
              </p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Discount </p>
              <p className="mcdt_p2">
                The discount rate is the key factor in business valuation that
                converts future Rupees into present value as of the valuation
                date.
              </p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">
                What size investment would you consider?{" "}
              </p>
              <p className="mcdt_p2">
                We encourage testing the waters to evaluate investor interest.
              </p>
              <div className="" style={{ display: "flex", gap: "2rem" }}>
                <div style={{ flex: 1 }}>
                  <Dropdown placeholder={"Min"} MenuList={[]} />
                </div>
                <div style={{ flex: 1 }}>
                  <Dropdown placeholder={"Max"} MenuList={[]} />
                </div>
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Sample agreement </p>

              <TextField
                id="outlined-basic"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                style={{
                  marginTop: "1rem",
                  background: "#F5F5F5",
                  border: "none !imporatant",
                  outline: "none !important",
                  width: "100%",
                }}
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Due diligence </p>
              <p className="mcdt_p3">Due diligence documents to attach</p>
              <p className="mcdt_p3">
                Technical and Intellectual Property :
                <span className="mcdt_p2">
                  Intellectual property ,Proprietery technology{" "}
                </span>
              </p>
              <p className="mcdt_p3">
                Tax and Financial :
                <span className="mcdt_p2">
                  Audited financial statement,ITR acknowledgment,CIBIL
                  report,Finacial projections
                </span>
              </p>
              <p className="mcdt_p3">
                Human Resources :
                <span className="mcdt_p2">
                  Employee register ,Schedule of ESOP
                </span>
              </p>
              <p className="mcdt_p3">
                Legal :
                <span className="mcdt_p2">
                  MOA,AOA,Prospectus,Loans,Minutes of Meeting,true copy of ROC
                </span>
              </p>
              <p className="mcdt_p3">
                We may ask for more documentation as permitted by law.
              </p>
              <TextField
                id="outlined-basic"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                style={{
                  marginTop: "1rem",
                  background: "#F5F5F5",
                  border: "none !imporatant",
                  outline: "none !important",
                  width: "100%",
                }}
              />
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/maincampcreation/aboutus")}
        />
        <FirstButton
          buttonText="Save and next"
          onClick={handleSubmit}
          className="btn"
        />
      </div>
    </div>
  );
};

export default MainDealTerm;
