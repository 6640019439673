import React from "react";
import Sidebar from "./Sidebar";
import FirstButton from "../../../common/button";
import Dropdown from "../../../common/dropdown";
import UploadImg from "../../../assets/images/logo/upload3.svg";
import UploadImg2 from "../../../assets/images/logo/upload2.svg";
import Scrolltotop from "../../../assets/images/oicons/svg/scrollup.svg";
import { useNavigate } from "react-router-dom";
const TechNeedBasicDetails = () => {
  const navigate = useNavigate();
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton
              buttonText="Save and Exit"
              className="borderbtn"
              onClick={() => navigate("/marketplace/marketplaceactivity")}
            />
          </div>
          <div>
            <h1 className="tnbd_h1">Basic Details</h1>
            <p className="tnbd_f3">
              How you describe your need is as crucial as what you are seeking.
            </p>
          </div>
          <div className="tnbd_cont">
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">What are you looking for?</h4>
              <Dropdown MenuList={DropList} />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Title of the Need</h4>
              <p className="tnbd_f2">
                Give a concise and comprehensive statement of what you need. The
                title must start with "seeking."
              </p>
              <input className="login_input" placeholder="Type Here" />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Description of need</h4>
              <p className="tnbd_f2">
                A detailed description of your Call leads to receive much better
                proposals
              </p>
              <textarea
                className="precampfaq_textarea"
                placeholder="Type Here"
                style={{ margin: "0" }}
              ></textarea>
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Project size</h4>
              <input
                className="login_input"
                placeholder="Enter amount in Dollars"
              />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Requirement origin</h4>
              <Dropdown />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Engagement opportunity</h4>
              <p className="tnbd_f2">Desired Outcome</p>
              <Dropdown MenuList={DropListEng} />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Campaign tile</h4>
              <p className="tnbd_f2">
                This image will represent as Campaign main tile image
                <br />
                302 x 200 pixels recommended resolution, maximum size 600kb.
              </p>
              <button className="UploadBtn">
                <img src={UploadImg} alt="upload" className="w100" />
              </button>
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Need banner images</h4>
              <p className="tnbd_f2">
                This image will represent as header in the main campaign page
                <br />
                648 x 488 pixels recommended resolution, maximum size 900kb..
              </p>
              <button className="UploadBtn">
                <img src={UploadImg2} alt="upload" className="w100" />
              </button>
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Sector tags</h4>
              <p className="tnbd_f2">
                Giving a tag is the key for your need to be found by appropriate
                stakeholders
              </p>
              <Dropdown />
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton buttonText="Previous" className="borderbtn" />
        <FirstButton
          buttonText="Save and next"
          className="btn"
          onClick={() => navigate("/marketplace/techneeddetails")}
        />
      </div>
    </div>
  );
};

export default TechNeedBasicDetails;

const DropList = [
  { value: "2", label: "Game-changing technologies and ideas" },
  { value: "3", label: "Partners for the Consortium" },
  { value: "7", label: "Ready to Market: Technical" },
  { value: "8", label: "Innovation and Products" },
  { value: "9", label: "Research services and capabilities" },
  { value: "10", label: "Specific scientific innovation" },
  { value: "11", label: "Specific technical innovation" },
];
const DropListEng = [
  { value: "1", label: "Investment" },
  { value: "2", label: "Licensing" },
  { value: "3", label: "Mergers and acquisition" },
  { value: "4", label: "Commercialization" },
  { value: "5", label: "Technology transfer" },
  { value: "6", label: "Commericlization" },
  { value: "7", label: "Technology transfer" },
  { value: "8", label: "Partnership" },
  { value: "9", label: "Research collaboration" },
];
