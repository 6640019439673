import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img01 from "../../../../../assets/images/slide1.png";
import img02 from "../../../../../assets/images/slider/panwar1.PNG";
import img03 from "../../../../../assets/images/slider/panwar2.PNG";
import img04 from "../../../../../assets/images/slider/panwar3.PNG";
import img05 from "../../../../../assets/images/slider/panwar4.PNG";
import img06 from "../../../../../assets/images/slider/panwar5.PNG";
import img07 from "../../../../../assets/images/slider/panwar6.PNG";
import img08 from "../../../../../assets/images/slider/panwar7.PNG";
import img09 from "../../../../../assets/images/slider/panwar8.PNG";
import img10 from "../../../../../assets/images/slider/panwar9.PNG";

import { Button } from "@material-ui/core";

const Pitch = () => {
  const data = [
    {
      number: 1,
      text: "5 seater luxury jet’s design & simulations completed, and fuselage production started in June 2022.",
    },
    {
      number: 2,
      text: "Innovated sustainable wind powered energy generation technology, verified by industry experts.",
    },
    {
      number: 3,
      text: "Expect to take our first flight in the next 18 months, working with FAA for certification.",
    },
    {
      number: 4,
      text: "The cost of travel will substantially fall to ₹ 2000  VS ₹ 1,44,000 per hour by its fossil fuel counterpart",
    },
    {
      number: 5,
      text: "Panwar Jet’s CEO, yisha, was featured on the cover page of Utah Business Magazine in Jan 2022.",
    },
    {
      number: 6,
      text: "Founders, Nick & yisha featured as successful startup launch couple on CSUF Business in Sep, 2021!",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
  };
  return (
    <>
      <div className="pitch">
        <div className="pitch-container">
          <div className="heighlights">
            <h1 className="brands_primary">Highlights</h1>

            {data.map((item, index) => (
              <div
                key={index}
                style={{
                  color: "#070908",
                  display: "flex",
                  alignItems: "center",
                }}
                className="tile_secondary heighlights_text"
              >
                <span className="heighlight_point">{item.number}</span>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="slider">
        <Slider {...settings}>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img01}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img02}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img03}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img04}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img05}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img06}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img07}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img08}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img09}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img10}></img>
          </div>
        </Slider>
      </div>

      <div
        style={{ backgroundColor: "#fff", marginBottom: "0" }}
        className="faq"
      >
        <div className="faq_container">
          <h1 className="faq_primary light-dark">Company FAQ</h1>
          <div className="questions">
            <div className="question_container--1">
              <div className="question1">
                <h1 className="question light-dark">Why Get Involved ?</h1>
                <p className="answer light-dark">
                  Our proven technology is making us clear leader in the
                  industry. Our cost of manufacturing is expected almost 40%
                  less compared to other Electric aircraft manufacturers as we
                  only carry 10% of the battery size.{" "}
                </p>
              </div>
              <div className="question2">
                <h1 className="question light-dark">What Are We Up to Now? </h1>
                <p className="answer light-dark">
                  We have already tested and finalized our technology this year
                  in May 2022. Tech is ready to put into our aircraft. We
                  finished aircraft designing in June 2022.
                </p>
              </div>
            </div>

            <div className="question_container--2">
              <div className="question1 ">
                <h1 className="question light-dark">
                  What is the Market size?
                </h1>
                <p className="answer light-dark">
                  The passenger aircraft market will be over $36B by 2028
                </p>
              </div>
              <div className="question2">
                <h1 className="question light-dark">
                  What we are expecting Next?
                </h1>
                <p className="answer light-dark">
                  We are expected to file for FAA certification by end of
                  October 2022.
                </p>
              </div>
            </div>
          </div>
          {/* <FirstButton className="btn" buttonText="View More" marginTop="5rem"/> */}
        </div>
      </div>
      <div className="bottom_status">
        <div></div>
        <Button id="closed" className="btn" variant="contained">
          Investment Closed
        </Button>
      </div>
    </>
  );
};

export default Pitch;
