import React from "react";
import img1 from "../../../assets/images/icons/mp3.png";
import img2 from "../../../assets/images/icons/mp4.png";
import img3 from "../../../assets/images/icons/mp5.png";
import img4 from "../../../assets/images/icons/mp6.png";
const MarketPlaceSuccess = () => {
  return (
    <>
      {/* <div className="review">
      <div className="review_container">
        <h1 className="review_primary">Investors comment</h1>
        <img className="review_img" src={reviews} alt="" />
      </div>
    </div> */}

      <div className="comment" style={{ backgroundColor: "black" }}>
        <div className="comment_container">
          <h1 style={{ color: "#fff" }} className="review_primary">
            What others say
          </h1>
          <div>
            {list.map((item, index) => (
              <div className="ss_main">
                <div>
                  {" "}
                  <img className="profile_img" src={item.img} alt="" />{" "}
                </div>
                <div>
                  <p className="ss_f1" style={{ color: "#fff" }}>
                    {item.head}
                  </p>
                  <p className="ss_f2" style={{ color: "#fff" }}>
                    {item.desc}
                  </p>
                  <p className="ss_f3" style={{ color: "#fff" }}>
                    {item.bot}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketPlaceSuccess;

const list = [
  {
    img: img1,
    desc: "Smaarket market place is giving us the opportunity to share our university portfolio of research findings and capabilities to the  global institutions.",
    bot: "Bidia anuiera,Technology transfer office",
  },
  {
    img: img2,
    desc: "We use Smaarket market Place as a window display of all the technologies the university offers.It enables us to identify which companies are interested in innovation and research, as well as their needs.",
    bot: "Lourdes Jane, Head of innovation and Technology transfer",
  },
  {
    img: img3,
    desc: "Barex is a new entrant in the market, and our capabilities have been communicated to a large enough audience.Our innovative solutions found the right homes for commercialization and licensing..",
    bot: "Ramarao, Chief executive officer",
  },
  {
    img: img4,
    desc: "We've used the Smaarket Market Place to post technical challenges as well as respond to technology calls for potential IP out-licensing in non-competition industries.",
    bot: " Austin zolda, R&D Director",
  },
];
