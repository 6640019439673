import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import { useNavigate } from "react-router-dom";
const Questions = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />

        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Questionnaire </h4>
          <div className="cfp_wd">
            <div>
              <p className="reoffcard_p1 prequal_mr">
                What's the rough size of your community?{" "}
              </p>
              <p className="signupready_p5">
                (Include your email list, social media following . We’d like to
                understand the rough size of your current audience.) add the
                content below whats the rough size of your community?
              </p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1 prequal_mr">
                How much Indian rupees do you want to raise ?{" "}
              </p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1 prequal_mr">
                How many months would you use runway for your business ?{" "}
              </p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1 prequal_mr ">
                Do you Provide Stakeholders with Painkiller, Vitamin, or a Cure?
              </p>
              <p className="cfp_p">
                Pain Killers: Solves issues, disrupt industries, or invent with
                a specific goal in mind. Vitamins: Aim to make the world a
                better place by acting on their ideals on a holistic level.
                Cure: Make the problem completely disappear
              </p>

              <input
                type="text "
                className="login_input"
                placeholder="Type Here"
              />
              <div>
                <p className="cfp_p prequal_mr">
                  Does your product or service positively impact social,
                  environmental, or humane goals?
                </p>

                <label class="form-control">
                  <input type="radio" name="radio" />
                  Yes
                </label>

                <label class="form-control">
                  <input type="radio" name="radio" />
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/prequalify/contact")}
        />
        <FirstButton
          buttonText="Save and next"
          className="btn"
          onClick={() => navigate("/prequalify/finish")}
        />
      </div>
    </div>
  );
};

export default Questions;
