import React from "react";
import { useNavigate } from "react-router-dom";
import img from "../../../assets/images/icons/explore1.svg";
import FirstButton from "../../../common/button";
import { role } from "../../utils";

const FundRaiseMain = () => {
  const userrole = localStorage.getItem("role");

  const navigate = useNavigate();
  const handleNavigate = () => {
    if (userrole === "fundraisers") {
      navigate("/prequalify");
      window.location.reload();
    } else {
      navigate("/signup");
    }
  };

  return (
    <>
      <div className="main">
        <div className="main_text">
          <h1 className="primaryText">
            ₹ 100 crore raised across the Smaarket ecosystem
          </h1>
        </div>
        <div className="main_container w90">
          <div className="main_tiles mr8">
            <p className="secondaryText">
              {" "}
              We assist founders in raising capital, engaging their communities,
              and scaling for the future.
            </p>
            <p style={{ marginTop: "2rem" }} className="secondaryText">
              Raise ₹50 lakh to ₹ 7 crores from superfans who believe in you.
            </p>
            {userrole === role[0].role ? (
              <FirstButton
                className="btn"
                float="left"
                marginTop="5rem"
                buttonText="Start raising now"
                onClick={handleNavigate}
              />
            ) : (
              <FirstButton
                className="btn"
                float="left"
                marginTop="5rem"
                buttonText="Signup as a Founder"
                onClick={() => navigate("/signup")}
              />
            )}
          </div>

          <div className="main_content main_imgcont2">
            <img src={img} className="main_img1" style={{ width: "60%" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FundRaiseMain;
