import { Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "50px",
    height: "24px",
    padding: "0px",
    marginRight: "1rem",
  },
  switchBase: {
    color: "#19976A",
    padding: "1px",
    "&$checked": {
      "& + $track": {
        backgroundColor: "#19976A",
      },
    },
  },
  thumb: {
    color: "white",
    width: "20px",
    height: "20px",
    margin: "1px",
  },
  track: {
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: "1 !important",
    "&:after, &:before": {
      color: "white",
      fontSize: "9px",
      position: "absolute",
      top: "7px",
    },
    "&:after": {
      content: "'YES'",
      left: "5px",
    },
    "&:before": {
      content: "'NO '",
      right: "7px",
    },
  },
  checked: {
    color: "#19976A !important",
    transform: "translateX(25px) !important",
  },
});

export default function SwitchGreen({ checked, handleChange }) {
  const classes = useStyles();
  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={checked}
      onChange={handleChange}
      name="checkedA"
      inputProps={{ "aria-label": "secondary checkbox" }}
    />
  );
}
