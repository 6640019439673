import React from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../../../assets/images/icons/sp1.svg";
import img2 from "../../../assets/images/icons/sp2.svg";
import img3 from "../../../assets/images/icons/sp3.svg";
import img4 from "../../../assets/images/icons/sp4.svg";
import img5 from "../../../assets/images/icons/sp5.svg";
import img6 from "../../../assets/images/icons/sp6.svg";
import FirstButton from "../../../common/button";

const ShowCards = () => {
  const navigate = useNavigate();
  const data = [
    {
      title: "Creative Services",
      desc: "Create a compelling customer experience with top crowdfunding video agencies and design firms to drive loyal backers to your campaign or business.",
      image: img1,
    },
    {
      title: "Legal  &Financial Services",
      desc: "Get your company up and running with expert assistance in areas such as contracting and small business finance.",
      image: img2,
    },
    {
      title: "Marketing",
      desc: "Expand your reach and build an audience with these agencies offering digital marketing services and more.",
      image: img3,
    },
    {
      title: "Packaging and  Fulfillment",
      desc: "Get help managing your supply chain and delivering to your backers.",
      image: img4,
    },
    {
      title: "Prototype and Manufacturing",
      desc: "Make your product development more efficient and cost-effective by using these resources for prototyping, procurement, 3D printing, and more.",
      image: img5,
    },
    {
      title: "Retail and Market growth",
      desc: "Plan your retail strategy and get your product out of the warehouse and into stores with these prominent brands and agencies.",
      image: img6,
    },
  ];

  return (
    <>
      <div className="showCards">
        <div id="landing_primary" className="main_text">
          <h1 className="primary_title">Expert directory</h1>
          <p className="primary_subTitle">
            All the Right Experts to Help Your Business
          </p>
        </div>

        <div className="showCards_container">
          <div className="cards">
            {data.map((item, index) => (
              <div
                style={{ position: "relative" }}
                key={index}
                className="card"
              >
                <img className="tile_img" alt="" src={item.image} />
                <h1 className="card_heading"> {item.title}</h1>
                <p
                  style={{ marginBottom: "5rem" }}
                  className="card_text card_height"
                >
                  {item.desc}
                </p>
                <div
                  style={{
                    position: "absolute",
                    bottom: "0px",
                  }}
                  className="card_btnmr"
                >
                  <FirstButton
                    buttonText="View Partners"
                    className="borderbtn"
                    onClick={() => navigate("/serviceproviders/category")}
                  />
                </div>
              </div>
            ))}
          </div>
          <FirstButton
            className="btn"
            marginTop="6rem"
            buttonText="View more Partners"
            onClick={() => navigate("/serviceproviders/category")}
          />
        </div>
      </div>
    </>
  );
};

export default ShowCards;
