import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const precapdata = [
  {
    id: "1",
    name: "Joe James",
    company: "The Al Irrigation  -Chameleon Card",
    city: "Whirl FLow",
    state: "India",
    date: "12/02/2022",
    type: "Phenotyping",
    status: "Approved",
  },
  {
    id: "2",
    name: "John Walsh",
    company: "Adjustable wheel",
    city: "Hartford",
    state: "India",
    date: "12/02/2022",
    type: "wheels",
    status: "Approved",
  },
  {
    id: "3",
    name: "Bob Herm",
    company: "Non-destructive grapevine yield estimation",
    city: "Tampa",
    state: "India",
    date: "12/02/2022",
    type: "Wine",
    status: "Process",
  },
  {
    id: "4",
    name: "James Houston",
    company: "Wirl flow - superior agitator for mixing tanks",
    city: "Dallas",
    state: "Europe",
    date: "12/02/2022",
    type: "Processing plants",
    status: "Rejected",
  },
];
export const investdata = [
  {
    id: "1",
    name: "Joe James",
    company: "Samai",
    state: "India",
    date: "12/02/2022",
    type: "Phenotyping",
    status: "Approved",
  },
  {
    id: "2",
    name: "John Walsh",
    company: "Eyeuity",
    state: "India",
    date: "12/02/2022",
    type: "wheels",
    status: "Approved",
  },
  {
    id: "3",
    name: "Bob Herm",
    company: "Hugg",
    state: "India",
    date: "12/02/2022",
    type: "Wine",
    status: "Process",
  },
  {
    id: "4",
    name: "Lamp Innovation",
    company: "Lamp Innovation",
    state: "Europe",
    date: "12/02/2022",
    type: "Processing plants",
    status: "Rejected",
  },
];
export const funddata = [
  {
    id: "1",
    name: "Joe James",
    company:
      "Seeking Methodologies To Validate Postbiotics Functional Benefits From Plant-Based Fermentations",
    city: "Whirl FLow",
    state: "India",
    date: "21-11-2022",
    type: "Research Need",
    status: "Approved",
  },
  {
    id: "2",
    name: "John Walsh",
    company: "Seeking Perfume Technologies For Packaging",
    city: "Hartford",
    state: "India",
    date: "30-11-2022",
    type: "Tech Need",
    status: "Approved",
  },
  {
    id: "3",
    name: "Bob Herm",
    company:
      "Method For Pharmaceutical - Origin Micropollutants Removal From Wastewater",
    city: "Tampa",
    state: "India",
    date: "31-01-2022",
    type: "Research Offer",
    status: "Process",
  },
  {
    id: "4",
    name: "Lamp Innovation",
    company: "Lamp Innovation",
    city: "Dallas",
    state: "Europe",
    date: "12/02/2022",
    type: "Processing plants",
    status: "Rejected",
  },
];

export const options = {
  filterType: "checkbox",
  responsive: "scrollMaxHeight",
};

export const precapcolumns = [
  {
    name: "id",
    label: "Slno",
    width: "2rem",
    options: {
      filter: true,
      sort: true,
      width: "2rem",
      customBodyRenderLite: (dataIndex) => {
        return (
          <span style={{ fontSize: "1.6rem" }}>
            {precapdata[dataIndex]?.id}
          </span>
        );
      },
    },
  },
  {
    name: "company",
    label: "Title",
    options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <span style={{ fontSize: "1.6rem" }}>
            {precapdata[dataIndex]?.company}
          </span>
        );
      },
    },
  },
  {
    name: "city",
    label: "Startup",
    options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <span style={{ fontSize: "1.6rem" }}>
            {precapdata[dataIndex]?.city}
          </span>
        );
      },
    },
  },
  {
    name: "type",
    label: "Type",
    options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <span style={{ fontSize: "1.6rem" }}>
            {precapdata[dataIndex]?.type}
          </span>
        );
      },
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <span style={{ fontSize: "1.6rem" }}>
            {precapdata[dataIndex]?.date}
          </span>
        );
      },
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <span
            className={
              precapdata[dataIndex]?.status === "Approved"
                ? "admin_statgreen"
                : precapdata[dataIndex]?.status === "Rejected"
                ? "admin_statred"
                : precapdata[dataIndex]?.status === "Process"
                ? "admin_statprocess"
                : ""
            }
          >
            {precapdata[dataIndex]?.status}
          </span>
        );
      },
    },
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <span
            style={{
              display: "flex",
            }}
          >
            <button
              className="deletebtn"
              style={{ border: "1px solid red !important" }}
            >
              <DeleteOutlineIcon sx={{ fontSize: "2rem", color: "red" }} />
            </button>{" "}
            <button className="editbtn admin_tablebtn">
              <EditIcon sx={{ fontSize: "2rem" }} />
            </button>
          </span>
        );
      },
    },
  },
];
