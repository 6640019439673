import React from "react";
import Img from "../../assets/images/img/sp1.png";
import Img1 from "../../assets/images/icons/sp1.svg";
import Img2 from "../../assets/images/icons/sp2.svg";
import Img3 from "../../assets/images/icons/sp3.svg";
import Img4 from "../../assets/images/icons/sp4.svg";
import Img5 from "../../assets/images/icons/sp5.svg";
import Img6 from "../../assets/images/icons/sp6.svg";
import FirstButton from "../../common/button";
import { useNavigate } from "react-router-dom";
const ServiceCatergory = () => {
  const navigate = useNavigate();
  return (
    <div className="pt_7">
      <div className="bg-black">
        <div className="dflex alcenter p13 gap2">
          <div>
            <h1 className="sp_h1 ">Experts Directory</h1>
            <p className="p_f4">
              Launch your campaign, expand your business, and get your product
              to market with this exclusive group of high-quality companies.
              These professionals have been vetted by smaarket and are highly
              recommended by campaigners and staff.
            </p>
          </div>
          <div className="w100">
            <img src={Img} alt="" className="w100" />
          </div>
        </div>
      </div>
      <div className="p1012">
        <h2 className="sp_f1">Category</h2>
        <div className="dflex gap5 jc_sb fwrap sp_mrt2">
          {catlist.map((item, index) => (
            <div
              key={index}
              className="sp_card"
              onClick={() => navigate("/serviceproviders/creativeservices")}
            >
              <div>
                {" "}
                <img src={item?.img} style={{ width: "25%", height: "8rem" }} />
              </div>
              <p className="sp_f2_mr2 ">{item?.title}</p>
              <p className="sp_f16_mr2">{item?.desc}</p>
              <div>
                <FirstButton buttonText="View Partners" className="borderbtn" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ptp_center mb2 ">
        <p className="sp_f16_mr1"> Want to become an Expert ?</p>
        <FirstButton buttonText="Apply now" className="btn" />
      </div>
    </div>
  );
};

export default ServiceCatergory;

const catlist = [
  {
    img: Img1,
    title: "Creative Services",
    desc: "Create a compelling customer experience with top crowdfunding video agencies and design firms to drive loyal backers to your campaign or business.",
  },
  {
    img: Img2,
    title: "Legal  &Financial Services",
    desc: "Get your company up and running with expert assistance in areas such as contracting and small business finance.",
  },
  {
    img: Img3,
    title: "Marketing",
    desc: "Expand your reach and build an audience with these agencies offering digital marketing services and more.",
  },
  {
    img: Img4,
    title: "Packaging and  Fulfillment",
    desc: "Get help managing your supply chain and delivering to your backers.",
  },
  {
    img: Img5,
    title: "Prototype and Manufacturing",
    desc: "Make your product development more efficient and cost-effective by using these resources for prototyping, procurement, 3D printing, and more.",
  },
  {
    img: Img6,
    title: "Retail and Market growth",
    desc: "Plan your retail strategy and get your product out of the warehouse and into stores with these prominent brands and agencies.",
  },
];
