import React from "react";
import Card from "../../../../../common/cards/Card";
import FirstButton from "../../../../../common/button";
import Img1 from "../../../../../assets/images/img/mtpoimg1.png";
import Img2 from "../../../../../assets/images/img/mtpoimg2.png";
import Img3 from "../../../../../assets/images/img/mtpoimg3.png";
import Img4 from "../../../../../assets/images/img/mtpoimg4.png";
import Img5 from "../../../../../assets/images/img/mtpoimg5.png";
import Img6 from "../../../../../assets/images/img/mtpoimg6.png";
const list = ["Transportation", "AI", "Automobiles", "B2C", "Motor", "Engine"];
const TechOffer = () => {
  return (
    <div className="cfp_wd1 wtr2">
      <h4 className="reoff_h4">Tech offers</h4>
      {/* <div className='reffcard_cont'>
        <div>
        <div className="reoffcard_main">
<div>
    <img src={CardImg} alt="" width="100%"/>
</div>

<p className='reoffcard_p1'>Title of th tech Offer in two Lines</p>
<p className="reoffcard_p2">Description in 4 lines of content. Lorem ipsum dolor sit amet, conser adipiscing elit dsgrg. dolor sit amet, conser adipiscing.</p>
<div className='reoffcard_cont2'>

{list.map((item,index)=>(
    <div className='reoffcard_cont6 reoffcard_p2'>
        {item}
    </div>
))}

</div>  

<div className='reoffcard_cont3'>
    <p className='reoffcard_p2'>Engagements opportunity</p>
    <p className='reoffcard_p2' style={{fontWeight:700,marginTop:"0.5rem"}}>Licensing, Partnership, Research </p>




</div>
        </div>
        <div className='reoff_cont8'>
    Number of interests :04
</div>
        </div>
        <div>
        <div className="reoffcard_main">
<div>
    <img src={CardImg} alt="" width="100%"/>
</div>

<p className='reoffcard_p1'>Title of th tech Offer in two Lines</p>
<p className="reoffcard_p2">Description in 4 lines of content. Lorem ipsum dolor sit amet, conser adipiscing elit dsgrg. dolor sit amet, conser adipiscing.</p>
<div className='reoffcard_cont2'>

{list.map((item,index)=>(
    <div className='reoffcard_cont6 reoffcard_p2'>
        {item}
    </div>
))}

</div>

<div className='reoffcard_cont3'>
    <p className='reoffcard_p2'>Engagements opportunity</p>
    <p className='reoffcard_p2' style={{fontWeight:700,marginTop:"0.5rem"}}>Licensing, Partnership, Research </p>




</div>


        </div>
        <div className='reoff_cont8'>
    Number of interests :04
</div>
        </div>
        
        </div>
        <div className='reoff_cont7'>
            <FirstButton buttonText="View more" className='btn'/>
        </div> */}
      <div className="mtpo_cardmain">
        {cardData.map((item, index) => (
          <div key={index} className="mtpo_mr w40 w4t100">
            <Card data={item} />
          </div>
        ))}
      </div>
      <div className="reoff_cont7">
        <p className="reoffcard_p2" style={{ margin: "2rem 0 0.5rem 0" }}>
          Keep Exploring !
        </p>
        <FirstButton buttonText="Explore MarketPlace" className="btn" />
      </div>
    </div>
  );
};

export default TechOffer;

const cardData = [
  {
    image: Img1,
    description:
      "A simple, visual sensor and digital platform that improves water and nutrient use by farmers.",
    title: "The Al Irrigation  -Chameleon Card",
    tags: ["Agriculture", "Food", "Digital"],
    engagements: "Licensing, Partnership, Investment  ",
    patientorigin: "India",
    patientownership: ["India", "Latin America"],
    trllevel: "Four",
    daysleft: "40",
    numberofinterests: "14",
  },
  {
    image: Img6,
    description:
      "Actively configurable wheel with incorporated suspension.This extends the ability of small vehicles to travel on rough terrain by providing an alternative suspension system in a small package.",
    title: "Adjustable wheel",
    tags: ["Space", "Defense", "Manufacturing"],
    engagements: "Licensing, Partnership, Investment ",
    patientorigin: "India",
    patientownership: ["India", "U.S.A"],
    trllevel: "Nine",
    daysleft: "60 ",
    numberofinterests: "06",
  },

  // {
  //     image:Img2,
  //     description:"A superior agitator for mixing tanks in mineral processing plants.",
  //     title:"Wirl flow - superior agitator for mixing tanks",
  //     tags:["Mining","Minerals","Environment"],
  //     engagements:"Licensing, Partnership, Joint Venture  ",
  //     patientorigin:"Europe",
  //     patientownership:["India","Europe"],
  //     trllevel:" Six",
  //     daysleft:"32 ",
  //     numberofinterests:"07"
  // },

  // {
  //     image:Img3,
  //     description:"Continuous assessment of plant water status with a static thermal imaging sensor.",
  //     title:"NProxiCrop - static thermal imaging sensor",
  //     tags:["Agriculture","Food","water"],
  //     engagements:"Tech transfer, Partnership, Investment ",
  //     patientorigin:"Australia",
  //     patientownership:["Australia"],
  //     trllevel:"Eight",
  //     daysleft:"32 ",
  //     numberofinterests:"07"
  // },
  {
    image: Img4,
    description:
      "henoMobile Lite technology is a mobile ground-based platform for the phenotyping of crops.",
    title: "Non destructive in-field crop phenotyping tech",
    tags: ["Agriculture", "Food", "Automobiles"],
    engagements: "Licensing, Jointventure, Investment  ",
    patientorigin: "Africa",
    patientownership: ["India", "Africa"],
    trllevel: "Seven",
    daysleft: "40",
    numberofinterests: "04",
  },
  {
    image: Img5,
    description:
      "Winegrape yield forecasting tool based on interpretation of video data using machine learning methods.",
    title: "Non-destructive grapevine yield estimation",
    tags: ["Agriculture", "Food", "IT"],
    engagements: "Licensing, Partnership, Field trail",
    patientorigin: "India",
    patientownership: ["India"],
    trllevel: "Four",
    daysleft: "50 ",
    numberofinterests: "14",
  },
];
