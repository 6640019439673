import React from "react";
import a1 from "../../../../../assets/images/img/a1.png";
import a2 from "../../../../../assets/images/img/a2.png";
import a3 from "../../../../../assets/images/img/a3.png";
import a4 from "../../../../../assets/images/img/a4.png";
import a5 from "../../../../../assets/images/img/a5.png";
import a6 from "../../../../../assets/images/img/a6.png";
import a7 from "../../../../../assets/images/img/a7.png";
import a8 from "../../../../../assets/images/img/a8.png";

import { Button } from "@material-ui/core";
import FirstButton from "../../../../../common/button";
import { useNavigate } from "react-router-dom";

const Aboutus = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="about">
        <div className="about_container">
          <h1 className="brands_primary">About Hugg</h1>
          <div className="comp_info_container">
            <div className="comp_info">
              <div>
                <p className="comp_info--title">Legal Name </p>
                <p className="comp_info--data">Hugg</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--title">No of employees </p>
                <p className="comp_info--data">08</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--title">Incorporation number</p>
                <p className="comp_info--data">U 12345 KL 2018 XYC 12456</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--title">DIPP number</p>
                <p className="comp_info--data">E5 515 561651</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--title">Head Quartered  Location</p>
                <p className="comp_info--data">Bengaluru ,KA</p>
              </div>

              <div style={{ marginTop: "4rem" }}>
                <p className="comp_info--title">Incorporation date</p>
                <p className="comp_info--data">30th February 2020</p>
              </div>
            </div>

            <div className="comp_info">
              <div>
                <p className="comp_info--title">Organization type</p>
                <p className="comp_info--data">Private Limited </p>
              </div>

              {/* <div style={{marginTop:"4rem"}}>
              <p className="comp_info--title">No of employees </p>
              <p className="comp_info--data">26</p>
            </div> */}
            </div>
          </div>

          <div className="teams">
            <h1 className="teams_primary">Our Team</h1>
            <div className="teams_container flex-wrap">
              {team.map((item, index) => (
                <div className="teamsRow--1 w48 wid100">
                  <div>
                    <img alt="" src={item.img} />
                  </div>
                  <div className="team_info">
                    <p className="comp_info--data">
                      {item.name}{" "}
                      <span style={{ color: "#B2B2B2" }}>
                        {item.designation}
                      </span>{" "}
                      <i className="fa-brands fa-linkedin-in comp_social_icon"></i>{" "}
                    </p>
                    <p style={{ margin: "1rem 0" }} className="comp_info--data">
                      DIN Number
                      <span style={{ color: "#B2B2B2" }}>{item.dinnumber}</span>
                    </p>
                    <p className="reoffcard_p2">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mentors">
            <h1 className="teams_primary">Mentors and Advisors </h1>
            <div className="mentors_list">
              {Mentor.map((item, index) => (
                <div className="mentor">
                  <img src={item.img} alt="" height="auto" />
                  <p className="comp_info--data">
                    {item.name}{" "}
                    <i className="fa-brands fa-linkedin-in comp_social_icon"></i>{" "}
                  </p>
                  <p className="comp_info--title">{item.role}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="flowchart">
        <div className="flow_container">
          <h1 className="flow_h1">Journey so far</h1>
          <div className="flow_main">
            <div className="flow_cont">
              <div className="flow_cont1"></div>
              <div className="flow">May 2022</div>
              <div className="flow_cont3">
                Finalized on materials and Technology
              </div>
            </div>

            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1">
                {" "}
                <span className="bottom">Finalized on design</span>
              </div>
              <div className="flow">June 2022</div>
              <div className="flow_cont3"></div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1"> </div>
              <div className="flow">June 2022</div>
              <div className="flow_cont3">
                Production and Testing First Prototype
              </div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1">
                <span className="bottom">
                  Testing our first prototype Production and Testing First
                  Prototype
                </span>
              </div>
              <div className="flow">August 2022</div>
              <div className="flow_cont3"></div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1"></div>
              <div className="flow">September 2022</div>
              <div className="flow_cont3">
                First sale of our product in Amazon.
              </div>
            </div>
          </div>
        </div>
        <div className="flow_container">
          <h1 className="flow_h1">Journey ahead</h1>
          <div className="flow_main">
            <div className="flow_cont">
              <div className="flow_cont1"></div>
              <div className="flow">October 2021</div>
              <div className="flow_cont3">Plastic injection tooling </div>
            </div>

            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1">
                {" "}
                <span className="bottom">November 2021</span>
              </div>
              <div className="flow">November 2021</div>
              <div className="flow_cont3"></div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1"> </div>
              <div className="flow">January 2022</div>
              <div className="flow_cont3">Green Certification </div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1">
                <span className="bottom">Production + QC </span>
              </div>
              <div className="flow">March 2022 </div>
              <div className="flow_cont3"></div>
            </div>
            <div className="flow_line"></div>
            <div className="flow_cont">
              <div className="flow_cont1"></div>
              <div className="flow">June 2022</div>
              <div className="flow_cont3">Shipment Tentitive</div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom_status">
        <div></div>
        <FirstButton
          buttonText="Get perks"
          className="btn"
          margin="0 4rem 0 0 "
          onClick={() => navigate("/investment/donatemainperk")}
        />
      </div>
    </>
  );
};

export default Aboutus;

const team = [
  {
    name: "Konica Gupta",
    designation: "Founder and CEO",
    dinnumber: "XXXXXXXX",
    description:
      "Former GM & VP of Operations at Panasonic Hollywood Labs, Jeannine has over 30 years experience in product development through high-volume manufacturing.",
    img: a1,
  },
  {
    name: "Danish Pormit",
    designation: "Supply & Distribution",
    dinnumber: "XXXXXXXX",
    description:
      "Over 20 years leading global supply chains as VP for Activision's Guitar Hero, Nike, Meta's Oculus/Portal, and COO for Farmgirl Flowers, Laura is an expert in program management with ten-year focus on sustainability.",
    img: a2,
  },
  {
    name: "Mohita padma",
    designation: "Product head",
    dinnumber: "XXXXXXXX",
    description:
      "Our first angel! Joan is a results-driven fundraiser for both non-profit and for-profit sectors. Former account manager at Sysco Foods and EcoLab. Experienced trade show and events project manager.",
    img: a3,
  },
  {
    name: "Bindesh Suri",
    designation: "Liaison & Fundraising",
    dinnumber: "XXXXXXXX",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum accumsan in ante vitae.",
    img: a4,
  },
];
const Mentor = [
  { name: "Shaun Peter", role: "Mentor", img: a5 },
  { name: "Stephen code", role: "Investor", img: a6 },
  { name: "Coman Fun", role: " Angel Investor", img: a7 },
  { name: "Manohar karnik", role: "Angel investor", img: a8 },
];
