import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import VerfiyImg from '../../assets/images/icons/verifyemail.svg'
import FirstButton from '../../common/button'

const Verifyemail = () => {
  const navigate=useNavigate()
  const handleNavigate=()=>{
    navigate("/signupready")
  }
  return (
    <div className='verify_main'>

        <img src={VerfiyImg} className="verfiyImg "alt="" />
        <p className='verify_heading'>We have just sent you a welcome email.</p>
        <p className='verfiy_p1'> Please check your inbox to verify your email address and complete your account setup.
    </p>
    <FirstButton buttonText="Go to my inbox" className="borderbtn" onClick={handleNavigate}/>
    <p className='everifylink' style={{margin:"2rem 0"}}>
    Email not received 
<Link to="/" className='everifylink' style={{color:"#19976A",fontWeight:"600"}}> Send it again </Link>
</p>
    
    </div>
  )
}

export default Verifyemail