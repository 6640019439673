import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import FirstButton from "../../../../../common/button";
import Img1 from "../../../../../assets/images/img/marketimg1.png";
import Img2 from "../../../../../assets/images/img/marketimg2.png";
import Img3 from "../../../../../assets/images/img/marketimg3.png";
import Img4 from "../../../../../assets/images/img/marketimg4.png";
import Img5 from "../../../../../assets/images/img/marketimg5.png";
import Img6 from "../../../../../assets/images/img/marketimg6.png";

const yourPost = [
  {
    title: {
      image: Img1,
      title:
        "Seeking Methodologies To Validate Postbiotics Functional Benefits From Plant-Based Fermentations",
    },
    type: "Research Need",
    date: "21-11-2022",
    validtill: "20-11-2022",
    status: "Active",
  },
  {
    title: { image: Img2, title: "Seeking Perfume Technologies For Packaging" },
    type: "Tech Need",
    date: "30-11-2022",
    validtill: "21-05-2023",
    status: "Active",
  },
  {
    title: {
      image: Img3,
      title:
        "Method For Pharmaceutical - Origin Micropollutants Removal From Wastewater",
    },
    type: "Research Offer",
    date: "01-01-2022",
    validtill: "31-01-2022",
    status: "InActive",
  },
];

const draftpost = [
  {
    title: {
      image: Img4,
      title: "Vertical axis wind turbine for slow and changing wind speed",
    },
    type: "Tech Need",
    date: "01-01-2022",
  },
  {
    title: {
      image: Img5,
      title: "Wheel slip reset controller for brake systems",
    },
    type: "Research Need",
    date: "28-04-2022",
  },
  {
    title: {
      image: Img6,
      title:
        "Medical device for the non-invasive treatment of tumors using shear waves and LIFU ultrasounds",
    },
    type: "Tech Need",
    date: "14-11-2022",
  },
];

const Posts = () => {
  return (
    <>
      <div>
        <h4 className="market_p">Your Posts</h4>

        <div>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="markettable_head" width="30%">
                    Title
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Type
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    PostData
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    ValidTill
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Status
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Report
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {yourPost.map((row, index) => (
                  <TableRow
                    key={index}
                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <div className="posttablecell1">
                        <img
                          src={row.title && row.title.image}
                          alt=""
                          width="44rem"
                          height="44rem"
                        />
                        <p className="markettable_font">
                          {row.title && row.title.title}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.type}
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.date}
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.validtill}
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.status}
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      <FirstButton
                        buttonText="View "
                        className="borderbtn"
                        padding="12rem 24rem"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="postbtncont" style={{ textAlign: "center" }}>
          <FirstButton className="borderbtn" buttonText="View more" />
        </div>
      </div>
      <div>
        <h4 className="market_p">Draft Posts</h4>

        <div>
          <TableContainer className="markettable_font">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="markettable_head" width="30%">
                    Title
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Last Updated
                  </TableCell>
                  <TableCell align="left" className="markettable_head">
                    Type
                  </TableCell>
                  {/* <TableCell align="left" className="markettable_head">Status</TableCell> */}
                  <TableCell
                    align="left"
                    className="markettable_head"
                  ></TableCell>
                  <TableCell
                    align="left"
                    className="markettable_head"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {draftpost.map((row, index) => (
                  <TableRow
                    key={index}
                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <div className="posttablecell1">
                        <img
                          src={row.title && row.title.image}
                          alt=""
                          width="44rem"
                          height="44rem"
                        />
                        <p className="markettable_font">
                          {row.title && row.title.title}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.date}
                    </TableCell>
                    <TableCell align="left" className="markettable_font">
                      {row.type}
                    </TableCell>
                    {/* <TableCell align="left" className="markettable_font">{row.status}</TableCell> */}
                    <TableCell
                      align="left"
                      className="markettable_font"
                      style={{ fontWeight: "600" }}
                    >
                      {" "}
                      <a className="pricolor" href="">
                        Edit
                      </a>{" "}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="markettable_font"
                      style={{ fontWeight: "600" }}
                    >
                      {" "}
                      <a className="pricolor" href="">
                        Delete
                      </a>{" "}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="postbtncont" style={{ textAlign: "center" }}>
          <FirstButton className="borderbtn" buttonText="View more" />
        </div>
      </div>
    </>
  );
};

export default Posts;
