import React from 'react';
import img01 from '../../../assets/images/n1.png'; 
import img02 from '../../../assets/images/n2.png'; 
import img03 from '../../../assets/images/n3.png'; 
const data  = [
  {
   tilte:"Our Investor Engagement Platform is Live",
   date: "May 01 2022",
   desc: "We are happy to announce that our investor engagement platform is live now! We will be able to share our company updates and engage with you and receive valuable feedback on different aspects. We are very excited to have you onboard.",
   img: img01
  },
  {
   tilte:"Thank You Investors For ₹ 7,00,000,00 Raised",
   date: "February 29 2022",
   desc: "Join us today to build the world's first all-electric long-range luxury aircraft!",
   img: img02
  },
  {
   tilte:"100% funded",
   date: "May 01 2022",
   desc: "We are happy to announce that our investor engagement platform is live now! We will be able to share our company updates and engage with you and receive valuable feedback on different aspects. We are very excited to have you onboard.",
   img: img03
  },
]
const UpdateAndNews = ()=>{
  return(
    <>
     <div className='updateAndNews'>
      <div className='updateAndNews_container'>
        <h1 className='brands_primary'>Updates & News</h1> 
        <div className='updates_container'>
          {
            data && data.map((item,index)=>(
              <div className='updates'>
                <div className='update_thumb'>
                  <img className='update_img' alt='' src={item.img}/>
                </div>
                <div className='update_content'>
                  <h1 style={{color:"#070908"}} className='tile_primary'>{item.tilte}</h1>
                  <p style={{color:"#070908"}} className='date'>{item.date}</p>
                  <p style={{color:"#070908"}} className='tile_secondary'>{item.desc}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
     </div>
    </>
  )
}
 
export default UpdateAndNews