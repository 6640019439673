import React from "react";
import Sidebar from "../SidebarforPrecamp/SidebarPreCamp";
import FirstButton from "../../../../common/button";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router";

const ProjectDetails = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/precampaigncreation/externallinks");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <div>
            <h4 className="market_h4"> Project Details</h4>
            <div className="font_p1 ">
              <p>Tell a story about why you're running a campaign.</p>
            </div>
            <div className="cfp_wd">
              <div>
                <h4 className="cfp_h4 cfp_mr1">Mission </h4>

                <textarea
                  placeholder="Type here"
                  className="precampfaq_textarea"
                ></textarea>

                <div></div>
                <div>
                  <p className="font_p2">Upload a  relevant image</p>
                  <TextField
                    // id="outlined-basic"

                    type="file"
                    inputProps={{
                      multiple: true,
                    }}
                    style={{
                      marginTop: "1rem",
                      background: "#F5F5F5",
                      border: "none !imporatant",
                      outline: "none !important",
                      width: "97.5%",
                    }}
                  />
                </div>
              </div>
              <div>
                <p className="cfp_h4 cfp_mr1">Product or Service features</p>
                <textarea
                  placeholder="Type here"
                  className="precampfaq_textarea"
                ></textarea>
                <p className="font_p2">Upload a  relevant image</p>
                <TextField
                  id="outlined-basic"
                  type="file"
                  inputProps={{
                    multiple: true,
                  }}
                  style={{
                    marginTop: "1rem",
                    background: "#F5F5F5",
                    border: "none !imporatant",
                    outline: "none !important",
                    width: "97.5%",
                  }}
                />
              </div>
              <div>
                <p className="cfp_h4 cfp_mr1">Business Highlights</p>
                <p className="font_p2">
                  Present the company at a glance and highlight key company
                  facts such as traction,
                  <br /> accomplishments, profits, clients, partners, products,
                  and sales.
                </p>
                <textarea
                  placeholder="Type here"
                  className="precampfaq_textarea"
                ></textarea>
                <p className="font_p2">Upload a  relevant image</p>
                <TextField
                  id="outlined-basic"
                  type="file"
                  inputProps={{
                    multiple: true,
                  }}
                  style={{
                    marginTop: "1rem",
                    background: "#F5F5F5",
                    border: "none !imporatant",
                    outline: "none !important",
                    width: "100%",
                  }}
                />
              </div>

              <div>
                <p className="cfp_h4 cfp_mr1">Product or Service Video</p>
                <input
                  type="text"
                  className="login_input"
                  placeholder="Type Here"
                />
              </div>

              <div>
                <p className="cfp_h4 cfp_mr1">Product or Service Images</p>
                {/* <input type="text" className='login_input' /> */}
                <TextField
                  id="outlined-basic"
                  type="file"
                  inputProps={{
                    multiple: true,
                  }}
                  style={{
                    marginTop: "1rem",
                    background: "#F5F5F5",
                    border: "none !imporatant",
                    outline: "none !important",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/precampaigncreation/basic")}
        />
        <FirstButton
          buttonText="Save and next"
          onClick={handleClick}
          className="btn"
        />
      </div>
    </div>
  );
};

export default ProjectDetails;
