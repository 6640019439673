import React from "react";
import SearchDetailsMain from "./SearchDetailsMain";
import UpdateAndNews from "./UpdateAndNews";
import DetailTabs from "./DetailTabs";
const InvestDetails = ()=>{
  return(
   <>
    <SearchDetailsMain/>
    <UpdateAndNews/>
    <DetailTabs/>

   </>
  )
}

export default InvestDetails