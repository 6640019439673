import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FirstButton from "../../../../../common/button";
import SwitchGreen from "../../../../../common/Switch";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

const Payment = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div>
      <div className="ptp_cont">
        <div className="ptp_cont1">
          <div>
            <h2 className="ptp_h2">Payment</h2>
            <div>
              <h3 className="ptp_h3"> Items Choose</h3>
              {items.map((itm, index) => (
                <div key={index} className="paym_mr">
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "green",
                          "&.Mui-checked": { color: "green" },
                          "& .MuiSvgIcon-root": { fontSize: 24 },
                        }}
                      />
                    }
                    labelPlacement="end"
                    label={
                      <span
                        style={{
                          fontSize: "1.6rem",
                          marginLeft: "1rem",
                          fontWeight: "400",
                        }}
                      >
                        {itm.label}
                      </span>
                    }
                  />
                </div>
              ))}
            </div>
            <div className="ptp_mr">
              <h3 className="ptp_h3">Yenilik Foundation Fund</h3>
              <p className="ptp_p2">
                By helping Yenilik foundation you're helping us to promote the
                startup ecosystem in India and boosting the nation’s growth.
              </p>
              <div className="mdp_cont2">
                <p className="mcdt_p2">Donate to ecosystem cause </p>
                <div className="mcdt_p2">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          sx={{
                            color: "#19976A",
                            "&.Mui-checked": { color: "#19976A" },
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                          }}
                        />
                      }
                      label={<span style={{ fontSize: "1.6rem" }}>Yes</span>}
                    />{" "}
                    <FormControlLabel
                      sx={{ fontSize: 16 }}
                      value="no"
                      control={
                        <Radio
                          sx={{
                            color: "#19976A",
                            "&.Mui-checked": { color: "#19976A" },
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                          }}
                        />
                      }
                      label={<span style={{ fontSize: "1.6rem" }}>No</span>}
                    />
                  </RadioGroup>
                </div>
              </div>
              <div>
                <p className="ptp_p2">Contribution Amount </p>
                <input className="login_input" placeholder="Enter" />
                <p className="ptp_p1 ptp_center">Ten thousand Rupees</p>
              </div>
            </div>
          </div>
          <div className="ptp_cont2">
            <p className="ptp_p2 ptp_color">Crowdfunding is not shopping</p>
            <p className="ptp_p2 ptp_color">Refund Details</p>
            <p className="ptp_p2">
              Smaarket does not guarantee that the perks offered by the
              campaigner will be produced or delivered and is not directly
              responsible for refunding contributions
            </p>
          </div>
        </div>
        <div className="ptp_cont3">
          <div className="ptp_mr1">
            <h2 className="ptp_h3">Summary</h2>
            <div>
              <div className="ptp_cont4">
                <p className="ptp_p2 ptp_fw">Particulars</p>
                <p className="ptp_p2 ptp_fw">Amount</p>
              </div>
              {summylist.map((item, index) => (
                <div className="ptp_cont4">
                  <p className="ptp_p2">{item.particular}</p>
                  <p className="ptp_p2">{item.amount}</p>
                </div>
              ))}
              <div className="ptp_cont4 ptp_br">
                <p className="ptp_p2 ptp_fw">Total</p>
                <p className="ptp_p2 ptp_fw">₹ 2,50,000</p>
              </div>
            </div>
          </div>
          <div className="ptp_mr1">
            <h2 className="ptp_h3">Legal Stuff</h2>
            {List.map((item, index) => (
              <FormControlLabel
                label={<p className="risk_label">{item.label}</p>}
                control={<SwitchGreen sx={{ m: 1 }} />}
                key={index}
                onChange={handleChange}
                sx={{ m: 1 }}
                style={{ alignItems: "start" }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;

const List = [
  {
    label:
      "I’ve read the Donation FAQ. I understand startups are risky and can afford to lose my entire donation.",
  },
  {
    label:
      "I understand Smaarket does not offer Donation advice. I am making my own Donation decisions.",
  },
  {
    label:
      "I agree to the contracts with my electronic signature and authorizeSmaarket to debit my account.",
  },
];

const summylist = [
  { particular: "Donation without perks", amount: "₹ 1,00,000" },
  { particular: "Donation without perks", amount: "₹ 1,25,000" },
  { particular: "Charges", amount: "₹ 2,000" },
  { particular: "Convenience Fee", amount: "₹ 3,000" },
  { particular: "GST (18%)", amount: "₹ 3,000" },
  { particular: "Yenilik Foundation Fund", amount: "₹ 3,000" },
];

const items = [
  { label: "Donation with perks  (₹ 1,00,000)" },
  { label: "Couple of huggs (₹ 25,000)" },
  { label: "8 X Hugg's  (₹ 1,00,000)" },
];
