import React from 'react'
import img1 from "../../../../assets/images/img/pb1.svg"
import img2 from "../../../../assets/images/img/pb2.svg"
import img3 from "../../../../assets/images/img/pb3.svg"

const PremiumBenifits = () => {
  return (
    <div className='pb_padding'>
        <div className='pb_maincont'>
            <h1 className='faq_primary 'style={{color:"black"}}>How do you BENEFIT </h1>
            <div className='pb_cont'>
    {card.map((item,index)=>(
        <div key={index} className="pb_card">
            <img src={item.img} className="w100"/>
            <p className='pb_f1'>{item.title}</p>
            <p className='pb_f2'>{item.desc}</p>
        </div>
    ))}
            </div>
        </div>
    </div>
  )
}

export default PremiumBenifits

const card=[
    {img:img1,title:"Verified and filtered leads",desc:"We clearly streamline and improve efforts."},
    {img:img2,title:"Dedicated relationship manager",desc:"Your relationship manager acts as a single point of contact across your requirment."},
    {img:img3,title:"Social Media and Email Promotion",desc:"Our specialists understand the challenges you face and provide you with tailor-made solutions."},
]