import React from "react";
import FirstButton from "../../../../../common/button";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@mui/material";
import doc from "../../../../../assets/images/oicons/svg/document.svg"
import policy from "../../../../../assets/images/oicons/svg/policy.svg"

const CompFinancial = ()=>{ 
    const data = [
    {
     number: 1,
     text:"Our future success depends on completing DCGA certification and some times it takes more time than expected. "
    },
    {
     number: 2,
     text:"Our future success depends on the efforts of a small management team. The loss of services of the members of the management team may have an adverse effect on the company. There can be no assurance that we will be successful in attracting and retaining other personnel we require to successfully grow our business."
    },
    {
     number: 3,
     text:"The transferability of the Securities you are buying is limited Any stock purchased through this crowdfunding campaign is subject to SEC limitations on transfer. This means that the stock/note that you purchase cannot be resold for a period of one year.  (Furthermore, you should be prepared to hold this investment for several years or longer. -- see the following Risk Factor.) The exception to this rule is if you are transferring the stock back to the Company, to an 'accredited investor,' as part of an offering registered with the Commission, to a member of your family, trust created for the benefit of your family, or in connection with your death or divorce."
    }
  ] 

  const tableData1 = [
    {
      date: "20-08-2020",
      type:"Equity",
      investor:"Prasanth Koman",
      amount:"₹ 12,00,000"
    },
    {
      date: "20-04-2021",
      type:"Equity",
      investor:"Dushanth",
      amount:"₹ 10,00,000"
    },
    {
      date: "20-04- 2021",
      type:"Equity",
      investor:"vuble angles",
      amount:"₹ 25,00,000"
    },
    {
      date: "20-04-2021",
      type:"Equity",
      investor:"GuruX",
      amount:"₹ 18,00,000"
    },
    {
      date: "20-04-2022",
      type:"Equity",
      investor:"Time venture",
      amount:"₹ 45,00,000"
    },
  ]
  const tableData2 = [
    {
      date: "21-08-2020",
      type:"Loan",
      investor:"Amar ",
      amount:"₹ 5,00,000"
    },
    {
      date: "20-04-2021",
      type:"Equity",
      investor:"Kuber ",
      amount:"₹ 18,00,000"
    },
    {
      date: "20-04-2021",
      type:"Equity",
      investor:"Laxmi ",
      amount:"₹ 15,00,000"
    },
    {
      date: "20-04-2021",
      type:"Equity",
      investor:"Venkat",
      amount:"₹ 7,00,000"
    },
    {
      date: "20-04-2022",
      type:"Equity",
      investor:"Balaji",
      amount:"₹ 8,00,000"
    },
  ]
  return(
    <>
    <div className="comp_financials">
      <div className="comp_financials_container">
        <h1 className="brands_primary">At a Glance</h1>
         <div className="comp_info_container">
           <div className="comp_info">
            <div>
              <p className="comp_info--data center">₹ 20,000 </p>
              <p className="comp_info--title center">Revenue</p>
            </div>

            <div style={{marginTop:"4rem"}}>
              <p className="comp_info--data center">N/A</p>
              <p className="comp_info--title center">Net Margin </p>
            </div>
           </div>

           <div className="comp_info">
            <div>
              <p className="comp_info--data center">₹ 20,000</p>
              <p className="comp_info--title center">Net Loss</p>
            </div>

            <div style={{marginTop:"4rem"}}>
              <p className="comp_info--data center">N/A</p>
              <p className="comp_info--title center">Net Margin</p>
            </div>
           </div>


           <div className="comp_info">
            <div>
              <p className="comp_info--data center" >₹ 20,000</p>
              <p className="comp_info--title center">Previous valuation</p>
            </div>

            <div style={{marginTop:"4rem"}}>
              <p className="comp_info--data center">-441%</p>
              <p className="comp_info--title center">Return on Assets</p>
            </div>
           </div>


           <div className="comp_info">
            <div>
              <p className="comp_info--data center">₹ 20,000 </p>
              <p className="comp_info--title center">Cash in Hand</p>
            </div>

            <div style={{marginTop:"4rem"}}>
              <p className="comp_info--data center"> ₹ 0 </p>
              <p className="comp_info--title center">Revenue per Employee </p>
            </div>
           </div>


           <div className="comp_info">
            <div>
              <p className="comp_info--data center">-₹ 8.56 </p>
              <p className="comp_info--title center">Earnings per Share</p>
            </div>

            <div style={{marginTop:"4rem"}}>
              <p className="comp_info--data center">55% </p>
              <p className="comp_info--title center">Cash to Assets</p>
            </div>
           </div>


           <div className="comp_info">
            <div>
              <p className="comp_info--data center">NA </p>
              <p className="comp_info--title center">Revenue to Receivables</p>
            </div>

            <div style={{marginTop:"4rem"}}>
              <p className="comp_info--data center">0% </p>
              <p className="comp_info--title center">Debt Ratio </p>
            </div>
           </div>


         </div>   
          


          <h1  style={{marginTop:"10rem", marginBottom: '4rem'}} className="brands_primary">Documents</h1>
          <div className="documents">
              <div className="document--1" >
                 <div className="document_detail" style={{justifyContent:"space-between"}}>
                  <h1 className="document_name">Due Diligence documents </h1>
                  <img src={doc} width="20%" alt="document"/>
                 </div>
                 <FirstButton marginTop={"2rem"} buttonText="Download" className="greenBorder"/>
              </div>
              <div className="document--2">
                 <div className="document_detail" style={{justifyContent:"space-between"}}>
                  <h1 className="document_name">Sample agreement </h1>
                  <img src={policy} width="20%" alt="document"/>
                 </div>
                 <FirstButton marginTop={"2rem"} buttonText="Download" className="greenBorder"/>
              </div>
          </div> 


          <h1  style={{marginTop:"10rem", marginBottom: '4rem'}} className="brands_primary">Use of Funds</h1>
          <div className="useOfFunds">
            <div className="fund_amount">₹ 7,00,000</div>
            <div className="fund_use">40% salaries, 42.5% real estate and Assets (Machines and Equipment), 2% admin expenses and 8% marketing & advertising. Smaarket fee 7.5%</div>
          </div>

          <div className="useOfFunds">
            <div className="fund_amount">₹ 15,00,000</div>
            <div className="fund_use">40% salaries, 42.5% real estate and Assets (Machines and Equipment), 2% admin expenses and 8% marketing & advertising. Smaarket fee 7.5%</div>
          </div>

          <div style={{borderBottom:"none"}} className="useOfFunds">
            <div className="fund_amount">₹ 28,00,000</div>
            <div className="fund_use">40% salaries, 42.5% real estate and Assets (Machines and Equipment), 2% admin expenses and 8% marketing & advertising. Smaarket fee 7.5%</div>
          </div> 
         <div style={{display:"flex", alignItems: 'center', justifyContent: "center"}}>
          <FirstButton marginTop={"2rem"} buttonText="View more" className="greenBorder"/>
        </div>
                         
        <h1  style={{marginTop:"10rem", marginBottom: '4rem'}} className="brands_primary">Past fund raises</h1>
          <div style={{backgroundColor: "#fff"}} className='mdsp_tablecont'>
                <table style={{backgroundColor: "#fff"}} className="mdsp_table">
                    <thead className='mdsp_tablehead'>
                        <tr className='mdsp_tableheadtr'>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Lead investor or Funder</th>
                            <th>amount</th>
                        </tr>
                    </thead>
                    <tbody className="mdsp_body">
                    {tableData1.map((item, key) => {
          return (
            <tr key={key} className="mdsp_tableheadtr" >
              <td>{item.date}</td>
              <td>{item.type}</td>
              <td>{item.investor}</td>
              <td>{item.amount}</td>
            </tr>
          )
        })}

                    </tbody>
                </table>
                </div> 
        <div style={{display:"flex", alignItems: 'center', justifyContent: "center"}}>
          <FirstButton marginTop={"2rem"} buttonText="View more" className="greenBorder"/>
        </div>

        <h1  style={{marginTop:"10rem", marginBottom: '4rem'}} className="brands_primary">Financial Liabilities</h1>
          <div style={{backgroundColor: "#fff"}} className='mdsp_tablecont'>
                <table style={{backgroundColor: "#fff"}} className="mdsp_table">
                    <thead className='mdsp_tablehead'>
                        <tr className='mdsp_tableheadtr'>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Lead investor or Funder</th>
                            <th>amount</th>
                        </tr>
                    </thead>
                    <tbody className="mdsp_body">
                    {tableData2.map((item, key) => {
          return (
            <tr key={key} className="mdsp_tableheadtr" >
              <td>{item.date}</td>
              <td>{item.type}</td>
              <td>{item.investor}</td>
              <td>{item.amount}</td>
            </tr>
          )
        })}

                    </tbody>
                </table>
                </div>
          <div style={{display:"flex", alignItems: 'center', justifyContent: "center"}}>
          <FirstButton marginTop={"2rem"} buttonText="View more" className="greenBorder"/>
        </div>




            <h1  style={{marginTop:"10rem", marginBottom: '4rem'}} className="brands_primary">Risks Involved</h1>
            {
            data.map((item,index)=>(
            <div key={index} style={{color:"#070908", display:"flex", alignItems:"center"}} className="tile_secondary heighlights_text">
              <span className="heighlight_point">{item.number}</span>
               <p>{item.text}</p> 
            </div>
            ))
          }
         <div style={{display:"flex", alignItems: 'center', justifyContent: "center"}}>
          <FirstButton marginTop={"2rem"} buttonText="Read More Risks" className="greenBorder"/>
        </div>
      </div>
      <div className="bottom_status">
       <div></div>
       <Button id="closed" className="btn" variant="contained">Investment Closed</Button>
    </div>
    </div>
    </>
  )
}
 
export default CompFinancial