import React from "react";
import IMG01 from "../../../../assets/images/img/sami.png";
import ProgressBar from "../../../../common/progressBar/Progress";
import FirstButton from "../../../../common/button.jsx";
import share from "../../../../assets/images/icons/share.png";
const data = {
  amount: "₹ 76,00,000 Raised Successfully",
  percentage: 44.56,
  status: "First goal Hit..!! (You can still invest)",
  duration: "38 Days Left",
  color: "#A29501",
  bgcolor: "#FCFCFC",
  value: "40",
};
const SearchDetailsMain = () => {
  return (
    <>
      <div className="searchMain p-7 ">
        <div className="searchMain_container">
          <div className="searchMain_container--1">
            <img style={{ width: "100%" }} alt="" src={IMG01} />
          </div>
          <div className="searchMain_container--2">
            <div className="scp_cont1">
              <div>
                <h1 className="scp_h1">Samai</h1>
              </div>
              <div className="scp_cont2">
                <button className="share_btn">
                  <img src={share} />
                </button>
                <FirstButton
                  buttonText="Add to watchlist"
                  className="borderbtn"
                />
              </div>
            </div>
            <div className="comp_details">
              <p className="comp_link">Samai.com</p>
              <div className="comp_social">
                <i className="fa-brands fa-twitter comp_social_icon"></i>
                <i className="fa-brands fa-linkedin-in comp_social_icon"></i>
                <i className="fa-brands fa-google comp_social_icon"></i>
                <i className="fa-brands fa-facebook-f comp_social_icon"></i>
              </div>
            </div>
            <div className="tags">
              <div style={{ backgroundColor: "#F5F5F5" }} className="tag">
                <p className="tag_label">Sustainability</p>
              </div>
              <div style={{ backgroundColor: "#F5F5F5" }} className="tag">
                <p className="tag_label">Sustainability</p>
              </div>
              <div style={{ backgroundColor: "#F5F5F5" }} className="tag">
                <p className="tag_label">CCD</p>
              </div>
            </div>
            <p style={{ color: "#070908" }} className="secondaryText sam_padding">
              Our mission is to accelerate the upcycled food and sustainable
              manufacturing movements by repurposing spent grains into a
              zero-waste, nutritious, single-use cup alternative. Our vision is
              to revolutionize the way people think about using disposable
              containers.
            </p>
            <div style={{ margin: "2rem 0" }}>
              <ProgressBar data={data} type="samai" />
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#070908" }} className="counts">
        <div className="counts_container">
          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              CCD
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Type of Instrument
            </p>
          </div>
          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              20%
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Discount
            </p>
          </div>
          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              30th Feb 2023
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              End Date
            </p>
          </div>
          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              ₹ 10,000
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Min Investment
            </p>
          </div>
          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              ₹ 1,25,50,000
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Target
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchDetailsMain;
