import React from "react";
import d1 from "../../../../assets/images/img/d1.png";
import d2 from "../../../../assets/images/img/d2.png";
import d3 from "../../../../assets/images/img/d3.png";
import FirstButton from "../../../../common/button";

const Details = () => {
  return (
    <div className="scpdetail_main">
      <div className="scpdetail_cont">
        <div className="w50">
          <h1 className="scpdetail_h1">Our Mission</h1>
          <p className="scpdetail_p">
            Our mission is to alleviate some of humanity’s greatest healthcare
            challenges by using the eye as a window on the health of the body.,
          </p>
          <p className="scpdetail_p">
            Since becoming the largest MedTech raise ever on Seedrs in our last
            round, we have advanced our technology, filed new patents and
            generated significant commercial interest. In addition we have also
            developed an exciting new solution for the multi-billion-dollar
            myopia monitoring market - the AX1 – and are now in negotiations for
            substantial orders with several global lens and spectacle
            manufacturers.
          </p>
        </div>
        <div className="w50">
          <img src={d1} alt="" className="w100" />
        </div>
      </div>
      <div className="scpdetail_cont">
        <div className="w50">
          <img src={d2} alt="" className="w100" />
        </div>
        <div className="scpdetail_cont4 w50">
          <h1 className="scpdetail_h1">About our product</h1>
          <p className="scpdetail_p">
            Revolutionary multi-patented optical handheld technology will
            deliver safe, instant and contact-free disease screening &
            monitoring.
          </p>
          <p className="scpdetail_p">
            Commercial negotiations with leading lens and spectacle
            manufacturers and European distributor network being established.
          </p>
        </div>
      </div>
      <div className="scpdetail_cont">
        <div className="w50">
          <h1 className="scpdetail_h1">Business Highlights</h1>
          <p className="scpdetail_p">
            Addressing combined global markets worth over $39Bn/year. Including
            Ophthalmology - $5Bn, Diabetes Screening - $20Bn & Diabetes
            Monitoring - $14Bn.
          </p>
        </div>
        <div className="w50">
          <img src={d3} alt="" className="w100" />
        </div>
      </div>

      <div className="scpdetail_cont2">
        <h1 className="scpdetails_detailh1">Company FAQ</h1>
        <div className="scpdetail_cont1">
          <div>
            <p className="scpdetail_p2">What are your product features ?</p>
            <p className="scpdetail_p3">
              We’re first of its kind platform in India that provide one stop
              solution to all needs .
            </p>
          </div>
          <div>
            <p className="scpdetail_p2">What are your product features ?</p>
            <p className="scpdetail_p3">
              We’re first of its kind platform in India that provide one stop
              solution to all needs .
            </p>
          </div>
        </div>
        <div className="scpdetail_cont1">
          <div c>
            <p className="scpdetail_p2">What are your product features ?</p>
            <p className="scpdetail_p3">
              We’re first of its kind platform in India that provide one stop
              solution to all need of a startup and innovation ecosystem. Our
              mission is to boost in
            </p>
          </div>
          <div>
            <p className="scpdetail_p2">What are your product features ?</p>
            <p className="scpdetail_p3">
              We’re first of its kind platform in India that provide one stop
              solution to all need of a startup and innovation ecosystem. Our
              mission is to boost in
            </p>
          </div>
        </div>
        <FirstButton
          buttonText="Read More"
          className="borderbtn"
          marginTop="2rem"
        />
      </div>
    </div>
  );
};

export default Details;
