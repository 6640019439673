import React from "react";
import Card from "../../../../../common/cards/Card";
import Img1 from "../../../../../assets/images/img/mtpoimg1.png";
import Img2 from "../../../../../assets/images/img/mtpoimg2.png";
import Img3 from "../../../../../assets/images/img/mtpoimg3.png";
import Img4 from "../../../../../assets/images/img/mtpoimg4.png";
import Img5 from "../../../../../assets/images/img/mtpoimg5.png";
import Img6 from "../../../../../assets/images/img/mtpoimg6.png";
import FirstButton from "../../../../../common/button";
import DropDown from "../../../../../common/dropdown";
import { useNavigate } from "react-router-dom";
const list = ["AI & Machine Learning", "₹ 1 CR+ Revenue", "Clear all"];
const TechonologyOffer = () => {
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    if (id === 6) {
      navigate("/marketplace/techoffercampaign");
    }
  };
  return (
    <div>
      <div className="mtpo_cont1">
        <div style={{ width: "100%" }}>
          <input
            className="login_input"
            placeholder=" Start typing for Search"
          />
        </div>
        <div style={{ width: "100%" }}>
          <DropDown placeholder="Sector" />
        </div>
        <div style={{ width: "100%" }}>
          <DropDown placeholder="Sort by" />
        </div>
      </div>
      <div className="mtpo_cont2">
        {list.map((item, index) => (
          <div className="mtpo_p" key={index}>
            <p>{item} </p>
            <p>
              {" "}
              <i className="fa fa-close"></i>
            </p>
          </div>
        ))}
      </div>
      <div className="mtpo_cardmain">
        {cardData.map((item, index) => (
          <div
            key={index}
            className="mtpo_mr wd30"
            onClick={() => handleNavigate(item.id)}
          >
            <Card data={item} />
          </div>
        ))}
      </div>

      <div className="reoff_cont7">
        <FirstButton buttonText="View More" className="borderbtn" />
      </div>
    </div>
  );
};

export default TechonologyOffer;

const cardData = [
  {
    image: Img1,
    description:
      "A simple, visual sensor and digital platform that improves water and nutrient use by farmers.",
    title: "The Al Irrigation  -Chameleon Card",
    tags: ["Agriculture", "Food", "Digital"],
    engagements: "Licensing, Partnership, Investment  ",
    patientorigin: "India",
    patientownership: ["India", "Latin America"],
    trllevel: "Four",
    daysleft: "40",
    numberofinterests: "14",
  },
  {
    image: Img2,
    description:
      "A superior agitator for mixing tanks in mineral processing plants.",
    title: "Wirl flow - superior agitator for mixing tanks",
    tags: ["Mining", "Minerals", "Environment"],
    engagements: "Licensing, Partnership, Joint Venture  ",
    patientorigin: "Europe",
    patientownership: ["India", "Europe"],
    trllevel: " Six",
    daysleft: "32 ",
    numberofinterests: "07",
  },

  {
    image: Img3,
    description:
      "Continuous assessment of plant water status with a static thermal imaging sensor.",
    title: "ProxiCrop - static thermal imaging sensor",
    tags: ["Agriculture", "Food", "water"],
    engagements: "Tech transfer, Partnership, Investment ",
    patientorigin: "Australia",
    patientownership: ["Australia"],
    trllevel: "Eight",
    daysleft: "32 ",
    numberofinterests: "07",
  },
  {
    image: Img4,
    description:
      "henoMobile Lite technology is a mobile ground-based platform for the phenotyping of crops.",
    title: "Non destructive in-field crop phenotyping tech",
    tags: ["Agriculture", "Food", "Automobiles"],
    engagements: "Licensing, Jointventure, Investment  ",
    patientorigin: "Africa",
    patientownership: ["India", "Africa"],
    trllevel: "Seven",
    daysleft: "40",
    numberofinterests: "04",
  },
  {
    image: Img5,
    description:
      "Winegrape yield forecasting tool based on interpretation of video data using machine learning methods.",
    title: "Non-destructive grapevine yield estimation",
    tags: ["Agriculture", "Food", "IT"],
    engagements: "Licensing, Partnership, Field trail",
    patientorigin: "India",
    patientownership: ["India"],
    trllevel: "Four",
    daysleft: "50 ",
    numberofinterests: "14",
  },
  {
    id: 6,
    image: Img6,
    description:
      "Actively configurable wheel with incorporated suspension.This extends the ability of small vehicles to travel on rough terrain by providing an alternative suspension system in a small package.",
    title: "Adjustable wheel",
    tags: ["Space", "Defense", "Manufacturing"],
    engagements: "Licensing, Partnership, Investment ",
    patientorigin: "India",
    patientownership: ["India", "U.S.A"],
    trllevel: "Nine",
    daysleft: "60 ",
    numberofinterests: "06",
  },
];
