import React from 'react'
import Sidebar from '../../SidebarforProfiler/Sidebar'
import plant from "../../../../assets/images/logo/plant.png"
import FirstButton from '../../../../common/button'
import {  useNavigate } from 'react-router-dom'

const MarketPlace = () => {
  const navigate=useNavigate()

  const handleClick=()=>{
    
    navigate('/fundraising/marketplaceactivity')

  }
  return (
    <div className='cfp_main'>
        <Sidebar/>

        <div className='cfp_cont1'>
          <div style={{textAlign:"center", paddingTop:"8rem"}}>
        <img src={plant} alt="" width="164rem" height="164rem" />
        <h4 className='cfp_mr2'>Bring your project to life.</h4>
        <FirstButton buttonText="Discover Marketplace" className="btn" marginTop="2rem" onClick={handleClick}/>
        </div>
        </div>
    </div>
  )
}

export default MarketPlace