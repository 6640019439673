import React, { useState } from "react";
import FirstButton from "../../common/button";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { useNavigate } from "react-router-dom";

const Signupready = () => {
  const [value, setValue] = useState("individual");
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/signupoffounders");
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div className="signupready_main" style={{height:"80vh"}}>
      <p className="signupready_p1 signuready_mr">Almost Done !!</p>
      {/* <p className='signupready_p2'>Please provide the following details to complete signup</p> */}
      <div className="signupready_cont1">
        <div style={{ textAlign: "start", margin: "2rem 0" }}>
          <h2 className="signupready_p3">Profile type</h2>
          <p className="signupready_p4" style={{margin:"0.5rem 0"}}>What describe you the best?</p>
          <div style={{margin:"1rem 0"}}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              style={{ flexDirection: "row", }}
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="individual"
                control={
                  <Radio
                    sx={{
                      color: "#19976A",
                      "&.Mui-checked": { color: "#19976A" },
                      "& .MuiSvgIcon-root": { fontSize: 24 },
                    }}
                  />
                }
                label={<span style={{ fontSize: "1.6rem",marginTop:"1rem" }}>Individual</span>}
              />
              <FormControlLabel
                value="organization"
                control={
                  <Radio
                    sx={{
                      color: "#19976A",
                      "&.Mui-checked": { color: "#19976A" },
                      "& .MuiSvgIcon-root": { fontSize: 24 },
                    }}
                  />
                }
                label={<span style={{ fontSize: "1.6rem" }}>Organization</span>}
              />
            </RadioGroup>
          </div>
        </div>
        {value === "individual" && (
          <div className="signupready_cont1">
            <p className="signupready_p3">Full Legal name</p>
            <p className="signupready_p4 signup_mr1 ">
              Please provide your full legal name, including any middle names,
              as it appears on your legal ID
            </p>
            <input
              className="login_input signup_mr"
              placeholder="Type Here"
              type="text"
            />
          </div>
        )}
        {value === "organization" && (
          <div className="signupready_cont1">
            <p className="signupready_p3">Legal Business Name </p>
            <p className="signupready_p4 signup_mr1">
              Organization name as per incorporation certificate
            </p>
            <input
              className="login_input signup_mr"
              placeholder="Type Here"
              type="text"
            />
          </div>
        )}
        {/* <p className='signupready_p5'>By clicking “sign up” I agree toSmaarket’s terms of use and privacy policy</p> */}
        <FirstButton
          buttonText="Continue"
          className="btn"
          width="100%"
          margin="2rem 0 5rem 0"
          onClick={handleNavigate}
        />
      </div>
    </div>
  );
};

export default Signupready;
