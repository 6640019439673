import React from 'react'
import Img from "../../../assets/images/logo/ex2.png"
import FirstButton from '../../../common/button'
const TechOfferComplete = () => {
  return (
    <div className='mtoc_main'>
        <img src={Img}/>
        <h2 className='mtoc_p'>Please complete your profile before responding to a post.</h2>
        <FirstButton buttonText="Go to my Profile" className="borderbtn"/>
    </div>
  )
}

export default TechOfferComplete