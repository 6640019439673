import React from "react";
import { useNavigate } from "react-router-dom";
import img from "../../../assets/images/icons/explore5.svg";
import FirstButton from "../../../common/button";
const FundRaiseStory = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/marketplace/techofferlisting");
  };
  return (
    <>
      <div className="frs_main">
        <div className="story_header">
          <h1 className="story_heading">
            Between creativity and innovation, <br />
            There is smaarket -{" "}
            <span style={{ color: "#19976A" }}>Marketplace </span>
          </h1>
        </div>

        <div className="frs_cont1">
          <div className="story_content">
            <p className="story_text">
              At Smaarket, we curate, connect, validate, and analyze scientific
              and technological knowledge to uncover the unseen connections that
              drive innovations.{" "}
            </p>
            <p className="story_text">
              We weave in opportunities for organizations to use their
              imaginations to speed up their work. Because, as the world shifts
              toward science and technology, science and technology enter the
              marketplace.
            </p>
            <p className="story_text">
              So, if you're exploring technology, making strategic decisions, or
              managing R&D activities, consider teaming with us to get there
              faster.
            </p>

            <FirstButton
              className="btn"
              buttonText="Explore Marketplace"
              onClick={handleNavigate}
              marginTop={"5rem"}
            />
          </div>
          <div className="story_img story_txtal">
            <img src={img} className="story_img1" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FundRaiseStory;
