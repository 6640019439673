import React from 'react'
import { Link, useLocation} from 'react-router-dom';


const Sidebar = () => {
	const {pathname}=useLocation()
	const splitLocation = pathname.split("/");
  return (
	<div className='sidebar_main'>
	<div className='sidebar_cont1'>
	<nav className='nav-wd' >
					
						<div className='nav_cont'>
							<div className={splitLocation[2] === "baiscdetails" ? "active" : "sidebarNav_item"}> 
								<Link to="/maincampdonations/baiscdetails">Basic</Link>
							</div>

								<div className={splitLocation[2] === "pitch"  ? "active" : "sidebarNav_item"}> 
								  <Link to="/maincampdonations/pitch">Pitch</Link>
								</div>

								<div className={splitLocation[2] === "aboutus" ? "active" : "sidebarNav_item"}> 
							  	<Link to="/maincampdonations/aboutus">About Us</Link>
								</div>

                <div className={splitLocation[2] === "perks" ? "active" : "sidebarNav_item"}> 
								  <Link to="/maincampdonations/perks">Perks</Link>
								</div>

                <div className={splitLocation[2] === "financials" ? "active" : "sidebarNav_item"}> 
								  <Link to="/maincampdonations/financials">Financials</Link>
								</div>

								<div className={splitLocation[2] === "analytics" ? "active" : "sidebarNav_item"}> 
								 <Link to="/maincampdonations/analytics">Analytics</Link>
								</div>

								<div className={splitLocation[2] === "finish" ? "active" : "sidebarNav_item"}> 
								  <Link to="/maincampdonations/finish">Finish</Link>
								</div>
						</div>
    </nav>
     
    </div>
	</div>
  )
}

export default Sidebar