import React from "react";
import { scrollToTestDiv } from "../../utils";
import scrollto from "../../../assets/images/oicons/svg/scrollup.svg";

const Financials = () => {
  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          margin: "2rem 0",
        }}
      >
        {financial.map((item, index) => (
          <div
            style={{
              border: "1px solid black",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
            }}
            onClick={() => scrollToTestDiv(index)}
            key={index}
            className="faq_f3 pills"
          >
            {item.title}
          </div>
        ))}
      </div>
      <div>
        {financial.map((item, index) => (
          <div className="infq_cont faq_mr" key={index} id={index}>
            <h2 className="faq_f3 faq_f600 faq_mr1">{item.title} </h2>
            <p className="faq_f3">{item.dec}</p>
          </div>
        ))}
      </div>
      <div className="ta_scrollcont" style={{textAlign:"end"}}>
        <img src={scrollto} onClick={scrolltotop} />
      </div>
    </div>
  );
};

export default Financials;

const financial = [
  {
    title: "Account Receivables",
    dec: "The money that a company has a right to receive because it has provided customers with goods and/or services. For example, a manufacturer will have an account receivable when it delivers a truckload of goods to a customer on June 1 and the customer is allowed to pay in 30 days.",
  },
  {
    title: "Cash or Cash Equivalents",
    dec: "A current asset account which includes currency, coins, checking accounts, and undeposited checks received from customers. The amounts must be unrestricted.",
  },
  {
    title: "Cash to assets",
    dec: "The cash-asset ratio is the current value of marketable securities and cash, divided by the company's current liabilities. Also known as the cash ratio, the cash-asset ratio compares the amount of highly liquid assets (such as cash and marketable securities) to the number of short-term liabilities. This figure is used to measure a firm's liquidity or its ability to pay its short-term obligations.",
  },
  {
    title: "Cost of Goods Sold",
    dec: "The direct costs attributable to the production of the goods sold by a company. This amount includes the cost of the materials used in creating the good along with the direct labour costs used to produce the good. Also referred to as cost of sales. ",
  },
  {
    title: "Debt ratio",
    dec: "The debt ratio is a financial ratio that measures the extent of a  company's leverage. The debt ratio is defined as the ratio of total debt to total assets, expressed as a decimal or percentage. It can be interpreted as the proportion of a company's assets that are financed by debt.",
  },
  {
    title: "Earnings per share",
    dec: "Earnings per share is the portion of a company's profit that is allocated to each outstanding share of stock",
  },
  {
    title: "Gross margin",
    dec: "The gross margin represents the amount of revenue retained after the direct costs of producing the goods and services it sells.",
  },
  {
    title: "Long-Term Debt",
    dec: "The amount owed for a period exceeding 12 months from the date of the balance sheet. It could be in the form of a bank loan, mortgage bonds, debenture, or other obligations not due for one year. A firm must disclose its long-term debt on its balance sheet with its interest rate and date of maturity.",
  },
  {
    title: "Net income",
    dec: "An entity's income minus the cost of goods sold, expenses and taxes for an accounting period.",
  },
  { title: "Net Margin", dec: " " },
  {
    title: "Total Assets",
    dec: "The basic accounting equation states that assets = liabilities + stockholders' equity. In the accounting industry, assets are defined as anything that a business owns, has value, and can be converted to cash. Assets are broken down into two main categories. These two categories are current assets and noncurrent assets.",
  },
  {
    title: "Return on assets",
    dec: "Return on assets (ROA) shows how profitable a company is relative to its total assets.",
  },
  {
    title: "Revenue",
    dec: "Usually shown as the top item in an income (profit and loss) statement from which all charges, costs, and expenses are subtracted to arrive at net income. Also called sales, or (in the UK) turnover.",
  },
  {
    title: "Revenue per employee",
    dec: "Total revenues divided by the number of employees.",
  },
  {
    title: "Revenue to receivables",
    dec: "The accounts receivable turnover ratio is an accounting measure used to quantify a company's effectiveness in collecting its receivables, or money owed by clients. The ratio shows how well a company uses and manages the credit it extends to customers and how quickly that short-term debt is collected or paid.",
  },
];
