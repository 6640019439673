import React, { useState } from "react";
import Sidebar from "../SidebarforPrecamp/SidebarPreCamp";
import FirstButton from "../../../../common/button";
import SwitchGreen from "../../../../common/Switch";
import { FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router";

const risksList = [
  {
    label:
      "I Understand Smaarket does not provide legal, financial or tax advice of any kind you will consult a professional adviser.",
  },
  {
    label:
      "I have the sophistication to evaluate investment Campaign on Smaarket on my own.",
  },
  { label: "I agree to the Terms, and Privacy Policy." },
];

const Finish = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    navigate("/precampaigncreation");
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="cfp_h4">Finishing up</h4>

          <div className="cfp_wd">
            <div>
              <p className="market_p market_mr">Terms and Conditions</p>
              {risksList.map((item, index) => (
                <FormControlLabel
                style={{alignItems:"start"}}
                  label={<p className="risk_label">{item.label}</p>}
                  control={<SwitchGreen sx={{ m: 1 }} />}
                  key={index}
                  onChange={handleChange}
                  sx={{ m: 1 }}
                />
              ))}
            </div>
            <div>
              <p className="cfp_h4 market_mr">Main campaign launch date</p>
              <p className="font_p2" style={{ padding: "1rem 0 1rem 0" }}>
                We recommend at least 30 days of pre-campaign before the main
                campaign launches.
                <br />
                Select below the main campaign launch date.
              </p>
              <input type="date" placeholder="Select" className="date" />
            </div>
            <p
              className="font_p2"
              style={{ textAlign: "center", color: "#198097" }}
            >
              28 days to go for launch
            </p>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/precampaigncreation/faq")}
        />
        <FirstButton
          buttonText="Preview and Submit"
          className="btn"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default Finish;
