import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./views/Home";
import Footer from "./components/Navigation/Footer";
import Navbar from "./components/Navigation/Navbar";
import Search from "./views/SeachPage";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Verifyemail from "./components/Signup/Verifyemail";
import Signupready from "./components/Signup/Signupready";
import SignupofFounders from "./views/SignupofFounders";

import CompoanyProfile from "./components/FundRaising/MyProfiler/CompnayProfile/CompoanyProfile";
import MarketPlace from "./components/FundRaising/MyProfiler/MarketPlace/MarketPlace";
import MarketPlaceActivity from "./components/FundRaising/MyProfiler/MarketPlace/MarketPlaceActivity/MarketPlaceActivity";
import Settings from "./components/FundRaising/MyProfiler/Settings.jsx/Settings";
import Watchlist from "./components/FundRaising/MyProfiler/Watchlist/Watchlist";
import ReferandEarn from "./components/FundRaising/MyProfiler/ReferandEarn/ReferandEarn";
import Basic from "./components/FundRaising/PrecampCreation/Basic/Basic";
import ExternalLinks from "./components/FundRaising/PrecampCreation/ExternalLinks/ExternalLinks";
import ProjectDetails from "./components/FundRaising/PrecampCreation/ProjectDetails/ProjectDetails";
import Faq from "./components/FundRaising/PrecampCreation/Faq/Faq";
import Finish from "./components/FundRaising/PrecampCreation/Finish.jsx/Finish";
import Finalpage from "./components/FundRaising/PrecampCreation/finalpage/finalpage";
import PreQualifyMain from "./components/FundRaising/PreQualifyingApplication/PreQualifymain/PreQualifyMain";
import BasicDetails from "./components/FundRaising/PreQualifyingApplication/BasicDetails/BasicDetails";
import Contact from "./components/FundRaising/PreQualifyingApplication/Contact/Contact";
import Questions from "./components/FundRaising/PreQualifyingApplication/Questions/Questions";
import PreAppFinish from "./components/FundRaising/PreQualifyingApplication/Finish/PreAppFinish";
import PreQualEnd from "./components/FundRaising/PreQualifyingApplication/PreQualEnd/PreQualEnd";
import MainEnd from "./components/FundRaising/MainCampCreation/MainEnd/MainEnd";
import MainFinish from "./components/FundRaising/MainCampCreation/MainFinish/MainFinish";
import MainAnalytics from "./components/FundRaising/MainCampCreation/MainAnalytics/MainAnalytics";
import MainDealTerm from "./components/FundRaising/MainCampCreation/MainDealTerm/MainDealTerm";
import MainPitch from "./components/FundRaising/MainCampCreation/MainPitch/MainPitch";
import MainBasic from "./components/FundRaising/MainCampCreation/MainBasic/MainBasic";
import Explore from "./views/Explore";
import MarketPlacePage from "./views/MarketPlacePage";
import FundRaisers from "./views/FundRaisers";
import MainAboutUs from "./components/FundRaising/MainCampCreation/MainAboutus/MainAboutus";
import MainFinancails from "./components/FundRaising/MainCampCreation/MainFinancials/MainFinancials";
import SearchDetails from "./views/SearchDetails";
import MainDonationsBasic from "./components/FundRaising/MainCampCreationDonation/MainDonationBasic/MainDonationsBasic";
import MainDonationPitch from "./components/FundRaising/MainCampCreationDonation/MainDonationPitch/MainDonationPitch";
import MainDonationAboutus from "./components/FundRaising/MainCampCreationDonation/MainDonationAboutus/MainDonationAboutus";
import MainDonationAnalytics from "./components/FundRaising/MainCampCreationDonation/MainDonationAnalytics/MainDonationAnalytics";
import MainDonationFinish from "./components/FundRaising/MainCampCreationDonation/MainDonationFinish/MainDonationFinish";
import MainDonationEnd from "./components/FundRaising/MainCampCreationDonation/MainDonationEnd/MainDonationEnd";
import MainDonationFinancials from "./components/FundRaising/MainCampCreationDonation/MainDonationFinancials/MainDonationFinancials";
import MainDonationPerks from "./components/FundRaising/MainCampCreationDonation/MainDonationPerks/MainDonationPerks";
import MainDonationsPerksDetails from "./components/FundRaising/MainCampCreationDonation/MainDonationPerks/MainDonationPerksDetails";
import MainDonationSetPerks from "./components/FundRaising/MainCampCreationDonation/MainDonationPerks/MainDonationSetPerks";
import Dashstartcamp from "./components/FundRaising/DashboardforCampManage/Dashstartcamp";
import DashcompleteSubmission from "./components/FundRaising/DashboardforCampManage/DashcompleteSubmission";
import DashStartPreCamp from "./components/FundRaising/DashboardforCampManage/Dashprecamp";
import DashReviewPreCamp from "./components/FundRaising/DashboardforCampManage/Dashreviewprecamp";
import DashPreCampCompSubmission from "./components/FundRaising/DashboardforCampManage/DashPreCampCompSubmission";
import DashStartMainCamp from "./components/FundRaising/DashboardforCampManage/DashStartMainCamp";
import DashStartMainCampTwo from "./components/FundRaising/DashboardforCampManage/DashStartMainCampTwo";
import UpComingDeals from "./components/InvestmentDonations/PrecampPages/UpcomingDeals/UpComingDeals";
import SingleCampPage from "./components/InvestmentDonations/SingleCampPage/SingleCampPage";
import InvestSettings from "./components/InvestmentDonations/MyProfile/Settings";
import InvestReferandEarn from "./components/InvestmentDonations/MyProfile/ReferandEarn";
import InvestWatchlist from "./components/InvestmentDonations/MyProfile/WatchList";
import InvestProfile from "./components/InvestmentDonations/MyProfile/Profile";
import MarketProfile from "./components/MarketPlace/Myprofile/marketProfile";
import MarketPlaceMain from "./components/MarketPlace/Myprofile/MarketplaceActivity/Marketplace";
import MarketplaceActivity from "./components/MarketPlace/Myprofile/MarketplaceActivity/MarketplaceActivity";
import MarketWatchlist from "./components/MarketPlace/Myprofile/Watchlist/Watchlist";
import MarketReferandEarn from "./components/MarketPlace/Myprofile/ReferAndEarns";
import MarketSettings from "./components/MarketPlace/Myprofile/Settings.jsx/Settings";
import ListingPage from "./components/MarketPlace/TechOffer/TechOfferListingPage/ListingPage";
import TechOfferComplete from "./components/MarketPlace/TechOffer/TechOfferComplete";
import SuccessPageAfterReply from "./components/MarketPlace/TechOffer/SuccessPageAfterReply";
import SubmitResponsePage from "./components/MarketPlace/TechOffer/SubmitResponsePage";
import TechOfferCampaign from "./components/MarketPlace/TechOffer/TechOfferCampaign/TechOfferCampaign";
import TechNeedPage from "./components/MarketPlace/TechOffer/TechNeedCampaign/TeahNeedPage";
import TechNeedComplete from "./components/MarketPlace/TechOffer/TechNeedCampaign/TechNeedComplete";
import TechNeedSuccess from "./components/MarketPlace/TechOffer/TechNeedCampaign/TechNeedSuccess";
import TechNeedSubmitResponse from "./components/MarketPlace/TechOffer/TechNeedCampaign/TechNeedSubmitResponse";
import TechNeedBasicDetails from "./components/MarketPlace/PostingTechNeed/BasicDetails";
import TechNeedDetails from "./components/MarketPlace/PostingTechNeed/TechNeedDetails";
import TechNeedAboutUs from "./components/MarketPlace/PostingTechNeed/TechNeedAboutUs";
import TechNeedFinish from "./components/MarketPlace/PostingTechNeed/TechNeedFinish";
import TechNeedEnd from "./components/MarketPlace/PostingTechNeed/TechNeedEnd";
import TechOfferDetails from "./components/MarketPlace/PostingTechOffer/TechOfferDetails";
import TechOfferAboutUs from "./components/MarketPlace/PostingTechOffer/TechOfferAboutus";
import TechOfferFinish from "./components/MarketPlace/PostingTechOffer/TechOfferFinish";
import TechOfferEnd from "./components/MarketPlace/PostingTechOffer/TechOfferEnd";
import TechOfferBasicDetails from "./components/MarketPlace/PostingTechOffer/TechOfferBasicDetails";
import ActiveDeals from "./components/InvestmentDonations/InvestmentCampaign/ActiveDeals";
import InvestDetails from "./components/InvestmentDonations/InvestmentCampaign/InvestDetail/InvestDetail";
import ProceedToPay from "./components/InvestmentDonations/InvestmentCampaign/ProceedToPay";
import InvestCampEnd from "./components/InvestmentDonations/InvestmentCampaign/InvestCampEnd";
import DonateCampEnd from "./components/InvestmentDonations/DonatingCampaign/DonatingCampaignEnd";
import DonateMainPerk from "./components/InvestmentDonations/DonatingCampaign/DonatePerks/DonateMainPerk";
import DonateDetails from "./components/InvestmentDonations/DonatingCampaign/DonateDetail/DonateDetail";
import HelpFaq from "./components/FAQ/Faq";
import Feedback from "./components/FAQ/Feedbacks";
import FaqSearch from "./components/FAQ/FaqSearch";
import ServiceCatergory from "./components/ServiceProviders/Catergory";
import CreativeServices from "./components/ServiceProviders/ExpertDirectory/CreativeServices";
import HelpGlossary from "./components/FAQ/Glossary";

import MarketPremium from "./components/InvestmentDonations/MarketplacePremium/MarketPremium";
import TechnologyAssessment from "./components/InvestmentDonations/MarketplacePremium/TechnologyAssesment";
import DashCampPage from "./components/FundRaising/DashboardforCampManage/CampaignPage/CampPage";
import Portfolio from "./components/InvestmentDonations/MyProfile/Portfolio";
import Admin from "./components/Admin";
import AdminPreCamp from "./components/Admin/Precamp";
import AdminFundraisers from "./components/Admin/Fundraisers";
import AdminMarketplace from "./components/Admin/Marketplace";
import AdminStartups from "./components/Admin/Startups";
import DashCompleteSub from "./components/FundRaising/DashboardforCampManage/DashCompleteSub";
import EndCampignInvest from "./components/FundRaising/DashboardforCampManage/EndCampignInvest";

function App() {
  const role = localStorage.getItem("role");
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <ScrollToTop />
          <Navbar />
          <Routes>
            {/* Homepages */}
            <Route exact path="explore-startups" element={<Explore />} />
            <Route exact path="market-place" element={<MarketPlacePage />} />
            <Route exact path="raise-capital" element={<FundRaisers />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/search" element={<Search />} />
            <Route exact path="search/detail" element={<SearchDetails />} />

            <Route exact path="login" element={<Login />} />
            <Route exact path="signup" element={<Signup />} />
            <Route exact path="verifyemail" element={<Verifyemail />} />
            <Route exact path="signupready" element={<Signupready />} />
            <Route
              exact
              path="signupoffounders"
              element={<SignupofFounders />}
            />

            <Route
              exact
              path="investment/activedeals"
              element={<ActiveDeals />}
            />
            <Route
              exact
              path="investment/investdetails"
              element={<InvestDetails />}
            />
            {/* ------------Routes for Investment/Donation-----Pre campaign Pages */}

            <Route
              exact
              path="investment/upcomingdeals"
              element={<UpComingDeals />}
            />
            <Route
              exact
              path="investment/singlecamppage"
              element={<SingleCampPage />}
            />
            {/* Fund raisng my profile routes */}
            <Route
              exact
              path="investment/donatedetail"
              element={<DonateDetails />}
            />
            {/* {role==="fundraiser"?<> */}
            <Route
              exact
              path="fundraising/companyprofile"
              element={<CompoanyProfile />}
            />
            <Route
              exact
              path="fundraising/marketplace"
              element={<MarketPlace />}
            />
            <Route
              exact
              path="fundraising/marketplaceactivity"
              element={<MarketPlaceActivity />}
            />
            <Route exact path="fundraising/settings" element={<Settings />} />
            <Route
              exact
              path="fundraising/marketplacewatchlist"
              element={<Watchlist />}
            />
            <Route
              exact
              path="fundraising/marketplacereferandearn"
              element={<ReferandEarn />}
            />
            {/* Fund Raising Pre Camppaogn creation routes */}
            <Route exact path="precampaigncreation/basic" element={<Basic />} />
            <Route
              exact
              path="precampaigncreation/externallinks"
              element={<ExternalLinks />}
            />
            <Route
              exact
              path="precampaigncreation/projectdetails"
              element={<ProjectDetails />}
            />
            <Route exact path="precampaigncreation/faq" element={<Faq />} />
            <Route
              exact
              path="precampaigncreation/finish"
              element={<Finish />}
            />
            <Route exact path="precampaigncreation" element={<Finalpage />} />
            {/* Fund Raising Pre qualify Application */}
            <Route exact path="prequalify" element={<PreQualifyMain />} />
            <Route
              exact
              path="prequalify/basicdetails"
              element={<BasicDetails />}
            />
            <Route exact path="prequalify/contact" element={<Contact />} />
            <Route exact path="prequalify/questions" element={<Questions />} />
            <Route exact path="prequalify/finish" element={<PreAppFinish />} />
            <Route exact path="prequalify/end" element={<PreQualEnd />} />
            {/* Fund raisng Main Campaign creation -Investment */}
            <Route exact path="maincampcreation" element={<MainEnd />} />
            <Route
              exact
              path="maincampcreation/finish"
              element={<MainFinish />}
            />
            <Route
              exact
              path="maincampcreation/analytics"
              element={<MainAnalytics />}
            />
            <Route
              exact
              path="maincampcreation/dealterms"
              element={<MainDealTerm />}
            />
            <Route
              exact
              path="maincampcreation/pitch"
              element={<MainPitch />}
            />
            <Route
              exact
              path="maincampcreation/baiscdetails"
              element={<MainBasic />}
            />
            <Route
              exact
              path="maincampcreation/aboutus"
              element={<MainAboutUs />}
            />

            <Route
              exact
              path="maincampcreation/financials"
              element={<MainFinancails />}
            />

            {/* Fund raising Main Campaign creation -donation routes */}
            <Route exact path="maincampdonations" element={<MainDonationEnd />} />
            <Route
              exact
              path="maincampdonations/finish"
              element={<MainDonationFinish />}
            />
            <Route
              exact
              path="maincampdonations/analytics"
              element={<MainDonationAnalytics />}
            />
            <Route
              exact
              path="maincampdonations/dealterms"
              element={<MainDealTerm />}
            />
            <Route
              exact
              path="maincampdonations/pitch"
              element={<MainDonationPitch />}
            />
            <Route
              exact
              path="maincampdonations/baiscdetails"
              element={<MainDonationsBasic />}
            />
            <Route
              exact
              path="maincampdonations/aboutus"
              element={<MainDonationAboutus />}
            />
            <Route
              exact
              path="maincampdonations/financials"
              element={<MainDonationFinancials />}
            />
            <Route
              exact
              path="maincampdonations"
              element={<MainDonationEnd />}
            />
            <Route
              exact
              path="maincampdonations/perks"
              element={<MainDonationPerks />}
            />
            <Route
              exact
              path="maincampdonations/perksdetails"
              element={<MainDonationsPerksDetails />}
            />
            <Route
              exact
              path="maincampdonations/setperks"
              element={<MainDonationSetPerks />}
            />
            {/* ---------Routes for Fund Raising-  Dashboard for campaign management */}
            <Route exact path="dashstartcamp" element={<Dashstartcamp />} />
            <Route
              exact
              path="dashcompletesubmission"
              element={<DashcompleteSubmission />}
            />
            <Route
              exact
              path="dashstartprecamp"
              element={<DashStartPreCamp />}
            />
            <Route
              exact
              path="dashreviewprecamp"
              element={<DashReviewPreCamp />}
            />
            <Route
              exact
              path="dashprecampcompsubmission"
              element={<DashPreCampCompSubmission />}
            />
            <Route
              exact
              path="dashstartmaincamp"
              element={<DashStartMainCamp />}
            />
            <Route
              exact
              path="dashstartmaincamptwo"
              element={<DashStartMainCampTwo />}
            />
            <Route exact path="dashcamppage" element={<DashCampPage />} />
            <Route exact path="dashcompletesub" element={<DashCompleteSub />} />
            <Route
              exact
              path="endcampigninvest"
              element={<EndCampignInvest />}
            />
            {/* </>:<Route exact path="login" element={<Login/>} />} */}
            {role === "investor" ? (
              <>
                {/* ------------Routes for Investment/Donations-----My profile */}
                <Route
                  exact
                  path="investment/settings"
                  element={<InvestSettings />}
                />
                <Route
                  exact
                  path="investment/referandearn"
                  element={<InvestReferandEarn />}
                />
                <Route
                  exact
                  path="investment/watchlist"
                  element={<InvestWatchlist />}
                />
                <Route
                  exact
                  path="investment/profile"
                  element={<InvestProfile />}
                />
                {/* Routes for Investment/Donation----------InvestmentCampaign */}

                <Route
                  exact
                  path="investment/investporceedtopay"
                  element={<ProceedToPay />}
                />
                <Route
                  exact
                  path="investment/investcampend"
                  element={<InvestCampEnd />}
                />
                <Route
                  exact
                  path="investment/portfolio"
                  element={<Portfolio />}
                />
                {/* Routes for Investment/Donation---------DonatingCampaign */}
                <Route
                  exact
                  path="investment/donatecampend"
                  element={<DonateCampEnd />}
                />
                <Route
                  exact
                  path="investment/donatemainperk"
                  element={<DonateMainPerk />}
                />
              </>
            ) : (
              <Route exact path="login" element={<Login />} />
            )}
            {/* {role==="marketplace"?<> */}
            {/* --------Routes for Marketplace-------MyProfile------------------ */}
            <Route
              exact
              path="marketplace/companyprofile"
              element={<MarketProfile />}
            />
            <Route
              exact
              path="marketplace/marketplace"
              element={<MarketPlaceMain />}
            />
            <Route
              exact
              path="marketplace/marketplaceactivity"
              element={<MarketplaceActivity />}
            />
            <Route
              exact
              path="marketplace/watchlist"
              element={<MarketWatchlist />}
            />
            <Route
              exact
              path="marketplace/referandearn"
              element={<MarketReferandEarn />}
            />
            <Route
              exact
              path="marketplace/settings"
              element={<MarketSettings />}
            />
            {/* </>:<Route exact path="login" element={<Login/>} />} */}
            {/* Router for MarketPlace-------- Tech Offer----  */}
            <Route
              exact
              path="marketplace/techofferlisting"
              element={<ListingPage />}
            />
            <Route
              exact
              path="marketplace/techoffercomplete"
              element={<TechOfferComplete />}
            />
            <Route
              exact
              path="marketplace/successpageafterreply"
              element={<SuccessPageAfterReply />}
            />
            <Route
              exact
              path="marketplace/submitresponsepage"
              element={<SubmitResponsePage />}
            />
            <Route
              exact
              path="marketplace/techoffercampaign"
              element={<TechOfferCampaign />}
            />
            {/* Marketplace tech nedd routes */}
            <Route
              exact
              path="marketplace/techneedpage"
              element={<TechNeedPage />}
            />
            <Route
              exact
              path="marketplace/techneedcompelete"
              element={<TechNeedComplete />}
            />
            <Route
              exact
              path="marketplace/techneedsuccess"
              element={<TechNeedSuccess />}
            />
            <Route
              exact
              path="marketplace/techneedsubmitresponse"
              element={<TechNeedSubmitResponse />}
            />
            {/* Posting a tech need */}
            <Route
              exact
              path="marketplace/techneedbasicdetails"
              element={<TechNeedBasicDetails />}
            />
            <Route
              exact
              path="marketplace/techneeddetails"
              element={<TechNeedDetails />}
            />
            <Route
              exact
              path="marketplace/techneedaboutus"
              element={<TechNeedAboutUs />}
            />
            <Route
              exact
              path="marketplace/techneedfinish"
              element={<TechNeedFinish />}
            />
            <Route
              exact
              path="marketplace/techneedend"
              element={<TechNeedEnd />}
            />
            {/* MarketPlace -Posting Tech OfferRoutes */}
            <Route
              exact
              path="marketplace/techofferbasicdetails"
              element={<TechOfferBasicDetails />}
            />
            <Route
              exact
              path="marketplace/techofferdetails"
              element={<TechOfferDetails />}
            />
            <Route
              exact
              path="marketplace/techofferaboutus"
              element={<TechOfferAboutUs />}
            />
            <Route
              exact
              path="marketplace/techofferfinish"
              element={<TechOfferFinish />}
            />
            <Route
              exact
              path="marketplace/techofferend"
              element={<TechOfferEnd />}
            />
            <Route
              exact
              path="marketplace/premium"
              element={<MarketPremium />}
            />
            <Route
              exact
              path="marketplace/techAssessment"
              element={<TechnologyAssessment />}
            />

            {/* Help center */}
            <Route exact path="help/faq" element={<HelpFaq />} />
            <Route exact path="help/feedback" element={<Feedback />} />
            <Route exact path="help/faqsearch" element={<FaqSearch />} />
            <Route exact path="help/glossary" element={<HelpGlossary />} />
            {/* Service Provider Routes */}
            <Route
              exact
              path="serviceproviders/category"
              element={<ServiceCatergory />}
            />
            <Route
              exact
              path="serviceproviders/creativeservices"
              element={<CreativeServices />}
            />
            {/* -----------------------------Admin Routes----------------------------------- */}
            <Route exact path="admin" element={<Admin />} />
            <Route exact path="/admin/precampains" element={<AdminPreCamp />} />
            <Route
              exact
              path="/admin/fundraisers"
              element={<AdminFundraisers />}
            />
            <Route
              exact
              path="/admin/marketplace"
              element={<AdminMarketplace />}
            />
            <Route exact path="/admin/startups" element={<AdminStartups />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
