import React from "react";
import { useNavigate, Link } from "react-router-dom";
import FirstButton from "../../common/button";
import img from "../../assets/images/oicons/svg/lock.svg";

const Restricted = () => {
  const navigate = useNavigate();
  return (
    <div className="restrict_main">
      <img src={img} alt="" />
      <h1 className="restrict_f1">
        To view the rest of the campaign, join our community.
        <br />
        It's free, fast and simple.
      </h1>
      <p className="restrict_f2">
        Due to financial regulations, you need to join our community to view the
        full opportunity.
      </p>
      <div className="restrict_btncont">
        <FirstButton
          buttonText="Signup"
          width="100%"
          className="btn"
          onClick={() => navigate("/signup")}
        />
      </div>
      <p className="restrict_f3"> Already have an Account ? </p>
      <p className="restrict_f3">
        {" "}
        <span className="cs_fw">Risk Warning : </span> Smaarket is targeted
        exclusively at investors and donors who are sufficiently sophisticated
        to understand risks and make their own decisions.
      </p>
    </div>
  );
};

export default Restricted;
