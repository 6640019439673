export const roles = (email) => {
  const role1 = "fundraiser";
  const role2 = "investor";
  const role3 = "marketplace";
  const role4 = "basic";

  if (email === "shubham@smaarket.com") {
    localStorage.setItem("email", email);
    localStorage.setItem("role", role1);

    let role = role1;
    return role;
  } else if (email === "rajath@smaarket.com") {
    localStorage.setItem("email", email);
    localStorage.setItem("role", role2);
    let role = role2;
    return role;
  } else if (email === "satish@smaarket.com") {
    localStorage.setItem("email", email);
    localStorage.setItem("role", role3);
    let role = role3;
    return role;
  } else {
    localStorage.setItem("email", email);
    localStorage.setItem("role", role4);
    let role = role4;
    return role;
  }
};

export const role = [
  {
    role: "fundraisers",
    email: "rajath@smaarket.com",
    name: "Rajath",
  },
  {
    role: "investor",
    email: "satish@smaarket.com",
    name: "Satish",
  },
  {
    role: "marketplace",
    email: "shubham@smaarket.com",
    name: "Shubham",
  },
  {
    role: "admin",
    email: "superadmin@smaarket.com",
    name: "Admin",
  },
  {
    role: "login",
  },
];

export const scrollToTestDiv = (index) => {
  const divElement = document.getElementById(index);
  divElement.scrollIntoView({
    behavior: "auto" /*or smooth*/,
    block: "center",
  });
};
