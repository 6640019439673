import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../assets/images/icons/avatar2.png";
import FirstButton from "../../../common/button";
const DashStartPreCamp = () => {
  const navigate = useNavigate();
  return (
    <div className="dashcompsub_contmain">
      <div className="dashcompsubmain">
        <div className="dashcompsub1">
          <div className="dashcompsub_cont">
            <div>
              {" "}
              <img src={Avatar} alt="" />{" "}
            </div>
            <div>
              <h2 className="dashcompsub_h2">Bally Robotics</h2>
              <p className="dashcompsub_p">Application ID : SM02103A</p>
            </div>
          </div>
          <div>
            <FirstButton
              buttonText="Contact Campaign Manager"
              width="100%"
              marginTop="2rem "
              className="btnsecondary"
            />
            <FirstButton
              buttonText="View Campaign History"
              width="100%"
              marginTop="1.5rem "
              className="borderbtn"
            />
          </div>
        </div>
        <div className="dashcompsub1">
          <p className="brand_name dashcompsub_p1">
            Your Application has been submitted for review, we will get back to
            you in 5 to 7 days
          </p>

          <FirstButton
            buttonText="Start Pre-campaign"
            width="100%"
            marginTop="10.5rem"
            className="btngrey"
            onClick={() => navigate("/dashreviewprecamp")}
          />
        </div>
      </div>
    </div>
  );
};

export default DashStartPreCamp;
