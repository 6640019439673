import React from "react";
import img1 from "../../../assets/images/icons/ss1.png";
import img2 from "../../../assets/images/icons/ss2.png";
import img3 from "../../../assets/images/icons/ss3.png";
import img4 from "../../../assets/images/icons/ss4.png";
const SuccessStory = () => {
  return (
    <>
      {/* <div className="review">
      <div className="review_container">
        <h1 className="review_primary">Investors comment</h1>
        <img className="review_img" src={reviews} alt="" />
      </div>
    </div> */}

      <div className="comment">
        <div className="comment_container">
          <h1 style={{ color: "#000" }} className="review_primary">
            Founder Success stories
          </h1>
          <div>
            {list.map((item, index) => (
              <div className="ss_main">
                <div>
                  {" "}
                  <img src={item.img} alt="" />{" "}
                </div>
                <div>
                  <p className="ss_f1">{item.head}</p>
                  <p className="ss_f2">{item.desc}</p>
                  <p className="ss_f3">{item.bot}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessStory;

const list = [
  {
    img: img1,
    head: "Completed two successful campaigns on Smaarket .",
    desc: "We have successfully completed two capital-raising initiatives on Smaarket. Our experiences were nothing short of fantastic on both occasions. Their onboarding, campaign creation, and investor management processes are all simple and straightforward.",
    bot: "Rangarajan, Founder  Quantam industries",
  },
  {
    img: img2,
    head: "Raised ₹ 7 cr  in  3 months ",
    desc: "Smaarket convinced me of the potential of crowdfunding. Phatam's success on the platform not only yielded significant funds to drive development ambitions, but also linked us to investors who genuinely care about what we're developing and became some of our most influential advocates in the process.",
    bot: "Sujatha Rao, CEO ,Phatam",
  },
  {
    img: img3,
    head: "Raised ₹ 1 Cr  in under 60 days",
    desc: "Working with the Smaarket crew was a delight. It was a collaborative partnership in which we shared ideas, strategy, and execution plans. This transparency was extended to all levels of the company as well as our investment base.",
    bot: "Dhanavantri, CEO Alpha Health",
  },
  {
    img: img4,
    head: "Raised ₹ 3 cr from 1000 investors",
    desc: "It was a pleasure to collaborate with Smaarket on our H2O care fundraising. We worked with them at the height of the pandemic, and despite the numerous unknowns, Smaarket was invaluable in guiding us through a challenging raising. I would strongly advise anyone trying to raise funds for their business to use Smaarket.",
    bot: "Ganesh Jalkar, CEO H2o Care",
  },
];
