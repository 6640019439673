import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import Scrolltotop from "../../../../assets/images/oicons/svg/scrollup.svg";
import Dropdown from "../../../../common/dropdown";
import { useNavigate } from "react-router-dom";

const list = ["menu", "low"];
const MainFinancails = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/maincampcreation/analytics");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Financials</h4>
          <div className="cfp_wd1">
            <div>
              <p className="counts_title">
                Demonstrate to the investor that you will be able to handle the
                money responsibly and successfully.
              </p>
            </div>

            <div className="mcdt_cont">
              <p className="mcdt_p1">At a Glance</p>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Revenue</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Net Loss</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
              </div>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Previous valuation</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Cash in Hand</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
              </div>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Earnings per Share</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Revenue to Receivables</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
              </div>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Net Margin</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Gross Margin</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
              </div>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Return on Assets</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Revenue per Employee</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
              </div>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Cash to Assets</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Debt Ratio</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">
                Utilization of crowd funding raised money
              </p>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Amount</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Intended purpose</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
              </div>
              <div className="mcb_btn_cont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add +"
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Past fund raises</p>
              <div className="mainfin_cont1">
                <div className="mainfin_cont3">
                  <p className="mcdt_p2">Amount</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Enter"
                  />
                </div>
                <div className="mainfin_cont3">
                  <p className="mcdt_p2">Date</p>
                  <input type="date" className="date" />
                </div>
                <div className="mainfin_cont5">
                  <p className="mcdt_p2">Type</p>
                  <Dropdown MenuList={dropType} />
                </div>
                <div className="mainfin_cont4">
                  <p className="mcdt_p2">Lead investor or Funder</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
              </div>
              <div className="mcb_btn_cont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add +"
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Financial Liabilities</p>
              <div className="mainfin_cont1">
                <div className="mainfin_cont3">
                  <p className="mcdt_p2">Amount</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Enter"
                  />
                </div>
                <div className="mainfin_cont3">
                  <p className="mcdt_p2">Date</p>
                  <input type="date" className="date" />
                </div>
                <div className="mainfin_cont5">
                  <p className="mcdt_p2">Type</p>
                  <Dropdown MenuList={dropType1} />
                </div>
                <div className="mainfin_cont4">
                  <p className="mcdt_p2">Lead investor or Funder</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Type Here"
                  />
                </div>
              </div>
              <div className="mcb_btn_cont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add +"
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Risks </p>
              <p className="mcdt_p2">
                Investing carries risks, including loss of capital and
                illiquidity
              </p>
              <div className="mainfin_cont6">
                <input
                  type="text"
                  className="login_input"
                  placeholder="Type Here"
                />
                <input
                  type="text"
                  className="login_input"
                  placeholder="Type Here"
                />
                <input
                  type="text"
                  className="login_input"
                  placeholder="Type Here"
                />
              </div>
              <div className="mcb_btn_cont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add +"
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/maincampcreation/dealterms")}
        />
        <FirstButton
          buttonText="Save and next"
          onClick={handleSubmit}
          className="btn"
        />
      </div>
    </div>
  );
};

export default MainFinancails;

const dropType = [
  { value: "1", label: "Bootstrapping" },
  { value: "2", label: "Business Loan" },
  { value: "3", label: "Crowd funding" },
  { value: "4", label: "Equity funding" },
  { value: "5", label: "Grant" },
];
const dropType1 = [
  { value: "1", label: "Bootstrapping" },
  { value: "2", label: "Business Loan" },
  { value: "3", label: "Crowd funding" },
  { value: "4", label: "Equity funding" },
];
