import React, { useState } from "react";
import Sidebar from "../../../SidebarforProfiler/Sidebar";
import { Tabs, Tab } from "@mui/material";
import Posts from "./Tabs/Posts";
import Replies from "./Tabs/Replies";
import FirstButton from "../../../../../common/button";
import Scrolltotop from "../../../../../assets/images/oicons/svg/scrollup.svg";

const activity = [
  { value: "05", indicator: "Total Offers made" },
  { value: "02", indicator: "Total Needs created" },
  { value: "01", indicator: "Successful deals" },
  { value: "01", indicator: "Active subscription" },
];
const tablist = ["Posts", "Replies"];

const MarketPlaceActivity = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (e, value) => {
    setTabIndex(value);
  };
  return (
    <div className="cfp_main">
      <Sidebar />
      <div className="cfp_cont1">
        <h4 className="market_h4">Marketplace activity</h4>

        <div>
          <p className="market_p">Your Activity in numbers</p>

          <div className="market_cont1">
            {activity.map((item, index) => (
              <div key={index}>
                <p className="market_p1">{item.value}</p>
                <p className="market_p2">{item.indicator}</p>
              </div>
            ))}
          </div>
        </div>

        <div style={{ paddingTop: "5rem" }}>
          <div className="borderTab">
            <Tabs
              variant="fullWidth"
              onChange={handleChange}
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#19976A",
                  height: 3,
                },
              }}
            >
              {tablist.map((item, index) => (
                <Tab
                  style={{
                    fontSize: "1.6rem",
                    color: `${tabIndex === index ? "black" : "black"}`,
                    fontWeight: `${tabIndex === index ? "bold" : 400}`,
                    textTransform: "none",
                    fontFamily: "Montserrat",
                  }}
                  value={index}
                  label={item}
                  key={index}
                />
              ))}
            </Tabs>
          </div>
        </div>

        {tabIndex === 0 && (
          <div>
            <Posts />
          </div>
        )}
        {tabIndex === 1 && (
          <div>
            <Replies />
          </div>
        )}

        <div className="cfp_footer">
          <p className="cfp_p">Keep seeking for that deal</p>
          <FirstButton buttonText="Go to MarketPlace" className="btn" />
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketPlaceActivity;
