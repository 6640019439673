import React from "react";
import ExploreMain from "../components/LandingPages/explore/ExploreMain";
import Counts from "../components/Home/Counts";
import DonationCards from "../components/LandingPages/explore/DonationCardsSection";
import ExploreGrid from "../components/LandingPages/explore/ExploreGrid";
import ExploreReview from "../components/LandingPages/explore/ExploreReview";
import ExploreFAQ from "../components/LandingPages/explore/ExploreFAQ";
const Explore = ()=>{
  return (
    <>

    <ExploreMain/>
    <Counts/>
    <DonationCards/>
    <ExploreGrid />
    <ExploreReview/>
    <ExploreFAQ/>
    
    </>
  )
}

export default Explore