import React, { useState } from "react";
import FirstButton from "../../common/button";

const interestsList = [
  { id: 1, name: "Aerospace" },
  { id: 2, name: "Agriculture" },
  { id: 3, name: "Artificial Intelligence" },
  { id: 4, name: "Assistive Devices" },
  { id: 5, name: "B2B" },
  { id: 6, name: "B2C" },
  { id: 7, name: "Bio-Technology" },
  { id: 8, name: "Digital Health" },
  { id: 9, name: "Entertainment" },
  { id: 10, name: "Farming" },
  { id: 11, name: "Food" },
  { id: 12, name: "Generator" },
  { id: 13, name: "Health and Wellness" },
  { id: 14, name: "Mobility" },
  { id: 15, name: "Space" },
  { id: 16, name: "Sustainability" },
  { id: 17, name: "Technology" },
  { id: 18, name: "Venture Funded" },
  { id: 19, name: "Women Founder" },
];

const Interests = (props) => {
  const [active, setActive] = useState([]);

  const handleClick = (val) => {
    setActive((oldArray) => [...oldArray, val]);
  };
  const handleNavigate = () => {
    props.change(3, props.role);
  };

  return (
    <div className="interest_main">
      <h4 className="interest_head">What are your Interests ?</h4>
      <div className="invest_profilecont" style={{ margin: "3rem 0" }}>
        {interestsList.map((item, index) => (
          // <FirstButton buttonText={item} className="borderbtn" backgroundColor="#F5F5F5" margin="0.5rem"/>
          <p
            key={index}
            className={
              active.includes(item.id)
                ? "invest_profiletilesactive"
                : "invest_profiletiles"
            }
            onClick={() => handleClick(item.id)}
          >
            {item.name}
          </p>
        ))}
      </div>
      <FirstButton
        buttonText="Proceed"
        className="btn"
        backgroundColor="#F5F5F5"
        margin="0.5rem"
        width="40rem"
        onClick={handleNavigate}
      />
    </div>
  );
};

export default Interests;
