import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import scrollto from "../../../assets/images/oicons/svg/scrollup.svg";
const InvestorFaq = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {question.map((item, index) => (
        <Accordion
          key={index}
          style={{ boxShadow: "none", border: "none", padding: "0" }}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#19976A", fontSize: "1.6rem" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ padding: "0 !important", border: "0 !important" }}
          >
            <p className="faq_f3 faq_f600">{item.ques}</p>
          </AccordionSummary>

          <AccordionDetails>
            <div
              className="faq_f3"
              dangerouslySetInnerHTML={{ __html: item.ans }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="dflex alcenter faq_mr3">
        <p className="faq_f3">
          If you cant find what youre looking for write to us.{" "}
          <span>
            {" "}
            <Link className="faq_f600">Email us </Link>{" "}
          </span>
        </p>
        <img src={scrollto} onClick={scrolltotop} />
      </div>
    </div>
  );
};

export default InvestorFaq;

const question = [
  {
    ques: "What is Smaarket?",
    ans: "There is no better location to begin the search for fascinating and creative businesses and inventions that surprise and amaze with live crowdfunding campaigns and a marketplace.",
  },
  {
    ques: "What distinguishes Smaarket from the stock exchange?",
    ans: "Startups on Smaarket are substantially younger than those listed on the Stock Exchange. Here are some significant differences: It is far riskier. Win big or lose everything. Hold for the long run.",
  },
  {
    ques: "Any advice for a first-time Smaarket investor? What is our recommendation?",
    ans: ' Begin slowly <ol className="faq_f3"> <li> Expect to lose everything. Never put more money into something than you can afford to lose. </li><li> Only invest in what you know. Preferably, a product or mission that you are passionate about. </li> <li> Conduct your research. On their company profile, you may also ask the founders a question. </li><li> Expand your horizons. It is preferable to make several small investments rather than one large one. </li> </ol> <p className="faq_f3">It will also help you learn more. Please keep in mind that these are suggestions rather than investment advice, and you should draw your own conclusions when determining what to invest in. </p>',
  },
  {
    ques: "What types of businesses can be found on Smaarket?",
    ans: "Almost the entire Indian economy, as we like to say! That's the exciting part! We've supported startups from Agritech to Space tech which creates Positive impact such as: Flying automobiles, space telescopes, and fusion reactors are examples of moonshots. Biotechnology, such as glowing plants, cancer studies, and so much more! The one point in common? All of the companies that successfully raise funds on Smaarket have a devoted following of supporters.",
  },
  {
    ques: "How much money can I invest?",
    ans: "As part of our commitment to broadening investing access,Smaarket allows investments starting at ₹ 50,000 —the lowest in the industry. Ultimately, every startup fundraising on Smaarket sets its own minimum investment.",
  },
  {
    ques: "How much money can I expect to make?",
    ans: "First things first: there are no guarantees for returns in crowdfunding. That's why seasoned investors diversify their startup portfolios. While most early-stage businesses will probably fail, some may flourish, and a select few may evolve into <strong>unicorns,</strong> generating enormous returns. Your investment carries a high risk/high reward ratio in both cases. In both cases, you become a part of a network of other investors who wish to support the startups.",
  },
  {
    ques: "Who may invest? ",
    ans: "Smaarket allows any Indian national over the age of 18 to make investments. We have a diversified investor base with regard to age, gender, income, background, and point of view. Please go to each firm's deal page for information on who can invest for specifics on additional conditions for each specific campaign, which are determined by the company on smaarket.",
  },
  {
    ques: "How do startups get listed on smaarket?",
    ans: "Smaarket is a highly curated platform. Only carefully chosen startups that have undergone extensive due diligence and evaluation by the investment committee are featured. To better diversify your portfolio, we have a wide choice of startups across stages, sectors, and business models.",
  },
  {
    ques: "What do I need to start?",
    ans: "Join now! We make a lot of effort to make investing simple and inexpensive. Joining Smaarket and beginning to invest just takes a few seconds. Start investing. We urge you to read our instructional materials and conduct independent research on the startups you are considering investing in.",
  },
  {
    ques: "What do you charge?",
    ans: "Smaarket charges 0.5% as a convenience fee and 0.5% as a legal fee of the total invested + 18% GST + Payment gateway fee (if applicable) on the total investment, but only if successful . These fees are not refundable or adjustable.",
  },
];
