import React,{useState} from 'react'
import Sidebar from "../Sidebar/Sidebar"
import FirstButton from '../../../../common/button'
import SwitchGreen from '../../../../common/Switch'
import {FormControlLabel} from '@mui/material'
import { useNavigate} from 'react-router-dom'
import Gift from "../../../../assets/images/logo/gift.png"

const MainDonationPerks = () => {
  const navigate=useNavigate()
  const handleClick=()=>{
    navigate("/maincampdonations/perksdetails")
  }
  const handleSubmit=()=>{
    navigate("/maincampdonations/financials")
  }
  return (
    <div>
      <div className="cfp_main">
      <Sidebar/>
      
      <div className="cfp_cont1">
      <div className='precampbtncont1'>
          <FirstButton buttonText="Save and Exit" className="borderbtn"/>
        </div>
       <div className='mdp_cont'>
        <h1 className='final_p1'>Set your Perks</h1>
        <p className='font_p1'>Perks are rewards provided to backers in exchange for their financial <br/> assistance.  Up until a perk is claimed, you can alter the details of your perk.</p>
        <div>
        <img src={Gift} alt="" className='mdp_img' />
        <h2 className='mdp_pq'>Lets get started</h2>
        <p className='font_p2'>Create your perks here <br/>
Most creators offer 3-10 Perk tiers, which can be physical items or special experiences.</p>
</div>
<div className='mdp_btncont'>
    <FirstButton buttonText="Create New Perk" onClick={handleClick} className='btn' />
</div>
       </div>
      </div>
      </div>
      <div className="precampbtncont">
        <FirstButton buttonText="Previous" className="borderbtn" onClick={()=>navigate("/maincampdonations/aboutus")}/>
        <FirstButton buttonText="Save and next" onClick={handleSubmit} className="btn"/>
      </div>
    </div>
  )
}

export default MainDonationPerks