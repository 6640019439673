import React from "react";

const TilesGraph = (props) => {
  const data = props.data;
  return (
    <div>
      <div className="tilegh_maincont">
        <div>
          <div className="tile_ghmain">
            {data &&
              data.map((item, index) => (
                <div key={index}>
                  <div style={{ display: "inline-block" }}>
                    <p className="tilegh_f1">{item.year}</p>
                    <div>
                      {item.companies.map((company, id) => (
                        <div
                          className={
                            company.value > 0 && company.value < 25
                              ? "tilegh_red"
                              : company.value >= 25 && company.value < 50
                              ? "tilegh_orange"
                              : company.value >= 50 && company.value < 75
                              ? "tilegh_blue"
                              : company.value >= 75 && company.value <= 100
                              ? "tilegh_green"
                              : "tilegh_def"
                          }
                        >
                          {company.name}
                          <p>{company.value} %</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TilesGraph;
