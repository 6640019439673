import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { Tabs, Tab } from "@mui/material";
import InvestorFaq from "./FaqTabs/InvestorFaq";
import FoundersFaq from "./FaqTabs/FoundersFaq";
import DonarFq from "./FaqTabs/DonarFq";
import Marketplacefaq from "./FaqTabs/Marketplacefaq";

const tablist = [
  "Investor Faq",
  "Founders Faq",
  "Donor Faq",
  "Marketplace Faq",
];
const HelpFaq = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (e, value) => {
    setTabIndex(value);
  };
  return (
    <div className="dflex ">
      <Sidebar />

      <div className="w70 p132">
        <h1 className="faq_f1 faq_mr"> FAQs</h1>
        <div className="faq_mr">
          <h2 className="faq_f2">How can we help you?</h2>
          <input className="login_input" placeholder="Search" />
        </div>
        <div>
          <div className="borderTab">
            <Tabs
              variant="fullWidth"
              onChange={handleChange}
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#19976A",
                },
              }}
            >
              {tablist.map((item, index) => (
                <Tab
                  style={{
                    fontSize: "1.6rem",
                    color: `${tabIndex === index ? "#070908" : "#070908"}`,
                    fontWeight: `${tabIndex === index ? "bold" : 400}`,
                    textTransform: "none",
                  }}
                  value={index}
                  label={item}
                  key={index}
                />
              ))}
            </Tabs>
          </div>
          {tabIndex === 0 && <InvestorFaq />}
          {tabIndex === 1 && <FoundersFaq />}
          {tabIndex === 2 && <DonarFq />}
          {tabIndex === 3 && <Marketplacefaq />}
        </div>
      </div>
    </div>
  );
};

export default HelpFaq;
