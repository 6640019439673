import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img01 from "../../../../../assets/images/img/donateimg2.png";
import img001 from "../../../../../assets/images/slider/hugg1.PNG";
import img02 from "../../../../../assets/images/slider/hugg2.PNG";
import img03 from "../../../../../assets/images/slider/hugg3.PNG";
import img04 from "../../../../../assets/images/slider/hugg4.PNG";
import img05 from "../../../../../assets/images/slider/hugg5.PNG";
import img06 from "../../../../../assets/images/slider/hugg6.PNG";
import img07 from "../../../../../assets/images/slider/hugg7.PNG";
import img08 from "../../../../../assets/images/slider/hugg8.PNG";
import img09 from "../../../../../assets/images/slider/hugg9.PNG";
import FirstButton from "../../../../../common/button";
import { useNavigate } from "react-router-dom";
const Pitch = () => {
  const naviagte = useNavigate();
  const data = [
    {
      number: 1,
      text: "India. single-use cup market is $4.2B. Samai's alternative is well-timed to compete in this space.",
    },
    {
      number: 2,
      text: "Theme park, travel, service partnerships in place to grow to est. $44.7M by 2027, 5 year CAGR 79.8%",
    },
    {
      number: 3,
      text: "Presence in 14 states servicing a variety of applications. 2022-2023 capacity already SOLD OUT!",
    },
    {
      number: 4,
      text: "Early-to-market upcycled, made in the  India  edible cup. Profitable by 2024 with $4.5M EBIT (est.).",
    },
    {
      number: 5,
      text: "Advisory board rounds out our team by bringing agricultural research and food production expertise",
    },
    {
      number: 6,
      text: "We aim to keep 285M single-use cups out of landfill and prevent >4,800 tons of food waste by 2027.",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
  };
  return (
    <>
      <div className="pitch">
        <div className="pitch-container">
          <div className="heighlights">
            <h1 className="brands_primary">Highlights</h1>

            {data.map((item, index) => (
              <div
                key={index}
                style={{
                  color: "#070908",
                  display: "flex",
                  alignItems: "center",
                }}
                className="tile_secondary heighlights_text"
              >
                <span className="heighlight_point">{item.number}</span>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="slider">
        <Slider {...settings}>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img001}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img02}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img03}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img04}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img05}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img06}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img07}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img08}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img09}></img>
          </div>
        </Slider>
      </div>
      <div className="pitch">
        <div className="pitch-container">
          <h1
            style={{ marginTop: "5rem", marginBottom: "2rem" }}
            className="brands_primary"
          >
            Use of Funds
          </h1>
          <div className="useOfFunds">
            <div className="fund_amount">₹ 25,00,000</div>
            <div className="fund_use">
              40% salaries, 42.5% real estate and Assets (Machines and
              Equipment), 2% admin expenses and 8% marketing & advertising.
              Smaarket fee 7.5%
            </div>
          </div>
          <div className="useOfFunds">
            <div className="fund_amount">₹40,00,000</div>
            <div className="fund_use">
              35% Production Development (commission the building of 2 high
              volume automated production lines), 22% Operations, 20.5% Sales &
              Marketing, 10% Reserves, 7.5% We Smaarket fees 5% payment towards
              related party bridge loan.
            </div>
          </div>
          <div className="useOfFunds">
            <div className="fund_amount">₹ 60,00,000</div>
            <div className="fund_use">
              50% Production Development (finish build of our R&D / manual
              production facility), 23% payment towards related party bridge
              loan, 16.5% Operations, 7.5% We Smaarket fees, 3% Sales &
              Marketing.
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <FirstButton buttonText="View More" className="btn" />
        </div>
      </div>

      <div
        style={{ backgroundColor: "#fff", marginBottom: "0" }}
        className="faq"
      >
        <div className="faq_container">
          <h1 className="faq_primary light-dark">Company FAQ</h1>
          <div className="questions">
            <div className="question_container--1">
              <div className="question1">
                <h1 className="question light-dark">
                  Is it separable for cleaning?{" "}
                </h1>
                <p className="answer light-dark">
                  Yes, both the inner silicone and the mouth plug can be easily
                  separated from the body for more thorough cleaning.{" "}
                </p>
              </div>
              <div className="question2">
                <h1 className="question light-dark">
                  What kind of material is used for the inner silicone?{" "}
                </h1>
                <p className="answer light-dark">
                  We have chosen to go with medical grade anti-microbial
                  silicone. This is to ensure that the ‘plasticky’ taste that
                  many are familiar with in reusable silicone cups, is
                  eliminated.{" "}
                </p>
              </div>
            </div>

            <div className="question_container--2">
              <div className="question1 ">
                <h1 className="question light-dark">
                  Can I mix and match the colours if I pledge for more than one?
                </h1>
                <p className="answer light-dark">
                  Sure, after the pledge has been made, there will be a survey
                  sent out so you can make the desired selections.
                </p>
              </div>
              <div className="question2">
                <h1 className="question light-dark">
                  How do you adjust the height?
                </h1>
                <p className="answer light-dark">
                  The height is adjusted by simply holding the section you would
                  like to collapse to with one hand, and then twisting the base
                  with the other.{" "}
                </p>
              </div>
            </div>
          </div>
          <FirstButton
            className="btn"
            buttonText="View More"
            marginTop="5rem"
          />
        </div>
      </div>
      <div className="pitch">
        <div className="pitch-container">
          <h1 className="brands_primary">Risks and disclosures</h1>

          {risklist.map((item, index) => (
            <div
              key={index}
              style={{
                color: "#070908",
                display: "flex",
                alignItems: "center",
              }}
              className="tile_secondary heighlights_text"
            >
              <span className="heighlight_point">{item.number}</span>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="bottom_status">
        <div className="bottom_cont1">
          <FirstButton
            buttonText="Get Perks"
            className="btn"
            onClick={() => naviagte("/investment/donatemainperk")}
          />
        </div>
      </div>
    </>
  );
};

export default Pitch;

const risklist = [
  {
    number: 1,
    text: "Our success is primarily dependent on Stakeholder acceptance, which is extremely difficult to predict and, therefore, inherently risky.",
  },
  {
    number: 2,
    text: "We principally plan presently to operate in one business, and our lack of a diversified business could adversely affect us. ",
  },
  {
    number: 3,
    text: "We principally plan presently to operate in one business, and our lack of a diversified business could adversely affect us. ",
  },
];
