import React from "react";
import Select from "react-select";

const colourStyles = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    background: "#f5f5f5",
    fontSize: "1.3rem",
    fontWeight: "500",
    padding: ".6rem 0",
  }),

  menuList: (styles) => ({
    ...styles,
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "0 1rem 2rem rgba(0,0,0,.1)",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    fontSize: "1.3rem",
    background: isFocused ? "#19976A" : isSelected ? "#fff" : undefined,
    zIndex: 1,
    color: isFocused ? "#fff" : isSelected ? "#000" : undefined,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
};

export default function Dropdown({ MenuList, placeholder }) {
  return (
    <Select
      name="filter"
      options={MenuList}
      className="basic-multi-select"
      classNamePrefix="select"
      styles={colourStyles}
      placeholder={placeholder}
    />
  );
}
