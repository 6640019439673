import React from "react";
import Sidebar from "./Sidebar";
import FirstButton from "../../../common/button";
import TextField from "@mui/material/TextField";
import TextEditor from "../../../common/TextEditor";
import Scrolltotop from "../../../assets/images/oicons/svg/scrollup.svg";
import { useNavigate } from "react-router-dom";
const TechOfferDetails = () => {
  const navigate = useNavigate();
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton
              buttonText="Save and Exit"
              className="borderbtn"
              onClick={() => navigate("/marketplace/marketplaceactivity")}
            />
          </div>
          <div>
            <h1 className="tnbd_h1">Details of Offer</h1>
            {/* <p className='tnbd_f3'>How you describe your need is as crucial as what you are seeking..</p> */}
          </div>
          <div className="tnbd_cont">
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">About the Offer</h4>
              <p className="tnbd_f2">
                Out come :what your need is about and which solution you expect
                to get from stakeholders
                <br />
                Criteria : What is specific advice on judging Whether a
                proposal is innovative or has potential?{" "}
              </p>

              <TextEditor />

              <FirstButton
                buttonText="Add more Heading +"
                className="borderbtn"
                width="100%"
                borderRadius="25rem"
                marginTop="2rem"
              />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Desired outcome</h4>
              <p className="tnbd_f2">
                Out come : what your need is about and which solution you expect
                to get from stakeholders
              </p>
              <TextEditor />
              <FirstButton
                buttonText="Add more Heading +"
                className="borderbtn"
                width="100%"
                borderRadius="25rem"
                marginTop="2rem"
              />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Evaluation Criteria</h4>
              <p className="tnbd_f2">
                Criteria : What is specific advice on judging Whether a
                proposal is innovative or has potential?{" "}
              </p>
              <TextEditor />
              <FirstButton
                buttonText="Add more Heading +"
                className="borderbtn"
                width="100%"
                borderRadius="25rem"
                marginTop="2rem"
              />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Response sheet format.</h4>
              {/* <p className='tnbd_f2'>Upload the file in a Microsoft word format. </p> */}

              <TextField
                id="outlined-basic"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                style={{
                  marginTop: "1rem",
                  background: "#F5F5F5",
                  border: "none !imporatant",
                  outline: "none !important",
                  width: "100%",
                }}
              />
              <FirstButton
                buttonText="Add more Heading +"
                className="borderbtn"
                width="100%"
                borderRadius="25rem"
                marginTop="2rem"
              />
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/marketplace/techofferbasicdetails")}
        />
        <FirstButton
          buttonText="Save and next"
          className="btn"
          onClick={() => navigate("/marketplace/techofferaboutus")}
        />
      </div>
    </div>
  );
};

export default TechOfferDetails;
