import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import FirstButton from "../../../../common/button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ex from "../../../../assets/images/logo/ex1.png";
import Dropdown from "../../../../common/dropdown";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const DeleteModal = (props) => {
  const navigate = useNavigate()
  const name = localStorage.getItem("name");
  const logout = () => {
    localStorage.removeItem("name")
    localStorage.removeItem("role")
    localStorage.removeItem("email")
    navigate("/")
    window.location.reload()

  }
  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mdpd_modal" style={{ width: "51%" }}>
          <div className="mdpd_modalbtn">
            {" "}
            <button className="mdpd_close" onClick={props.close}>
              <CloseIcon />
            </button>
          </div>
          <div style={{ textAlign: "center" }}>
            <img src={ex} />
          </div>
          <div className="deleletModalMain">
            <h1 className="delete_h1">
              {name}, We’re Sorry to see you go.{" "}
            </h1>
            <div className="mdpd_cont2">
              <p className="delete_p">Why do you want to delete your account?</p>
              <Dropdown placeholder="Select..." MenuList={list} />
              <p className="addwatchmodal_cont">
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "green",
                        "&.Mui-checked": { color: "green" },
                        "& .MuiSvgIcon-root": { fontSize: 24 },
                      }}

                    />
                  }
                  labelPlacement="end"
                  label={
                    <p style={{ fontSize: "1.6rem !important", padding: "1rem 0 !important" }}>
                      I understand that Deteted account can’t be  retrieved and
                      its irreversible.
                    </p>
                  }
                />
              </p>
            </div>

            <div className="deletemodal_btncont">
              <FirstButton
                buttonText="Cancel"
                onClick={props.close}
                className="borderbtn"
              />
              <FirstButton
                buttonText="Delete Account"
                onClick={logout}
                className="btn"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
const list = [
  { value: 1, label: "I'm concerned about my personal data. " },
  { value: 2, label: "I get too many notifications from Smaarket." },
  { value: 3, label: " I want to change my Smaarket account email address. " },
  { value: 4, label: "I have multiple accounts. " },
  { value: 5, label: "I just don't need Smaarket any more. " },
  { value: 6, label: "I want different products and services from Smaarket." },
];
