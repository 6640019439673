import React from "react";

import ProgressBar from "../progressBar/Progress";
import { useNavigate } from "react-router-dom";
import Img from "../../assets/images/img/ifia.png";
import Img1 from "../../assets/images/logo/check.png";

const InvestmentCard = (props) => {
  const data = props.data;
  const onClick = props.onClick;
  const navigate = useNavigate();
  const type = props.type;

  return (
    <div className="cards_investment" onClick={onClick}>
      <div className="cards_content">
        <div className="card_thumbnail">
          <img className="card_img" src={data.img} alt="" />
        </div>
        <div className="card_logo">
          <img className="card_logo--comp" src={data.img2} alt="" />
        </div>
        <div className="card_head">
          <h1 className="card_primary">{data.title}</h1>
          <div className="card_imgcont">
            <img src={Img} />
            <img src={Img1} />
          </div>
        </div>
        <p className="card_secondary card_mr">{data.description}</p>
        <div className="tags tags_height">
          {data.tags.map((item, index) => (
            <div className="tag" key={index}>
              <p className="tag_label" style={{ textTransform: "capitalize" }}>{item}</p>
            </div>
          ))}
        </div>
        {type === "upcoming" ? <></> : <ProgressBar data={data.progressdata} />}
        {type === "upcoming" ? (
          <p style={{ marginTop: "2rem" }} className="final_data--heading">
            15 days to go
          </p>
        ) : (
          <div className="final_data_container">
            <div className="final_data">
              <p className="final_data--heading">
                {type === "donation" || type === "complete" ? "Amount raised" : "Min Amount"}{" "}
              </p>
              <h1 className="final_data--sub">{data.minamount}</h1>
            </div>
            {type === "complete" ? (
              <></>
            ) : (
              <div className="final_data">
                <p className="final_data--heading"> Target</p>
                <h1 className="final_data--sub">{data.target}</h1>
              </div>
            )}

            <div className="final_data">
              <p className="final_data--heading"> Investor</p>
              <h1 className="final_data--sub">{data.investors}</h1>
            </div>
          </div>
        )}
      </div>
      {type === "complete" ? (
        <></>
      ) : (
        <div className="investmentCard_bottom">
          {data.title === "Hugg" ? < p className="seeking"
          >Seeking Donation</p> : <p className="seeking">Seeking Investment</p>}
        </div>
      )
      }
    </div >
  );
};

export default InvestmentCard;
