import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import { useNavigate } from "react-router-dom";
import Scrolltotop from "../../../../assets/images/oicons/svg/scrollup.svg";

const MainDonationAnalytics = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/maincampdonations/finish");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />

        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Analytics and Marketing</h4>
          <div className="cfp_wd1">
            <div>
              <p className="reoffcard_p1">Google Analytics</p>
              <p className="signupready_p5">
                Enable Google Analytics to gain more insight about your campaign
                and contributions. To get started, set up a Universal Analytics
                Property in Google Analytics. Learn more about it here.
              </p>
              <p className="cfp_p">Tracking ID</p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
            </div>
            <div>
              <p className="reoffcard_p1">Google Ad Tracking</p>
              <p className="signupready_p5">
                Setup Google Adwords to better market your campaign to visitors
                and contributors. Learn more about using Google Adwords.
              </p>
              <p className="cfp_p">Conversion ID</p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
              <p className="cfp_p">Conversion Label</p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
              <p className="cfp_p">Remarketing ID</p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
            </div>
            <div>
              <p className="reoffcard_p1">Facebook Ad Tracking</p>
              <p className="signupready_p5">
                Setup Facebook Ads to better market your campaign to visitors
                and contributors. To make the most of Facebook ads on Indiegogo,
                please follow this step-by-step guide.
              </p>
              <p className="signupready_p5">
                Important: In the Facebook-Verified Domain field, please do not
                input prefixes, subdomains, and any suffixes after the '.com'.
                Correct format example: yourdomain.com.
              </p>
              <p className="cfp_p">Facebook Pixel ID</p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
              <p className="cfp_p">Facebook-Verified Domain</p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
              <p className="cfp_p">Remarketing ID</p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/maincampdonations/financials")}
        />
        <FirstButton
          buttonText="Save and next"
          onClick={handleSubmit}
          className="btn"
        />
      </div>
    </div>
  );
};

export default MainDonationAnalytics;
