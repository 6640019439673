import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import Pitch from "./tabViewContents/Pitch";
import About from "./tabViewContents/About";
import Discussion from "./tabViewContents/Discussion";
import Analytics from "./tabViewContents/Analytics";
import CompFinancials from "./tabViewContents/CompFinancial";
import Restricted from "../tabsView/tabViewContents/Restricted";
const DetailTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const role = localStorage.getItem("role");
  const handleTabChange = async (e, value) => {
    setTabIndex(value);
  };
  return (
    <>
      <div className="detailsTabs">
        <div className="detailTabs_container">
          <div className="borderTab">
            <Tabs
              variant="fullWidth"
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#19976A",
                },
              }}
            >
              {tabList.map((item, ind) => (
                <Tab
                  style={{
                    fontSize: "1.25rem",
                    color: `${tabIndex === ind ? "#19976A" : "black"}`,
                    fontWeight: `${tabIndex === ind ? "bold" : 400}`,
                  }}
                  value={ind}
                  label={item}
                  key={ind}
                />
              ))}
            </Tabs>
          </div>
        </div>
        {tabIndex === 0 && <Pitch />}
        {tabIndex === 1 && (
          //  role ==="investor"?<About/>:<Restricted/>
          <></>
        )}
        {tabIndex === 2 && (
          //  role==="investor"?<CompFinancials/>:<Restricted/>
          <></>
        )}
        {tabIndex === 3 && (
          // role==="investor"? <Analytics/>:<Restricted/>
          <></>
        )}
        {tabIndex === 4 && <Discussion />}
      </div>
    </>
  );
};
const tabList = [
  "Pitch",
  "About Us",
  "Financials",
  "Smaarket Analytics",
  "Discussion",
];
export default DetailTabs;
