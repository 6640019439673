import React from 'react'
import { Link,NavLink, useLocation, useParams} from 'react-router-dom';
import {Nav }from 'react-bootstrap';

const Sidebar = () => {
	const {pathname}=useLocation()
	const splitLocation = pathname.split("/");
  return (
    <div className='sidebar_main'>
		<div className='sidebar_cont1'>
	<nav className='nav-wd' >
					
					<div className='nav_cont'>
						<div className={splitLocation[2] === "basic" ? "active" : "sidebarNav_item"}> 
							<Link to="/precampaigncreation/basic">Basic</Link>
						</div>

							<div className={splitLocation[2] === "projectdetails"  ? "active" : "sidebarNav_item"}> 
							  <Link to="/precampaigncreation/projectdetails">Project Details</Link>
							</div>

							<div className={splitLocation[2] === "externallinks" ? "active" : "sidebarNav_item"}> 
							  <Link to="/precampaigncreation/externallinks">External Links</Link>
							</div>

			<div className={splitLocation[2] === "faq" ? "active" : "sidebarNav_item"}> 
							  <Link to="/precampaigncreation/faq">FAQ</Link>
							</div>

			<div className={splitLocation[2] === "finish" ? "active" : "sidebarNav_item"}> 
							  <Link to="/precampaigncreation/finish">Finish</Link>
							</div>

					</div>
</nav>
</div>
     
    </div>
  )
}

export default Sidebar