import React, { useState } from "react";
import Sidebar from "../SidebarforPrecamp/SidebarPreCamp";
import FirstButton from "../../../../common/button";
import Delete from "../../../../assets/images/oicons/svg/deleteIcon.svg";

import { useNavigate } from "react-router";
const Faq = () => {
  const [add, setadd] = useState([]);
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const Menulist = [
    "Shubham",
    "Nayan",
    "Sachin",
    "Umesh",
    "Sahnkar",
    "Dhanush",
  ];

  const handleClick = () => {
    navigate("/precampaigncreation/finish");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save as Draft" className="borderbtn" />
          </div>
          <h4 className="market_h4 ex_mr">FAQs</h4>
          <p class="cfp_p ex_mr1">About your business and campaign</p>
          <div className="cfp_wd">
            <div>
              <div>
                <div className="precampfaq_cont">
                  <p>FAQ 1</p>
                  <img src={Delete} alt="" />
                </div>
                <div className="">
                  <p className="precampfaq_cont1">
                    Do you offer any guarantees or returns ? 
                  </p>
                  <p className="precampfaq_cont1">
                    No, we do not guarantee any returns. 
                  </p>
                </div>
              </div>
              <div>
                <div className="precampfaq_cont">
                  <p>FAQ 2</p>
                  <img src={Delete} alt="" />
                </div>
                <div className="">
                  <p className="precampfaq_cont1">
                    Does your business intend to stay private, plan to IPO, or
                    hope to be acquired? 
                  </p>
                  <p className="precampfaq_cont1">
                    We are open to all scenarios for an exit and hope to
                    maximize the opportunity for our investor when the situation
                    arises. 
                  </p>
                </div>
              </div>
            </div>

            <div>
              <FirstButton
                className="borderbtn"
                buttonText="Add +"
                width="100%"
                borderRadius="5rem"
              />
            </div>
            {/* <Dropdown MenuList={Menulist}/> */}
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/precampaigncreation/externallinks")}
        />
        <FirstButton
          buttonText="Save and next"
          onClick={handleClick}
          className="btn"
        />
      </div>
    </div>
  );
};

export default Faq;
