import React, { useState } from "react";
import FirstButton from "../../common/button";
import { Link, useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { GoogleLogin } from "react-google-login";
import validtick from "../../assets/images/oicons/svg/validtick.svg"
import { role } from "../utils";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (email === role[0].email) {
      localStorage.setItem("email", email);
      localStorage.setItem("role", role[0].role);
      localStorage.setItem("name", role[0].name);

      navigate("/raise-capital");
    } else if (email === role[1].email) {
      localStorage.setItem("email", email);
      localStorage.setItem("role", role[1].role);
      localStorage.setItem("name", role[1].name);

      navigate("/explore-startups");
    } else if (email === role[2].email) {
      localStorage.setItem("email", email);
      localStorage.setItem("role", role[2].role);
      localStorage.setItem("name", role[2].name);

      navigate("/market-place");
    } else if (email === role[3].email) {
      localStorage.setItem("email", email);
      localStorage.setItem("role", role[3].role);
      localStorage.setItem("name", role[3].name);
      navigate("/admin");
    }

    navigate("/verifyemail");
    window.location.reload();
  };
  const handleChange = (event) => {
    // const name=event.tareget.name
    const value = event.target.value;
    setEmail(value);
  };

  return (
    <div className="login_main">
      <div className="login_heading">
        Create a diverse, vetted startup portfolio right away
      </div>
      <div className="login_body">
        <GoogleLogin
          buttonText="Sign up with Google"
          className="login_button sgb_mr"
        />
        <Divider className="login_divider pr">or sign up with</Divider>
        <div>
          <p className="login_para">
            <label> Email ID</label>
          </p>
          <input
            className="login_input"
            value={email}
            name="email"
            onChange={handleChange}
            placeholder="Enter Your Email Id"
            id="outlined-basic"
            label="Email ID"
            variant="outlined"
          />
        </div>
        <div>
          <p className="login_para">
            <label htmlFor=""> Password</label>
          </p>
          <input
            className="login_input"
            id="outlined-basic"
            label="Password"
            placeholder="Enter Your Password"
            variant="outlined"
          />
           <div className="validcont">
            <div className="validcont2">
            <img src={validtick} alt=""/>
            <span className="validfont">UpperCase Letters</span>
            </div>
            <div className="validcont2">
            <img src={validtick} alt=""/>
            <span className="validfont">Characters </span>
            </div>
            <div className="validcont2" >
            <img src={validtick} alt=""/>
            <span className="validfont">Lowercase Letter </span>
            </div>
          </div>
        </div>

        <div>
          <p
            className="login_last_para signup_mr"
            style={{ paddingBottom: "0", margin: "0" }}
          >
            by clicking "sign up" I agree toSmaarkets's terms of use and privacy
            policy
          </p>
          <FirstButton
            className="btn"
            buttonText="Sign Up"
            onClick={handleSubmit}
            marginTop="3rem"
            width="100%"
          />
          <p className="login_last_para">
            Already registered ?
            <span>
              <Link to="/login" className="login_link">
                {" "}
                Login
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
