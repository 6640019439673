import React from 'react';
import SearchResults from '../components/InvestmentDonations/SeachPage/SearchResults';
const Search = ()=>{
  return (
    <>
    <SearchResults/>
    </>
  )
}
 
export default Search