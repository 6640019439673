import React,{useState} from 'react'
import {Link} from "react-router-dom"
import Sidebar from './Sidebar'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';

const tablist=["Investor Faq", "Founders Faq", "Donor Faq", "Marketplace Faq"]
const FaqSearch = () => {
    const [tabIndex,setTabIndex]=useState(0)

    const handleChange=(e,value)=>{
      setTabIndex(value)

    }
  return (
    <div className='dflex '>
   
        <Sidebar/>

        <div className='w70 p132'>
            <h1 className='faq_f1 faq_mr'> FAQs</h1>
            <div className='faq_mr'>
                <h2 className='faq_f2 faq_mr1'>How can we help you?</h2>
                <input className='login_input' placeholder="&#xF002; Search" />
                </div>
                <div className='faq_mrt3'>
                    <Link className='faq_f3 faq_f600'> Back to Faq</Link>
                    <h1 className='feedback_f1 faq_mr faq_f600'>Search results</h1>
                    <div>
                      
                        {question.map((item,index)=>(
    <Accordion style={{ boxShadow: "none",border:"none",padding:"0" }} key={index}>

     
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:"#19976A",fontSize:"1.6rem"}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{padding:"0 !important",border:"0 !important"}}
        >
            <p  className="faq_f3 faq_f600">{item.ques}</p>
            </AccordionSummary>
         
            <AccordionDetails>

            </AccordionDetails>
         
      </Accordion>
          ))}
                    </div>
                </div>


        </div>
    </div>
  )
}

export default FaqSearch

const question=[
    {ques:"Luctus venenatis, lectus magna fringilla urna, porttitor ?"},
    {ques:"Purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor ?"},
    {ques:"Lorem ipsum dolor sit amet, consectetur adipiscing elitlectus magna fringilla urna, porttitor ?"},
    {ques:"Lorem ipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magn, porttitor  ?"},
    {ques:"Lorem ipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magn, porttitor  ?"},
    {ques:"Lorem ipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magn, porttitor  ?"},
    {ques:"Lorem ipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magn, porttitor  ?"},
    {ques:"Lorem ipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magn, porttitor  ?"},
]