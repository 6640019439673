import React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const Table = () => {
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: "#FF0000",
          },
        },
        MUIDataTablePagination: {
          root: {
            backgroundColor: "#000",
            color: "#fff",
          },
        },
      },
    });
  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={"Activities"}
        data={data}
        columns={columns}
        options={{
          selectableRows: false,
          responsive: "scrollMaxHeight",
        }}
      />
    </ThemeProvider>
  );
};

const columns = [
  {
    name: "id",
    label: "Slno",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { minWidth: "20px", maxWidth: "20px" } }),
      customBodyRenderLite: (dataIndex) => {
        return (
          <span style={{ fontSize: "1.6rem" }}>{data[dataIndex]?.id}</span>
        );
      },
    },
  },
  {
    name: "company",
    label: "Title",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { minWidth: "80px", maxWidth: "80px" } }),
      customBodyRenderLite: (dataIndex) => {
        return (
          <span style={{ fontSize: "1.6rem" }}>{data[dataIndex]?.company}</span>
        );
      },
    },
  },
  // {
  //   name: "city",
  //   label: "Startup",
  //   options: {
  //     filter: true,
  //     sort: false,
  //     customBodyRenderLite: (dataIndex) => {
  //       return (
  //         <span style={{ fontSize: "1.6rem" }}>{data[dataIndex]?.city}</span>
  //       );
  //     },
  //   },
  // },
  {
    name: "type",
    label: "Type",
    options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <span style={{ fontSize: "1.6rem" }}>{data[dataIndex]?.type}</span>
        );
      },
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: true,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <span style={{ fontSize: "1.6rem" }}>{data[dataIndex]?.date}</span>
        );
      },
    },
  },
];

const data = [
  {
    id: "1",
    name: "Joe James",
    company: "Vertical axis wind turbine for slow and changing wind speed",
    city: "Yonkers",
    state: "NY",
    date: "12/02/2022",
    type: "Tech Need",
  },
  {
    id: "2",
    name: "John Walsh",
    company: "Wheel slip ",
    city: "Hartford",
    state: "CT",
    date: "10-04- 2022",
    type: "Tech offer",
  },
  {
    id: "3",
    name: "Bob Herm",
    company: "Green Power",
    city: "Tampa",
    state: "FL",
    date: "29-07-2022",
    type: "Reserch Need",
  },
  {
    id: "4",
    name: "James Houston",
    company: "Farm Minds",
    city: "Dallas",
    state: "TX",
    date: "12/02/2022",
    type: "No Perks",
  },
];

const options = {
  filterType: "checkbox",
  responsive: "scrollMaxHeight",
};
