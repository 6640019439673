import React from "react";
import { useNavigate } from "react-router-dom";
import FirstButton from "../../../common/button";

const FundRaiseFAQ = ()=>{
  const navigate=useNavigate()
  return (
    <>
    
    <div className="faq">
      <div className="faq_container">
        <h1 className="faq_primary">Fund Raising FAQ</h1>
        <div className="questions">
          <div className="question_container--1">
            <div className="question1">
              <h1 className="question">What is Smaarket?</h1>
              <p className="answer">There is no better location to begin the search for fascinating and creative businesses and inventions that surprise and amaze with live crowdfunding campaigns and a marketplace.</p>
            </div>
            <div className="question2">
              <h1 className="question">How are you better than your competitors?</h1>
              <p className="answer">We deliver more money, faster. We're the largest funding portal by amount raised, number of companies funded, number of investors, and most follow-on financing by venture capitalists.</p>
            </div>
          </div>

          <div className="question_container--2">
            <div className="question1">
              <h1 className="question">Why raise funds from the crowd?</h1>
              <p className="answer">Make your most loyal customers owners. They are often your most passionate evangelists. Investors have a financial interest in helping your business succeed. For example, if you have an EVbike facility and your customer is also an investor, she will most likely purchase one or more and bring her friends with her.</p>
            </div>
            <div className="question2">
              <h1 className="question">Besides money, how else do you help?</h1>
              <p className="answer">Smaarket created the X to help founders get off the ground and succeed, by immersing them in a community of other founders. X is now a separate company, but all smaarket alumni are members who get: Access to an online community of other founders and mentors Help with fundraising after Smaarket. Invites to events and workshops Review of Accelerator Program Applications</p>
            </div>
          </div>
        </div>
        <FirstButton className="whitebtn" buttonText="Read More" marginTop="5rem" onClick={()=>navigate("/help/faq")}/>
      </div>
    </div>
    </>
  )
}

export default FundRaiseFAQ