import React from "react";
import Img1 from "../../../../assets/images/img/investcard2.png";
import Img from "../../../../assets/images/img/investcard1.png";
import Img2 from "../../../../assets/images/img/investcard3.png";
import Img3 from "../../../../assets/images/img/investcard4.png";
import Img4 from "../../../../assets/images/img/investcard5.png";
import Img5 from "../../../../assets/images/img/investcard6.png";
import Img6 from "../../../../assets/images/img/investcard7.png";
import Img7 from "../../../../assets/images/img/investcard8.png";
import Img8 from "../../../../assets/images/img/investcard9.png";
import Img9 from "../../../../assets/images/img/investcard10.png";
import Img10 from "../../../../assets/images/img/investcard11.png";
import Img11 from "../../../../assets/images/img/investcard12.png";
import Imgsmall1 from "../../../../assets/images/img/investsmall1.png";
import Imgsmall2 from "../../../../assets/images/img/investsmall2.png";
import Imgsmall3 from "../../../../assets/images/img/investsmall3.png";
import Imgsmall4 from "../../../../assets/images/img/investsmall4.png";
import Imgsmall5 from "../../../../assets/images/img/investsmall5.png";
import Imgsmall6 from "../../../../assets/images/img/investsmall6.png";
import Imgsmall7 from "../../../../assets/images/img/investsmall7.png";
import Imgsmall8 from "../../../../assets/images/img/investsmall8.png";
import Imgsmall9 from "../../../../assets/images/img/investsmall9.png";
import Imgsmall10 from "../../../../assets/images/img/investsmall10.png";
import Imgsmall11 from "../../../../assets/images/img/investsmall11.png";
import Imgsmall12 from "../../../../assets/images/img/investsmall12.png";
import InvestmentCard from "../../../../common/cards/InvestmentCard";
import FirstButton from "../../../../common/button";
import SearchFilter from "./SearchFilter";
import { useNavigate } from "react-router-dom";
const All = () => {
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    if (id === cardData[0].id) {
      navigate("/investment/investdetails");
    } else if ((id = cardData[1].id)) {
      navigate("/investment/donatedetail");
    }
  };
  return (
    <div>
      <div className="tabContent">
        <h1 className="tab_heading">Active Deals</h1>
        <p className="tab_subHeading">
          Browse current Acitve opportunities on Smaarket. All companies are
          Vetted & Pass Due diligence.
        </p>
      </div>
      <div className="search-filter">
        <SearchFilter />
      </div>
      <div className="adaall_main">
        {cardData.map((item, index) => (
          <InvestmentCard
            data={item}
            type="donations"
            key={index}
            onClick={() => handleNavigate(item.id)}
          />
        ))}
      </div>
      <div className="mdp_cont adall_btnmr">
        <FirstButton buttonText="View More" marginTop="2rem" className="btn" />
      </div>
      <div>
        <div className="tabContent">
          <h1 className="tab_heading">Upcoming Deals</h1>
        </div>
        <div className="search-filter">{/* <SearchFilter/> */}</div>
        <div className="adaall_main">
          {cardDataupcoming.map((item, index) => (
            <InvestmentCard data={item} type="upcoming" key={index} />
          ))}
        </div>
        <div className="mdp_cont adall_btnmr">
          <FirstButton buttonText="See all" marginTop="2rem" className="btn" onClick={() => navigate("/investment/upcomingdeals")} />
        </div>
      </div>
    </div>
  );
};

export default All;
const cardData = [
  {
    id: 1,
    img: Img,
    img2: Imgsmall1,
    title: "SAMAI",
    description: "Patented, single-use alternative: edible, compostable cups",
    tags: ["Sustainable", "Women founder", "CCD", "Bio-Technology","Food"],
    progressdata: {
      amount: "",
      percentage: 44.56,
      status: 40,
      duration: "",
      value: "40",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "20,000",
    target: "1,25,50,000",
    investors: "23",
  },
  {
    id: 2,
    img: Img1,
    img2: Imgsmall2,
    title: "Hugg",
    description:
      "The pocket friendly insulated mug.Up to 9 height options, pocket friendly, and climate positive.",
    tags: ["Food and Beverage", "B2C", "Donation"],
    progressdata: {
      amount: "",
      percentage: 106.5,
      status: 15,
      duration: "",
      value: "10",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "15,000",
    target: "50,00,000",
    investors: "78",
  },
  {
    img: Img2,
    img2: Imgsmall3,
    title: "ZYSTEM 3E",
    description:
      "Building has never been easier. The only dry-stack wall construction system in the world.",
    tags: ["Construction", "Technology", "CCD", "Venture Funded", "B2B"],
    progressdata: {
      amount: "",
      percentage: 71.92,
      status: 19,
      duration: "",
      value: "70",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "35,000",
    target: "35,000",
    investors: "70",
  },
  {
    img: Img3,
    img2: Imgsmall4,
    title: "Bally Robotics",
    description:
      "Democratizing robotics with affordable, extensible, no-code robotic arms",
    tags: ["Robotics", "Automation", "CSOP", "AI", "Technology"],
    progressdata: {
      amount: "",
      percentage: 153.5,
      status: 9,
      duration: "",
      value: "50",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "20,000",
    target: "50,00,000",
    investors: "78",
  },
  {
    img: Img4,
    img2: Imgsmall5,
    title: "Lamp Innovation",
    description:
      "Distributed solar power — for the billions who live where the grid has failed.",
    tags: ["Green Power", "Renewable ", "CCD", "Solar Energy", "Energy"],
    progressdata: {
      amount: "",
      percentage: 60,
      status: 52,
      duration: "",
      value: "60",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "10,000",
    target: "45,70,000",
    investors: "78",
  },
  {
    img: Img5,
    img2: Imgsmall6,
    title: "IUNA",
    description:
      "Science-backed, plant-based nutraceuticals | The future of wellness",
    tags: ["Health and wellness", "Cosmetics", "B2C", "CSOP"],
    progressdata: {
      amount: "",
      percentage: 75,
      status: 35,
      duration: "",
      value: "70",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "30,000",
    target: "2,25,00,000",
    investors: "78",
  },
  {
    img: Img6,
    img2: Imgsmall7,
    title: "Yuncooked",
    description: "The un-restaurant: an omnichannel plant-based food company",
    tags: ["Food", "Women  Founder", "CCD", "B2C"],
    progressdata: {
      amount: "",
      percentage: 68,
      status: 26,
      duration: "",
      value: "80",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "15,000",
    target: "2,25,00,000",
    investors: "78",
  },
  {
    img: Img7,
    img2: Imgsmall8,
    title: "Huc Labs",
    description:
      "Digitally recovering resources from waste, making ecological vitality profitable",
    tags: ["Waste Management", "Material", "CCD"],
    progressdata: {
      amount: "",
      percentage: 115,
      status: 2,
      duration: "",
      value: "50",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "15,000",
    target: "1,00,00,000",
    investors: "78",
  },
  {
    img: Img8,
    img2: Imgsmall9,
    title: "Yourworld",
    description:
      "Plant-based baking mixes reimagined with hidden fruits and veggies",
    tags: ["Food", "Plant Based", "CSOP", "B2C"],
    progressdata: {
      amount: "",
      percentage: 82,
      status: 38,
      duration: "",
      value: "80",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "20,000",
    target: "50,50,000",
    investors: "78",
  },
];
const cardDataupcoming = [
  {
    img: Img9,
    img2: Imgsmall10,
    title: "Reyght charger",
    description:
      "Rightcharge helps electric car drivers choose the right charger, connect with the right installer and switch to the right energy tariff",
    tags: ["Energy", "Electric vehicle", "CCD", "Sustainability", "Women founder"],
  },
  {
    img: Img10,
    img2: Imgsmall11,
    title: "Salvatech",
    description:
      "Salvtech’s award-winning eco-friendly water technology enables farmers to grow more, save water and improve soil health.",
    tags: ["Technology", "Water", "CSOP", "Venture Funded", "Sustainability"],
  },
  {
    img: Img11,
    img2: Imgsmall12,
    title: "Hydro one",
    description:
      "Game changing innovations in offshore wind power, energy storage and water desalination",
    tags: ["Energy", "Climate Tech", "CSOP", "B2B", "Venture Funded"],
  },
];
