import React from "react";
import { useNavigate } from "react-router-dom";
import img from "../../../assets/images/icons/market2.svg";
import FirstButton from "../../../common/button";
const MarketPlaceStory = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="frs_main">
        <div className="story_header">
          <h1 className="story_heading">
            Meet your next collaborator.
            <br />
            Be <span style={{ color: "#19976A" }}>Premium </span> plus. <br />A
            tailored funnel to reach your target.{" "}
          </h1>
        </div>

        <div className="frs_cont1">
          <div className="story_content story_width">
            <p style={{ fontWeight: "700" }} className="story_text">
              One membership, many exclusive privileges{" "}
            </p>
            <p className="story_text">
              <ul className="osc_list">
                <li>Post Maximum Technology Needs </li>
                <li>Posting Maximum Technology Offers </li>
                <li>Dedicated relationship manager </li>
                <li>Email and Social media promotion</li>
              </ul>
            </p>
            <p className="story_text">
              Whether your needs are large or small, we're here to help you
              success.
            </p>

            <FirstButton
              marginTop={"5rem"}
              className="whitebtn"
              buttonText=" Get Premium Plus"
              onClick={() => navigate("/marketplace/premium")}
            />
          </div>
          <div className="story_imgecont ">
            <img src={img} className="story_img--image w70" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketPlaceStory;
