import React, { useState } from "react";
import Firstbutton from "../../../common/button";
import Eyeuity from "../../../assets/images/img/Eyeuity.png";
import share from "../../../assets/images/icons/share.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import Details from "./TabContent/Details";
import AddwatchlistModal from "./AddwatchlistModal";
import Discussion from "./TabContent/Discussion";
import Restricted from "../Restricted";

const tabList = ["Details", "Discussions"];

const SingleCampPage = () => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [tabIndex, setTabIndex] = useState(0);
  const [show, setShow] = useState(false);
  const handleTabChange = async (e, value) => {
    setTabIndex(value);
  };
  const handelSubmit = () => {
    navigate("/signup");
  };
  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <div className="scp_main">
        <div className="scp_cont">
          <div>
            <img src={Eyeuity} alt="" className="scp_img" />
          </div>
          <div className="scp_cont3">
            <div className="scp_cont1">
              <div>
                <h1 className="scp_h1">Eyeuity</h1>
              </div>
              <div className="scp_cont2">
                <button className="share_btn">
                  <img src={share} />
                </button>
                <Firstbutton
                  buttonText="Add to watchlist"
                  className="btn"
                  onClick={handleOpen}
                />
              </div>
            </div>
            <div className="scp_con3">
              <Link className="scp_link">.eyeuitycom</Link>
              <div className="comp_social">
                <i className="fa-brands fa-twitter scp_btncont"></i>
                <i className="fa-brands fa-linkedin-in  scp_btncont"></i>
                <i className="fa-brands fa-google scp_btncont"></i>
                <i className="fa-brands fa-facebook-f  scp_btncont"></i>
              </div>
            </div>
            <div className="scp_cont4">
              <p className="scp_p2">MedTech</p>
              <p className="scp_p2">B2C</p>
              <p className="scp_p2">CCD</p>
            </div>
            <p className="scp_p">
              Based on a simple scan of the eye, Occuity is developing handheld,
              non-invasive screening and monitoring solutions for diseases such
              as myopia, diabetes, and Alzheimer’s - some of the greatest global
              healthcare challenges of our time.
            </p>
            <p className="scp_p1">22 Days untill the campaign goes live</p>
          </div>
        </div>
        <div className="scp_cont5">
          <div className="borderTab">
            <Tabs
              variant="fullWidth"
              onChange={handleTabChange}
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#19976A",
                  height: 3,
                },
              }}
            >
              {tabList.map((item, index) => (
                <Tab
                  style={{
                    fontSize: "1.6rem",
                    color: `${tabIndex === index ? "black" : "black"}`,
                    fontWeight: `${tabIndex === index ? "bold" : 400}`,
                    textTransform: "none",
                    fontFamily: "Montserrat",
                  }}
                  value={index}
                  label={item}
                  key={index}
                />
              ))}
            </Tabs>
          </div>
        </div>
        {tabIndex === 0 && (
          <div>
            <Details />
          </div>
        )}
        {tabIndex === 1 &&
          (role === "investor" ? <Discussion /> : <Restricted />)}
      </div>
      <div className="scpdetail_cont3">
        <p className="brand_name">
          22 Days untill the campaign goes live, Get updates on the campaign
        </p>
        {role === "investor" ? (
          <Firstbutton
            buttonText="Add a watchlist"
            className="btn"
            onClick={handleOpen}
          />
        ) : (
          <Firstbutton
            buttonText="Signup"
            className="btn"
            onClick={handelSubmit}
          />
        )}
      </div>
      <AddwatchlistModal show={show} close={handleClose} />
    </>
  );
};

export default SingleCampPage;
