import React, { useState } from "react";
import FirstButton from "../../common/button";
import { FormControlLabel } from "@mui/material";
import SwitchGreen from "../../common/Switch";
import { useNavigate } from "react-router-dom";
import { role } from "../utils";

const risksList = [
  {
    label:
      "I understand Smaarket does not offer advice. I have the sophistication to raise investments on my own.",
  },
  {
    label:
      "I agree to the Terms, Privacy Policy, Investor Agreement, and the Electronic Consent & Delivery Agreement.",
  },
  {
    label:
      "I understand fund raising are very risky. I can afford  to not raise  investment on Smaarket.",
  },
];
const risksList1 = [
  {
    label:
      "I understand startups are very risky. I can afford a 100% loss of all investments or Perks  on Smaarket.",
  },
  {
    label:
      "I understand securities on Smaarket are not easily re-sold. There is no secondary market. I can wait years for a return.",
  },
  {
    label:
      "I understand Smaarket does not offer investment or donor advice. I have the sophistication to evaluate investments or donation on my own.",
  },
];
const risksList2 = [
  {
    label:
      "I’ve read the Marketplace FAQ. I understand that the marketplace is different than the usual marketplace and I can afford to take a risk.",
  },
  {
    label:
      "I understand Smaarket does not offer any advice. I am making my own decisions.",
  },
  {
    label: "I agree to the Terms, Privacy Policy",
  },
  {
    label:
      "I agree to the contracts with my electronic signature and authorize Smaarket to debit my account.",
  },
];

const Risks = (props) => {
  const [checked, setChecked] = useState(false);
  const { role } = props;

  const navigate = useNavigate();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleNavigate = () => {
    if (role === 1) {
      localStorage.setItem("role", "fundraisers");
      navigate("/raise-capital");
    } else if (role === 2) {
      localStorage.setItem("role", "investor");
      navigate("/explore-startups");
    } else if (role === 3) {
      localStorage.setItem("role", "marketplace");
      navigate("/market-place");
    }
    // window.location.reload();
  };
  return (
    <div className="risk_main">
      {role == 1 ? (
        <>
          <p className="risk_head">
           Smaarket is different than the stock market{" "}
          </p>
          <div className="risk_cont">
            {risksList.map((item, index) => (
              <FormControlLabel
                label={<p className="risk_label">{item.label}</p>}
                control={<SwitchGreen sx={{ m: 1 }} />}
                key={index}
                onChange={handleChange}
                sx={{ m: 1 }}
                style={{alignItems:"start"}}
              />
            ))}
            <div className="risk_btn ">
              <FirstButton
                buttonText="Proceed"
                className="btn"
                width="100%"
                onClick={handleNavigate}
              />
            </div>
          </div>
        </>
      ) : role === 2 ? (
        <>
          <p className="risk_head">
           Smaarket is different than the stock market{" "}
          </p>
          <div className="risk_cont">
            {risksList1.map((item, index) => (
              <FormControlLabel
                label={<p className="risk_label">{item.label}</p>}
                control={<SwitchGreen sx={{ m: 1 }} />}
                key={index}
                onChange={handleChange}
                sx={{ m: 1 }}
                style={{alignItems:"start"}}
              />
            ))}
            <div className="risk_btn ">
              <FirstButton
                buttonText="Proceed"
                className="btn"
                width="100%"
                onClick={handleNavigate}
              />
            </div>
          </div>
        </>
      ) : role === 3 ? (
        <>
          <p className="risk_head">
            Smaarket is different than the Marketplace{" "}
          </p>
          <div className="risk_cont">
            {risksList2.map((item, index) => (
              <FormControlLabel
                label={<p className="risk_label">{item.label}</p>}
                control={<SwitchGreen sx={{ m: 1 }} />}
                key={index}
                onChange={handleChange}
                sx={{ m: 1 }}
                style={{alignItems:"start"}}
              />
            ))}
            <div className="risk_btn ">
              <FirstButton
                buttonText="Proceed"
                className="btn"
                width="100%"
                onClick={handleNavigate}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Risks;
