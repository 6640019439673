import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../assets/images/icons/avatar2.png";
import FirstButton from "../../../common/button";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from "chart.js";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { textAlign } from "@mui/system";

const EndCampignInvest = () => {
  const navigate = useNavigate();
  return (
    <div className="dashcompsub_contmain">
      <div className="dashcompsubmain">
        <div className="dashcompsub1">
          <div className="dashcompsub_cont">
            <div>
              {" "}
              <img src={Avatar} alt="" />{" "}
            </div>
            <div>
              <h2 className="dashcompsub_h2">Bally Robotics</h2>
              <p className="dashcompsub_p">Application ID : SM02103A</p>
            </div>
          </div>
          <div>
            <FirstButton
              buttonText="Contact Campaign Manager"
              width="100%"
              marginTop="2rem "
              className="borderbtn"
            />
            <FirstButton
              buttonText="View Campaign History"
              width="100%"
              marginTop="1.5rem "
              className="borderbtn"
            />
            <FirstButton
              buttonText="Visit My Campaign"
              width="100%"
              marginTop="1.5rem "
              className="borderbtn"
              onClick={() => navigate("/dashcamppage")}
            />
          </div>
        </div>
        <div className="dashcompsub1">
          <p className="brand_name ">
            Main Campaign launched successfully and running. Track your progress
            from Control center.
          </p>
          <p className="brand_name dashmaincamp_p1 ">
            60 Days of campaign remaining
          </p>
          <p className="brand_name dashmaincamp_p1">
            Main campaign pitch yet to be started, Submission deadline : 20th
            March 2022
          </p>

          <FirstButton
            buttonText="End Campaign"
            width="100%"
            className="btngrey"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
      <div className="dcsc_cont1">
        <div>
          <p className="dcsc_f1">Raised so far</p>
          <p className="dcsc_f2">₹ 25,2300</p>
        </div>
        <div>
          <p className="dcsc_f1">Days left</p>
          <p className="dcsc_f2">60</p>
        </div>
        <div>
          <p className="dcsc_f1">Investors</p>
          <p className="dcsc_f2">2,375</p>
        </div>
        <div>
          <p className="dcsc_f1">Watchlisted</p>
          <p className="dcsc_f2">475</p>
        </div>
      </div>
      <div className="gg_maincont4 gg_mr1">
        <h2 className="gg_f3">Fund Raise progress</h2>
        <p className="gg_f2">
          Lorem ipsum dolor sit amet, consecteturquam, purus sit amet luctus
          venenatis
        </p>
        <div className="gg_maincont ">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="triangle-down"></div>
          </div>
          <div className="d-flex">
            <div className="gg_bg1"></div>
            <div className="gg_bg2"></div>
            <div className="gg_bg3"></div>
          </div>
          <div className="gg_main">
            <p className="gg-f1">0</p>
            <p className="gg-f1">25</p>
            <p className="gg-f1">50</p>
            <p className="gg-f1">100</p>
          </div>
        </div>
      </div>
      <div className="dscs_cont3">
        <h1 className="dscs_f3"> Fund rasing progress</h1>
        <div>
          <Bar options={options} data={bardata} width={100} height={250} />
        </div>
      </div>
      <div className="dscs_cont2 bar_mr">
        <div className=" w50 dscs_cont3">
          <h1 className="dscs_f3">Amount Promised</h1>
          <Line
            id="lineChart"
            type="line"
            options={{
              tension: 0.3,
              animation: { responsive: true, duration: 3000 },
              plugins: {
                legend: { display: false },
                tooltip: {
                  backgroundColor: "#00124d",
                  yAlign: "bottom",
                  xAlign: "center",
                  titleAlign: "center",
                  usePointStyle: true,
                  callbacks: {
                    labelPointStyle: function (context) {
                      return { pointStyle: "circle", rotation: 0 };
                    },
                  },
                },
              },
            }}
            data={linedata}
          />
        </div>
        <div className="w50 dscs_cont3">
          <h1 className="dscs_f3"> Campaign Interaction</h1>
          <Line
            id="lineChart"
            type="line"
            options={{
              tension: 0.3,
              animation: { responsive: true, duration: 3000 },
              plugins: {
                legend: { display: false },
                tooltip: {
                  backgroundColor: "#00124d",
                  yAlign: "bottom",
                  xAlign: "center",
                  titleAlign: "center",
                  usePointStyle: true,
                  callbacks: {
                    labelPointStyle: function (context) {
                      return { pointStyle: "circle", rotation: 0 };
                    },
                  },
                },
              },
            }}
            data={linedata2}
          />
        </div>
      </div>
    </div>
  );
};

export default EndCampignInvest;
const bardata = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      data: [10, 50, 80, 100, 120, 150, 180, 200],
      backgroundColor: "#26ABA4",
    },
  ],
};
const options = { maintainAspectRatio: false, responsive: true };
const linedata = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      label: "",
      data: [20, 100, 200, 600, 500],
      fill: true,
      fill: "start",
      backgroundColor: () => {
        const ctx = document.getElementById("lineChart").getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 180);
        gradient.addColorStop(0, "rgba(129,107,255,1)");
        gradient.addColorStop(1, "rgba(129,107,255,0)");
        return gradient;
      },
      borderColor: "#26ABA4",
    },
  ],
};
const linedata2 = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      label: "",
      data: [20, 100, 200, 600, 500],
      fill: true,
      fill: "start",
      backgroundColor: () => {
        const ctx = document.getElementById("lineChart").getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 180);
        gradient.addColorStop(0, "rgba(129,107,255,1)");
        gradient.addColorStop(1, "rgba(129,107,255,0)");
        return gradient;
      },
      borderColor: "#26ABA4",
    },
    {
      label: "",
      data: [50, 600, 300, 200, 400],
      fill: true,
      fill: "start",
      backgroundColor: () => {
        const ctx = document.getElementById("lineChart").getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 180);
        gradient.addColorStop(0, "rgba(129,107,255,1)");
        gradient.addColorStop(1, "rgba(129,107,255,0)");
        return gradient;
      },
      borderColor: "#F2536D",
    },
  ],
};
