import React from 'react'
import { Link,NavLink, useLocation, useParams} from 'react-router-dom';
import {Nav }from 'react-bootstrap';

const Sidebar = () => {
	const {pathname}=useLocation()
	const splitLocation = pathname.split("/");
  return (
    <div className='sidebar_main'>
			<div className='sidebar_cont1'>
        <nav className='nav-wd' >
					
						<div className='nav_cont'>
							<div className={splitLocation[2] === "creativeservices" ? "active" : "sidebarNav_item"}> 
								<Link to="/serviceproviders/creativeservices">Creative Services</Link>
							</div>

								<div className={splitLocation[2] === "legal" ? "active" : "sidebarNav_item"}> 
								  <Link to="/serviceproviders/legal">Legal & Financial</Link>
								</div>

								<div className={splitLocation[2] === "marketing" ? "active" : "sidebarNav_item"}> 
							  	<Link to="/serviceproviders/marketing">Marketing</Link>
								</div>

                <div className={splitLocation[2] === "packaging" ? "active" : "sidebarNav_item"}> 
								  <Link to="/serviceproviders/packaging">Packaging  & Fulfillment</Link>
								</div>

                <div className={splitLocation[2] === "prototype" ? "active" : "sidebarNav_item"}> 
								  <Link to="/serviceproviders/prototype">Prototype & Manufacturing</Link>
								</div>

								<div className={splitLocation[2] === "retail" ? "active" : "sidebarNav_item"}> 
								 <Link to="/serviceproviders/retail">Retail & Market growth</Link>
								</div>

						
						</div>
    </nav>
	</div>
    </div>
  )
}

export default Sidebar