import React, { useState } from "react";
import Img1 from "../../../../assets/images/logo/marketcardLogo1.png";
import Img2 from "../../../../assets/images/logo/marketcardLogo2.png";
import Img3 from "../../../../assets/images/logo/marketcardLogo3.png";
import Arrow from "../../../../assets/images/logo/forwardarrow.png";
import { Tabs, Tab } from "@material-ui/core";
import TechonologyOffer from "./Listingtabs/TechonologyOffer";
import TechonologyNeed from "./Listingtabs/TehonologyNeed";
import { useNavigate } from "react-router-dom";
const cardlist = [
  {
    id: 1,
    img: Img1,
    title: "Post your Need",
    description:
      "Seeking out for ready-to-market technological, scientific products  or partnerships.",
  },
  {
    id: 2,
    img: Img2,
    title: "Post your Offer",
    description:
      "Looking out for ready-to-market technological, scientific products  or partnerships. or Research",
  },
  {
    id: 3,
    img: Img3,
    title: "Technology assessment",
    description:
      "Evaluation of intellectual capital, research, and innovation.",
  },
];
const tabList = ["Technology offer", "Technology need"];
const ListingPage = () => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = async (e, value) => {
    setTabIndex(value);
  };
  const handleNavigate = (id) => {
    if (id === 1) {
      navigate("/marketplace/techneedbasicdetails");
    } else if (id === 2) {
      navigate("/marketplace/techofferbasicdetails");
    } else if (id === 3) {
      navigate("/marketplace/techAssessment");
    }
  };
  return (
    <div className="mpto_main mtop_exmain">
      <h1 className="mpto_h1">MarketPlace</h1>
      <p className="mpto_p">
        Innovate with the right partner. Novel technologies, great startups and
        innovative business opportunities at one click..
      </p>

      <div className="mpto_cont">
        {cardlist.map((item, index) => (
          <div
            className="mpto_cont1"
            onClick={() => handleNavigate(item.id)}
            key={index}
          >
            <img src={item.img} alt="" />
            <div className="mpto_cont2">
              <p className="mpto_cardtitle">{item.title} </p>
              <p>
                <img src={Arrow} />
              </p>
            </div>
            <p className="mpto_p1">{item.description}</p>
          </div>
        ))}
      </div>

      <div>
        <div className="borderTab">
          <Tabs
            variant="fullWidth"
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#19976A",
              },
            }}
          >
            {tabList.map((item, ind) => (
              <Tab
                style={{
                  fontSize: "1.6rem",
                  color: `${tabIndex === ind ? "black" : "black"}`,
                  fontWeight: `${tabIndex === ind ? "bold" : 400}`,
                  textTransform: "none",
                }}
                value={ind}
                label={item}
                key={ind}
              />
            ))}
          </Tabs>
        </div>
        {tabIndex === 0 && <TechonologyOffer />}
        {tabIndex === 1 && <TechonologyNeed />}
      </div>
    </div>
  );
};

export default ListingPage;
