import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import Upload from "../../../../assets/images/logo/upload.svg";
import { Link, useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Scrolltotop from "../../../../assets/images/oicons/svg/scrollup.svg";

import AddModal from "./AddModal";
import Dropdown from "../../../../common/dropdown";

const MainDonationsPerksDetails = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/maincampdonations/pitch");
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleClick = () => {
    navigate("/maincampdonations/setperks");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Perk Details </h4>
          <div className="cfp_wd1">
            <div>
              <p className="counts_title">
                Perks are rewards provided to backers in exchange for their
                financial assistance. Make sure your perks are not{" "}
                <Link>Prohibited</Link> .
              </p>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Title</p>
              <p className="mcdt_p2">
                The title for your perk is what will appear on your campaign
                page and throughout smaarket.
                <br />
                Create a title that accurately conveys the contents of what this
                perk offer
              </p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Description</p>
              <p className="mcdt_p2">
                Give more specifics on Perks. Use your imagination to inform
                backers about what they would get after claiming this perk.
              </p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Perk image </p>
              <p className="mcdt_p2">
                {" "}
                Please refrain from using photos that include text, such as
                those with the words "price," "discount," or "Smaarket brand
                colors." 660x440 pixels are the ideal size. Supports JPG or PNG.
              </p>
              <button className="UploadBtn">
                <img src={Upload} alt="" className="w100" />
              </button>
            </div>

            <div className="mcdt_cont">
              <p className="mcdt_p1">Items included</p>
              <p className="mcdt_p2">
                {" "}
                Add the items included in this perk. Items could be tangible or
                digital experiences, or even just a thank you. Specify the item
                quantity and add additional items to create bundles.
              </p>
              <div className="mdf_btncont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add a new Item +"
                  onClick={handleShow}
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Items included</p>
              <p className="mcdt_p2">
                {" "}
                Add the items included in this perk. Items could be tangible or
                digital experiences, or even just a thank you. Specify the item
                quantity and add additional items to create bundles.
              </p>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Select item</p>
                  <Dropdown MenuList={[]} placeholder="Select..." />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Quantity</p>
                  <input
                    placeholder="Enter"
                    type="text"
                    className="login_input"
                  />
                </div>
              </div>
              <div className="mdf_btncont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add a new Item +"
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Pricing</p>
              <p className="mcdt_p2">
                {" "}
                Set the amount you want to receive from backers who opt this
                perk.{" "}
              </p>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Discounted perk price.</p>
                  <input
                    placeholder="Enter"
                    type="text"
                    className="login_input"
                  />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Reatil price.</p>
                  <input
                    placeholder="Enter"
                    type="text"
                    className="login_input"
                  />
                </div>
              </div>
              <p className="mdp_p1">Approximately 25% Off</p>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">ShippingCharges</p>
              <div className="mdp_cont2">
                <p className="mcdt_p2">
                  Does this perk contain items that you need to ship?
                </p>
                <div className="mcdt_p2">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          sx={{
                            color: "#19976A",
                            "&.Mui-checked": { color: "#19976A" },
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                          }}
                        />
                      }
                      label={<span style={{ fontSize: "1.6rem" }}>Yes</span>}
                    />{" "}
                    <FormControlLabel
                      sx={{ fontSize: 16 }}
                      value="no"
                      control={
                        <Radio
                          sx={{
                            color: "#19976A",
                            "&.Mui-checked": { color: "#19976A" },
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                          }}
                        />
                      }
                      label={<span style={{ fontSize: "1.6rem" }}>No</span>}
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Select a location</p>
                  <Dropdown placeholder={"Select..."} MenuList={[]} />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Charges</p>
                  <input
                    placeholder="Enter"
                    type="text"
                    className="login_input"
                  />
                </div>
              </div>
              <div className="mdf_btncont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add a new location +"
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
            <div className="mcdt_cont">
              <p className="mcdt_p1">Estimated delivery date</p>
              <p className="mcdt_p2">
                {" "}
                Give yourself plenty of time. It's better to deliver to backers
                ahead of schedule than behind.We recommend that you post an
                email update to backers whenever you change this date.
              </p>
              <div className="mdp_cont1">
                <Dropdown MenuList={[]} placeholder="Select..." />
              </div>
              <p className="mdp_p1">02 month 28 days from now</p>
            </div>
            <div></div>
            <div className="mdp_btncont2">
              <FirstButton
                buttonText="Preview and Add Perk"
                className="btn"
                onClick={handleClick}
              />
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton buttonText="Previous" className="borderbtn" />
        <FirstButton
          buttonText="Save and next"
          onClick={handleSubmit}
          className="btn"
        />
      </div>

      <AddModal show={show} close={handleClose} />
    </div>
  );
};

export default MainDonationsPerksDetails;
