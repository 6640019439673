import React, { useState } from "react";
import Sidebar from "./Sidebar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Modal from "@mui/material/Modal";
import FirstButton from "../../common/button";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import { funddata } from "./Table/data";
const AdminFundraisers = () => {
  const precapcolumns = [
    {
      name: "id",
      label: "Slno",
      width: "2rem",
      options: {
        filter: true,
        sort: true,
        width: "2rem",
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ fontSize: "1.6rem" }}>
              {funddata[dataIndex]?.id}
            </span>
          );
        },
      },
    },
    {
      name: "company",
      label: "Title",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ fontSize: "1.6rem" }}>
              {funddata[dataIndex]?.company}
            </span>
          );
        },
      },
    },
    {
      name: "city",
      label: "Startup",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ fontSize: "1.6rem" }}>
              {funddata[dataIndex]?.city}
            </span>
          );
        },
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ fontSize: "1.6rem" }}>
              {funddata[dataIndex]?.type}
            </span>
          );
        },
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ fontSize: "1.6rem" }}>
              {funddata[dataIndex]?.date}
            </span>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span
              className={
                funddata[dataIndex]?.status === "Approved"
                  ? "admin_statgreen"
                  : funddata[dataIndex]?.status === "Rejected"
                  ? "admin_statred"
                  : funddata[dataIndex]?.status === "Process"
                  ? "admin_statprocess"
                  : ""
              }
            >
              {funddata[dataIndex]?.status}
            </span>
          );
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ display: "flex" }}>
              <button
                className="deletebtn"
                style={{ border: "1px solid red !important" }}
                onClick={handleOpen}
              >
                <DeleteOutlineIcon
                  sx={{ fontSize: "2rem", color: "#c65353" }}
                />
              </button>{" "}
              <button
                className="editbtn"
                style={{ marginLeft: "1rem" }}
                onClick={handleOpen}
              >
                <EditIcon sx={{ fontSize: "2rem" }} />
              </button>
            </span>
          );
        },
      },
    },
  ];
  const [show, setShow] = useState(false);
  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className="admin_main">
      <div className="admin_w20">
        <Sidebar />
      </div>
      <div className="admin_w80">
        <h4 className="scp_h1" style={{ margin: "2rem 0" }}>
          Fundraisers
        </h4>
        <div>
          <MUIDataTable
            title={"Activities"}
            data={funddata}
            columns={precapcolumns}
            options={{
              selectableRows: false,
              responsive: "scrollMaxHeight",
            }}
          />
        </div>
      </div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mdpd_modal" style={{ width: "51%" }}>
          <div className="mdpd_modalbtn">
            {" "}
            <button className="mdpd_close" onClick={() => handleClose()}>
              <CloseIcon />
            </button>
          </div>

          <div className="addwatchlist_modalmain">
            <h1 className="addwatchlist_h1">
              Are you sure you want to delete{" "}
            </h1>

            <div className="deletemodal_btncont" style={{ margin: "2rem 0" }}>
              <FirstButton
                buttonText="Cancel"
                onClick={handleClose}
                className="borderbtn"
              />
              <FirstButton
                buttonText="Delete"
                onClick={handleClose}
                className="btn"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminFundraisers;

const list = [
  { details: "33", title: "Pre-Campaigns" },
  { details: "22", title: "Fundraisers" },
  { details: "11", title: "Startups" },
  { details: "44", title: "investors" },
];
