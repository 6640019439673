import React from "react";
import Img1 from "../../../../../assets/images/img/investcard17.png";
import Img from "../../../../../assets/images/img/investcard1.png";
import Img2 from "../../../../../assets/images/img/investcard3.png";
import Img3 from "../../../../../assets/images/img/investcard14.png";
import Img4 from "../../../../../assets/images/img/investcard15.png";
import Img5 from "../../../../../assets/images/img/investcard16.png";
import Img9 from "../../../../../assets/images/img/investcard10.png";
import Img10 from "../../../../../assets/images/img/investcard11.png";
import Img11 from "../../../../../assets/images/img/investcard12.png";
import Imgsmall1 from "../../../../../assets/images/img/investsmall1.png";
import Imgsmall2 from "../../../../../assets/images/img/investsmall17.png";
import Imgsmall3 from "../../../../../assets/images/img/investsmall3.png";
import Imgsmall4 from "../../../../../assets/images/img/investsmall14.png";
import Imgsmall5 from "../../../../../assets/images/img/investsmall15.png";
import Imgsmall6 from "../../../../../assets/images/img/investsmall16.png";
import Imgsmall10 from "../../../../../assets/images/img/investsmall10.png";
import Imgsmall11 from "../../../../../assets/images/img/investsmall11.png";
import Imgsmall12 from "../../../../../assets/images/img/investsmall12.png";
import InvestmentCard from "../../../../../common/cards/InvestmentCard";
import FirstButton from "../../../../../common/button";
import SearchFilter from "./SearchFilter";
import { useNavigate } from "react-router-dom";
const All = () => {
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    if (id === cardData[3]?.id) {
      navigate("/investment/singlecamppage");
    }
  };
  return (
    <div>
      <div className="tabContent">
        <h1 className="tab_heading">Upcoming Deals</h1>
        {/* <p className='tab_subHeading'>Browse current   Acitve opportunities on Smaarket.
 All companies are Vetted & Pass Due diligence.</p> */}
      </div>
      <div className="search-filter">
        <SearchFilter />
      </div>
      <div className="adaall_main">
        {cardData.map((item, index) => (
          <InvestmentCard
            data={item}
            type="upcoming"
            key={index}
            onClick={() => handleNavigate(item.id)}
          />
        ))}
      </div>
      <div className="mdp_cont adall_btnmr">
        <FirstButton buttonText="View More" marginTop="2rem" className="btn" />
      </div>
      <div>
        <div className="tabContent">
          <h1 className="tab_heading">Active Deals</h1>
        </div>
        <div className="search-filter">{/* <SearchFilter/> */}</div>
        <div className="adaall_main">
          {cardDataupcoming.map((item, index) => (
            <InvestmentCard data={item} type="donations" key={index} />
          ))}
        </div>
        <div className="mdp_cont adall_btnmr">
          <FirstButton buttonText="See all" marginTop="2rem" className="btn" />
        </div>
      </div>
    </div>
  );
};

export default All;
const cardData = [
  {
    img: Img9,
    img2: Imgsmall10,
    title: "Reyght charger",
    description:
      "Rightcharge helps electric car drivers choose the right charger, connect with the right installer and switch to the right energy tariff",
    tags: ["Energy", "Electric vehicle", "CCD", "Sustainability", "Women founder"],
  },
  {
    img: Img10,
    img2: Imgsmall11,
    title: "Salvatech",
    description:
      "Salvtech’s award-winning eco-friendly water technology enables farmers to grow more, save water and improve soil health.",
    tags: ["Technology", "Water", "CSOP", "Venture Funded", "Sustainability"],
  },
  {
    img: Img11,
    img2: Imgsmall12,
    title: "Hydro one",
    description:
      "Game changing innovations in offshore wind power, energy storage and water desalination",
    tags: ["Energy", "Climate Tech", "CSOP", "B2B", "Venture Funded"],
  },
  {
    id: 3,
    img: Img3,
    img2: Imgsmall4,
    title: "Eyeuity",
    description:
      "Based on a simple scan of the eye, Occuity is developing handheld, non-invasive screening and monitoring solutions for diseases",
    tags: ["Med Tech", "B2C", "Health", "AI", "CSOP"],
  },
  {
    img: Img4,
    img2: Imgsmall5,
    title: "Foxer industries",
    description: "We supply plastic and polymer body parts for car manufacturers.",
    tags: ["Manufacturing", "B2B", "CSOP", "Sustainability", "Industrial"],
  },
  {
    img: Img5,
    img2: Imgsmall6,
    title: "Ransonia",
    description: "Plant based sanitizers and range of products for all sorts of hygiene needs.",
    tags: ["Health", "B2C", "Sustainability", "Health and wellness", "CCD"],
  },
];
const cardDataupcoming = [
  {
    img: Img,
    img2: Imgsmall1,
    title: "SAMAI",
    description: "Patented, single-use alternative: edible, compostable cups",
    tags: ["Sustainable","Women founder", "CCD", "Bio-Technology", "Food"],
    progressdata: {
      amount: "",
      percentage: 44.56,
      status: 40,
      duration: "",
      value: "40",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "20,000",
    target: "1,25,50,000",
    investors: "23",
  },
  {
    img: Img1,
    img2: Imgsmall2,
    title: "ARACE FEMCARE",
    description:
      "First farm-to-body tampon with hemp fiber - leading innovation for period care",
    tags: ["Sustainable", "Women Founder", "CCD", "Health and wellness", "B2B"],
    progressdata: {
      amount: "",
      percentage: 70.55,
      status: 34,
      duration: "",
      value: "70.55",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "40,000",
    target: "90,00,000",
    investors: "68",
  },
  {
    img: Img2,
    img2: Imgsmall3,
    title: "ZYSTEM 3E",
    description:
      "Building has never been easier. The only dry-stack wall construction system in the world.",
    tags: ["Construction", "Technology", "CCD", "Venture Funded", "B2B"],
    progressdata: {
      amount: "",
      percentage: 100.92,
      status: 19,
      duration: "",
      value: "1",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "35,000",
    target: "35,000",
    investors: "70",
  },
];
