import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dropdown from "../../../../../common/dropdown";
import FirstButton from "../../../../../common/button";
const Address = () => {
  return (
    <div>
      <h1>Choose Address</h1>
      <div className="mdp_cont2 add_width">
        <p className="mcdt_p2">Address type </p>
        <div className="mcdt_p2">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="yes"
              control={
                <Radio
                  sx={{
                    color: "#19976A",
                    "&.Mui-checked": { color: "#19976A" },
                    "& .MuiSvgIcon-root": { fontSize: 24 },
                  }}
                />
              }
              label={<span style={{ fontSize: "1.6rem" }}>Home</span>}
            />{" "}
            <FormControlLabel
              sx={{ fontSize: 16 }}
              value="no"
              control={
                <Radio
                  sx={{
                    color: "#19976A",
                    "&.Mui-checked": { color: "#19976A" },
                    "& .MuiSvgIcon-root": { fontSize: 24 },
                  }}
                />
              }
              label={<span style={{ fontSize: "1.6rem" }}>Work</span>}
            />
          </RadioGroup>
        </div>
      </div>
      <div>
        <div className="add_cont">
          <div className="add_cont1">
            <p className="add_f1">Full Name</p>
            <input className="login_input" placeholder="Type here" />
          </div>
          <div className="add_cont1">
            <p className="add_f1">10-digit mobile number</p>
            <input className="login_input" placeholder="+91" />
          </div>
        </div>
        <div className="add_cont">
          <div className="add_cont1">
            <p className="add_f1">Pincode</p>
            <input className="login_input" placeholder="Type here" />
          </div>
          <div className="add_cont1">
            <p className="add_f1">Locality</p>
            <input className="login_input" placeholder="Type here" />
          </div>
        </div>
        <div>
          <p className="add_f1">Address (Area and street)</p>
          <input placeholder="Type Here" className="login_input" />
        </div>
        <div className="add_cont">
          <div className="add_cont1">
            <p className="add_f1">City/District/Town</p>
            <input className="login_input" placeholder="Type here" />
          </div>
          <div className="add_cont1">
            <p className="add_f1">State</p>
            <Dropdown />
          </div>
        </div>
        <div className="add_cont">
          <div className="add_cont1">
            <p className="add_f1">Landmark (optional)</p>
            <input className="login_input" placeholder="Type here" />
          </div>
          <div className="add_cont1">
            <p className="add_f1">Alternate phone number (Optional)</p>
            <input className="login_input" placeholder="Type here" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
