import React from "react";
import InvestmentCard from "../../../common/cards/InvestmentCard";
import FirstButton from "../../../common/button";
import Sidebar from "./Sidebar";
import Img from "../../../assets/images/img/investcard1.png";
import Imgsmall1 from "../../../assets/images/img/investsmall1.png";
import Imgsmall4 from "../../../assets/images/img/investsmall4.png";
import Img3 from "../../../assets/images/img/investcard4.png";
import Img1 from "../../../assets/images/img/investcard2.png";
import Imgsmall2 from "../../../assets/images/img/investsmall2.png";
import Img6 from "../../../assets/images/img/investcard7.png";
import Imgsmall7 from "../../../assets/images/img/investsmall7.png";
import Img9 from "../../../assets/images/img/investcard10.png";
import Imgsmall15 from "../../../assets/images/img/investsmall14.png";
import Img7 from "../../../assets/images/img/investcard14.png";
import Imgsmall10 from "../../../assets/images/img/investsmall10.png";
import Img4 from "../../../assets/images/img/investcard15.png";
import Imgsmall5 from "../../../assets/images/img/investsmall15.png";
import { useNavigate } from "react-router-dom";
const InvestWatchlist = () => {
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    if (id === cardData[1].id) {
      navigate("/investment/donatedetail");
    } else if (id == cardData[0].id) {
      navigate("/investment/investdetails");
    }
  };
  return (
    <div className="cfp_main">
      <Sidebar />
      <div className="cfp_cont1">
        <h4 className="market_h4">Marketplace Watchlist</h4>

        <div>
          <div className="tabContent">
            <div>
              <h1 className="mcdt_p1">Active Deals</h1>
              <div className="adaall_main">
                {cardData.map((item, index) => (
                  <InvestmentCard
                    data={item}
                    type="donations"
                    key={index}
                    onClick={() => handleNavigate(item.id)}
                  />
                ))}
              </div>
              <div className="mdp_cont">
                <FirstButton
                  buttonText="View More"
                  marginTop="2rem"
                  className="btn"
                />
              </div>
            </div>
            <div>
              <h1 className="mcdt_p1">Pre campaigns</h1>
              <div className="search_results">
                {cardData2.map((item, index) => (
                  <InvestmentCard data={item} type="upcoming" key={index} />
                ))}
              </div>
              <div className="mdp_cont">
                <FirstButton
                  buttonText="See All"
                  marginTop="2rem"
                  className="btn"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="invest_watchlistbtncont">
          <p className="invest_watchlistp1">Keep Exploring !</p>
          <FirstButton
            buttonText="Explore Marketplace"
            marginTop="1rem"
            className="btn"
          />
        </div>
      </div>
    </div>
  );
};

export default InvestWatchlist;

const cardData = [
  {
    id: 1,
    img: Img,
    img2: Imgsmall1,
    title: "SAMAI",
    description: "Patented, single-use alternative: edible, compostable cups",
    tags: ["Sustainable", "CCD", "Bio-Technology", "Food"],
    progressdata: {
      amount: "",
      percentage: 44.56,
      status: 40,
      duration: "",
      value: "40",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "20,000",
    target: "1,25,50,000",
    investors: "23",
  },
  {
    id: 2,
    img: Img1,
    img2: Imgsmall2,
    title: "Hugg",
    description:
      "The pocket friendly insulated mug.Up to 9 height options, pocket friendly, and climate positive.",
    tags: ["Food and Beverage", "B2C", "Donation"],
    progressdata: {
      amount: "",
      percentage: 106.5,
      status: 15,
      duration: "",
      value: "10",
      color: "#146C4D",
      bgcolor: "#19976A",
    },

    minamount: "15,000",
    target: "50,00,000",
    investors: "78",
  },
  {
    img: Img3,
    img2: Imgsmall4,
    title: "Bally Robotics",
    description:
      "Democratizing robotics with affordable, extensible, no-code robotic arms",
    tags: ["ROBOTICS", "AUTOMATION", "CSOP", "CSOP", "SECTOR"],
    progressdata: {
      amount: "",
      percentage: 153.5,
      status: 9,
      duration: "",
      value: "80",
      color: "#146C4D",
      bgcolor: "#19976A",
    },
    minamount: "20,000",
    target: "50,00,000",
    investors: "78",
  },
  {
    img: Img6,
    img2: Imgsmall7,
    title: "Yuncooked",
    description: "The un-restaurant: an omnichannel plant-based food company",
    tags: ["FOOD", "WOMEN  FOUNDER", "CCD", "CSOP", "SECTOR"],
    progressdata: {
      amount: "",
      percentage: 68,
      status: 26,
      duration: "",
      value: "80",
      color: "#A29501",
      bgcolor: "#FCFCFC",
    },
    minamount: "15,000",
    target: "2,25,00,000",
    investors: "78",
  },
];
const cardData2 = [
  {
    img: Img7,
    img2: Imgsmall15,
    title: "Eyeuity",
    description:
      "Based on a simple scan of the eye, Occuity is developing handheld, non-invasive screening and monitoring solutions for diseases",
    tags: ["Med Tech", "Med Tech", "Health", "AI", "CSOP"],
  },
  {
    img: Img4,
    img2: Imgsmall5,
    title: "Foxer industries",
    description: "Lorem ipsum dolor sit amet, conser adipiscing elit dsgrg.",
    tags: ["SECTOR", "SECTOR", "CSOP", "SECTOR", "CSOP"],
  },
  {
    img: Img9,
    img2: Imgsmall10,
    title: "Reyght charger",
    description:
      "Rightcharge helps electric car drivers choose the right charger, connect with the right installer and switch to the right energy tariff",
    tags: ["ENERGY", "ELECTRIC VEHICLE", "CCD", "SECTOR", "CSOP"],
  },
];
