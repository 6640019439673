import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FirstButton from "../../../common/button";
import SwitchGreen from "../../../common/Switch";
import { Link, useNavigate } from "react-router-dom";

const ProceedToPay = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div className="ptp_pd">
      <div>
        <h1 className="ptp_h1">Invest in Samai</h1>
        <p className="ptp_p">Details are encrypted and secured.</p>
      </div>
      <div className="ptp_cont">
        <div className="ptp_cont1">
          <div>
            <h2 className="ptp_h2">Payment</h2>
            <div>
              <h3 className="ptp_h3">Investment amount</h3>
              <input className="login_input" placeholder="Enter" />
              <p className="ptp_p1 ptp_center">Amount in Rupees</p>
            </div>
            <div className="ptp_mr">
              <h3 className="ptp_h3">Yenilik Foundation Fund</h3>
              <p className="ptp_p2">
                By helping Yenilik foundation you're helping us to promote the
                startup ecosystem in India and boosting the nation’s growth.
              </p>
              <div className="mdp_cont2">
                <p className="mcdt_p2">Donate to ecosystem cause </p>
                <div className="mcdt_p2">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          sx={{
                            color: "#19976A",
                            "&.Mui-checked": { color: "#19976A" },
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                          }}
                        />
                      }
                      label={<span style={{ fontSize: "1.6rem" }}>Yes</span>}
                    />{" "}
                    <FormControlLabel
                      sx={{ fontSize: 16 }}
                      value="no"
                      control={
                        <Radio
                          sx={{
                            color: "#19976A",
                            "&.Mui-checked": { color: "#19976A" },
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                          }}
                        />
                      }
                      label={<span style={{ fontSize: "1.6rem" }}>No</span>}
                    />
                  </RadioGroup>
                </div>
              </div>
              <div>
                <p className="ptp_p2">Contribution Amount </p>
                <input className="login_input" placeholder="Enter" />
                <p className="ptp_p1 ptp_center">Ten thousand Rupees</p>
              </div>
            </div>
          </div>
          <div className="ptp_cont2">
            <p className="ptp_p2 ptp_color">Deal Terms</p>
            <p className="ptp_p2">
              SAFE : gives you the right to future shares in the company. If you
              invest, you're betting the company will be worth more than $10M
              eventually.{" "}
            </p>
            <p className="ptp_p2 ptp_color">Refund Details</p>
            <p className="ptp_p2">
              You can cancel investment and obtain a refund up to 48 hours
              before a Campaign closing.
            </p>
            <Link className="ptp_p2" style={{ fontWeight: "600" }}>
              {" "}
              View Agreement
            </Link>
          </div>
        </div>
        <div className="ptp_cont3">
          <div className="ptp_mr1">
            <h2 className="ptp_h3">Summary</h2>
            <div>
              <div className="ptp_cont4">
                <p className="ptp_p2 ptp_fw">Particulars</p>
                <p className="ptp_p2 ptp_fw">Amount</p>
              </div>
              {summylist.map((item, index) => (
                <div className="ptp_cont4">
                  <p className="ptp_p2">{item.particular}</p>
                  <p className="ptp_p2">{item.amount}</p>
                </div>
              ))}
              <div className="ptp_cont4 ptp_br">
                <p className="ptp_p2 ptp_fw">Total</p>
                <p className="ptp_p2 ptp_fw">₹ 10,46,000</p>
              </div>
            </div>
          </div>
          <div className="ptp_mr1">
            <h2 className="ptp_h3">Legal Stuff</h2>
            {List.map((item, index) => (
              <FormControlLabel
                label={<p className="risk_label">{item.label}</p>}
                control={<SwitchGreen sx={{ m: 1 }} />}
                key={index}
                onChange={handleChange}
                sx={{ m: 1 }}
                style={{ alignItems: "start" }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="precampbtncont ptp_btncont">
        <FirstButton buttonText="Previous" className="borderbtn" />
        <FirstButton
          buttonText="Proceed to Pay"
          className="btn"
          onClick={() => navigate("/investment/investcampend")}
        />
      </div>
    </div>
  );
};

export default ProceedToPay;

const List = [
  {
    label:
      "I’ve read the Investor FAQ. I understand startups are risky and can afford to lose my entire investment.",
  },
  {
    label:
      "I understand these investments are not easily resold. I can wait years for a return.",
  },
  {
    label:
      "I understand Smaarket does not offer investment advice. I am making my own investment decisions.",
  },
  {
    label:
      "I agree to the contracts with my electronic signature and authorize Smaarket to debit my account.",
  },
  { label: "I agree to the Smaarket Terms & Conditions" },
];

const summylist = [
  { particular: "Invested  Amount", amount: "₹ 10,00,000" },
  { particular: "Yenilik Foundation Fund", amount: "₹ 20,000" },
  { particular: "Convenience Fee", amount: "₹ 23,000" },
  { particular: "GST (18%)", amount: "₹ 18,000" },
  { particular: "Deduct TDS", amount: "-₹ 4,500" },
];
