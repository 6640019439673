import React, { useState, useRef } from "react";
import { scrollToTestDiv } from "../../utils";
import scrollto from "../../../assets/images/oicons/svg/scrollup.svg";

const Basic = () => {
  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          margin: "2rem 0",
        }}
      >
        {basic.map((item, index) => (
          <div
            style={{
              border: "1px solid black",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
            }}
            onClick={() => scrollToTestDiv(index)}
            key={index}
            className="faq_f3 pills"
          >
            {item.title}
          </div>
        ))}
      </div>
      <div>
        {basic.map((item, index) => (
          <div className="infq_cont faq_mr" key={index} id={index}>
            <h2 className="faq_f3 faq_f600 faq_mr1">{item.title} </h2>
            <p className="faq_f3">{item.dec}</p>
          </div>
        ))}
      </div>
      <div className="ta_scrollcont" style={{textAlign:"end"}}>
        <img src={scrollto} onClick={scrolltotop} />
      </div>
    </div>
  );
};

export default Basic;

const basic = [
  {
    title: "Angel Investor",
    dec: "Angel investors are individuals who provide capital to promising startup firms in exchange for a stake in the company, typically in the form of equity or royalties.",
  },
  {
    title: "Annual Recurring Revenue (ARR)",
    dec: "Amount of annual revenue generated by contracted work; measure for SaaS or subscription-based businesses.",
  },
  {
    title: "Backers",
    dec: "Backers or donors are folks who pledge money to join creators in bringing projects to life. Smaarket is not a shopping store, backers or donors support the Innovative Projects",
  },
  {
    title: "Bootstrapping",
    dec: "A founder using personal funds and/or operating revenue from the company rather than using any outside funds",
  },
  {
    title: "Burn Rate",
    dec: "1. How much ₹₹₹ the startup burning each month RevenueExpenses Burn Rate.",
  },
  {
    title: "Cap Table",
    dec: "The term cap table refers to a capitalization table in the startup world. This keeps track of who owns equity in a business, such as each founder, employee, and investor.",
  },
  {
    title: "Escrow",
    dec: "A legal arrangement in which a third party holds a substantial sum of money until certain criteria are met, at which point the funds are paid to the intended party.",
  },
  {
    title: "Exit",
    dec: "A company is acquired by a larger company or begins selling stock publicly.",
  },
  {
    title: "Follow-on Investment",
    dec: "A subsequent investment an investor makes in a portfolio company.",
  },
  {
    title: "Funding goal",
    dec: "It is the amount of money that a creator needs to complete their project.",
  },
  {
    title: "IRR",
    dec: "IRR is an abbreviation meaning internal rate of return.In contrast to a simple exit multiple, IRR considers how long it took to earn a return. It is a tougher method of determining the performance of your investments.",
  },
  {
    title: "Lead Investor",
    dec: "A lead investor is an individual or an organisation who will lead a funding round and may also act on behalf of the other investors.",
  },
  {
    title: "Minimum Viable Product (MVP)",
    dec: "MVP is a product version that has just enough features to be usable by early customers, who can then provide input for future product development.",
  },
  {
    title: "Pivot",
    dec: "A Company drastically alters its strategy to better suit the market, client preferences, and so on.",
  },
  {
    title: "Pre-money Valuation",
    dec: "1. The valuation of a company prior to the investment about to be made. 2. Pre-money valuation = post-money valuation - money raised in a given round.",
  },
  {
    title: "Project",
    dec: "A project is a finite work with a clear goal that you’d like to bring to life.",
  },
  {
    title: "Post-money Valuation",
    dec: "1. A company’s estimated value after an outside investment is made. 2. Post-money valuation = pre-money valuation + money raised in a given round.",
  },
  {
    title: "Priced Round",
    dec: "1. An equity-based round of investing with a concrete pre-money valuation. 2. Basically investors are buying straight shares/stock. This means there’s a price per share based on a set valuation and the company is releasing a set number of shares.",
  },
  {
    title: "Retail Investor",
    dec: "A retail investor is a non-professional investor. Also known as individual investors",
  },
  {
    title: "Revenue Renewal Rate",
    dec: "Rate at which customers return to buy a company’s service or product again.",
  },
  {
    title: "Rewards",
    dec: "Rewards or Perks are a creator's chance to share a piece of their project with their backer or donor community. Typically, these are one-of-a-kind experiences, limited editions, or copies of the work being produced.",
  },
  {
    title: "Rolling Close",
    dec: "A company pulls in one portion, or ~tranche~, of the money they've raised while the round remains open.",
  },
  {
    title: "Runway ",
    dec: "How long a company could survive given its current revenue and expenses (usually expressed in months). Startups usually have between 6 to 18 months of runway.",
  },
  {
    title: "Saturation",
    dec: "How crowded any given market is (with existing companies).",
  },
  {
    title: "Scalability",
    dec: "A company's potential to grow – to secure more customers, break into more markets, and dominate the market it breaks into it.",
  },
  {
    title: "Securities",
    dec: "Any and all investment contracts. Debt instruments, equity instruments",
  },
  {
    title: "Seed Round",
    dec: "A startup raises early funds from private investors (often in exchange for equity stakes) to form its company. Typically, these investors are friends, family, or acquaintances of the founders, and the money raised covers the basics—operating expenses, product development, team payroll, rent, equipment, etc.",
  },
  {
    title: "Series A",
    dec: "The A is the first significant investment by a venture capitalist or very large investor.",
  },
  {
    title: "Term Sheet",
    dec: "A document often with terms that investors and a company agree upon before finalising a deal. The most important terms are usually the percentage of ownership and voting rights an investor will get through the deal.",
  },
  { title: "Unicorn", dec: "A company with a $1 billion valuation." },
];
