import React from "react";
import FirstButton from "../../../../common/button";
import Sidebar from "../SidebarforPrecamp/SidebarPreCamp";
import upload from "../../../../assets/images/logo/upload.svg";
import upload2 from "../../../../assets/images/logo/upload3.svg";
import Dropdown from "../../../../common/dropdown";
import { useNavigate } from "react-router";

const deals = [
  { value: 0, label: "CCD - Compulsorily Convertible Debentures" },
  { value: 0, label: "CCPS - Compulsorily Convertible Preference Shares " },
  { value: 0, label: "NCD -  Non Convertible Debentures" },
  { value: 0, label: "CSOP - Community Stock Option Pool" },
];
const sector = [
  { value: 0, label: "Aerospace" },
  { value: 1, label: "Agriculture" },
  { value: 2, label: "Artificial intelligence" },
  { value: 3, label: "Assistive Devices" },
  { value: 4, label: "B2B" },
  { value: 5, label: "B2C" },
  { value: 6, label: "Bio-Technology" },
  { value: 7, label: "Digital Health" },
  { value: 8, label: "Entertainment" },
  { value: 9, label: "Farming" },
  { value: 10, label: "Food" },
  { value: 11, label: "Gener8tor" },
  { value: 12, label: "Health and wellness" },
  { value: 13, label: "Mobility" },
  { value: 14, label: "Space" },
  { value: 15, label: "Sustainability" },
  { value: 16, label: "Technology" },
  { value: 17, label: " Venture Funded" },
  { value: 18, label: "Women Founde" },
];
const Basic = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/precampaigncreation/projectdetails");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Basic Details</h4>
          <div>
            <p className="font_p1 cfp_cont">
              A Hint to Start :<br />
              The nature of the campaign can vary, but for the majority of
              successful <br /> outcomes that we've seen, the campaign requires
              a solid strategy,mission <br /> and a story to tell.
              <br />
              Present a clear opportunity and value for stakeholders.
            </p>
            <div className="cfp_cont">
              <h4 className="cfp_h4">Campagin Title</h4>
              <p className="cfp_p">Short description of Campaign</p>
              <input
                type="text"
                placeholder="Type here"
                className="login_input"
              />
            </div>
            <div className="cfp_cont">
              <h4 className="cfp_h4">Fund raise mode </h4>
              <p className="cfp_p">Help people discover your fund raise</p>
              <input
                type="text"
                placeholder="Auto filled and Locked"
                className="login_input"
              />
            </div>
            <div className="cfp_cont">
              <h4 className="cfp_h4">Deal type </h4>
              <p className="cfp_p">Structure your raise</p>
              <Dropdown MenuList={deals} />
            </div>
            <div className="cfp_cont">
              <h4 className="cfp_h4">
                What size investment would you consider?{" "}
              </h4>
              <p className="cfp_p">
                We encourage testing the waters to evaluate investor interest.
              </p>
              <div className="" style={{ display: "flex", gap: "2rem" }}>
                <div style={{ flex: 1 }}>
                  <Dropdown placeholder={"Min"} MenuList={[]} />
                </div>
                <div style={{ flex: 1 }}>
                  <Dropdown placeholder={"Max"} MenuList={[]} />
                </div>
              </div>
              <div className="cfp_cont">
                <h4 className="cfp_h4">Business tagline </h4>
                <p className="cfp_p">
                  Explain the value of the product, service, or brand
                </p>
                <textarea
                  name=""
                  placeholder="Type here"
                  className="precampfaq_textarea"
                ></textarea>
              </div>
              <div className="cfp_cont">
                <h4 className="cfp_h4">Sector tags</h4>
                <p className="cfp_p">
                  Personalize your campaign by adding tags
                </p>
                <Dropdown MenuList={sector} />
              </div>

              <div className="cfp_cont">
                <h4 className="cfp_h4">Business logo </h4>
                <p className="cfp_p">
                  {" "}
                  This logo will represent your business in Campaign main image
                  .<br />
                  100 x 100 pixels recommended resolution, maximum size 200kb.
                </p>
                <div>
                  <button className="UploadBtn">
                    <img src={upload} alt="" className="w100" />
                  </button>
                </div>
              </div>
              <div className="cfp_cont">
                <h4 className="cfp_h4">Campaign Tile </h4>
                <p className="cfp_p">
                  {" "}
                  This image will represent as Campaign main tile image <bt />
                  302 x 200 pixels recommended resolution, maximum size 600kb.
                </p>
                <div>
                  <button className="UploadBtn">
                    <img src={upload2} alt="" className="w100" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton buttonText="Previous" className="borderbtn" />
        <FirstButton
          buttonText="Save and Next"
          onClick={handleClick}
          className="btn"
        />
      </div>
    </div>
  );
};

export default Basic;
