import React from "react";
import { scrollToTestDiv } from "../../utils";
import scrollto from "../../../assets/images/oicons/svg/scrollup.svg";

const Contracts = () => {
  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          margin: "2rem 0",
        }}
      >
        {contracts.map((item, index) => (
          <div
            style={{
              border: "1px solid black",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
            }}
            onClick={() => scrollToTestDiv(index)}
            key={index}
            className="faq_f3 pills"
          >
            {item.title}
          </div>
        ))}
      </div>
      <div>
        {contracts.map((item, index) => (
          <div className="infq_cont faq_mr" key={index} id={index}>
            <h2 className="faq_f3 faq_f600 faq_mr1">{item.title} </h2>
            <p className="faq_f3">{item.dec}</p>
          </div>
        ))}
      </div>
      <div className="ta_scrollcont" style={{textAlign:"end"}}>
        <img src={scrollto} onClick={scrolltotop} />
      </div>
    </div>
  );
};
export default Contracts;

const contracts = [
  {
    title: "Common Stock",
    dec: "Security that represents ownership in a corporation. Holders of common stock exercise control by electing a board of directors and voting on corporate policy.",
  },
  {
    title: "Compulsorily Convertible Debentures ",
    dec: "Hybrid securities have the same financial rights as equity shares but no voting rights. CCDs do not come on the cap table.",
  },
  {
    title: "Compulsorily Convertible Preference Shares",
    dec: "Hybrid securities which have the same financial rights as equity shares but restricted voting rights. CCPS come on the cap table.",
  },
  {
    title: "Community Stock Option Pool",
    dec: "options which have the same financial rights as equity shares but no voting rights. CSOPs do not come on the cap table and are the fastest to execute",
  },
  {
    title: "Convertible Note",
    dec: "A convertible note is an unsecured loan that converts to stock at some point in the future.",
  },
  {
    title: "Non-Convertible Debentures",
    dec: "secured debt securities which provide a fixed rate of interest periodically with the investment repayment at the end of the tenure. NCDs do not come on the cap table.",
  },
  {
    title: "Preferred Stock",
    dec: "Similar to common stock, this security is a piece of ownership in a company. Typically, this stock carries voting rights, as common stock does.",
  },
  {
    title: "Revenue Share",
    dec: "This is a promissory note that is paid back from a share of the revenues of the business.",
  },
  {
    title: "SAFE (Simple Agreement for Future Equity)",
    dec: "A SAFE, which was developed by Y Combinator in 2013, gives an investor the right to earn stock at a later date if the firm sells shares in future funding.",
  },
];
