import React from "react";
import img1 from "../../assets/images/thumb1.png";
import img2 from "../../assets/images/thumb2.png";
import img3 from "../../assets/images/thumb3.png";
import img4 from "../../assets/images/thumb4.png";

import Slider from "react-slick";
const News = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="news">
        <div className="news_container">
          <h1 className="news_heading">Smaarket in news</h1>
        </div>

        {/* <div className="news_thumbnails"> */}
        <div className="news_slider">
          <Slider {...settings}>
            <div className="thumbnails">
              <img alt="" src={img1} className="w100" />
              <p className="news_title">
                Smaarket announces new opportunities for innovators to Shape the
                Future on Smaarket market place.
              </p>
            </div>
            <div className="thumbnails">
              <img alt="" src={img2} className="w100" />
              <p className="news_title">
                Lassion Capital Opens Fund to Crowd Investors in Collaboration
                with Smaarket
              </p>
            </div>
            <div className="thumbnails">
              <img alt="" src={img3} className="w100" />
              <p className="news_title">Smaarket Dominates Crowdfunding </p>
            </div>
            <div className="thumbnails">
              <img alt="" src={img4} className="w100" />
              <p className="news_title">
                The smaarket innovation Index is one such step towards fostering
                and furthering the ongoing wave of Research and innovation world
                wide.
              </p>
            </div>
            <div className="thumbnails">
              <img alt="" src={img2} className="w100" />
              <p className="news_title">
                Lassion Capital Opens Fund to Crowd Investors in Collaboration
                with Smaarket
              </p>
            </div>
            <div className="thumbnails">
              <img alt="" src={img3} className="w100" />
              <p className="news_title">Smaarket Dominates Crowdfunding </p>
            </div>
          </Slider>
        </div>
      </div>
      {/* 
    </div> */}
    </>
  );
};
export default News;
