import React,{useState} from 'react'
import img1 from "../../../assets/images/img/cs1.png"
import img2 from "../../../assets/images/img/cs2.png"
import img3 from "../../../assets/images/img/cs3.png"
import FirstButton from '../../../common/button'
import Sidebar from "./Sidebar"



const CreativeServices = () => {
  const handleScroll=()=>{
    window.scrollTo(0, 0);
  }


    
  return (
    <>
    <div className='dflex ' >
   
        <Sidebar/>

        <div className='w70 p132'>

            <h1 className='cs_h1'>Experts Directory</h1>
            <div>
                <h2 className='cs_f1'>Creative Services</h2>
                <div className='cs_wd85'>
                    {cardlist.map((item,index)=>(
                        <div key={index} className="cs_card">
                            <div className='cs_w40'>
                                <img src={item.img} alt="" className='w100' />
                            </div>
                            <div className='cs_w60'>
                                
                                <p className='cs_f2'>Creative Services</p>
                                <h1 className='cs_f3 cs_mr'>{item.title}</h1>
                                <p className='cs_f2 cs_mr'>Located in {item.location}, Since {item.year}</p>
                                <div className='cs_h15'>
                                <p className='cs_f4'>{item.desc}</p>
                                {item.discount!==null?<>
                                <p className='cs_f4 cs_fw'>Special Discounts</p>
                                <p className='cs_f4 '>{item.discount}</p>
                                </>:<></>}
                                </div>
                                <div className=''>
                                <FirstButton buttonText="Contact Expert"  width="100%" className="borderbtn"/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='ptp_center mb2 '>
        <p className='sp_f16_mr1'> Want to become an Expert ?</p>
        <FirstButton buttonText="Apply now" className="btn"/>
    </div>
                </div>
                
             
<div>


        </div>
   

    </div>
    </>

  )
  }

  export default CreativeServices

const cardlist=[
    {img:img1,title:"Qubeed Design Agency",desc:"We help entrepreneurs with crowdfunding campaign design services and pre-launch landing page designs that sell.",discount:"10% OFF for campaign page design" ,location:"Banglore",year:"2015"
},
{img:img2,title:"olecula Agency",desc:"Molecula is a content agency with strategic thinking. They create digital media content that makes a powerful connection.",discount:"Free product photography with every video AND a free consultation." ,location:"Mumbai",year:"2019"
},
{img:img3,title:"gency 2.0",desc:"Agency 2.0 uses a holistic approach to create highly successful launches with its prelaunch strategy and live launch growth campaign.",discount:null ,location:"Hyderabad",year:"2010"
}
]






