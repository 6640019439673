import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img01 from "../../../../../../assets/images/slider/balley1.PNG";
import img02 from "../../../../../../assets/images/slider/balley2.PNG";
import img03 from "../../../../../../assets/images/slider/balley3.PNG";
import img04 from "../../../../../../assets/images/slider/balley4.PNG";
import img05 from "../../../../../../assets/images/slider/balley5.PNG";
import img06 from "../../../../../../assets/images/slider/balley6.PNG";
import img07 from "../../../../../../assets/images/slider/balley7.PNG";
import img08 from "../../../../../../assets/images/slider/balley8.PNG";

import { Button } from "@material-ui/core";
const Pitch = () => {
  const data = [
    {
      number: 1,
      text: "Ally is making robotic arms affordable, lightweight, smart, and easy to use.",
    },
    {
      number: 2,
      text: "No-code programming and imitation learning for unique ease of use",
    },
    {
      number: 3,
      text: "$30M potential revenue via a non-binding Letter of Intent",
    },
    {
      number: 4,
      text: "Designed from day one with manufacturability and affordability in mind",
    },
    {
      number: 5,
      text: "Led by alums from Microsoft, Stanley Black & Decker, and Nokia",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
  };
  return (
    <>
      <div className="pitch">
        <div className="pitch-container">
          <div className="heighlights">
            <h1 className="brands_primary">Highlights</h1>

            {data.map((item, index) => (
              <div
                key={index}
                style={{
                  color: "#070908",
                  display: "flex",
                  alignItems: "center",
                }}
                className="tile_secondary heighlights_text"
              >
                <span className="heighlight_point">{item.number}</span>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="slider">
        <Slider {...settings}>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img01}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img02}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img03}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img04}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img05}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img06}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img07}></img>
          </div>
          <div className="slide">
            <img style={{ width: "100%" }} alt="" src={img08}></img>
          </div>
        </Slider>
      </div>

      <div
        style={{ backgroundColor: "#fff", marginBottom: "0" }}
        className="faq"
      >
        <div className="faq_container">
          <h1 className="faq_primary light-dark">Company FAQ</h1>
          <div className="questions">
            <div className="question_container--1">
              <div className="question1">
                <h1 className="question light-dark">
                  What are your product features ?
                </h1>
                <p className="answer light-dark">
                  We’re first of its kind platform in India that provide one
                  stop solution to all needs .
                </p>
              </div>
              <div className="question2">
                <h1 className="question light-dark">
                  What are your product features ?
                </h1>
                <p className="answer light-dark">
                  We’re first of its kind platform in India that provide one
                  stop solution to all needs .
                </p>
              </div>
            </div>

            <div className="question_container--2">
              <div className="question1 ">
                <h1 className="question light-dark">
                  What are your product features ?
                </h1>
                <p className="answer light-dark">
                  We’re first of its kind platform in India that provide one
                  stop solution to all need of a startup and innovation
                  ecosystem. Our mission is to boost in
                </p>
              </div>
              <div className="question2">
                <h1 className="question light-dark">
                  What are your product features ?
                </h1>
                <p className="answer light-dark">
                  We’re first of its kind platform in India that provide one
                  stop solution to all need of a startup and innovation
                  ecosystem. Our mission is to boost in
                </p>
              </div>
            </div>
          </div>
          {/* <FirstButton className="btn" buttonText="View More" marginTop="5rem"/> */}
        </div>
      </div>
    </>
  );
};

export default Pitch;
