import React, { useState } from "react";
import Sidebar from "../Sidebar";
import { Tabs, Tab } from "@mui/material";
import TechOffer from "./WatchlistTabs/TechOffers";
const tablist = ["Technology offer", "Technology need"];
const MarketWatchlist = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (e, value) => {
    setTabIndex(value);
  };
  return (
    <div className="cfp_main">
      <Sidebar />
      <div className="cfp_cont1">
        <h4 className="market_h4">Marketplace Watchlist</h4>
        <div>
          <div className="borderTab">
            <Tabs
              variant="fullWidth"
              onChange={handleChange}
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#19976A",
                  height: 3,
                },
              }}
            >
              {tablist.map((item, index) => (
                <Tab
                  style={{
                    fontSize: "1.6rem",
                    color: `${tabIndex === index ? "black" : "black"}`,
                    fontWeight: `${tabIndex === index ? "bold" : 400}`,
                    textTransform: "none",
                    fontFamily: "Montserrat",
                  }}
                  value={index}
                  label={item}
                  key={index}
                />
              ))}
            </Tabs>
          </div>
        </div>
        {tabIndex === 0 && <TechOffer />}
        {tabIndex === 1 && <p></p>}
        {tabIndex === 2 && (
          //  <ResearchOffer/>
          <p>Research Offers</p>
        )}
        {tabIndex === 3 && <p></p>}
      </div>

      <div></div>
    </div>
  );
};

export default MarketWatchlist;
