import React, { useState } from "react";

import All from "./Tabs/All";
import { Tabs, Tab } from "@material-ui/core";

const SearchResults = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = async (e, value) => {
    setTabIndex(value);
  };

  return (
    <div className="search">
      <div className="search_container">
        <div className="borderTab">
          <Tabs
            variant="fullWidth"
            onChange={handleTabChange}
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#19976A",
                height: 3,
              },
            }}
          >
            {tabList.map((item, index) => (
              <Tab
                style={{
                  fontSize: "1.6rem",
                  color: `${tabIndex === index ? "black" : "black"}`,
                  fontWeight: `${tabIndex === index ? "bold" : 400}`,
                  textTransform: "none",
                  fontFamily: "Montserrat",
                }}
                value={index}
                label={item}
                key={index}
              />
            ))}
          </Tabs>
        </div>
        {tabIndex === 0 && <All />}
        {tabIndex === 1 && <div className="tabContent"></div>}
        {tabIndex === 2 && <div className="tabContent"></div>}
      </div>
    </div>
  );
};

const tabList = ["All", "Investments", "Donations"];
export default SearchResults;
