import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import Pitch from "./Tabs/Pitch";
import Aboutus from "./Tabs/Aboutus";
import Discussion from "./Tabs/Discussion";
import Restricted from "../../Restricted";
const DetailTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = async (e, value) => {
    setTabIndex(value);
  };
  const role = localStorage.getItem("role");
  return (
    <>
      <div className="detailsTabs">
        <div className="detailTabs_container">
          <div className="borderTab">
            <Tabs
              variant="fullWidth"
              onChange={handleTabChange}
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#19976A",
                  height: 3,
                },
              }}
            >
              {tabList.map((item, index) => (
                <Tab
                  style={{
                    fontSize: "1.6rem",
                    color: `${tabIndex === index ? "black" : "black"}`,
                    fontWeight: `${tabIndex === index ? "bold" : 400}`,
                    textTransform: "none",
                    fontFamily: "Montserrat",
                  }}
                  value={index}
                  label={item}
                  key={index}
                />
              ))}
            </Tabs>
          </div>
        </div>
        {tabIndex === 0 && <Pitch />}
        {tabIndex === 1 && (role === "investor" ? <Aboutus /> : <Restricted />)}
        {tabIndex === 2 &&
          (role === "investor" ? <Discussion /> : <Restricted />)}
      </div>
    </>
  );
};
const tabList = ["Pitch", "About Us", "Discussions"];
export default DetailTabs;
