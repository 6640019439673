import React from "react";
import FirstButton from "../../../common/button";
import Plant from "../../../assets/images/logo/plant.png";
import { useNavigate } from "react-router-dom";

const Dashstartcamp = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="dashstartcamp_cont">
        <img src={Plant} alt="" />
        <h1 className="market_h4">
          Bring your campaign to life.
          <br />
          First, let’s get you set up. Please fill out the form.
        </h1>
        <FirstButton
          buttonText="Start a campaign"
          className="btn"
          margin="2rem 0"
          onClick={() => navigate("/prequalify")}
        />
        <p className="goal_p2">
          Please note that after you launch a campaign, you have limited options
          for editing, hiding, or deleting it.
        </p>
      </div>
    </div>
  );
};

export default Dashstartcamp;
