import React from "react";
import { useNavigate } from "react-router-dom";
import img from "../../../assets/images/icons/Invest12.svg";
import FirstButton from "../../../common/button";
import { role } from "../../utils";

const ExploreMain = () => {
  const navigate = useNavigate();
  const userrole = localStorage.getItem("role");
  const handleNavigate = () => {
    if (userrole === "investor") {
      navigate("/investment/activedeals");
      window.location.reload();
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <div className="main">
        <div className="main_text">
          <h1 className="primaryText">Invest in a future you believe in.</h1>
        </div>
        <div className="main_container w90">
          <div className="main_tiles mr8">
            <p className="secondaryText">
              Access vetted opportunities <br></br> in startups to Invest or
              donate.
            </p>
            <p className="secondaryText">
              Join thousands of investors and donors.
            </p>
            <p className="secondaryText">
              It’s your turn to build your dream portfolio.
            </p>
            {userrole === role[1].role ? (
              <FirstButton
                className="btn"
                float="left"
                marginTop="5rem"
                buttonText="Get Started Now"
                onClick={handleNavigate}
              />
            ) : (
              <FirstButton
                buttonText="Signup to Invest"
                className="btn"
                float="left"
                marginTop="5rem"
                onClick={() => navigate("/signup")}
              />
            )}
          </div>

          <div className="main_content main_imgcont2">
            <img src={img} alt="" className="main_img1" />
            {/* <Button style={{float:"right", marginTop:"10rem"}} id='mui_greenBtn' color="primary" size='medium' variant='contained'>Back startup</Button> */}
            {/* <FirstButton className="btn" float="right" marginTop="10rem" buttonText="Back Startups" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExploreMain;
