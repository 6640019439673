import React from "react";
import signup1 from "../../assets/images/oicons/svg/ex1.svg";
import signup2 from "../../assets/images/oicons/svg/ex2.svg";
import signup3 from "../../assets/images/oicons/svg/ex3.svg";
import { useNavigate } from "react-router-dom";
const goalList = [
  {
    id: 1,
    image: signup1,
    title: "Explore Startups",
    discription:
      "Invest or donate in curated and vetted startups across sectors",
  },
  {
    id: 2,
    image: signup2,
    title: "Raise Capital",
    discription:
      "Start Raising Capital from your community .Raise INR 20 lakh to    INR  10 crore.",
  },
  {
    id: 3,
    image: signup3,
    title: "Discover Market ",
    discription:
      "Scout Best fit technologies and Partners for your research and innovation needs.",
  },
];
const Goals = (props) => {
  return (
    <div style={{ padding: "8rem 3rem" }}>
      <h4 className="goal_head">Choose your goal</h4>
      <p className="goal_p1">
        We're happy you're here. To move forward, please choose path
      </p>

      <div className="goal_cont">
        {goalList.map((item, index) => (
          <div
            key={index}
            className="goals_cont2"
            onClick={() => props.change(1, item.id)}
          >
            <div className="goal_title">
              <img src={item.image} alt="" width="80px" />
              <h4 className="goal_title1">{item.title}</h4>
            </div>
            <p className="goal_p2">{item.discription}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Goals;
