import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../assets/images/icons/avatar2.png";
import FirstButton from "../../../common/button";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from "chart.js";
import { Doughnut, Bar, Line } from "react-chartjs-2";
const DashCompleteSub = () => {
  const navigate = useNavigate();
  return (
    <div className="dashcompsub_contmain">
      <div className="dashcompsubmain">
        <div className="dashcompsub1">
          <div className="dashcompsub_cont">
            <div>
              {" "}
              <img src={Avatar} alt="" />{" "}
            </div>
            <div>
              <h2 className="dashcompsub_h2">Bally Robotics</h2>
              <p className="dashcompsub_p">Application ID : SM02103A</p>
            </div>
          </div>
          <div>
            <FirstButton
              buttonText="Contact Campaign Manager"
              width="100%"
              marginTop="2rem "
              className="borderbtn"
            />
            <FirstButton
              buttonText="View Campaign History"
              width="100%"
              marginTop="1.5rem "
              className="borderbtn"
            />
            <FirstButton
              buttonText="Vist My Campaign"
              width="100%"
              marginTop="1.5rem "
              className="borderbtn"
            />
          </div>
        </div>
        <div className="dashcompsub1">
          <p className="brand_name ">
            Your Pre launch Application has been reviewed, and Successful for
            launch on 19th February{" "}
          </p>
          <p className="brand_name dashmaincamp_p1 ">
            Main campaign will be launched automatically on 27th March 2022 .{" "}
          </p>
          <p className="brand_name dashmaincamp_p1">
            Main campaign pitch yet to be started. Submission deadline : 20th
            March 2022.
          </p>

          <FirstButton
            buttonText="Complete your Submission"
            width="100%"
            className="btn"
            onClick={() => navigate("/endcampigninvest")}
          />
        </div>
      </div>

      <div className="dcsc_cont1">
        <div>
          <p className="dcsc_f1">Amount Promised</p>
          <p className="dcsc_f2">₹ 32,375</p>
        </div>
        <div>
          <p className="dcsc_f1">Days left</p>
          <p className="dcsc_f2">34</p>
        </div>
        <div>
          <p className="dcsc_f1">Page visitors</p>
          <p className="dcsc_f2">2,375</p>
        </div>
        <div>
          <p className="dcsc_f1">Watchlisted</p>
          <p className="dcsc_f2">475</p>
        </div>
      </div>

      <div className="dscs_cont2">
        <div className=" w50 dscs_cont3">
          <h1 className="dscs_f3">Amount Promised</h1>
          <Line
            id="lineChart"
            type="line"
            options={{
              tension: 0.3,
              animation: { responsive: true, duration: 3000 },
              plugins: {
                legend: { display: false },
                tooltip: {
                  backgroundColor: "#26ABA4",
                  yAlign: "bottom",
                  xAlign: "center",
                  titleAlign: "center",
                  usePointStyle: true,
                  callbacks: {
                    labelPointStyle: function (context) {
                      return { pointStyle: "circle", rotation: 0 };
                    },
                  },
                },
              },
            }}
            data={linedata}
          />
        </div>
        <div className="w50 dscs_cont3">
          <h1 className="dscs_f3"> Campaign Interaction</h1>
          <Line
            id="lineChart"
            type="line"
            options={{
              tension: 0.3,
              animation: { responsive: true, duration: 3000 },
              plugins: {
                legend: { display: false },
                tooltip: {
                  backgroundColor: "#26ABA4",
                  yAlign: "bottom",
                  xAlign: "center",
                  titleAlign: "center",
                  usePointStyle: true,
                  callbacks: {
                    labelPointStyle: function (context) {
                      return { pointStyle: "circle", rotation: 0 };
                    },
                  },
                },
              },
            }}
            data={linedata2}
          />
        </div>
      </div>
      <div className="dscs_cont2 dscs_mr">
        <div className=" w50 dscs_cont3">
          <h1 className="dscs_f3">Gender Splitup</h1>
          <div className="dscs_dough">
            <Doughnut
              data={gender}
              width={100}
              height={200}
              options={options}
            />
          </div>
        </div>
        <div className="w50 dscs_cont3">
          <h1 className="dscs_f3"> Top location - interests</h1>
          <div className="dscs_dough">
            <Doughnut
              data={dataLocation}
              width={100}
              height={200}
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashCompleteSub;
const linedata = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      label: "",
      data: [20, 100, 200, 600, 500],
      fill: true,
      fill: "start",
      backgroundColor: () => {
        const ctx = document.getElementById("lineChart").getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 180);
        gradient.addColorStop(0, "rgba(129,107,255,1)");
        gradient.addColorStop(1, "rgba(129,107,255,0)");
        return gradient;
      },
      borderColor: "#26ABA4",
    },
  ],
};
const linedata2 = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      label: "",
      data: [20, 100, 200, 600, 500],
      fill: true,
      fill: "start",
      backgroundColor: () => {
        const ctx = document.getElementById("lineChart").getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 180);
        gradient.addColorStop(0, "rgba(129,107,255,1)");
        gradient.addColorStop(1, "rgba(129,107,255,0)");
        return gradient;
      },
      borderColor: "#26ABA4",
    },
    {
      label: "",
      data: [50, 600, 300, 200, 400],
      fill: true,
      fill: "start",
      backgroundColor: () => {
        const ctx = document.getElementById("lineChart").getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 180);
        gradient.addColorStop(0, "rgba(129,107,255,1)");
        gradient.addColorStop(1, "rgba(129,107,255,0)");
        return gradient;
      },
      borderColor: "#F2536D",
    },
  ],
};

const dataLocation = {
  labels: ["Bengaluru", "Hydrabad", "Mumbai", "Delhi"],
  datasets: [
    {
      label: "My First Dataset",
      data: [40, 20, 30, 10],
      backgroundColor: ["#26ABA4", "#9D53F2", "#3290ED", "#F2536D"],
      borderWidth: 2,
      responsive: true,
      // cutout: 130,
      maintainAspectRatio: true,
      animation: { duration: 3000 },
      width: "50%",
      heigth: "20rem",

      // radius: 100,
    },
  ],
};
const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "right",
      labels: {
        color: "#070908",
        font: {
          size: 12,
          family: "Montserrat",
        },
      },
    },
  },
};
const gender = {
  labels: ["Male", "Female", "Non-Binary"],
  datasets: [
    {
      label: "My First Dataset",
      data: [50, 30, 20],
       backgroundColor: ["#26ABA4", "#9D53F2", "#3290ED", "#F2536D"],
      borderWidth: 2,
      responsive: true,
      // cutout: 130,
      maintainAspectRatio: true,
      animation: { duration: 3000 },
      width: "50%",
      heigth: "20rem",

      // radius: 100,
    },
  ],
};
