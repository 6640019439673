import React from "react";
import Sidebar from "./Sidebar";
import FirstButton from "../../../common/button";
import Dropdown from "../../../common/dropdown";
import UploadImg from "../../../assets/images/logo/upload3.svg";
import UploadImg2 from "../../../assets/images/logo/upload2.svg";
import { useNavigate } from "react-router-dom";
const TechOfferBasicDetails = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton
              buttonText="Save and Exit"
              className="borderbtn"
              onClick={() => navigate("/marketplace/marketplaceactivity")}
            />
          </div>
          <div>
            <h1 className="tnbd_h1">Basic Details</h1>
            {/* <p className='tnbd_f3'>How you describe your need is as crucial as what you are seeking.</p> */}
          </div>
          <div className="tnbd_cont">
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">What do you intend to offer?</h4>
              <Dropdown MenuList={DropList} />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Title of the Offer</h4>
              <p className="tnbd_f2">
                Give your offer a title that is self-explanatory. Who it's for
                and what it is
              </p>
              <input className="login_input" placeholder="Type Here" />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Description of Offer</h4>
              <p className="tnbd_f2">
                Explain in detail what technology or research offers. Place
                emphasis on those aspects that make it different, and do not
                forget to mention the benefits it provides over other known
                technologies or research.
              </p>
              <textarea
                className="precampfaq_textarea"
                placeholder="Type Here"
              ></textarea>
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">TRL Level</h4>
              <Dropdown />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Patent origin</h4>
              <Dropdown />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Patent ownership</h4>
              <p className="tnbd_f2">Desired Outcome</p>
              <Dropdown />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Engagement opportunity</h4>
              <p className="tnbd_f2">Desired Outcome</p>
              <Dropdown MenuList={DropListEng} />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Campaign tile</h4>
              <p className="tnbd_f2">
                This image will represent as Campaign main tile image
                <br />
                302 x 200 pixels recommended resolution, maximum size 600kb.
              </p>
              <button className="UploadBtn">
                <img src={UploadImg} alt="upload" className="w100" />
              </button>
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Tech Offer banner image</h4>
              <p className="tnbd_f2">
                This image will represent as header in the main campaign page
                <br />
                648 x 488 pixels recommended resolution, maximum size 900kb..
              </p>
              <button className="UploadBtn">
                <img src={UploadImg2} alt="upload" className="w100" />
              </button>
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Sector tags</h4>
              <p className="tnbd_f2">
                Giving a tag is the key for your need to be found by appropriate
                stakeholders
              </p>
              <Dropdown />
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton buttonText="Previous" className="borderbtn" />
        <FirstButton
          buttonText="Save and next"
          className="btn"
          onClick={() => navigate("/marketplace/techofferdetails")}
        />
      </div>
    </div>
  );
};

export default TechOfferBasicDetails;

const DropList = [
  {
    value: "1",
    label: "Candidates for research funding and investment programs",
  },
  { value: "2", label: "Game-changing technologies and ideas" },
  { value: "3", label: "Partners for the Consortium" },
  { value: "4", label: "Ph.D. thesis validation for commercialization	" },
  { value: "5", label: "Ph.D. thesis validation for spin-off" },
  { value: "6", label: "PhD thesis validation for technology transfer	" },
  { value: "7", label: "Ready to Market: Technical" },
  { value: "8", label: "Innovation and Products" },
  { value: "9", label: "Research services and capabilities" },
  { value: "10", label: "Specific scientific innovation" },
  { value: "11", label: "Specific technical innovation" },
];
const DropListEng = [
  { value: "1", label: "Joint Venture" },
  { value: "2", label: "Mergers and acquisitions" },
  { value: "3", label: "Partnership" },
  { value: "4", label: "Spinoff" },
  { value: "5", label: "Technology transfer" },
  { value: "6", label: "Commericlization" },
  { value: "7", label: "Validation" },
  { value: "8", label: "Contract research" },
  { value: "9", label: "Investment" },
  { value: "10", label: "Expression of Interest" },
];
