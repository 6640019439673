import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../assets/images/icons/avatar2.png";
import FirstButton from "../../../common/button";
const DashStartMainCampTwo = () => {
  const navigate = useNavigate();
  return (
    <div className="dashcompsub_contmain">
      <div className="dashcompsubmain">
        <div className="dashcompsub1">
          <div className="dashcompsub_cont">
            <div>
              {" "}
              <img src={Avatar} alt="" />{" "}
            </div>
            <div>
              <h2 className="dashcompsub_h2">Bally Robotics</h2>
              <p className="dashcompsub_p">Application ID : SM02103A</p>
            </div>
          </div>
          <div>
            <FirstButton
              buttonText="Contact Campaign Manager"
              width="100%"
              marginTop="2rem "
              className="borderbtn"
            />
            <FirstButton
              buttonText="View Campaign History"
              width="100%"
              marginTop="1.5rem "
              className="borderbtn"
            />
          </div>
        </div>
        <div className="dashcompsub1">
          <p className="brand_name ">
            Your Pre launch Application has been reviewed, and Successful for
            launch on 19th February{" "}
          </p>
          <p className="brand_name dashmaincamp_p1 ">
            Main campaign will be launched automatically on 27th March 2022 .{" "}
          </p>
          <p className="brand_name dashmaincamp_p1">
            Main campaign pitch yet to be started. Submission deadline : 20th
            March 2022.
          </p>

          <FirstButton
            buttonText="Start Main campaign"
            width="100%"
            className="btn"
            onClick={() => navigate("/maincampcreation/baiscdetails")}
          />
        </div>
      </div>
    </div>
  );
};

export default DashStartMainCampTwo;
