import React from "react";
import Img1 from "../../assets/images/icons/Home8.svg"
import Img2 from "../../assets/images/icons/home9.svg"
import Img3 from "../../assets/images/icons/Home11.png"
import Img4 from "../../assets/images/icons/home12.svg"
import Img5 from "../../assets/images/icons/home15.png"
import Img6 from "../../assets/images/icons/home16.svg"

const Brands = ()=>{
  return (
    <>
    <div className="brands">
      <div className="brands_container">
        <h1 className="brands_primary">Companies we have helped with their journey</h1>
        <div className="brands_icons">
       <div className="brands_content">
          <div className="brand_icon">
            <img src={Img1} alt="" className="w100" />
          </div>
          <p className="brand_name brand_mr">Blue Thread</p>
        </div>
        <div className="brands_content">
          <div className="brand_icon">
            <img src={Img2} alt="" className="w100" />
          </div>
          <p className="brand_name brand_mr">Dezolenator</p>
        </div>
        <div className="brands_content">
          <div className="brand_icon">
            <img src={Img3} alt="" className="w100" />
          </div>
          <p className="brand_name brand_mr">Zeco Tech</p>
        </div>
        <div className="brands_content">
          <div className="brand_icon">
            <img src={Img4} alt="" className="w100" />
          </div>
          <p className="brand_name brand_mr">Yess Tech</p>
        </div>
        <div className="brands_content">
          <div className="brand_icon">
        <img src={Img5} alt="" className="w100" />
          </div>
          <p className="brand_name brand_mr">ModVans</p>
        </div>
        <div className="brands_content">
          <div className="brand_icon">
        <img src={Img6} alt="" className="w100" />
          </div>
          <p className="brand_name brand_mr">Bio Workz</p>
        </div>
        </div>
 
      </div>
    </div>
    </>
  )
}

export default Brands