import React from "react";
import { Button } from "@mui/material";
import FirstButton from "../../common/button";
import img from "../../assets/images/icons/home20.svg";
import { useNavigate } from "react-router-dom";

const BottomBanner = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/login");
  };
  const handleSignup = () => {
    navigate("/signup");
  };
  return (
    <>
      <div className="bottomBanner">
        <div className="bottomBanner_container ">
          <div className="bottomBanner_content ">
            <h1 className="bottomBanner_primary">Ready to get started?</h1>
            <p className="bottomBanner_secondary">
              It's free, quick and easy. <br />
              Join our community to view the full opportunity.
            </p>
            <div className="btn_grp">
              <div className="bottom_btn--1">
                <FirstButton
                  className="whitebtn "
                  buttonText="Login to your account"
                  onClick={handleLogin}
                />
              </div>
              <div className="bottom_btn--2">
                {/* <Button id='mui_greenBtn' color="primary" size='medium' variant='contained'>Create a new account </Button> */}
                <FirstButton
                  className="btn"
                  buttonText="Create a new account"
                  onClick={handleSignup}
                />
              </div>
            </div>
          </div>
          <div className="bottom_imgcont">
            <img src={img} alt="" className="bottom_img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomBanner;
