import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/oicons/svg/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FirstButton from "../../common/button";
import SideBar from "./SideBar";
import ArrowDown from "../../assets/images/icons/arrow_down.svg";

import { role } from "../utils";
import Img from "../../assets/images/oicons/svg/Vector.svg";
import Notifications from "../../assets/images/oicons/svg/notification.svg";
const Navbar = () => {
  const [openNav, setOpenNav] = useState(false);
  const [show, setShow] = useState(false);
  const [userrole, setuserRole] = useState("");
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const name = localStorage.getItem("name");
  useEffect(() => {
    let item = localStorage.getItem("role");
    if (item !== undefined && item !== null && item !== "") {
      setuserRole(item);
    }
    setUrl(location.pathname);
  });

  const handleLogin = () => {
    navigate("/login");
  };
  const handleSignup = () => {
    navigate("/signup");
  };

  const handleSidebar = () => {
    if (!openNav) {
      setOpenNav(true);
    } else {
      setOpenNav(false);
    }
  };
  const handleClose = () => {
    setOpenNav(false);
  };
  const handleShow = () => {
    if (!show) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handlelogout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    setShow(false);
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <SideBar getValue={handleClose} classValue={openNav} />
      <nav className="navbar">
        <div className="navbar_header">
          <div className="navbar-brand">
            <a className="d-flex" href="/" style={{ alignItems: "center" }}>
              <img className="brand-logo" alt="Logo" src={Logo} />
              <span className="navbar_logo">smaarket</span>
            </a>
          </div>
          {url === "/login" ||
          url === "/signup" ||
          url === "/verifyemail" ||
          url === "/signupready" ? (
            <></>
          ) : url === "/signupoffounders" ? (
            <p className="navigation_list--link"> Welcome {name} !!</p>
          ) : (
            <>
              <div className="navigation">
                <ul className="navigation_list">
                  <li className="navigation_list--link">
                    <Link
                      className="navigation_list--link"
                      to="/explore-startups"
                    >
                      Explore startups
                    </Link>
                  </li>

                  <li className="navigation_list--link">
                    <Link className="navigation_list--link" to="/raise-capital">
                      Raise Capital
                    </Link>
                  </li>

                  <li className="navigation_list--link">
                    <Link className="navigation_list--link" to="/market-place">
                      Marketplace
                    </Link>
                  </li>

                  <li className="navigation_list--link">
                    <div className="dropdown">
                      <button className="dropbtn_learn ">
                        <p>Learn</p>
                        <p>
                          {" "}
                          <img
                            src={ArrowDown}
                            style={{ marginTop: "0.8rem" }}
                            alt="arrow"
                          />
                        </p>
                      </button>
                      <div className="dropdown-content">
                        <Link to="/help/faq">Help Center</Link>
                        <Link to="/serviceproviders/category">
                          Expert Directory
                        </Link>
                        <Link to="/help/faq">FAQs</Link>
                        <Link to="/help/glossary">Glossary</Link>
                        <Link>Our story</Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              {userrole === "" ? (
                <div className="auth">
                  <ul
                    style={{ display: "flex", alignItems: "center" }}
                    className="navigation_list"
                  >
                    <li className="navigation_list--link">
                      {/* <a  className='navigation_list--link' target='_blank' href='/'>Login</a> */}
                      <FirstButton
                        buttonText="Login"
                        className="borderbtn1"
                        onClick={handleLogin}
                      />
                    </li>
                    <li>
                      {/* <Button id='mui_greenBtn' color="primary" size='medium' variant='contained'>Sign Up</Button> */}
                      <FirstButton
                        buttonText="Sign Up"
                        className="borderbtn1"
                        onClick={handleSignup}
                      />
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="nav_notifiicon">
                  <div>
                    <img src={Notifications} alt="notify" />
                  </div>
                  {userrole === role[0].role ? (
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #19976A",
                        padding: "0.8rem",
                        gap: "1rem",
                        alignItems: "center",
                        borderRadius: "4px",
                      }}
                    >
                      <img src={Img} alt="" />
                      <Link
                        to="/dashstartcamp"
                         className="controlbtn"
                      >
                        Control Center
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="dropdown">
                    <button className="dropbtn ">
                      <p>{name}</p>
                      <p>
                        {" "}
                        <img
                          src={ArrowDown}
                          style={{ marginTop: "0.8rem" }}
                          alt="arrow"
                        />
                      </p>
                    </button>
                    <div className="dropdown-content">
                      <Link
                        to={
                          userrole === role[0].role
                            ? "/fundraising/companyprofile"
                            : userrole === role[1].role
                            ? "/investment/profile"
                            : userrole === role[2].role
                            ? "/marketplace/companyprofile"
                            : ""
                        }
                      >
                        Profile
                      </Link>
                      <Link
                        to={
                          userrole === role[0].role
                            ? "fundraising/marketplaceactivity"
                            : userrole === role[1].role
                            ? "investment/portfolio"
                            : userrole === role[2].role
                            ? "marketplace/marketplaceactivity"
                            : ""
                        }
                      >
                        {userrole === role[1].role?<>Portfolio</>:<>Marketplace activity</>}
                      </Link>
                      <Link
                        to={
                          userrole === role[0].role
                            ? "fundraising/marketplacewatchlist"
                            : userrole === role[1].role
                            ? "investment/watchlist"
                            : userrole === role[2].role
                            ? "marketplace/watchlist"
                            : ""
                        }
                      >
                        Watchlist
                      </Link>
                      <Link
                        to={
                          userrole === role[0].role
                            ? "fundraising/marketplacereferandearn"
                            : userrole === role[1].role
                            ? "/investment/referandearn"
                            : userrole === role[2].role
                            ? "marketplace/referandearn"
                            : ""
                        }
                      >
                        Refer and Earn
                      </Link>
                      <Link
                        to={
                          userrole === role[0].role
                            ? "fundraising/settings"
                            : userrole === role[1].role
                            ? "/investment/settings"
                            : userrole === role[2].role
                            ? "/marketplace/settings"
                            : ""
                        }
                      >
                        Settings
                      </Link>
                      <Link onClick={handlelogout}>Logout</Link>
                    </div>
                  </div>
                  {/* ) : (
                <></>
              )} */}
                </div>
              )}
              <div onClick={handleSidebar} className="navigation_menu">
                <div></div>
              </div>
            </>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
