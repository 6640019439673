import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import FirstButton from "../../../../common/button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import Modal from "@mui/material/Modal";

const AddModal = (props) => {
  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="mdpd_modal">
          <div className="mdpd_modalbtn">
            {" "}
            <button className="mdpd_close" onClick={props.close}>
              <CloseIcon />
            </button>
          </div>
          <div className="mdpd_modalmain">
            <h1 className="mdpd_f1">Add a new item</h1>
            <div className="mdpd_cont2">
              <h2 className="mcdt_p1">Item Name</h2>
              <p className="mcdt_p2">
                Use concise and obvious name for the item.
              </p>
              <input
                placeholder="Type here"
                type="text"
                className="login_input"
              />
            </div>
            <div className=" mdpd_cont2">
              <h2 className="mcdt_p1">Options</h2>
              <p className="mcdt_p2">
                Do you have options that backers can choose from for this item,
                e.g., color, size, etc.? This will create SKUs that you can use
                later to help with fulfillment.
              </p>
              <div>
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        sx={{
                          color: "#19976A",
                          "&.Mui-checked": { color: "#19976A" },
                          "& .MuiSvgIcon-root": { fontSize: 24 },
                        }}
                      />
                    }
                    label={
                      <span style={{ fontSize: "1.6rem" }}>
                        Yes, I am offering options for this item.
                      </span>
                    }
                  />
                  <FormControlLabel
                    sx={{ fontSize: 16 }}
                    value="no"
                    control={
                      <Radio
                        sx={{
                          color: "#19976A",
                          "&.Mui-checked": { color: "#19976A" },
                          "& .MuiSvgIcon-root": { fontSize: 24 },
                        }}
                      />
                    }
                    label={
                      <span style={{ fontSize: "1.6rem" }}>
                        No, I am not offering any options for this item.
                      </span>
                    }
                  />
                </RadioGroup>
              </div>
              <div className="mainfin_cont">
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Option name</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Eg : Size"
                  />
                </div>
                <div className="mainfin_cont2">
                  <p className="mcdt_p2">Option value</p>
                  <input
                    type="text"
                    className="login_input"
                    placeholder="Eg : Small, medium, Large"
                  />
                </div>
              </div>
              <div className="mdf_btncont">
                <FirstButton
                  className="borderbtn"
                  buttonText="Add a new option"
                  width="100%"
                  borderRadius="5rem"
                />
              </div>
            </div>
            <div className="mdpd_btncont2">
              <FirstButton
                buttonText="Cancel"
                onClick={props.close}
                className="borderbtn"
              />
              <FirstButton buttonText="Save Item" className="btn" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddModal;
