import React from "react";
import { useNavigate } from "react-router-dom";
import img from "../../../../assets/images/img/mp1.svg";
import FirstButton from "../../../../common/button";

const MarketPremiumMain = () => {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const handleNavigate = () => {};

  return (
    <>
      <div className="main">
        <div className="main_text">
          <h1 className="primaryText">You’re in very good company.</h1>
        </div>
        <div className="main_container main_width">
          <div className="main_tiles">
            <p className="secondaryText mmr">
              A personalized funnel can help you address acute problems,
              decrease expenses, improve operations, and create new revenue
              sources.
            </p>
            {/* <p className='secondaryText'>Raise ₹50 lakh to ₹ 7 crores from superfans who believe in you.</p> */}
            <FirstButton
              className="btn"
              float="left"
              marginTop="4rem"
              buttonText="Explore Premium Plus"
              onClick={handleNavigate}
            />
          </div>

          <div className="main_content main_textend">
            <img src={img} className="w70" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketPremiumMain;
