import React from "react";
import IMG01 from "../../../../assets/images/img/donatimg1.png";
import Img from "../../../../assets/images/img/pcard1.png";
import Img1 from "../../../../assets/images/img/pcard2.png";
import Img2 from "../../../../assets/images/img/pcard3.png";
import ProgressBar from "../../../../common/progressBar/Progress";
import FirstButton from "../../../../common/button.jsx";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import share from "../../../../assets/images/icons/share.png";
import { useNavigate } from "react-router-dom";
const data = {
  amount: "₹ 55,00,000 Raised Successfully",
  percentage: 106.50,
  status: "First goal Hit..!! (You can still invest)",
  duration: "15 Days Left",
  color: "#146C4D",
  bgcolor: "#19976A",
  value: "20",
};
const SearchDetailsMain = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="searchMain p-13">
        <div className="searchMain_container">
          <div className="searchMain_container--1">
            <img style={{ width: "100%" }} alt="" src={IMG01} />
          </div>
          <div className="searchMain_container--2">
            <div className="scp_cont1">
              <div>
                <h1 className="scp_h1">Hugg</h1>
              </div>
              <div className="scp_cont2">
                <button className="share_btn">
                  <img src={share} />
                </button>
                <FirstButton
                  buttonText="Add to watchlist"
                  className="borderbtn"
                />
              </div>
            </div>
            <div className="comp_details">
              <p className="comp_link">Hugg.drink</p>
              <div className="comp_social">
                <i className="fa-brands fa-twitter comp_social_icon"></i>
                <i className="fa-brands fa-linkedin-in comp_social_icon"></i>
                <i className="fa-brands fa-google comp_social_icon"></i>
                <i className="fa-brands fa-facebook-f comp_social_icon"></i>
              </div>
            </div>
            <div className="tags">
              <div style={{ backgroundColor: "#fcfcfc" }} className="tag">
                <p className="tag_label">FOOD AND BEVERAGE </p>
              </div>
              <div style={{ backgroundColor: "#fcfcfc" }} className="tag">
                <p className="tag_label">B2C</p>
              </div>
              <div style={{ backgroundColor: "#fcfcfc" }} className="tag">
                <p className="tag_label">DONATION</p>
              </div>
            </div>
            <p style={{ color: "#070908" }} className="secondaryText">
              The pocket friendly insulated mug. Up to 9 height options, double
              walled insulation, pocket friendly, and climate positive. Cheap
              and sustainable solution . A design accommodates ‘air’ gaps in the
              lid and on the sides to offer insulation for the beverage when it
              is in it's expanded form factor, which makes it up to 4X more heat
              retentive than other collapsible mugs.
            </p>
            <div style={{ margin: "2rem 0" }}>
              <ProgressBar data={data} type="samai" />
            </div>
          </div>
        </div>
      </div>
      <div className="sdm_mr">
        <h1 className="brands_primary">Pearks Offered</h1>
        <div className="dpp_cont flex-col">
          {carddata.map((item, index) => (
            <div className="pcard_main" key={index}>
              <img src={item.img} alt="" className="w100" />
              <h1 className="pcard_f2 pcard_fw pcard_mr">{item.title}</h1>
              <div className="pcard_mr">
                <span className="pcard_f1 pcard_fw ">{item.price}</span>
                <span className="pcard_f1 pcard_color">
                  {item.actualprice} ({item.discount}off)
                </span>
              </div>
              <p className="pcard_f1">*Smaarket Special reward*</p>
              <div className="pcard_mr">
                <p className="pcard_f1 pcard_fw">Items Included</p>
                <p className="pcard_f1 ">{item.included}</p>
              </div>
              <div className="pcard_mr">
                <p className="pcard_f1 pcard_fw">Shipping Locations</p>
                <p className="pcard_f1 ">{item.location}</p>
              </div>
              <div className="pcard_mr">
                <p className="pcard_f1 pcard_fw">Estimate Shipping</p>
                <p className="pcard_f1">{item.estdate}</p>
              </div>
              <p
                className="pcard_f1 pcard_fw pcard_mr"
                style={
                  item.invetleft === "Sold out"
                    ? { color: "#C65353" }
                    : { color: "#19976A" }
                }
              >
                {item.invetleft}
              </p>

              <FirstButton
                buttonText={item.btntext}
                width="100%"
                className="btn"
                onClick={() => navigate("/investment/donatemainperk")}
              />
              {item.btntext === "Selected" ? (
                <div>
                  <p className="pcard_f1 pcard_fw pcard_mr">Choose Color</p>
                  <p>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          sx={{
                            color: "#19976A",
                            "&.Mui-checked": { color: "#19976A" },
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: "1.6rem" }}>Ocean Blue</span>
                      }
                    />
                  </p>
                  <p>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          sx={{
                            color: "#19976A",
                            "&.Mui-checked": { color: "#19976A" },
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: "1.6rem" }}>Black Coffee</span>
                      }
                    />
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SearchDetailsMain;

const carddata = [
  {
    img: Img,
    title: "hugg",
    price: "₹ 15,000",
    actualprice: "₹ 25,000",
    discount: "25% ",
    included: " huggs x 1",
    location: "Ships Worldwide",
    estdate: "31st February 2023",
    invetleft: "Sold out",
    btntext: "Sold Out",
  },
  {
    img: Img1,
    title: "Couple of huggs ",
    price: "₹ 25,000",
    actualprice: "₹ 50,000",
    discount: "50% ",
    included: " huggs x 2",
    location: "Ships Worldwide",
    estdate: "31st February 2023",
    invetleft: "Only 1 left",
    btntext: "Get this perk",
  },
  {
    img: Img2,
    title: "8 X hugg's",
    price: "₹ 1,00,000",
    actualprice: "₹ 2,00,000",
    discount: "50% ",
    included: " huggs x 8",
    location: "Ships Worldwide",
    estdate: "31st February 2023",
    invetleft: "Only 3 left",
    btntext: "Get this Perk",
  },
];
