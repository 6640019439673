import React from 'react'
import { Link, useLocation} from 'react-router-dom';


const Sidebar = () => {
	const {pathname}=useLocation()
	const splitLocation = pathname.split("/");
  return (
    <div className='sidebar_main'>
   	<div className='sidebar_cont1'>
	<nav className='nav-wd' >
					
						<div className='nav_cont'>
							<div className={splitLocation[2] === "techneedbasicdetails" ? "active" : "sidebarNav_item"}> 
								<Link to="/marketplace/techneedbasicdetails">Basic</Link>
							</div>

								<div className={splitLocation[2] === "techneeddetails"  ? "active" : "sidebarNav_item"}> 
								  <Link to="/marketplace/techneeddetails">Details</Link>
								</div>

								<div className={splitLocation[2] === "techneedaboutus" ? "active" : "sidebarNav_item"}> 
							  	<Link to="/marketplace/techneedaboutus">About Us</Link>
								</div>

								<div className={splitLocation[2] === "techneedfinish" ? "active" : "sidebarNav_item"}> 
								  <Link to="/marketplace/techneedfinish">Finish</Link>
								</div>
						</div>
    </nav>
     
    </div>
	</div>
  )
}

export default Sidebar