import React from 'react'
import { Link, useLocation} from 'react-router-dom';


const Sidebar = () => {
	const {pathname}=useLocation()
	const splitLocation = pathname.split("/");
  return (
	<div className='sidebar_main'>
	<div className='sidebar_cont1'>
	<nav className='nav-wd' >
					
						<div className='nav_cont'>
							<div className={splitLocation[2] === "faq" ? "active" : "sidebarNav_item"}> 
								<Link to="/help/faq">FAQs</Link>
							</div>

								<div className={splitLocation[2] === "glossary"  ? "active" : "sidebarNav_item"}> 
								  <Link to="/help/glossary">Glossary</Link>
								</div>

								<div className={splitLocation[2] === "feedback" ? "active" : "sidebarNav_item"}> 
							  	<Link to="/help/feedback">Feedbacks</Link>
								</div>
						</div>
    </nav>
     
    </div>
	</div>
  )
}

export default Sidebar