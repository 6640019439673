import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo/smlogo-full.svg";
import { Button } from "@mui/material";
import FirstButton from "../../common/button";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const [url, setUrl] = useState("");
  useEffect(() => {
    setUrl(location.pathname);
  });

  return (
    <>
      <div className="footer">
        <div className="footer_container">
          {url === "/login" ||
          url === "/signup" ||
          url === "/signupoffounders" ||
          url === "/verifyemail" ||
          url === "/signupready" ? (
            <></>
          ) : (
            <>
              <div className="footer_section--1">
                <div className="footer_links">
                  <div id="footer_main" className="footer_content">
                    <div className="navbar-brand">
                      <a className="d-flex" href="/">
                        <img className="brand-logo" alt="Logo" src={Logo} />
                        <span className="navbar_logo">smaarket</span>
                      </a>
                    </div>
                    <p className="footer_text">Connect and Grow</p>
                    <div className="footer_socialIcons">
                      <i className="fa-brands fa-linkedin-in"></i>
                      <i className="fa-brands fa-twitter"></i>
                      <i className="fa-brands fa-google"></i>
                      <i className="fa-brands fa-facebook-f"></i>
                    </div>

                    <div className="copy">
                      &#169; 2022Smaarket Inc. All rights reserved
                    </div>
                  </div>
                  <div className="footer_content">
                    <h1 className="content_heading">
                      For investors and Donors
                    </h1>
                    <ul className="footer_ul">
                      <li className="footer_li">Why invest/donate</li>
                      <li className="footer_li">How it works</li>
                      <li className="footer_li">FAQ</li>
                      <li className="footer_li">Type of securities</li>
                    </ul>
                  </div>
                  <div className="footer_content">
                    <h1 className="content_heading">For Startups</h1>
                    <ul className="footer_ul">
                      <li className="footer_li">WhySmaarket</li>
                      <li className="footer_li">How to Raise</li>
                      <li className="footer_li">FAQ</li>
                    </ul>
                  </div>

                  <div className="footer_content">
                    <h1 className="content_heading">For Marketplace</h1>
                    <ul className="footer_ul">
                      <li className="footer_li">How it works</li>
                      <li className="footer_li">FAQ</li>
                      <li className="footer_li">Marketplace Premium</li>
                    </ul>
                  </div>

                  <div className="footer_content">
                    <h1 className="content_heading">Support</h1>
                    <ul className="footer_ul">
                      <li className="footer_li">Partner Services</li>
                      <li className="footer_li">Help center</li>
                      <li className="footer_li">Give us your feedback</li>
                      <li className="footer_li">FAQs</li>
                    </ul>
                  </div>

                  <div className="footer_content">
                    <h1 className="content_heading">About us</h1>
                    <ul className="footer_ul">
                      <li className="footer_li">Our team /Our story</li>
                      <li className="footer_li">Partnerships</li>
                      <li className="footer_li">Press and Media</li>
                      <li className="footer_li">Events</li>
                      <li className="footer_li">Careers</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer_section--2">
                <h1 className="refer">
                  Refer and earn upto ₹ 15000/-{" "}
                  <span style={{ color: "#19976A" }}>Learn More</span>{" "}
                </h1>
                {/* <Button id='mui_greenBtn' color="primary" size='medium' variant='contained'>Learn More</Button> */}
                <FirstButton className="whitebtn" buttonText="New" />
              </div>
            </>
          )}
          <div className="footer_section--3">
            <div className="footer_terms">
              <ul className="terms">
                <li className="terms_li">Terms and conditions</li>
                <li className="terms_li">Legal Info</li>
                <li className="terms_li">Privacy policy</li>
                <li className="terms_li">Risks and warnings</li>
              </ul>
            </div>
            <div className="language">Change language : English(UK)</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
