import React from "react";
import { Link } from "react-router-dom";
import Party from "../../../../assets/images/oicons/svg/congrats.svg";

const PreQualEnd = () => {
  return (
    <div className="prequalend">
      <img src={Party} alt="" className="w20" />
      <p className="final_p1">
        Congratulations! <br />
        Your application has been successfully submitted.
      </p>
      <p className="font_p1 preQualend">
        We will get back to you within seven business days.
        <br />
        We would like to assure you that we will treat your application with the
        strictest confidence.
      </p>

      <Link to="/dashstartprecamp" className="font_p1 linkcolor">
        Go to Your Dashboard
      </Link>
    </div>
  );
};

export default PreQualEnd;
