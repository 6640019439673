import React from "react";
import { scrollToTestDiv } from "../../utils";
import scrollto from "../../../assets/images/oicons/svg/scrollup.svg";

const Ipr = () => {
  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          margin: "2rem 0",
        }}
      >
        {ipr.map((item, index) => (
          <div
            style={{
              border: "1px solid black",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
            }}
            onClick={() => scrollToTestDiv(index)}
            key={index}
            className="faq_f3 pills"
          >
            {item.title}
          </div>
        ))}
      </div>
      <div>
        {ipr.map((item, index) => (
          <div className="infq_cont faq_mr" key={index} id={index}>
            <h2 className="faq_f3 faq_f600 faq_mr1">{item.title} </h2>
            <p className="faq_f3">{item.dec}</p>
          </div>
        ))}
      </div>
      <div className="ta_scrollcont" style={{textAlign:"end"}}>
        <img src={scrollto} onClick={scrolltotop} />
      </div>
    </div>
  );
};

export default Ipr;

const ipr = [
  {
    title: "Author (Copyright)",
    dec: "creator of a work, who contributes decisively to the creative decisions and efforts that an original work encompasses. As a general rule, authors are granted moral and economic rights over the work and its exploitation.",
  },
  {
    title: "copyright",
    dec: "An intellectual property right allowing the holder to prevent copying of a working",
  },
  { title: "Design", dec: "The visual appearance of an object, logo or icon" },
  {
    title: "Design right",
    dec: "sometimes called an unregistered design right; an automatically arising right to prevent the copying of certain features of items",
  },
  {
    title: "Distinctive",
    dec: "a characteristic of a trade mark needed to obtain registration – distinctiveness may be inherent, or it may arise as the result of prolonged use over a period of time",
  },
  {
    title: "Due Diligence",
    dec: "the evaluation of an asset or a set of assets in order to get reliable knowledge about their status.",
  },
  {
    title: "Intellectual Property (IP)",
    dec: "the property over creations of the human mind. In a broad sense, it includes inventions, literary and artistic works, as well as symbols, names, images and designs used in trade, among others.",
  },
  {
    title: "Intellectual Property Rights (IPR)",
    dec: "the legally enforceable rights to intellectual property. They confer a right to exclude others from their use. This includes patents and utility models, industrial designs (or design registrations), trade secrets, trademarks, geographical indications and copyrights.",
  },
  {
    title: "Monopoly right",
    dec: "A right which allows the owner to stop infringements regardless of whether the infringer copied deliberately",
  },
  {
    title: "Non-Disclosure Agreements (NDA)",
    dec: "Also known as confidentiality agreements, NDAs are contracts that aim to protect the secrecy and confidentiality of the information known and shared by the parties and to impose limits and rules for its use. Penalty clauses are also included for cases of breach of confidentiality. NDAs can be signed with business partners but also with their own employees.",
  },
  {
    title: "Open innovation",
    dec: "an innovation strategy through which companies can cross their internal organisational boundaries and cooperate with external professionals to carry their projects forward and work on Research & Development",
  },
  {
    title: "original work",
    dec: "A document, drawing, photo, piece of music, etc. which is not itself copied from elsewhere and which can be protected by copyright",
  },
  {
    title: "Patent",
    dec: "An intellectual property right giving a monopoly in a technical invention for up to 20 years",
  },
  {
    title: "Second use patentability",
    dec: "the possibility of patenting a different use of an already known object/product (e.g. use of a chemical composition as a medicine to treat heart diseases whilst the original patent claims its use as an antibiotic).",
  },
  {
    title: "Technology transfer",
    dec: "the successful application or adaptation of a technology developed in an organisation with the aim to meeting the needs of one or more other organisations.A technology transfer not only includes the transfer between organisations but also between different industrial sectors. Some examples of technology transfers are licenses, joint ventures, manufacturing, or commercial agreements which include technical assistance.",
  },
  {
    title: "Trade mark",
    dec: "An intellectual property right giving the holder a monopoly in a sign that serves to distinguish his goods or services from those of other traders",
  },
  {
    title: "Work",
    dec: "from a copyright – a document, drawing, photo, piece of music, software etc",
  },
];
