import React from 'react'
import MarketPremiumMain from './Tabs/MarketMain'
import PremiumPackages from './Tabs/PremiumPackages'
import PremiumFAQ from './Tabs/PremiumFaq'
import PremiumBenifits from './Tabs/PremiumBenifits'

const MarketPremium = () => {
  return (
    <div>
      <MarketPremiumMain/>
      <PremiumBenifits/>
      <PremiumPackages/>
      <PremiumFAQ/>
    </div>
  )
}

export default MarketPremium