import React from 'react'
import { useNavigate } from 'react-router-dom'
import Avatar from "../../../assets/images/icons/avatar2.png"
import FirstButton from '../../../common/button'
const DashReviewPreCamp = () => {
    const navigate=useNavigate()
  return (
    <div className='dashcompsub_contmain'>
        <div className="dashcompsubmain">
            <div className='dashcompsub1'>
                <div className='dashcompsub_cont'>
                    <div> <img src={Avatar} alt="" /> </div>
                    <div> 
                        <h2 className='dashcompsub_h2'>Bally Robotics</h2>
                        <p className='dashcompsub_p'>Application ID : SM02103A</p>
                    </div>
                </div>
                <div>
                <FirstButton buttonText="Contact Campaign Manager" width="100%" marginTop="2rem " className="borderbtn"/>
                <FirstButton buttonText="View Campaign History" width="100%" marginTop="1.5rem " className="borderbtn" />
                </div>
            </div>
            <div className='dashcompsub1'>
                <p className="brand_name dashcompsub_p1">Congratulations. Your application has been Successful.
You can upload the Pre campaign </p>
<p className='brand_name dashcompsub_p1'>28 Days to go for Pre campaign launch </p>
<p className='brand_name'>Submit your application before  15 days prior to the launch (11/07/2022).</p>
    
                <FirstButton buttonText="Start Pre-Campaign" width="100%" marginTop="1.2rem" className="btn" onClick={()=>navigate("/precampaigncreation/basic")}/>
            </div>
        </div>
    </div>
  )
}

export default DashReviewPreCamp