import React from "react";
import Sidebar from "./Sidebar";
import FirstButton from "../../../common/button";
import TextField from "@mui/material/TextField";
import TextEditor from "../../../common/TextEditor";
import Dropdown from "../../../common/dropdown";
import avatarIcon from "../../../assets/images/icons/avataricon.png";
import Scrolltotop from "../../../assets/images/oicons/svg/scrollup.svg";
import { useNavigate } from "react-router-dom";
const TechOfferAboutUs = () => {
  const navigate = useNavigate();
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />
        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton
              buttonText="Save and Exit"
              className="borderbtn"
              onClick={() => navigate("/marketplace/marketplaceactivity")}
            />
          </div>
          <div>
            <h1 className="tnbd_h1">About the offer provider.</h1>
            <p className="tnbd_f3">
              Visible only to Smaarket operations .<br /> Individual and
              Organisation identity is concealed.
            </p>
          </div>
          <div className="tnbd_cont">
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Primary Representative</h4>
              <div className="tnbu_cont">
                <p className="tnbd_f2">Full Name</p>
                <input className="login_input" placeholder="Type Here" />
              </div>
              <div className="tnbu_cont">
                <p className="tnbd_f2">Designation</p>
                <Dropdown />
              </div>
              <div className="tnbu_cont">
                <p className="tnbd_f2">Official Email ID</p>
                <input className="login_input" placeholder="Type Here" />
              </div>
              <div className="tnbu_cont">
                <p className="tnbd_f2">Mobile number</p>
                <input className="login_input" placeholder="Type Here" />
              </div>
              <div className="tnbu_cont">
                <p className="tnbd_f2">Linkedin Profile</p>
                <input className="login_input" placeholder="Type Here" />
              </div>
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Company Introduction</h4>
              <p className="tnbd_f2">
                Describe your organization and highlight the most relevant
                information (product, market, service, sales and Rand D).
              </p>
              <TextEditor />
              <FirstButton
                buttonText="Add more Heading +"
                className="borderbtn"
                width="100%"
                borderRadius="25rem"
                marginTop="2rem"
              />
            </div>
            <div className="tnbd_cont1">
              <h4 className="tnbd_f1">Your Team</h4>
              <div className="mcb_cont4 tnbu_cont">
                <img src={avatarIcon} alt="" width="20%" height="20%" />
                <div>
                  <TextField
                    id="standard-basic"
                    label="Name"
                    fullWidth
                    variant="standard"
                    className="mcb_inp"
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                  />
                  <TextField
                    id="standard-basic"
                    label="Designation"
                    fullWidth
                    variant="standard"
                    className="mcb_inp"
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                  />
                  <TextField
                    id="standard-basic"
                    label="Linkedin profile"
                    fullWidth
                    variant="standard"
                    className="mcb_inp"
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                  />
                </div>
              </div>
              <FirstButton
                buttonText="Add +"
                className="borderbtn"
                width="100%"
                borderRadius="25rem"
                marginTop="2rem"
              />
            </div>
          </div>
          <div className="stti">
            <img src={Scrolltotop} onClick={handleScroll} />
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/marketplace/techofferdetails")}
        />
        <FirstButton
          buttonText="Save and next"
          className="btn"
          onClick={() => navigate("/marketplace/techofferfinish")}
        />
      </div>
    </div>
  );
};

export default TechOfferAboutUs;
