import React from "react";
import Img from "../../../assets/images/img/congrats1.png";
import Img1 from "../../../assets/images/img/z9.png";
import Img2 from "../../../assets/images/img/z10.png";
import Img3 from "../../../assets/images/img/z11.png";
import Img9 from "../../../assets/images/img/z12.png";
import Img4 from "../../../assets/images/icons/discord.svg";
import Img5 from "../../../assets/images/icons/facebook.svg";
import Img6 from "../../../assets/images/icons/reddit.svg";
import Img7 from "../../../assets/images/icons/linkedin.svg";
import Img8 from "../../../assets/images/icons/twitter.svg";
import FirstButton from "../../../common/button";
import { useNavigate } from "react-router-dom";
const DonateCampEnd = () => {
  const navigate = useNavigate();
  return (
    <div className="ice_main">
      <div>
        <img src={Img} alt="" />
        <h1 className="ice_h1">
          Congratulations..!! You’ve just Donated to Hugg
        </h1>
        <p className="ice_p1">Let the founders know why you have Donated !</p>
      </div>
      <div className="ice_cont2">
        <div className="ice_cont">
          {list.map((item, index) => (
            <div key={index}>
              <img src={item.img} alt="" />
              <p className="ice_p1">{item.name}</p>
            </div>
          ))}
        </div>
        <div className="ice_cont1">
          <div>
            <p className="ice_p1 ice_fw">
            What made you decide to donate in our initiative? 

            </p>
            <input
              type="text"
              className="login_input"
              placeholder="Type Here"
            />
          </div>
     
        </div>
        <div className="ice_mr1">
          <h2 className="ice_p2">Help Hugg Raise more money</h2>
          <p className="ice_p1 ice_fw">
            Help Hugg  raise more money, the faster they raise sooner they can
            focus on what matters, the initiative!!
          </p>
        </div>
        <div className="ice_cont">
          <FirstButton
            buttonText={[
              <img src={Img8} className="ice_btnimg" />,
              "Share on Twitter",
            ]}
            className="btnblack"
          />
          <FirstButton
            buttonText={[
              <img src={Img5} className="ice_btnimg" />,
              "Share on Facebook",
            ]}
            className="btnblack"
          />
          <FirstButton
            buttonText={[
              <img src={Img7} className="ice_btnimg" />,
              "Share on Linkedin",
            ]}
            className="btnblack"
          />
          <FirstButton
            buttonText={[
              <img src={Img4} className="ice_btnimg" />,
              "Share on Discord",
            ]}
            className="btnblack"
          />
          <FirstButton
            buttonText={[
              <img src={Img6} className="ice_btnimg" />,
              ,
              "Share on Reddit",
            ]}
            className="btnblack"
          />
        </div>
      </div>
      <FirstButton
        buttonText="Skip and Go To Dashboard"
        className="borderbtn"
        marginTop="2rem"
        onClick={() => navigate("/investment/portfolio")}
      />
    </div>
  );
};

export default DonateCampEnd;

const list = [
  { img: Img9, name: "Konica Gupta" },
  { img: Img3, name: "Mohita padma" },
  { img: Img2, name: "Danish Pormit" },
  { img: Img1, name: "Bindesh Suri" },
];
