import React from "react";
import { scrollToTestDiv } from "../../utils";
import scrollto from "../../../assets/images/oicons/svg/scrollup.svg";

const DealTerms = () => {
  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          margin: "2rem 0",
        }}
      >
        {dealterms.map((item, index) => (
          <div
            style={{
              border: "1px solid black",
              padding: "0.5rem 1rem",
              borderRadius: "4px",
            }}
            onClick={() => scrollToTestDiv(index)}
            key={index}
            className="faq_f3 pills"
          >
            {item.title}
          </div>
        ))}
      </div>
      <div>
        {dealterms.map((item, index) => (
          <div className="infq_cont faq_mr" key={index} id={index}>
            <h2 className="faq_f3 faq_f600 faq_mr1">{item.title} </h2>
            <p className="faq_f3">{item.dec}</p>
          </div>
        ))}
      </div>
      <div className="ta_scrollcont" style={{textAlign:"end"}}>
        <img src={scrollto} onClick={scrolltotop} />
      </div>
    </div>
  );
};

export default DealTerms;

const dealterms = [
  {
    title: "Discount Rate",
    dec: "1.The discount rate varies according to the risk of the specific startup. 2.The discount rate is a term in a Convertible Note or SAFE that gives investors a reduced price to that paid by the Series A investors.  Typical discounts range from 0% to 20% or more",
  },
  {
    title: "Pre-Money Valuation",
    dec: "The pre-money valuation is the valuation of the company before an investment has been made. It does not include the value of the cash a venture capital firm is about to invest. Pre-money valuations for early-stage startups are most often determined by supply and demand.",
  },
  {
    title: "Post-Money Valuation",
    dec: "The post-money valuation is the valuation of the company after the investment has been made. It is equal to the pre-money valuation plus the amount of the investment.",
  },
  {
    title: "Valuation Cap",
    dec: "The Valuation Cap is the most important term of a convertible note or a SAFE. It entitles investors to equity priced at the lower of the valuation cap or the pre-money valuation in the subsequent financing.",
  },
];
