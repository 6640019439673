import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Img from "../../../assets/images/icons/avatar2.png";

const Sidebar = () => {
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  return (
    <div className="sidebar_main" style={{textAlign:"start",alignItems:"start"}}>
      <div className="sidebar_details sideBarNav_profilepd">
        <img src={Img} alt="" />
        <p>Bally Robotics</p>
        <p>SM-JP-0145</p>
      </div>

      <div className="sidebar_cont1">
        <nav className="nav-wd">
          <div className="nav_cont">
            <div
              className={
                splitLocation[2] === "companyprofile"
                  ? "sideBarNav_itemprofileactive"
                  : "sideBarNav_itemprofile"
              }
            >
              <Link to="/fundraising/companyprofile">Company Profile</Link>
            </div>

            <div
              className={
                splitLocation[2] === "marketplace"
                  ? "sideBarNav_itemprofileactive"
                  : "sideBarNav_itemprofile"
              }
            >
              <Link to="/fundraising/marketplace">Marketplace Activity</Link>
            </div>

            <div
              className={
                splitLocation[2] === "marketplacewatchlist"
                  ? "sideBarNav_itemprofileactive"
                  : "sideBarNav_itemprofile"
              }
            >
              <Link to="/fundraising/marketplacewatchlist">Watchlist</Link>
            </div>

            <div
              className={
                splitLocation[2] === "marketplacereferandearn"
                  ? "sideBarNav_itemprofileactive"
                  : "sideBarNav_itemprofile"
              }
            >
              <Link to="/fundraising/marketplacereferandearn">
                Refer and Earn
              </Link>
            </div>

            <div
              className={
                splitLocation[2] === "settings" ? "sideBarNav_itemprofileactive" : "sideBarNav_itemprofile"
              }
            >
              <Link to="/fundraising/settings">Settings</Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
