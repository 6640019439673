import React from "react";

const Counts = ()=>{
  return (
    <div className="counts">
      <div className="counts_container">
        <div className="counts_content">
          <h1 className="counts_data">70,000 +</h1>
          <p className="counts_title">Platform Investors</p>
        </div>

        <div className="counts_content">
          <h1 className="counts_data">₹ 465 Cr +</h1>
          <p className="counts_title">Raised on platform</p>
        </div>

        <div className="counts_content">
          <h1 className="counts_data">15000 +</h1>
          <p className="counts_title">Platform donors</p>
        </div>

        <div className="counts_content">
          <h1 className="counts_data">37 +</h1>
          <p className="counts_title">Technology offers</p>
        </div>

        <div className="counts_content">
          <h1 className="counts_data">100 +</h1>
          <p className="counts_title">Technology Calls</p>
        </div>

      </div>
    </div>
  )
}

export default Counts;