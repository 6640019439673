import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from "chart.js";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import MUIDataTable from "mui-datatables";
import GaugeChart from "react-gauge-chart";
import TilesGraph from "../../../../../common/TilesGraph";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title
);
const options = { maintainAspectRatio: false };
const Ananlytics = () => {
  const precapcolumns = [
    {
      name: "id",
      label: "Slno",
      width: "2rem",
      options: {
        filter: true,
        sort: true,
        width: "2rem",
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ fontSize: "1.6rem" }}>
              {precapdata[dataIndex]?.id}
            </span>
          );
        },
      },
    },
    {
      name: "company",
      label: "Company",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ fontSize: "1.6rem" }}>
              {precapdata[dataIndex]?.company}
            </span>
          );
        },
      },
    },
    {
      name: "city",
      label: "Head Quarters",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ fontSize: "1.6rem" }}>
              {precapdata[dataIndex]?.city}
            </span>
          );
        },
      },
    },
    {
      name: "type",
      label: "Sector",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ fontSize: "1.6rem" }}>
              {precapdata[dataIndex]?.type}
            </span>
          );
        },
      },
    },
    {
      name: "date",
      label: "Last Funded",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span style={{ fontSize: "1.6rem" }}>
              {precapdata[dataIndex]?.date}
            </span>
          );
        },
      },
    },
    {
      name: "status",
      label: "Valuation",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <span
              className={
                precapdata[dataIndex]?.status === "Approved"
                  ? "admin_statgreen"
                  : precapdata[dataIndex]?.status === "Rejected"
                  ? "admin_statred"
                  : precapdata[dataIndex]?.status === "Process"
                  ? "admin_statprocess"
                  : "admin_statgreen"
              }
            >
              {precapdata[dataIndex]?.status}
            </span>
          );
        },
      },
    },
  ];
  return (
    <>
      <div className="anylatics_contaier">
        <div className="iada_mr">
          <h1 className="brands_primary">Sector Data</h1>
          <p className="iada_f1">
            We help investors and citizens avoid unsubstantiated claim through
            our Smaarket data{" "}
          </p>
        </div>
        {/* ------------------------------Local/Global */}
        <div className="iada_cont mt-2">
          <div className="w50">
            <h2 className="iada_f2">Local Sector Breakup</h2>
            <div className="iada_cont1">
              <Doughnut data={dataLocal} options={options} />
            </div>
          </div>
          <div className="w50">
            <h2 className="iada_f2">Global Sector Breakup</h2>
            <div className="iada_cont1">
              <Doughnut data={dataGlobal} options={options} />
            </div>
          </div>
        </div>
        {/*--------------------------- TopFunders------------------------------- */}
        <div className="iada_mr mt-6">
          <h1 className="iada_f2 iada_mr">Top Startups funded in sector</h1>
          <MUIDataTable
            title={"Startups"}
            data={precapdata}
            columns={precapcolumns}
            options={{
              selectableRows: false,
            }}
          />
        </div>
        {/* -------------------------------No fo Startup----------------------- */}
        <div className="iada_mr">
          <h1 className="iada_f2 iada_mr">No of startup working in domain</h1>
          <div>
            <Bar
              width={100}
              height={250}
              options={options}
              data={bardataStartups}
            />
          </div>
        </div>
        {/* --------------------Public traded companies------------------------ */}
        <div className="iada_mr">
          <h1 className="iada_f2">Publicly traded companies</h1>
          <div>
            <MUIDataTable
              title={"Activities"}
              data={precapdataTradedCompanies}
              columns={precapcolumns}
              options={{
                selectableRows: false,
              }}
            />
          </div>
        </div>
        {/*----------------------- Performance of Sector---------------------  */}
        <div className="iada_mr">
          <h1 className="iada_f2">Sector Performance</h1>
          <div>
            <TilesGraph title={""} data={dataSectorPerformance} />
          </div>
        </div>
        {/* ------------------Investment pattern---------------------------*/}
        <div className="iada_mr">
          <h1 className="brands_primary iada_mr">Investment pattern</h1>
          <div className="iada_cont">
            <div className="w50">
              <h2 className="iada_f2">India</h2>
              <div className="iada_cont1">
                <Line
                  id="lineChart"
                  type="line"
                  options={{
                    tension: 0.3,
                    animation: { responsive: true, duration: 3000 },
                    plugins: {
                      legend: { display: false },
                      tooltip: {
                        backgroundColor: "#00124d",
                        yAlign: "bottom",
                        xAlign: "center",
                        titleAlign: "center",
                        usePointStyle: true,
                        callbacks: {
                          labelPointStyle: function (context) {
                            return { pointStyle: "circle", rotation: 0 };
                          },
                        },
                      },
                    },
                  }}
                  data={linedata}
                />
              </div>
            </div>
            <div className="w50">
              <h2 className="iada_f2">Global</h2>
              <div className="iada_cont1">
                <Line
                  id="lineChart"
                  type="line"
                  options={{
                    tension: 0.3,
                    animation: { responsive: true, duration: 3000 },
                    plugins: {
                      legend: { display: false },
                      tooltip: {
                        backgroundColor: "#00124d",
                        yAlign: "bottom",
                        xAlign: "center",
                        titleAlign: "center",
                        usePointStyle: true,
                        callbacks: {
                          labelPointStyle: function (context) {
                            return { pointStyle: "circle", rotation: 0 };
                          },
                        },
                      },
                    },
                  }}
                  data={linedataGloabal}
                />
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------Patent Pattern------------------------------- */}
        <div className="iada_mr">
          <h1 className="brands_primary iada_mr">Patent Pattern</h1>
          <div className="iada_cont">
            <div className="w50">
              <h2 className="iada_f2">Patent applied in sector </h2>
              <div className="iada_cont1">
                <Bar options={options} data={bardata} />
              </div>
            </div>
            <div className="w50">
              <h2 className="iada_f2">No of patents available </h2>
              <div className="iada_cont1">
                <Bar options={options} data={bardatapatents} />
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------------Top Pears------------------------------- */}
        <div className="iada_mr">
          <h1 className="brands_primary iada_mr">Top Peers</h1>
          <MUIDataTable
            title={"India "}
            data={precapdata}
            columns={precapcolumns}
            options={{
              selectableRows: false,
            }}
          />
          <div className="iada_mr">
            <MUIDataTable
              title={"Global  "}
              data={precapdata}
              columns={precapcolumns}
              options={{
                selectableRows: false,
              }}
            />
          </div>
        </div>
        {/* -------------------------Risks----------------------- */}
        <div className="iada_mr">
          <h1 className="brands_primary iada_mr">Risks</h1>
          <div className="iada_cont">
            <div className="w33">
              <h2 className="iada_f2">Sector specific </h2>
              <div className="iada_cont1" style={{ height: "auto" }}>
                <GaugeChart
                  id="gauge-chart2"
                  nrOfLevels={5}
                  percent={0.36}
                  textColor="#000000"
                  animDelay={500}
                  animateDuration={5000}
                  colors={[
                    "#26ABA4",
                    "#9D53F2",
                    "#3290ED",
                    "#F7A452",
                    "#F2536D",
                  ]}
                />
              </div>
            </div>
            <div className="w33">
              <h2 className="iada_f2">Company specific </h2>
              <div className="iada_cont1" style={{ height: "auto" }}>
                <GaugeChart
                  id="gauge-chart2"
                  nrOfLevels={5}
                  percent={0.16}
                  textColor="#000000"
                  animDelay={500}
                  animateDuration={5000}
                  needleBaseColor="#464A4F"
                  colors={[
                    "#26ABA4",
                    "#9D53F2",
                    "#3290ED",
                    "#F7A452",
                    "#F2536D",
                  ]}
                />
              </div>
            </div>
            <div className="w33">
              <h2 className="iada_f2">Emotional Quotient </h2>
              <div className="iada_cont1" style={{ height: "auto" }}>
                <GaugeChart
                  id="gauge-chart2"
                  nrOfLevels={5}
                  percent={0.29}
                  textColor="#000000"
                  animDelay={500}
                  animateDuration={5000}
                  needleBaseColor="#464A4F"
                  colors={[
                    "#26ABA4",
                    "#9D53F2",
                    "#3290ED",
                    "#F7A452",
                    "#F2536D",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ananlytics;

const dataLocal = {
  labels: ["Light Aircraft", "Business Jets", "Transport Aircraft", "UAVs"],
  datasets: [
    {
      label: "My First Dataset",
      data: [40, 20, 30, 10],
      backgroundColor: ["#26ABA4", "#9D53F2", "#3290ED", "#F7A452"],
      borderWidth: 2,
      responsive: true,
      // cutout: 130,
      maintainAspectRatio: true,
      animation: { duration: 3000 },

      // radius: 100,
    },
  ],
};

const dataGlobal = {
  labels: ["Light Aircraft", "Business Jets", "Transport Aircraft", "UAVs"],
  datasets: [
    {
      label: "My First Dataset",
      data: [20, 50, 100, 300],
      backgroundColor: ["#26ABA4", "#9D53F2", "#3290ED", "#F7A452"],
      borderWidth: 2,
      responsive: true,
      // cutout: 130,
      maintainAspectRatio: true,
      animation: { duration: 3000 },

      // radius: 100,
    },
  ],
};

export const precapdata = [
  {
    id: "1",
    name: "Joe James",
    company: "Relativity Space",
    city: "Bengaluru, IN",
    state: "IN",
    date: "12/02/2022",
    type: "Aircraft",
    status: "$1 B",
  },
  {
    id: "2",
    name: "John Walsh",
    company: "Joby Aviation",
    city: "Hartford, US",
    state: "CT",
    date: "10/07/2021",
    type: "UAVs",
    status: "$2 B",
  },
  {
    id: "3",
    name: "Bob Herm",
    company: "Accion Systems",
    city: "Tampa, US",
    state: "FL",
    date: "24/08/2022",
    type: "Aircraft",
    status: "$1.4 B",
  },
  {
    id: "4",
    name: "James Houston",
    company: "Voyager Space Holdings",
    city: "Tel Aviv, Israel",
    state: "TX",
    date: "13/06/2022",
    type: "Jets",
    status: "$800 M",
  },
];

export const dataSectorPerformance = [
  {
    id: "3",
    year: "2016",
    companies: [
      {
        name: "WWY",
        value: 80,
      },
      {
        name: "EDZ",
        value: 30,
      },
      {
        name: "WWY",
        value: 10,
      },
    ],
  },
  {
    id: "4",
    year: "2017",
    companies: [
      {
        name: "WWY",
        value: 65,
      },
      {
        name: "EDZ",
        value: 15,
      },
      {
        name: "WWY",
        value: 32,
      },
    ],
  },
  {
    id: "5",
    year: "2018",
    companies: [
      {
        name: "WWY",
        value: 20,
      },
      {
        name: "EDZ",
        value: 85,
      },
      {
        name: "WWY",
        value: 67,
      },
    ],
  },
  {
    id: "6",
    year: "2019",
    companies: [
      {
        name: "WWY",
        value: 90,
      },
      {
        name: "EDZ",
        value: 10,
      },
      {
        name: "WWY",
        value: 32,
      },
    ],
  },
  {
    id: "7",
    year: "2020",
    companies: [
      {
        name: "WWY",
        value: 55,
      },
      {
        name: "EDZ",
        value: 80,
      },
      {
        name: "WWY",
        value: 12,
      },
    ],
  },
  {
    id: "8",
    year: "2021",
    companies: [
      {
        name: "WWY",
        value: 40,
      },
      {
        name: "EDZ",
        value: 10,
      },
      {
        name: "WWY",
        value: 78,
      },
    ],
  },
  {
    id: "9",
    year: "2022",
    companies: [
      {
        name: "WWY",
        value: 55,
      },
      {
        name: "EDZ",
        value: 80,
      },
      {
        name: "WWY",
        value: 48,
      },
    ],
  },
];

export const precapdataTradedCompanies = [
  {
    id: "1",
    name: "Joe James",
    company: "Taal Enterprises Limited",
    city: "Mumbai, IN",
    state: "IN",
    date: "12/02/2022",
    type: "Aircraft",
    status: "$1 B",
  },
  {
    id: "2",
    name: "John Walsh",
    company: "Hindustan Aeronautics Limited",
    city: "Bengaluru, IN",
    state: "CT",
    date: "10/07/2021",
    type: "UAVs",
    status: "$2 B",
  },
  {
    id: "3",
    name: "Bob Herm",
    company: "Taneja Aerospace Aviation Limited",
    city: "Delhi, IN",
    state: "FL",
    date: "24/08/2022",
    type: "Aircraft",
    status: "$1.4 B",
  },
  {
    id: "4",
    name: "James Houston",
    company: "Global Vectra Helicorp Limited",
    city: "Bengaluru, IN",
    state: "TX",
    date: "13/06/2022",
    type: "Jets",
    status: "$800 M",
  },
];

const optionsbar = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};
const bardataStartups = {
  labels: [
    "2010",
    "2011",
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
  ],
  datasets: [
    {
      label: "No. of Startups",
      data: [10, 11, 14, 14, 28, 32, 70, 80, 110, 150, 155, 180, 215],
      backgroundColor: "#26ABA4",
    },
  ],
};

const bardata = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "data",
      data: [20, 50, 150, 250, 360, 450, 650],
      backgroundColor: "#26ABA4",
    },
  ],
};
const bardatapatents = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "data",
      data: [10, 30, 80, 120, 160, 200, 250, 280, 320],
      backgroundColor: "#26ABA4",
    },
  ],
};

const linedata = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      label: "Total Fund Asked",
      data: [20, 100, 200, 600, 500],
      fill: true,
      fill: "start",
      backgroundColor: () => {
        const ctx = document.getElementById("lineChart").getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 180);
        gradient.addColorStop(0, "rgba(129,107,255,1)");
        gradient.addColorStop(1, "rgba(129,107,255,0)");
        return gradient;
      },
      borderColor: "green",
    },
  ],
};
const linedataGloabal = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      label: "Total Fund Asked",
      data: [100, 450, 700, 350, 1400],
      fill: true,
      fill: "start",
      backgroundColor: () => {
        const ctx = document.getElementById("lineChart").getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 180);
        gradient.addColorStop(0, "rgba(129,107,255,1)");
        gradient.addColorStop(1, "rgba(129,107,255,0)");
        return gradient;
      },
      borderColor: "green",
    },
  ],
};
