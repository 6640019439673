import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import Dropdown from "../../../../common/dropdown";
import TextField from "@mui/material/TextField";
import UploadImg from "../../../../assets/images/logo/upload.svg";
import { useNavigate } from "react-router-dom";

const list = ["jasndkijas", "ajhsdbvuhasdb"];
const BasicDetails = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />

        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save as Next" className="borderbtn" />
          </div>
          <h4 className="market_h4">Basic Details</h4>
          <div className="cfp_wd">
            <div>
              <p className="reoffcard_p1">Legal Business Name </p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1">Business Headquarters </p>
              <Dropdown MenuList={list} />
            </div>
            <div>
              <p className="reoffcard_p1">Organization type</p>
              <Dropdown MenuList={list} />
            </div>
            <div>
              <p className="reoffcard_p1">Corporate Identification Number</p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1">DPIIT/DIPP Number</p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1">No of employees</p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1">Upload Pitch deck</p>
              <p className="cfp_p">
                Upload deck in PDF format.Maximum 15 slides <br />
                If you've already created a slide deck to fundraise, we'll use
                it to create your profile faster. or Visit Partners page{" "}
              </p>
              <TextField
                id="outlined-basic"
                type="file"
                inputProps={{
                  multiple: true,
                }}
                style={{
                  marginTop: "1rem",
                  background: "#F5F5F5",
                  border: "none !imporatant",
                  outline: "none !important",
                  width: "100%",
                }}
              />
            </div>
            <div>
              <p className="reoffcard_p1">Business logo</p>
              <p className="cfp_p">Size 250 x 150 pixels , maximum 200kb.</p>
              <button className="UploadBtn">
                <img src={UploadImg} className="w100" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton buttonText="Previous" className="borderbtn" />
        <FirstButton
          buttonText="Save and more"
          className="btn"
          onClick={() => navigate("/prequalify/contact")}
        />
      </div>
    </div>
  );
};

export default BasicDetails;
