import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import SwitchGreen from "../../../../common/Switch";
import { FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
const data = [
  {
    name: "Perk Name 1",
    description: "Item description",
    price: "Amount",
    date: "date",
  },
  {
    name: "Perk Name 2",
    description: "Item description",
    price: "Amount",
    date: "date",
  },
];
const MainDonationSetPerks = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/maincampdonations");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />

        <div className="cfp_cont1">
          <div className="cfp_wd1">
            <div className="precampbtncont1">
              <FirstButton buttonText="Save " className="borderbtn" />
            </div>

            <h4 className="market_h4">Set your Perks </h4>

            <div>
              <p className="counts_title">
                Perks are rewards provided to backers in exchange for their
                financial assistance. Up until a perk is claimed, you can alter
                the details of your perk.
              </p>
            </div>

            <div className="mdsp_tablecont">
              <table className="mdsp_table">
                <thead className="mdsp_tablehead">
                  <tr className="mdsp_tableheadtr">
                    <th>Items included</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Est.delivery</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="mdsp_body">
                  {data.map((item, key) => {
                    return (
                      <tr key={key} className="mdsp_tableheadtr">
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td>{item.price}</td>
                        <td>{item.date}</td>
                        <td>
                          <DeleteIcon className="mdsp_icon" />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="mdp_btncont2">
              <FirstButton
                buttonText="Create  New Perk"
                className="btn"
                onClick={() => navigate("/maincampdonations/perksdetails")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton buttonText="Previous" className="borderbtn" />
        <FirstButton
          buttonText="Save and next"
          onClick={handleClick}
          className="btn"
        />
      </div>
    </div>
  );
};

export default MainDonationSetPerks;
