import React from 'react';
import img01 from '../../../../assets/images/img/donateimg3.png'; 
import img02 from '../../../../assets/images/img/donateimg4.png'; 
const data  = [
  {
   tilte:"Pivot on fixes and Shipping Update",
   date: "April 20 2022",
   desc: "There were a couple of relatively minor issues with the production samples we were experiencing that seemed to not be rectifiable. But after reviewing and talking with the manufacturing base, we should be able to with some structural changes.",
   img: img01
  },
  {
   tilte:"Solving Sonic Welding and Shipment Update",
   date: "April 20 2022",
   desc: "Hope everyone has been remaining well this past month, and apologies for the delayed update, there have been notable issues with the sonic welding which we are close to solving. ",
   img: img02
  },
  {
   tilte:"100% funded",
   date: "April 20 2022",
   desc: "After a impressive responses from wellwishers and a successful precampaign  were live..!!  ",
   img: img01
  },
]
const UpdateAndNews = ()=>{
  return(
    <>
     <div className='updateAndNews'>
      <div className='updateAndNews_container'>
        <h1 className='brands_primary'>Updates & News</h1> 
        <div className='updates_container'>
          {
            data && data.map((item,index)=>(
              <div className='updates'>
                <div className='update_thumb'>
                  <img className='update_img' alt='' src={item.img}/>
                </div>
                <div className='update_content'>
                  <h1 style={{color:"#070908"}} className='tile_primary'>{item.tilte}</h1>
                  <p style={{color:"#070908"}} className='date'>{item.date}</p>
                  <p style={{color:"#070908"}} className='tile_secondary'>{item.desc}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
     </div>
    </>
  )
}
 
export default UpdateAndNews