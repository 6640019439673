import React from "react";
import img1 from "../../../../assets/images/img/port4.png";
import img2 from "../../../../assets/images/img/port5.png";
import img3 from "../../../../assets/images/img/port6.png";
import { Link } from "react-router-dom";
import FirstButton from "../../../../common/button";

const Donation = () => {
  const data = [
    {
      img: img1,
      organisation: "Nirr Bio ",
      date: "03-03-2022",
      amount: "₹ 1,000,00",
      perks: "1 Year Subscribtion of  Nirr bio  Packaged food",
    },
    {
      img: img2,
      organisation: "Prana Labs",
      amount: "₹ 45,000",
      date: "20-05-2022",
      perks: "Gift voucher",
    },
    {
      img: img3,
      organisation: "Farm Minds",
      amount: "₹ 20,000",
      date: "10-08-2022",
      perks: "No Perks",
    },
  ];
  return (
    <div style={{overflowX:"scroll"}}>
      <table className="mdsp_table tablebgcolor">
        <thead className="mdsp_tablehead">
          <tr className="mdsp_tableheadtr">
            <th>Organisation</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Perks choosen</th>
            <th>Reciept </th>
          </tr>
        </thead>
        <tbody className="mdsp_body">
          {data.map((item, key) => {
            return (
              <tr key={key} className="mdsp_tableheadtr">
                <td
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <img src={item.img} alt="" />
                  {item.organisation}
                </td>

                <td>{item.date}</td>
                <td>{item.amount}</td>
                <td>{item.perks}</td>
                <td>
                  <Link
                    style={{
                      fontWeight: "600",
                    }}
                    className="pricolor"
                  >
                    Download
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ textAlign: "center", margin: "2rem 0 2rem 0" }}>
        <FirstButton buttonText="View More" className="borderbtn" />
      </div>
    </div>
  );
};

export default Donation;
