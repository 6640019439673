import React from "react";
import Card from "../../../../common/cards/Card";
import FirstButton from "../../../../common/button";
import Img1 from "../../../../assets/images/img/mtpoimg1.png";
import Img2 from "../../../../assets/images/img/mtpoimg2.png";
import Img3 from "../../../../assets/images/img/mtpoimg3.png";
import Img4 from "../../../../assets/images/img/tech1.png";
import share from "../../../../assets/images/icons/share.png";
import doc from "../../../../assets/images/oicons/svg/document.svg";
import { Navigate, useNavigate } from "react-router-dom";

const tags = ["Space", "Defense", "Manufacturing "];
const TechOfferCampaign = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="toc_main">
        <div className="toc_cont">
          <div className="w50">
            <img src={Img4} width="100%" />
          </div>
          <div className="w50">
            <h1 className="mpto_h1">
              Adjustable wheel - Actively configurable wheel with incorporated
              suspension.
            </h1>
            <div className="toc_cont1">
              <p className="toc_f1">Request Number : A0083Z</p>
              <div className="toc_cont2">
                <button className="share_btn">
                  <img src={share} />
                </button>
                <FirstButton
                  buttonText="Add to watchlist"
                  className="borderbtn"
                />
              </div>
            </div>
            <div className="toc_cont3">
              {tags.map((item, index) => (
                <p className="scp_p2" key={index}>
                  {item}
                </p>
              ))}
            </div>
            <p className="toc_f2">
              The adjustable wheel features an adjustable hub position relative
              to the wheel rim. This extends the ability of small vehicles to
              travel on rough terrain by providing an alternative suspension
              system in a small package.
            </p>

            <div className="toc_cont2">
              <p className="toc_f2">Engagement Opportunity</p>
              <p className="toc_f2 toc_f3">
                Licensing, Partnership, Investment{" "}
              </p>
            </div>
            <div className="toc_btn">
              <FirstButton
                buttonText="Respond  Now"
                className="btn "
                onClick={() => navigate("/marketplace/submitresponsepage")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="toc_cont4">
        <div className="counts_container">
          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              20-04-2023
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Deadline
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              India, Europe
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Patent
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              India, USA
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Country of origin
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              Level 9
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              TRL Level
            </p>
          </div>
        </div>
      </div>
      <div className="toc_cont5">
        <div>
          <h2 className="cfp_mr2">Description of offer</h2>
          <p className="cfp_p">
            The adjustable wheel features an adjustable hub position relative to
            the wheel rim. This extends the ability of small vehicles to travel
            on rough terrain by providing an alternative suspension system in a
            small package.
            <br />
            It also provides the ability to change gear ratio/ride height of
            vehicles while stationary or in motion. Its high-speed configuration
            allows vehicles to 'jump' which can allow it to get out of
            situations where wheels can become stuck. The increased chassis
            stability also allows carrying of sensitive payloads.
          </p>
        </div>
        <div>
          <h2 className="cfp_f24">Technology </h2>
          <p className="cfp_p toc_mr">
            The adjustable wheel provides user with:
          </p>
          <ul className="cfp_p toc_ul">
            <li>adaptive suspension</li>
            <li>ride height adjustments</li>
            <li>gear ratio adjustments</li>
            <li>allows wheelbase adjustment</li>
            <li>using wheels to walking sideways</li>
            <li>vehicle tilting</li>
            <li>vehicle 'hopping'</li>
          </ul>
        </div>
        <div>
          <h2 className="cfp_f24">Application</h2>
          <p className="cfp_p">
            This technology can be applied by both robotic companies and wheel
            manufacturers in a variety of domains where wheels may need to adapt
            to changing terrains. Research institutions may also be interested
            in this technology for research and educational purposes.
            <br />
            The team is highly experienced in robotics design and development
            and have numerous awards and patents for innovative technologies.
          </p>
        </div>
        <div>
          <h2 className="cfp_f24">Intellectual property</h2>
          <p className="cfp_p">
            Pending patent for a component of this proprietary technology
          </p>
        </div>
        <div>
          <h2 className="cfp_f24">The team</h2>
          <p className="cfp_p">
            The team is highly experienced in robotics design and development
            and have numerous awards and patents for innovative technologies.
          </p>
        </div>
        <div className="toc_mr1">
          <h2 className="cfp_mr2">Documents</h2>
          <div>
            <div className="prequaapp_card">
              <div className="prequaapp_cont2">
                <p className="signupready_p3">
                  Response <br />
                  format
                </p>
                <img src={doc} alt="" width="80rem" height="80rem" />
              </div>
              <p className="prequaapp_p1">
              To submit your response, use this response format.
              </p>
              <div>
                <FirstButton buttonText="Download Format" className="btn" />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 className="cfp_mr2">Similar Technology offers</h2>
          <div className="toc_cont6">
            {cardData.map((item, index) => (
              <div key={index} className="wd30">
                <Card data={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="scpdetail_cont3 toc_mr3">
        <p className="brand_name">
          Could this technology provide you with a competitive edge?
        </p>
        <FirstButton buttonText="Respond Now " className="btn" onClick={()=>navigate("/marketplace/submitresponsepage")} />
      </div>
    </div>
  );
};

export default TechOfferCampaign;

const cardData = [
  {
    image: Img1,
    description:
      "A simple, visual sensor and digital platform that improves water and nutrient use by farmers.",
    title: "The Al Irrigation  -Chameleon Card",
    tags: ["Agriculture", "Food", "Digital"],
    engagements: "Licensing, Partnership, Investment  ",
    patientorigin: "India",
    patientownership: ["India", "Latam"],
    trllevel: "Four",
    daysleft: "40",
    numberofinterests: "14",
  },
  {
    image: Img2,
    description:
      "A superior agitator for mixing tanks in mineral processing plants.",
    title: "Wirl flow - superior agitator for mixing tanks",
    tags: ["Mining", "Minerals", "Environment"],
    engagements: "Licensing, Partnership, Joint Venture  ",
    patientorigin: "Europe",
    patientownership: ["India", "Europe"],
    trllevel: " Six",
    daysleft: "32 ",
    numberofinterests: "07",
  },

  {
    image: Img3,
    description:
      "Continuous assessment of plant water status with a static thermal imaging sensor.",
    title: "ProxiCrop",
    tags: ["Agriculture", "Food", "water"],
    engagements: "Tech transfer, Partnership, Investment ",
    patientorigin: "Australia",
    patientownership: ["Australia"],
    trllevel: "Eight",
    daysleft: "32 ",
    numberofinterests: "07",
  },
];
