import React from "react";
import { useNavigate } from "react-router-dom";
import FirstButton from "../../../../common/button";

const PremiumFAQ = ()=>{
  const navigate=useNavigate()
  const handleNavigate=()=>{
    navigate("/help/faq")
  }
  return (
    <>
    <div style={{marginBottom:"0",backgroundColor:"#fff"}} className="faq">
      <div className="faq_container">
        <h1 className="faq_primary faq_color">Market Place FAQ</h1>
        <div className="questions">
          <div className="question_container--1">
            <div className="question1">
              
              <h1 className="question faq_color">What is Smaarket?</h1>
              <p className="answer faq_color">There is no better location to begin the search for fascinating and creative businesses and inventions that surprise and amaze with live crowdfunding campaigns and a marketplace.</p>
            </div>
            <div className="question2">
              <h1 className="question faq_color">How much is the success fee?</h1>
              <p className="answer faq_color ">Based on the positive outcome above the subscription fee, we charge between 3% and 12%.</p>
            </div>
          </div>

          <div className="question_container--2">
            <div className="question1">
              <h1 className="question faq_color">What happens to my data?</h1>
              <p className="answer faq_color">Your data is yours and yours alone. We do not reveal your identity or grant third-party applications or companies access to the platform. We do not sell or rent data.</p>
            </div>
            <div className="question2">
              <h1 className="question faq_color">Why should I subscribe to a Premium plan?</h1>
              <p className="answer faq_color">We do not advertise, sell, or rent data, nor do we make data available to third-party applications. As such, we charge a small subscription fee to cover the cost of providing this service.</p>
            </div>
          </div>
        </div>
        <FirstButton className="borderbtn" buttonText="View More" marginTop="5rem" onClick={handleNavigate}/>
      </div>
    </div>
    </>
  )
}

export default PremiumFAQ