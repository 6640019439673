import React from "react";
import Dropdown from "../../../../../common/dropdown";
import Select from "react-select";
import FirstButton from "../../../../../common/button";
import send from "../../../../../assets/images/icons/send.svg";
const Discussion = () => {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
  ];

  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      background: "#f5f5f5",
      fontSize: "1.3rem",
      fontWeight: "500",
      padding: ".6rem 0",
    }),

    menuList: (styles) => ({
      ...styles,
      background: "#fff",
      borderRadius: "5px",
      boxShadow: "0 1rem 2rem rgba(0,0,0,.1)",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      fontSize: "1.3rem",
      background: isFocused ? "#19976A" : isSelected ? "#fff" : undefined,
      zIndex: 1,
      color: isFocused ? "#fff" : isSelected ? "#000" : undefined,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <>
      <div className="discussion">
        <div className="discussion_container">
          <h1 className="dis_primary" style={{ margin: "2rem 0" }}>
            Ask your queries to the Founder
          </h1>
          <p className="dis_secondary">
            <span style={{ fontWeight: "700" }}>Important notice.</span> None of
            the information in the Discussion constitutes part of the campaign
            and it has not been reviewed or approved by Smaarket. Be Kind and
            respectful to views.Do not defame or deliberately misrepresent
            information for your own self-interest.
          </p>
          <p className="dis_secondary" style={{ margin: "2rem 0" }}>
            We reserve the right to remove any communications that you post that
            we consider to be abusive, trolling, spam, or otherwise
            inappropriate.
          </p>
          <div style={{ margin: "2rem 0" }}>
            <p className="mcdt_p2">What is Your Question?</p>
            <div className="discussion_send">
              <input className="login_input" placeholder="Type here" />
              <img src={send} />
            </div>

            <i className="fa-solid fa"></i>
          </div>
          <div style={{ display: "flex" }}>
            <div className="w90"></div>
            <div className="w10">
              <Select
                name="filter"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={colourStyles}
                placeholder="sort by"
              />
            </div>
          </div>

          <div className="discussion_content">
            <div className="discussion_content--heading">
              <p className="dis_name">Dasan kartha</p>
              <div style={{ margin: "0 1rem" }} className="tag">
                <p className="tag_label">INVESTOR</p>
              </div>
              <p className="dis_date">23 June 2022</p>
              <i className="fa-solid fa-flag"></i>
            </div>
            <p className="dis_comment">
              Hi. When is the last day to invest in ?
            </p>
            <div className="dis_reply">
              <div className="discussion_content--heading">
                <p className="dis_name">Ankit Malholtra</p>
                <div style={{ margin: "0 1rem" }} className="tag">
                  <p className="tag_label">CEO</p>
                </div>
                <p className="dis_date">23 June 2022</p>
                <i className="fa-solid fa-flag"></i>
              </div>
              <p className="dis_comment">
                How long the campaign remains open will depend on investor
                demand, though we certainly suggest completing your investment
                as early as you are able.
              </p>
            </div>
          </div>

          <div className="discussion_content">
            <div className="discussion_content--heading">
              <p className="dis_name">Rama Rao</p>
              <p className="dis_date">23 June 2022</p>
              <i className="fa-solid fa-flag"></i>
            </div>
            <p className="dis_comment">
              Noting the close relationship with Wavemaker and Miso, will your
              product(s) be made available and become a platform across
              industries for other customers?
            </p>
            <div className="dis_reply">
              <div className="discussion_content--heading">
                <p className="dis_name">Ankit Malholtra</p>
                <div style={{ margin: "0 1rem" }} className="tag">
                  <p className="tag_label">CEO</p>
                </div>
                <p className="dis_date">23 June 2022</p>
                <i className="fa-solid fa-flag"></i>
              </div>
              <p className="dis_comment">
                Hi Rama! If you want to become a customer or know someone who
                does, please send a note to info@allyrobotics.com.
              </p>
            </div>
          </div>

          <div className="discussion_content">
            <div className="discussion_content--heading">
              <p className="dis_name">Pichachi Bundar</p>
              <div style={{ margin: "0 1rem" }} className="tag">
                <p className="tag_label">INVESTOR</p>
              </div>
              <p className="dis_date">15 February 2022</p>
              <i className="fa-solid fa-flag"></i>
            </div>
            <p className="dis_comment">
              Cool project! Regarding competition, what do you think about
              https://www.abcdef.com ?
            </p>
            <div className="dis_reply">
              <div className="discussion_content--heading">
                <p className="dis_name">Ankit Malholtra</p>
                <div style={{ margin: "0 1rem" }} className="tag">
                  <p className="tag_label">CEO</p>
                </div>
                <p className="dis_date">17 February 2022</p>
                <i className="fa-solid fa-flag"></i>
              </div>
              <p className="dis_comment">
                Thank you. We're not going to comment on other products and
                companies. Our customers are excited about what we're developing
                and from industry leaders we've repeatably been told variations
                of, "there's nothing like what you're doing on the market".
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FirstButton
              marginTop={"2rem"}
              buttonText="Learn more"
              className="greenBorder"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Discussion;
