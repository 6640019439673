import React from "react";
import { Link } from "react-router-dom";
import Party from "../../../../assets/images/oicons/svg/congrats.svg";
const Finalpage = () => {
  return (
    <div className="prequalend">
      <img src={Party} alt="" className="w20" />
      <p className="final_p1">
        Congratulations! <br />
        Your Pre Campaign has been successfully submitted.
      </p>
      <p className="font_p1 preQualend">
        The review process status will be updated in your dashboard as
        applicable.
        <br />
        Keep the primary contact active and approachable all the time.
      </p>

      <Link to="/dashstartmaincamp" className="font_p1 linkcolor">
        Go to Dashboard
      </Link>
    </div>
  );
};

export default Finalpage;
