import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import scrollto from "../../../assets/images/oicons/svg/scrollup.svg";
const FoundersFaq = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  const scrolltotop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {question.map((item, index) => (
        <Accordion
          key={index}
          style={{ boxShadow: "none", border: "none", padding: "0" }}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#19976A", fontSize: "1.6rem" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ padding: "0 !important", border: "0 !important" }}
          >
            <p className="faq_f3 faq_f600">{item.ques}</p>
          </AccordionSummary>

          <AccordionDetails>
            <div
              className="faq_f3"
              dangerouslySetInnerHTML={{ __html: item.ans }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="dflex alcenter faq_mr3">
        <p className="faq_f3">
          If you cant find what youre looking for write to us.{" "}
          <span>
            {" "}
            <Link className="faq_f600">Email us </Link>{" "}
          </span>
        </p>
        <img src={scrollto} onClick={scrolltotop} />
      </div>
    </div>
  );
};

export default FoundersFaq;

const question = [
  {
    ques: "What is Smaarket?",
    ans: "There is no better location to begin the search for fascinating and creative businesses and inventions that surprise and amaze with live crowdfunding campaigns and a marketplace.",
  },
  {
    ques: "Why raise funds from the crowd?",
    ans: "Make your most loyal customers owners. They are often your most passionate evangelists. Investors have a financial interest in helping your business succeed. For example, if you have an EVbike facility and your customer is also an investor, she will most likely purchase one or more and bring her friends with her.",
  },
  {
    ques: "How are you better than your competitors?",
    ans: "We deliver more money, faster. We're the largest funding portal by amount raised, number of companies funded, number of investors, and most follow-on financing by venture capitalists.",
  },
  {
    ques: "Besides money, how else do you help?",
    ans: "Smaarket created the X to help founders get off the ground and succeed, by immersing them in a community of other founders. X is now a separate company, but all Smaarket alumni are members who get: Access to an online community of other founders and mentors Help with fundraising after Smaarket. Invites to events and workshops Review of Accelerator Program Applications.",
  },
  {
    ques: "What we'll do vs. what we'll need from you What you'll do for your Smaarket round:",
    ans: "Create your Smaarket pitch. You can upload your slide deck or create one from scratch. Bring the crowd. Invite customers, friends, family, and colleagues to invest in your business. Provide information about your business as required. What we'll do: Provide ready-to-use standard contracts and a standard SAFE. Polish your pitch to make it as straightforward and compelling as possible. Connect to our partner services Promote your round to our community of investors. Handle all investment transactions and provide tools that allow you to easily engage with investors post-close. Generate and review financials when you're ready to close your round.",
  },
  {
    ques: "Can a non-Indian company fundraise on smaarket?",
    ans: "No. Unfortunately, we can only accept companies formed in India through our India funding portal",
  },
  {
    ques: "Is my company a good fit for Smaarket?",
    ans: "From Agritech to Spacetech, we have funded it all. <br/> Companies that fit one of these categories often raise money successfully: <br/> Do you have a sizable network of friends, supporters, fans, or customers? Most businesses attract 50% of their investors. <br/> Are you doing anything unique that no one else has done? Investors are enthralled by the prospect of being a part of something ambitious. Is your product directly beneficial to people's lives? People like to invest in items that benefit the world. Do you have at least one investor who is investing under the same conditions? <br/> Have you gained any traction? Investors want proof that you can deliver.",
  },
  {
    ques: "Am I prepared to fundraise on Smaarket?",
    ans: "Before seeking funds from the general public, we propose that you contact certain possible investors you already know and secure their commitment. Why would a stranger invest in you if the people who know you best will not do so? If you can raise INR 1 crore or more from people you already know, it is far more probable that you will be successful on Smaarket.",
  },
  {
    ques: "How long will it take for my fundraising campaign to go live?",
    ans: "The pre-qualification process takes seven days, the pre-campaigning process takes 21 days, and the primary fundraising campaign takes 30 to 90 days. <br/> Supporters can begin adding rounds of the pre-campaign to their wishlist right away. These reservations are not legally binding, and no money has been received to you. After we assist you in completing your legal work, investors will be prompted to confirm their main campaign investments.",
  },
  {
    ques: "What do you charge?",
    ans: "Smaarket charges 7.5% of the total raised, but only if successful. +0.5% ownership in the company For instance, if a company raises INR 10,000,000, we charge INR 7,50000 + 0.5% of ownership in the company upon closing. There are no other fees. If you find a better price, we will match it.",
  },
  {
    ques: "How do I finish my raise?",
    ans: "You may close your round at any moment once your funding goal has been fulfilled and at least 21 days have passed.  Investors are given a 5-day warning before closure to cancel their investment; after that, their funds are locked in, and you'll work closely with our closing team to get your payment.",
  },
  {
    ques: "What if my raise fails?",
    ans: "Unfortunately, if your raise fails, you will not be able to run another one on Smaarket unless you can show that you've passed a critical milestone. Customer growth, the inclusion of new distribution channels, the introduction of new items, and other factors could all contribute to this.",
  },
  {
    ques: "Can I extend my funding deadline?",
    ans: "Yes. We provide a one-time extension of up to 90 days.",
  },
  {
    ques: "What updates will investors expect?",
    ans: "Updates are not required by law, but you should plan to inform your investors about your growth, sales, new partnerships, hires, and other things at the company at least quarterly.",
  },
  {
    ques: "What are the product stages in donation?",
    ans: "There are four product stages: <br/> Concept Stage: Campaigns in the concept stage have an idea for a physical perk that the campaigner intends to create but have yet to create a physical prototype. They may have mockups, drawings, or 3D renderings demonstrating the perk’s appearance or functionality once it is produced. Very basic prototypes (such as those composed of clay, wood, or paper) can also demonstrate that your campaign is in the concept stage. All Tech & Innovation campaigns that opt-in to product stages will default to concept stages. <br/> Prototype: Campaigns in the prototype stage have a working version of their physical perk. The campaign’s video successfully demonstrates the performance of core functions and working features as described in the campaign story. To be approved for the prototype stage, campaigners must embed a high-quality video showing a working prototype on their campaign page. The video provided on the campaign page must demonstrate the key features and functionality of the physical prototype. 1,<br/> Production: Campaigns in the production stage have begun the process of turning their prototype into a mass-produced perk. In order to be approved for production, campaigners need to provide clear evidence that their perk is being manufactured at scale.<br/> Shipping: Campaigns in the shipping stage currently have the inventory to ship perks to their backers and have begun actively shipping contributions to their backer community. In order to be approved for the shipping stage, proof of visual preparation for shipping, such as completed components or packaging, is required. If a campaigner is using Post, once 80% of their contributions’ tracking numbers have been uploaded, their campaign will be approved for the shipping stage. Keep in mind that all projects must meet Smaarket Terms of Use.",
  },
  {
    ques: "What campaigns fall under the 'Donation category' category?",
    ans: "Energy and Green Technology, Food & Beverages, Health & Fitness, Home Productivity, Transportation.",
  }
];
