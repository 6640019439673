import React from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { Nav } from "react-bootstrap";

const Sidebar = () => {
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  return (
    <div className="sidebar_main">
      <div className="sidebar_cont1">
        <nav className="nav-wd">
          <div className="nav_cont">
            <Link to="/maincampcreation/baiscdetails">
              <div
                className={
                  splitLocation[2] === "baiscdetails"
                    ? "active"
                    : "sidebarNav_item"
                }
              >
                Basic
              </div>
            </Link>
            <div
              className={
                splitLocation[2] === "pitch" ? "active" : "sidebarNav_item"
              }
            >
              <Link to="/maincampcreation/pitch">Pitch</Link>
            </div>

            <div
              className={
                splitLocation[2] === "aboutus" ? "active" : "sidebarNav_item"
              }
            >
              <Link to="/maincampcreation/aboutus">About Us</Link>
            </div>

            <div
              className={
                splitLocation[2] === "dealterms" ? "active" : "sidebarNav_item"
              }
            >
              <Link to="/maincampcreation/dealterms">Deal Terms</Link>
            </div>

            <div
              className={
                splitLocation[2] === "financials" ? "active" : "sidebarNav_item"
              }
            >
              <Link to="/maincampcreation/financials">Financials</Link>
            </div>

            <div
              className={
                splitLocation[2] === "analytics" ? "active" : "sidebarNav_item"
              }
            >
              <Link to="/maincampcreation/analytics">Analytics</Link>
            </div>

            <div
              className={
                splitLocation[2] === "finish" ? "active" : "sidebarNav_item"
              }
            >
              <Link to="/maincampcreation/finish">Finish</Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
