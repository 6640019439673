import React from "react";
import IMG01 from "../../../assets/images/comp.png";
import ProgressBar from "../../../common/progressBar/Progress";
const data = {
  amount: "76,00,000",
  percentage: 156.2,
  status: "Campaign completed successful",
  duration: "60 Days of total campaign",
  color: "#105039",
  bgcolor: "#19976A",
  value: "60",
};
const SearchDetailsMain = () => {
  return (
    <>
      <div className="searchMain p-13">
        <div className="searchMain_container">
          <div className="searchMain_container--1">
            <img style={{ width: "100%" }} alt="" src={IMG01} />
          </div>
          <div className="searchMain_container--2">
            <h1 className="comp_heading">Panwar Aero</h1>
            <div className="comp_details">
              <p className="comp_link">www.panwar.com</p>
              <div className="comp_social">
                <i className="fa-brands fa-twitter comp_social_icon"></i>
                <i className="fa-brands fa-linkedin-in comp_social_icon"></i>
                <i className="fa-brands fa-google comp_social_icon"></i>
                <i className="fa-brands fa-facebook-f comp_social_icon"></i>
              </div>
            </div>
            <div className="tags">
              <div style={{ backgroundColor: "#fcfcfc" }} className="tag">
                <p className="tag_label">Mobility</p>
              </div>
              <div style={{ backgroundColor: "#fcfcfc" }} className="tag">
                <p className="tag_label">Transformation</p>
              </div>
              <div style={{ backgroundColor: "#fcfcfc" }} className="tag">
                <p className="tag_label">CCD</p>
              </div>
            </div>
            <p style={{ color: "#070908" }} className="secondaryText">
              We are designing and building an all-electric self-charging long
              range aircraft for passengers, cargo, and medical supplies.
            </p>
            <p style={{ color: "#070908" }} className="secondaryText">
              Our proprietary technology (owned by Panwar Group, LLC) that only
              requires a small amount of battery pack to process air to generate
              enough power to fly the aircraft for several hours.We are doing
              something no one else is doing.....
            </p>
            <div style={{ margin: "2rem 0" }}>
              <ProgressBar data={data} />
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#070908" }} className="counts">
        <div className="counts_container">
          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              CCD
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Type of Instrument
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              10%
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Discount
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              February 28th 2022
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              End Date
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              ₹ 10,000
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Min Investment
            </p>
          </div>

          <div className="counts_content">
            <h1 style={{ color: "#fcfcfc" }} className="counts_data">
              ₹ 70,000,000
            </h1>
            <p style={{ color: "#fcfcfc" }} className="counts_title">
              Target
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchDetailsMain;
