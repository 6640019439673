import React from "react";
import Counts from "../components/Home/Counts";
import DonationCards from "../components/LandingPages/marketplacePage/DonationCardsSection";
import MarketPlaceMain from "../components/LandingPages/marketplacePage/MarketPlaceMain";
import MarketPlaceStory from "../components/LandingPages/marketplacePage/MarketPlaceStory";
import ShowCards from "../components/LandingPages/marketplacePage/ShowCards";
import MarketPlaceReview from "../components/LandingPages/marketplacePage/MarketPlaceReview";
import MarketPlaceSuccess from "../components/LandingPages/marketplacePage/MarketPlaceSuccess";
import MarketPlaceFAQ from "../components/LandingPages/marketplacePage/MarketPlaceFAQ";
import Offers from "../components/LandingPages/marketplacePage/Offer";
const MarketPlacePage = ()=>{
  return (
    <>
    
    <MarketPlaceMain/>
    <Counts />
    <DonationCards/>
    <Offers/>
    <MarketPlaceStory/>
    <ShowCards/>
    {/* <MarketPlaceReview/> */}
    <MarketPlaceSuccess/>
    <MarketPlaceFAQ/>

    </>
  )
}

export default MarketPlacePage