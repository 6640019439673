import React, { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import FirstButton from "../../../../common/button";
import SwitchGreen from "../../../../common/Switch";
import { FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";

const List = [
  "I Understand Smaarket does not provide legal, financial or tax advice of any kind you will consult a professional adviser.",
  "I have the sophistication to evaluate investment Campaign on Smaarket on my own.",
  "I agree to the Terms, and Privacy Policy.",
];
const PreAppFinish = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleClick = () => {
    navigate("/prequalify/end");
  };
  return (
    <div>
      <div className="cfp_main">
        <Sidebar />

        <div className="cfp_cont1">
          <div className="precampbtncont1">
            <FirstButton buttonText="Save and Exit" className="borderbtn" />
          </div>
          <h4 className="market_h4">Almost Done !!</h4>
          <div className="cfp_wd">
            <div>
              <p className="reoffcard_p1 prequal_mr">
                Do you have a referal code ?{" "}
              </p>
              <p className="signupready_p5">
                {" "}
                Referrals get priority screening.
              </p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
            </div>
            <div>
              <p className="reoffcard_p1 prequal_mr">
                Estimated Main Campaign launch date{" "}
              </p>
              <p className="signupready_p5">
                {" "}
                We recommend at least 30 days of pre-campaign before the main
                campaign launches. Select below the main campaign launch date.
              </p>
              <input
                type="text"
                className="login_input"
                placeholder="Type Here"
              />
              <p
                className="font_p2"
                style={{ textAlign: "center", color: "#198097" }}
              >
                28 days to go for launch
              </p>
            </div>
            <div>
              <p className="reoffcard_p1 prequal_mr">Terms and conditions </p>
              {List.map((item, index) => (
                <FormControlLabel
                style={{alignItems:"start"}}
                  label={<p className="risk_label">{item}</p>}
                  control={<SwitchGreen sx={{ m: 1 }} />}
                  key={index}
                  onChange={handleChange}
                  sx={{ m: 1 }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="precampbtncont">
        <FirstButton
          buttonText="Previous"
          className="borderbtn"
          onClick={() => navigate("/prequalify/questions")}
        />
        <FirstButton
          buttonText="Preview and Submit"
          onClick={handleClick}
          className="btn"
        />
      </div>
    </div>
  );
};

export default PreAppFinish;
