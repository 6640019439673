import React from 'react';
import Main from '../components/Home/Main';
import Counts from '../components/Home/Counts';
import Story from '../components/Home/Story';
import Brands from '../components/Home/Brands';
import Review from '../components/Home/Review';
import FAQ from '../components/Home/FAQ';
import News from '../components/Home/News';
import BottomBanner from '../components/Home/BottomBanner';
const Home = ()=> {
  return (
    <>
     <Main/>
     <Counts/>
     <Story/>
     <Brands />
     <Review />
     <FAQ/>
     <News/>
     <BottomBanner/>
    </>
  )
} 

export default Home;