import React, { useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Perks from "./Steps/Perks";
import Address from "./Steps/Address";
import Payment from "./Steps/Payment";
import FirstButton from "../../../../common/button";
import { useNavigate } from "react-router-dom";

const steps = ["Perks", "Address", "Payment"];

const DonateMainPerk = () => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const totalSteps = () => {
    return steps.length;
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    handleNext();
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="mpto_main">
      {activeStep === 0 && (
        <div>
          <h1 className="p_f3">Donate to Hugg initiative</h1>
          <p className="p_f4">Your details are encrypted and secured.</p>
        </div>
      )}
      {activeStep === 1 && (
        <div>
          <h1 className="p_f3">Donate to Smart farmer initiative</h1>
          <p className="p_f4">
            To Raise through crowdfunding, the law requires that we collect some
            information.
            <br />
            Your details are encrypted and secured.
          </p>
        </div>
      )}
      {activeStep === 2 && (
        <div>
          <h1 className="p_f3">Donate to Hugg initiative</h1>
          <p className="p_f4">.Your details are encrypted and secured..</p>
        </div>
      )}

      <div className="dmp_stepcont">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton className="stepButton" onClick={handleStep(index)}>
                <span className="dmp_f1 dmp_fw "> {label}</span>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div>
      {activeStep === 0 && (
        <>
          <Perks />
          <div className="precampbtncont ptp_btncont">
            <FirstButton buttonText="Previous" className="borderbtn" onClick={() => navigate("/investment/donatedetail")} />
            <FirstButton
              buttonText="Add and Proceed"
              onClick={handleComplete}
              className="btn"
            />
          </div>
        </>
      )}
      {activeStep === 1 && (
        <>
          <Address />
          <div className="precampbtncont ptp_btncont add_btncont">
            <FirstButton buttonText="Previous" className="borderbtn" onClick={handleBack} />
            <FirstButton
              buttonText="Add and Continue to Payment"
              onClick={handleComplete}
              className="btn"
            />
          </div>
        </>
      )}
      {activeStep === 2 && <><Payment />
        <div className="precampbtncont ptp_btncont">
          <FirstButton buttonText="Previous" className="borderbtn" onClick={handleBack} />
          <FirstButton
            buttonText="Proceed to Pay"
            className="btn"
            onClick={() => navigate("/investment/donatecampend")}
          />
        </div></>}
    </div>
  );
};

export default DonateMainPerk;
