import React from "react";
import FirstButton from "../../../../../common/button";
import Img from "../../../../../assets/images/img/pcard1.png";
import Img1 from "../../../../../assets/images/img/pcard2.png";
import Img2 from "../../../../../assets/images/img/pcard3.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const Perks = () => {
  return (
    <div style={{ textAlign: "start" }}>
      <h1 className="p_f1">Choose your perks</h1>
      <p className="p_f2">
        You are supporting a work-in-progress so it is the campaigner's
        responsibility to contact you if your preferences are no longer
        available.
      </p>
      <div className="dpp_cont flex-col">
        {carddata.map((item, index) => (
          <div className="pcard_main" key={index}>
            <img src={item.img} alt="" className="w100" />
            <h1 className="pcard_f2 pcard_fw pcard_mr">{item.title}</h1>
            <div className="pcard_mr">
              <span className="pcard_f1 pcard_fw ">{item.price}</span>
              <span className="pcard_f1 pcard_color">
                {" "}
                <strike>{item.actualprice} </strike> ({item.discount}off)
              </span>
            </div>
            <p className="pcard_f1">*Smaarket Special reward*</p>
            <div className="pcard_mr">
              <p className="pcard_f1 pcard_fw">Items Included</p>
              <p className="pcard_f1 ">{item.included}</p>
            </div>
            <div className="pcard_mr">
              <p className="pcard_f1 pcard_fw">Shipping Locations</p>
              <p className="pcard_f1 ">{item.location}</p>
            </div>
            <div className="pcard_mr">
              <p className="pcard_f1 pcard_fw">Estimate Shipping</p>
              <p className="pcard_f1">{item.estdate}</p>
            </div>
            <p
              className="pcard_f1 pcard_fw pcard_mr"
              style={
                item.invetleft === "Sold out"
                  ? { color: "#C65353" }
                  : { color: "#19976A" }
              }
            >
              {item.invetleft}
            </p>

            <FirstButton
              buttonText={item.btntext}
              width="100%"
              className={item.btntext === "Sold Out" ? "btngrey" : item.btntext === "Get this perk" ? "borderbtn" : item.btntext === "Selected" ? "btn" : ""}
            />
            {item.btntext === "Selected" ? (
              <div>
                <p className="pcard_f1 pcard_fw pcard_mr">Choose Color</p>
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <p>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Radio
                          sx={{
                            color: "#19976A",
                            "&.Mui-checked": { color: "#19976A" },
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: "1.6rem" }}>Ocean Blue</span>
                      }
                    />
                  </p>
                  <p>
                    <FormControlLabel
                      value="no"
                      control={
                        <Radio
                          sx={{
                            color: "#19976A",
                            "&.Mui-checked": { color: "#19976A" },
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                          }}
                        />
                      }
                      label={
                        <span style={{ fontSize: "1.6rem" }}>Black Coffee</span>
                      }
                    />
                  </p>
                </RadioGroup>
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>

      <div className="p_mr">
        <h1 className="pcard_f2">Donate for “Hugge” cause without a perk</h1>
        <div className="mdp_cont2">
          <p className="mcdt_p2">Back it without Perk</p>
          <div className="mcdt_p2">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    sx={{
                      color: "#19976A",
                      "&.Mui-checked": { color: "#19976A" },
                      "& .MuiSvgIcon-root": { fontSize: 24 },
                    }}
                  />
                }
                label={<span style={{ fontSize: "1.6rem" }}>Yes</span>}
              />{" "}
              <FormControlLabel
                sx={{ fontSize: 16 }}
                value="no"
                control={
                  <Radio
                    sx={{
                      color: "#19976A",
                      "&.Mui-checked": { color: "#19976A" },
                      "& .MuiSvgIcon-root": { fontSize: 24 },
                    }}
                  />
                }
                label={<span style={{ fontSize: "1.6rem" }}>No</span>}
              />
            </RadioGroup>
          </div>
        </div>
        <div>
          <h3
            className="ptp_h3"
            style={{ fontSize: "1.6rem", fontWeight: "400" }}
          >
            Contribution Amount
          </h3>
          <input className="login_input" placeholder="Enter" />
          <p className="ptp_p1 ptp_center">Ten thousand Rupees</p>
        </div>{" "}
        <div></div>
      </div>
    </div>
  );
};

export default Perks;

const carddata = [
  {
    img: Img,
    title: "hugg",
    price: "₹ 15,000",
    actualprice: "₹ 25,000",
    discount: "25% ",
    included: " huggs x 1",
    location: "Ships Worldwide",
    estdate: "31st February 2023",
    invetleft: "Sold out",
    btntext: "Sold Out",
  },
  {
    img: Img1,
    title: "Couple of huggs ",
    price: "₹ 25,000",
    actualprice: "₹ 50,000",
    discount: "50% ",
    included: " huggs x 2",
    location: "Ships Worldwide",
    estdate: "31st February 2023",
    invetleft: "Only 1 left",
    btntext: "Get this perk",
  },
  {
    img: Img2,
    title: "8 X hugg's",
    price: "₹ 1,00,000",
    actualprice: "₹ 2,00,000",
    discount: "50% ",
    included: " huggs x 8",
    location: "Ships Worldwide",
    estdate: "31st February 2023",
    invetleft: "Only 3 left",
    btntext: "Selected",
  },
];
