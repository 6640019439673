import React, { useState } from "react";
import Sidebar from "../../SidebarforProfiler/Sidebar";
import { FormControlLabel } from "@mui/material";
import SwitchGreen from "../../../../common/Switch";
import FirstButton from "../../../../common/button";
import { Link } from "react-router-dom";
import DeleteModal from "./DeleteModal";

const risksList = [
  {
    label:
      "Get reminders about your incomplete investments and alerts when deals you follow are closing.",
  },
  {
    label:
      "Get notified when new companies start fundraising and New product on market place on Smaarket.",
  },
  {
    label:
      "Get notified when an investor or founder sends you a private message.",
  },
  {
    label:
      "Receive our weekly newsletter with new companies, events, news & more.",
  },
];
const Settings = () => {
  const [show, setShow] = useState(false);

  const handleClickOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <div className="cfp_main">
      <Sidebar />

      <div className="cfp_cont1">
        <h4 className="market_h4">Settings</h4>
        <div>
          <p className="market_p">Notifications</p>
          {risksList.map((item, index) => (
            <FormControlLabel
              label={<p className="risk_label">{item.label}</p>}
              control={<SwitchGreen sx={{ m: 1 }} />}
              key={index}
              onChange={handleChange}
              sx={{ m: 1 }}
            />
          ))}
        </div>

        <div className="settings_cont">
          <h4 className="cfp_h4 settings_h4">Password</h4>
          <p className="cfp_p">Enter New password</p>
          <input placeholder="Enter" type="text" className="login_input" />
          <p className="cfp_p">Confirm New password</p>
          <input placeholder="Type here" type="text" className="login_input" />
          <p className="cfp_p">Current password</p>
          <input placeholder="Enter" type="text" className="login_input" />
          <div className="postbtncont">
            <FirstButton buttonText="Update" className="btn" />
          </div>
        </div>

        <div className="settings_cont">
          <h4 className="cfp_h4 settings_h4">Delete Account</h4>
          <p className="cfp_p">
            For your security, please enter your current password to confirm
            account deletion.
          </p>
          <input placeholder="Type here" type="text" className="login_input" />
          <div className="setting_btncont">
            <Link className="brand_name">Forget Password</Link>
            <FirstButton
              buttonText="Delete Account"
              className="btnred"
              onClick={handleClickOpen}
            />
          </div>
        </div>
      </div>
      <DeleteModal show={show} close={handleClose} />
    </div>
  );
};

export default Settings;
