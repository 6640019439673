import React from "react";
import { useNavigate } from "react-router-dom";
import FirstButton from "../../common/button";

const FAQ = ()=>{
  const navigate=useNavigate()
  const handleNavigate=()=>{
    navigate("/help/faq")
  }
  return (
    <>
    <div className="faq">
      <div className="faq_container">
        <h1 className="faq_primary"> FAQ</h1>
        <div className="questions">
          <div className="question_container--1">
            <div className="question1">
              <h1 className="question">What is Smaarket? </h1>
              <p className="answer">There is no better location to begin the search for fascinating and creative businesses and inventions that surprise and amaze with live crowdfunding campaigns and a marketplace.</p>
            </div>
            <div className="question2">
              <h1 className="question">What distinguishes Smaarket from the stock exchange? </h1>
              <p className="answer">Startups on Smaarket are substantially younger than those listed on the Stock Exchange. Here are some significant differences: It is far riskier. Win big or lose everything. Hold for the long run. .</p>
            </div>
          </div>

          <div className="question_container--2">
            <div className="question1">
              <h1 className="question">Why raise funds from the crowd?</h1>
              <p className="answer">Make your most loyal customers owners. They are often your most passionate evangelists. Investors have a financial interest in helping your business succeed. For example, if you have an EVbike facility and your customer is also an investor, she will most likely purchase one or more and bring her friends with her.</p>
            </div>
            <div className="question2">
              <h1 className="question">How much money can I invest?</h1>
              <p className="answer">As part of our commitment to broadening investing access,Smaarket allows investments starting at ₹ 50,000 —the lowest in the industry. Ultimately, every startup fundraising on Smaarket sets its own minimum investment. </p>
            </div>
          </div>
        </div>
        <FirstButton className="whitebtn" buttonText="View More" marginTop="5rem" onClick={handleNavigate}/>
      </div>
    </div>
    </>
  )
}

export default FAQ